import React, { useEffect, useState } from 'react';
import { getUserName } from '../utils/userUtils';
import CostList from './CostList';
import AddCostModal from './modals/AddCostModal';
import EditCostModal from './modals/EditCostModal';

const Costs = (props) => {
  const { caseData, user } = props;
  const [employees, setEmployees] = useState([]);
  const [showAddCostModal, setShowAddCostModal] = useState(undefined);
  const [showEditCostModal, setShowEditCostModal] = useState(undefined);
  const [reinitializing, setReinitializing] = useState(false);

  useEffect(() => {
    initEmployees();
  }, []);

  const initEmployees = async () => {
    if (!user || !user.userType) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getUsers(); // change to employees only
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      setEmployees(
        res.result.map((emp) => {
          return {
            label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
            value: emp.id,
          };
        })
      );
    }
  };

  const openAddCostModal = () => {
    setShowAddCostModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowAddCostModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
    });
  };

  const openEditCostModal = (costId, onEdit = false) => {
    setShowEditCostModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowEditCostModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
      costId: costId ? costId : {},
      onEdit,
    });
  };

  const reinitialize = (bool = true) => {
    setReinitializing(bool);
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Case Cost</h4>
        <button
          className="btn btn-sm btn-outline-success shadow-sm ms-2"
          style={{ width: 'max-content' }}
          onClick={openAddCostModal}
        >
          Add New <i className="bi bi-plus ms-1"></i>
        </button>
      </div>
      <div className="w-100">
        <CostList
          caseId={(caseData || {}).id}
          user={user}
          reinitialize={reinitialize}
          reinitializing={reinitializing}
          openEditCostModal={(costId, onEdit) =>
            openEditCostModal(costId, onEdit)
          }
        />

        <AddCostModal
          visible={!!showAddCostModal}
          caseData={showAddCostModal && showAddCostModal.caseData}
          onClose={showAddCostModal && showAddCostModal.onClose}
          user={showAddCostModal && showAddCostModal.user}
          employees={showAddCostModal && showAddCostModal.employees}
          reinitialize={reinitialize}
        />

        <EditCostModal
          visible={!!showEditCostModal}
          caseData={showEditCostModal && showEditCostModal.caseData}
          onClose={showEditCostModal && showEditCostModal.onClose}
          user={showEditCostModal && showEditCostModal.user}
          employees={showEditCostModal && showEditCostModal.employees}
          costId={showEditCostModal && showEditCostModal.costId}
          onEdit={showEditCostModal && showEditCostModal.onEdit}
          reinitialize={reinitialize}
        />
      </div>
    </div>
  );
};

export default Costs;
