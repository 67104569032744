import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { myGreenSuperLight } from '../../constants/colors';
import {
  TRANSFER_TYPES,
  DEPOSIT_TYPES,
  TICKLER_TYPES,
  CASE_SUB_TYPES,
} from '../../constants/constants';
import { getUserName } from '../../utils/userUtils';
import Checkbox from '../common/Checkbox';
import Input from '../common/Input';
import MultiSelect from '../common/MultiSelect';
import MyMessage from '../common/MyMessage';
import Select from '../common/Select';
import TextArea from '../common/TextArea';

const AddPendingIoltaModal = (props) => {
  const { caseData, visible, onClose, user, employees, reinitialize, type } =
    props;

  const [amount, setAmount] = useState(0);
  const [subType, setSubType] = useState('');
  const [caseType, setCaseType] = useState('H-1B CAP');
  const [desc, setDesc] = useState('');
  const [ioltaDate, setIoltaDate] = useState('');
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (type && type.length) {
      setSubType(
        type === 'deposit' ? 'cash' : type === 'transfer' ? 'cis-fee' : ''
      );
    }
  }, [type]);

  const createIolta = async () => {
    if (!validateIolta()) return;
    const configService = require('../../store/config/service');

    let body = {
      isPending: true,
      isRegular: false,
      type,
      subType,
      caseType,
      case: caseData.id,
      amount: parseFloat(amount),
      amountString: `${amount}`,
      ioltaDate: new Date(ioltaDate),
      ioltaDateStamp: +new Date(ioltaDate),
      desc,
      createdBy: user.id,
      createdByName: getUserName(user),
    };

    // console.log('body - ', body);

    const res = await configService.default.createIolta(body);
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const validateIolta = () => {
    let flag = true;
    if (!subType || !subType.length) {
      flag = false;
      handleError('subType', 'select a valid tickler subType');
    }
    if (!amount || isNaN(amount)) {
      flag = false;
      handleError('amount', 'add a valid amount');
    }
    if (!desc || !desc.length) {
      flag = false;
      handleError('desc', 'add a description');
    }
    if (!ioltaDate || !ioltaDate.length) {
      flag = false;
      handleError('ioltaDate', 'select a date');
    }
    if (!type || !type.length) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (!user || !user.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (!caseData || !caseData.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    return flag;
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  const doOnClose = () => {
    resetBlankModal();
    onClose();
  };

  const resetBlankModal = () => {
    setAmount(0);
    setIoltaDate('');
    setSubType(
      type === 'deposit' ? 'cash' : type === 'transfer' ? 'cis-fee' : ''
    );
    setCaseType('H-1B CAP');
    setDesc('');
    setErrors({});
    setMessage({});
    setLoading(false);
  };

  return visible ? (
    <div className="my-modal-container">
      <div className="my-modal-overlay" onClick={doOnClose}></div>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable my-modal z-index-9">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <div>
              <p className="modal-title mb-0">{'Adding'}</p>
              <h3 className="modal-title fw-bold capitalize">{`New ${type}`}</h3>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={doOnClose}
            ></button>
          </div>
          <div className="modal-body">
            <div className="container d-flex flex-column justify-content-center align-items-start p-2">
              <div className="mb-2">
                <p className="text-muted fs-italic fw-light size15">
                  Fill in the details below to add a new {type}!
                </p>

                <div className="row d-flex flex-wrap justify-content-start align-items-start">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <Select
                      label={`${type} Type`}
                      placeholder={`select a ${type} type`}
                      value={subType}
                      options={
                        type === 'deposit'
                          ? DEPOSIT_TYPES
                          : type === 'transfer'
                          ? TRANSFER_TYPES
                          : []
                      }
                      onChange={(e) => {
                        setSubType(e.target.value);
                        handleError('subType', '');
                      }}
                      err={errors.subType || ''}
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <Select
                      label={`Case Type`}
                      placeholder={`select a case type`}
                      value={caseType}
                      options={CASE_SUB_TYPES}
                      onChange={(e) => {
                        setCaseType(e.target.value);
                        handleError('caseType', '');
                      }}
                      err={errors.caseType || ''}
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <Input
                      label={'Amount'}
                      placeholder={'enter amount'}
                      type={'number'}
                      value={amount}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.amount &&
                          errors.amount.length
                        ) {
                          handleError('amount', '');
                        }
                        setAmount(e.target.value);
                      }}
                      err={errors && errors.amount}
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                    <Input
                      label={`${type === 'deposit' ? 'Deposit' : 'Transfer'} Date`}
                      placeholder={'enter date'}
                      type={'date'}
                      value={ioltaDate}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.ioltaDate &&
                          errors.ioltaDate.length
                        ) {
                          handleError('ioltaDate', '');
                        }
                        setIoltaDate(e.target.value);
                      }}
                      err={errors && errors.ioltaDate}
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <TextArea
                      label={'Description'}
                      placeholder={'add a description'}
                      value={desc}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.desc &&
                          errors.desc.length
                        ) {
                          handleError('desc', '');
                        }
                        setDesc(e.target.value);
                      }}
                      err={errors && errors.desc}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
            </div>
          </div>
          <div className="px-3">
            <MyMessage
              visible={!!message}
              message={message}
              onClose={() => {
                setMessage(undefined);
              }}
            />
          </div>
          <div
            className="modal-footer"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <button
              type="button"
              className="btn btn-outline ms-2 capitalize"
              onClick={doOnClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary ms-2 capitalize"
              onClick={createIolta}
            >
              Add {type}
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default AddPendingIoltaModal;
