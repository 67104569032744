import React, { useEffect, useState } from 'react';
import { H1B_STEPS, H1B_TYPES } from '../constants/constants';
import { getUserName } from '../utils/userUtils';
import Input from './common/Input';
import MyMessage from './common/MyMessage';
import Select from './common/Select';
import TextArea from './common/TextArea';

const H1BStatus = (props) => {
  const { caseData, user } = props;

  const [h1bExists, setH1bExists] = useState(false);
  const [h1bId, setH1bId] = useState('');
  const [type, setType] = useState('');
  const [steps, setSteps] = useState({});
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});

  useEffect(() => {
    initInfo();
  }, [caseData]);

  const initInfo = async () => {
    if (!caseData || !caseData.id) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getH1bByCase(caseData.id);
    if (res.result && res.result.length) {
      setH1bExists(true);
      setH1bId(res.result[0].id);
      setType(res.result[0].type);
      setSteps(res.result[0].steps);
      return;
    }

    let newSteps = {};
    H1B_STEPS.map((step) => {
      newSteps[step.value] = {
        title: step.label,
        finishDate: '',
        notes: '',
      };
    });
    setSteps(newSteps);
    setH1bExists(false);
  };

  const createH1b = async () => {
    if (!validateH1b()) return;
    const configService = require('../store/config/service');
    let body = {
      case: caseData.id,
      type,
      steps,
      createdBy: user.id,
      createdByName: getUserName(user),
    };

    const res = await configService.default.createH1b(body);
    if (!res) {
      setMessage({ type: 'warning', msg: 'no response from server!' });
    }
    if (res.error) {
      setMessage({ type: 'warning', msg: res.error });
    }
    if (res.result) {
      setMessage({ type: 'success', msg: 'Initiated H1B module succesfully!' });
      initInfo();
    }
  };

  const updateH1b = async () => {
    if (!validateH1b()) return;
    const configService = require('../store/config/service');
    let body = {
      type,
      steps,
      createdBy: user.id,
      createdByName: getUserName(user),
    };
    const res = await configService.default.updateH1b(h1bId, body);
    if (!res) {
      setMessage({ type: 'warning', msg: 'no response from server!' });
    }
    if (res.error) {
      setMessage({ type: 'warning', msg: res.error });
    }
    if (res.result) {
      setMessage({ type: 'success', msg: 'Updated H1B module succesfully!' });
      initInfo();
    }
  };

  const validateH1b = () => {
    let flag = true;
    if (!type || !type.length) {
      flag = false;
      handleError('type', 'select a valid type please');
    }
    if (!Object.keys(steps).length) {
      flag = false;
      setMessage({ type: 'warning', msg: 'oops! something went wrong.' });
    }
    if (!user || !user.id || !user.id.length) {
      flag = false;
      setMessage({ type: 'warning', msg: 'oops! something went wrong.' });
    }
    if (!caseData || !caseData.id || !caseData.id.length) {
      flag = false;
      setMessage({ type: 'warning', msg: 'oops! something went wrong.' });
    }
    if (h1bExists && (!h1bId || !h1bId.length)) {
      flag = false;
      setMessage({ type: 'warning', msg: 'oops! something went wrong.' });
    }
    return flag;
  };

  const setStepDate = (stepVal, dateVal) => {
    let newSteps = Object.assign({}, steps);
    newSteps[stepVal].finishDate = dateVal;
    setSteps(newSteps);
  };

  const setStepNotes = (stepVal, noteVal) => {
    let newSteps = Object.assign({}, steps);
    newSteps[stepVal].notes = noteVal;
    setSteps(newSteps);
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  return (
    <div className="w-100">
      <h3>H1B Info</h3>
      <div className="row">
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 info-card">
          <Select
            label={'H1B Type'}
            placeholder={'select an H1B type'}
            value={type}
            options={H1B_TYPES}
            onChange={(e) => {
              setType(e.target.value);
              handleError('type', '');
            }}
            err={errors.type || ''}
          />
        </div>
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 info-card d-flex align-items-center justify-content-start">
          <button
            onClick={h1bExists ? updateH1b : createH1b}
            className="btn btn-outline-primary"
          >
            Submit
          </button>
        </div>
      </div>

      <MyMessage
        visible={!!message}
        message={message}
        onClose={() => {
          setMessage({});
        }}
      />

      <hr />

      <div className="row">
        <div className="col-12 d-none d-lg-block mb-3">
          <div className="row mx-1">
            <div className="col-3">
              <h5>Steps</h5>
            </div>
            <div className="col-3">
              <h5>Dates</h5>
            </div>
            <div className="col-5">
              <h5>Notes</h5>
            </div>
          </div>
        </div>
        {H1B_STEPS.map((step, i) => (
          <div key={i}>
            <div className="col-12">
              <div className="row mx-1">
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12 d-flex justify-content-start align-items-center">
                  <h6 className="mb-3">{step.label}</h6>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12">
                  <Input
                    type={'date'}
                    value={(steps[step.value] || {}).finishDate || ''}
                    onChange={(e) => {
                      setStepDate(step.value, e.target.value);
                      handleError(`${step.value}-date`, '');
                    }}
                    err={errors[`${step.value}-date`] || ''}
                  />
                </div>
                <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12 col-12">
                  <TextArea
                    value={(steps[step.value] || {}).notes || ''}
                    onChange={(e) => {
                      if (
                        e.target.value &&
                        e.target.value.length &&
                        errors[`${step.value}-note`] &&
                        errors[`${step.value}-note`].length
                      ) {
                        handleError(`${step.value}-note`, '');
                      }
                      setStepNotes(step.value, e.target.value);
                    }}
                    err={errors && errors[`${step.value}-note`]}
                  />
                </div>
              </div>
            </div>
            {i < H1B_STEPS.length - 1 ? (
              <hr className="mt-3" />
            ) : (
              <React.Fragment />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default H1BStatus;
