import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CASE_TABS, REPORT_TABS } from '../../constants/constants';
import TicklersReport from '../TicklersReport';
import FinancialsReport from '../FinancialsReport';
import ImmigrationReport from '../ImmigrationReport';
import DailyRevenueReport from '../DailyRevenueReport';
import ClientBalancesReport from '../ClientBalancesReport';
import ClientStatementReport from '../ClientStatementReport';
import MonthlyRevenueReport from '../MonthlyRevenueReport';
import IOLTADailyTransactionReport from '../IOLTADailyTransactionReport';
import IOLTABalanceReport from '../IOLTABalanceReport';
import IOLTAStatementReport from '../IOLTAStatementReport';
import CollectionListReport from '../CollectionListReport';
import CaseCostReport from '../CaseCostReport';
import PERMStatusReport from '../PERMStatusReport';
import H1BStatusReport from '../H1BStatusReport';

const Reports = (props) => {
  const { user, isLoggedIn } = props;
  const { id: caseId } = useParams();
  const [caseData, setCaseData] = useState({});
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    initCase(caseId);
  }, [caseId]);

  const initCase = async (caseId) => {
    if (!caseId || !caseId.length) return;
    const configService = require('../../store/config/service');
    const res = await configService.default.getCase(caseId);
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      // console.log('case data', res.result);
      setCaseData(res.result);
    }
  };

  const getActiveComponent = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return <TicklersReport user={user} />;
      case 1:
        if (!user || !user.userType) return <React.Fragment />;
        if (user && user.userType && user.userType !== 'Admin')
          return <ImmigrationReport user={user} />;
        return <FinancialsReport user={user} />;
      case 2:
        return <ImmigrationReport user={user} />;
      default:
        break;
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mb-4">
          <div className="card pb-2">
            <div className="card-head px-3 py-3 pb-0 d-flex justify-content-start align-items-center">
              <div className="d-flex flex-column justify-content-start align-items-start w-100">
                <h5 className="fw-semi-bold">Reports</h5>
                <div className="d-flex flex-wrap justify-content-start align-items-center mt-2">
                  {REPORT_TABS.filter(
                    (tab) =>
                      user && user.userType && tab.roles.includes(user.userType)
                  ).map((tab, i) => (
                    <button
                      key={i}
                      className={`btn btn-sm btn-${
                        i === activeTabIndex
                          ? 'primary shadow'
                          : 'my-dark text-white shadow-sm'
                      } me-2 mb-2`}
                      style={{ width: 'max-content' }}
                      onClick={() => {
                        // console.log('clicked on tab', tab.idx);
                        setActiveTabIndex(i);
                      }}
                    >
                      {tab.label} <i className={`bi bi-${tab.icon} ms-1`}></i>
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <hr className="hy-light-hr mt-3 mb-2" />

            <div className="card-body p-3 m-0 w-100 d-flex flex-column justify-content-start align-items-start overflow-hidden">
              {getActiveComponent(activeTabIndex || 0)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth: { user, isLoggedIn } = {} } = state;
  return {
    user,
    isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload) => {
      dispatch(login(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
