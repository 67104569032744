import React, { useEffect, useState } from 'react';
import { getUserName } from '../utils/userUtils';
import FilingPaymentList from './FilingPaymentList';
import AddFilingPaymentModal from './modals/AddFilingPaymentModal';
import EditFilingPaymentModal from './modals/EditFilingPaymentModal';

const FilingPayments = (props) => {
  const { caseData, user } = props;
  const [employees, setEmployees] = useState([]);
  const [showAddFilingPaymentModal, setShowAddFilingPaymentModal] = useState(undefined);
  const [showEditFilingPaymentModal, setShowEditFilingPaymentModal] = useState(undefined);
  const [reinitializing, setReinitializing] = useState(false);

  useEffect(() => {
    initEmployees();
  }, []);

  const initEmployees = async () => {
    if (!user || !user.userType) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getUsers(); // change to employees only
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      setEmployees(
        res.result.map((emp) => {
          return {
            label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
            value: emp.id,
          };
        })
      );
    }
  };

  const openAddFilingPaymentModal = (type) => {
    setShowAddFilingPaymentModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowAddFilingPaymentModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
      type: type ? type : '',
    });
  };

  const openEditFilingPaymentModal = (type, filingPaymentId) => {
    setShowEditFilingPaymentModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowEditFilingPaymentModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
      type: type ? type : '',
      filingPaymentId: filingPaymentId ? filingPaymentId : '',
    });
  };

  const reinitialize = (bool = true) => {
    setReinitializing(bool);
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mb-0">{'Filing Fees Payments & Charges'}</h4>
        <div className="flex-one"></div>
        <div>
          <button
            className="btn btn-sm btn-outline-success shadow-sm ms-2"
            style={{ width: 'max-content' }}
            onClick={() => openAddFilingPaymentModal('payment')}
          >
            Add New Payment<i className="bi bi-plus ms-1"></i>
          </button>
          <button
            className="btn btn-sm btn-outline-success shadow-sm ms-2"
            style={{ width: 'max-content' }}
            onClick={() => openAddFilingPaymentModal('charge')}
          >
            Add New Charge<i className="bi bi-plus ms-1"></i>
          </button>
        </div>
      </div>
      <div className="w-100">
        <FilingPaymentList
          caseId={(caseData || {}).id}
          caseData={caseData}
          user={user}
          reinitialize={reinitialize}
          reinitializing={reinitializing}
          openEditFilingPaymentModal={(type, filingPaymentId) => openEditFilingPaymentModal(type, filingPaymentId)}
        />

        <AddFilingPaymentModal
          visible={!!showAddFilingPaymentModal}
          caseData={showAddFilingPaymentModal && showAddFilingPaymentModal.caseData}
          onClose={showAddFilingPaymentModal && showAddFilingPaymentModal.onClose}
          user={showAddFilingPaymentModal && showAddFilingPaymentModal.user}
          employees={showAddFilingPaymentModal && showAddFilingPaymentModal.employees}
          type={showAddFilingPaymentModal && showAddFilingPaymentModal.type}
          reinitialize={reinitialize}
        />

        <EditFilingPaymentModal
          visible={!!showEditFilingPaymentModal}
          caseData={showEditFilingPaymentModal && showEditFilingPaymentModal.caseData}
          onClose={showEditFilingPaymentModal && showEditFilingPaymentModal.onClose}
          user={showEditFilingPaymentModal && showEditFilingPaymentModal.user}
          employees={showEditFilingPaymentModal && showEditFilingPaymentModal.employees}
          type={showEditFilingPaymentModal && showEditFilingPaymentModal.type}
          paymentId={showEditFilingPaymentModal && showEditFilingPaymentModal.filingPaymentId}
          reinitialize={reinitialize}
        />
      </div>
    </div>
  );
};

export default FilingPayments;
