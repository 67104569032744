import React, { useEffect, useState } from 'react';
import { getUserName } from '../utils/userUtils';
import NoteList from './NoteList';
import AddNoteModal from './modals/AddNoteModal';
import EditNoteModal from './modals/EditNoteModal';

const Notes = (props) => {
  const { caseData, user } = props;
  const [employees, setEmployees] = useState([]);
  const [showAddNoteModal, setShowAddNoteModal] = useState(undefined);
  const [showEditNoteModal, setShowEditNoteModal] = useState(undefined);
  const [reinitializing, setReinitializing] = useState(false);

  useEffect(() => {
    initEmployees();
  }, []);

  const initEmployees = async () => {
    if (!user || !user.userType) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getUsers(); // change to employees only
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      setEmployees(
        res.result.map((emp) => {
          return {
            label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
            value: emp.id,
          };
        })
      );
    }
  };

  const openAddNoteModal = () => {
    setShowAddNoteModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowAddNoteModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
    });
  };

  const openEditNoteModal = (noteId) => {
    setShowEditNoteModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowEditNoteModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
      noteId: noteId ? noteId : {},
    });
  };

  const reinitialize = (bool = true) => {
    setReinitializing(bool);
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notes</h4>
        <button
          className="btn btn-sm btn-outline-success shadow-sm ms-2"
          style={{ width: 'max-content' }}
          onClick={openAddNoteModal}
        >
          Add New <i className="bi bi-plus ms-1"></i>
        </button>
      </div>
      <div className="w-100">
        <NoteList
          caseId={(caseData || {}).id}
          user={user}
          reinitialize={reinitialize}
          reinitializing={reinitializing}
          openEditNoteModal={(noteId) => openEditNoteModal(noteId)}
        />

        <AddNoteModal
          visible={!!showAddNoteModal}
          caseData={showAddNoteModal && showAddNoteModal.caseData}
          onClose={showAddNoteModal && showAddNoteModal.onClose}
          user={showAddNoteModal && showAddNoteModal.user}
          employees={showAddNoteModal && showAddNoteModal.employees}
          reinitialize={reinitialize}
        />

        <EditNoteModal
          visible={!!showEditNoteModal}
          caseData={showEditNoteModal && showEditNoteModal.caseData}
          onClose={showEditNoteModal && showEditNoteModal.onClose}
          user={showEditNoteModal && showEditNoteModal.user}
          employees={showEditNoteModal && showEditNoteModal.employees}
          noteId={showEditNoteModal && showEditNoteModal.noteId}
          reinitialize={reinitialize}
        />
      </div>
    </div>
  );
};

export default Notes;
