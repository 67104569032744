import React, { useEffect, useState } from 'react';
import {
  TICKLER_STATUSES,
  VISIBLE_APPOINTMENT_COLS,
  VISIBLE_CASE_COLS,
} from '../constants/constants';
import AppointmentCell from '../components/AppointmentCell';
import Input from './common/Input';
import Loading from './Loading';
import {
  dateFormat,
  getTodayEndDate,
  getTodayStartDate,
} from '../utils/userUtils';
import format from 'date-fns/format';
import Select from './common/Select';
import MyPagination from './common/MyPagination';
import NoEntry from './common/NoEntry';

const AppointmentList = (props) => {
  const {
    user,
    caseId,
    all = false,
    caseAll = false,
    openEditAppointmentModal,
    reinitializing,
    reinitialize,
  } = props;

  const [appointments, setAppointments] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [dataKeys, setDataKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startTime, setStartTime] = useState(getTodayStartDate());
  const [endTime, setEndTime] = useState(getTodayEndDate());
  const [appointmentStatus, setAppointmentStatus] = useState('pending');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    initDataKeys(appointments);
  }, [appointments]);

  useEffect(() => {
    initAppointments(page, user, caseId, searchQuery, startTime, endTime);
  }, [
    page,
    user,
    caseId,
    searchQuery,
    startTime,
    endTime,
    reinitializing,
    appointmentStatus,
  ]);

  useEffect(() => {
    initPageCount(user, caseId, searchQuery, startTime, endTime);
  }, [
    user,
    caseId,
    searchQuery,
    startTime,
    endTime,
    reinitializing,
    appointmentStatus,
  ]);

  const initPageCount = async (
    user,
    caseId,
    searchQuery,
    startTime,
    endTime
  ) => {
    if (!user || !user.id) return;
    const configService = require('../store/config/service');
    const res =
      appointmentStatus === 'pending'
        ? all
          ? await configService.default.getAppointmentsPageCount(
              searchQuery,
              startTime,
              endTime
            )
          : caseAll
          ? await configService.default.getCaseAppointmentsPageCount(
              caseId ? caseId : '',
              searchQuery,
              startTime,
              endTime
            )
          : await configService.default.getMyAppointmentsPageCount(
              user.id,
              caseId ? caseId : '',
              searchQuery,
              startTime,
              endTime
            )
        : all
        ? await configService.default.getCompletedAppointmentsPageCount(
            searchQuery,
            startTime,
            endTime
          )
        : caseAll
        ? await configService.default.getCaseCompletedAppointmentsPageCount(
            caseId ? caseId : '',
            searchQuery,
            startTime,
            endTime
          )
        : await configService.default.getMyCompletedAppointmentsPageCount(
            user.id,
            caseId ? caseId : '',
            searchQuery,
            startTime,
            endTime
          );
    if (!res) {
    }
    if (res.error) {
      setPageCount(1);
    }
    if (res.result && res.result.count) {
      setPageCount(res.result.count);
    }
  };

  const initAppointments = async (
    page,
    user,
    caseId,
    searchQuery,
    startTime,
    endTime
  ) => {
    try {
      if (!user || !user.id || !page) return;
      setLoading(true);
      if (reinitializing) {
        setAppointments([]);
        reinitialize(false);
        setPage(1);
        return;
      }
      const configService = require('../store/config/service');
      const res =
        appointmentStatus === 'pending'
          ? all
            ? await configService.default.getAppointments(
                page,
                searchQuery,
                startTime,
                endTime
              )
            : caseAll
            ? await configService.default.getCaseAppointments(
                caseId ? caseId : '',
                page,
                searchQuery,
                startTime,
                endTime
              )
            : await configService.default.getMyAppointments(
                user.id,
                caseId ? caseId : '',
                page,
                searchQuery,
                startTime,
                endTime
              )
          : all
          ? await configService.default.getCompletedAppointments(
              page,
              searchQuery,
              startTime,
              endTime
            )
          : caseAll
          ? await configService.default.getCaseCompletedAppointments(
              caseId ? caseId : '',
              page,
              searchQuery,
              startTime,
              endTime
            )
          : await configService.default.getMyCompletedAppointments(
              user.id,
              caseId ? caseId : '',
              page,
              searchQuery,
              startTime,
              endTime
            );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        setAppointments(res.result);
        setLoading(false);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const initDataKeys = (appointments) => {
    if (!appointments || !appointments.length) return;
    let newKeys = Object.keys(appointments[0]).filter((k) =>
      VISIBLE_APPOINTMENT_COLS.includes(k)
    );
    return setDataKeys(newKeys);
  };

  const gotoPreviousDay = () => {
    let dt = new Date(startTime);
    dt.setDate(dt.getDate() - 1);
    let newStartTime = getTodayStartDate(dt);
    let newEndTime = getTodayEndDate(dt);

    setStartTime(newStartTime);
    setEndTime(newEndTime);
  };
  const gotoNextDay = () => {
    let dt = new Date(startTime);
    dt.setDate(dt.getDate() + 1);
    let newStartTime = getTodayStartDate(dt);
    let newEndTime = getTodayEndDate(dt);

    setStartTime(newStartTime);
    setEndTime(newEndTime);
  };
  const keyToLabelMapping = {
    appointmentType: 'Type',
    appointmentWith: 'Attendees',
    startTimeStamp: 'Start Time',
    endTimeStamp: 'End Time',
    name: 'Name',
    location: 'Location',
  };

  return (
    <div className={`${caseId && caseId.length ? '' : 'card'} chart-card pb-2`}>
      <div className="card-head px-3 py-3 pb-0 d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between align-items-center w-100">
          {caseId && caseId.length ? (
            <React.Fragment />
          ) : (
            <>
              <h5 className="fw-semi-bold">Appointments</h5>
              <div className="d-flex justify-content-end align-items-center w-100">
                <button
                  className="btn btn-sm btn-outline-dark shadow-sm"
                  style={{ width: 'max-content' }}
                  onClick={() => {
                    gotoPreviousDay();
                  }}
                >
                  <i className="bi bi-chevron-left ms-1"></i> Prev. Day
                </button>
                <button
                  className="btn btn-sm btn-dark shadow-sm mx-2"
                  style={{ width: 'max-content' }}
                >
                  {dateFormat(new Date(startTime))}{' '}
                  <i className="bi bi-clock-history ms-1"></i>
                </button>
                <button
                  className="btn btn-sm btn-outline-dark shadow-sm"
                  style={{ width: 'max-content' }}
                  onClick={() => {
                    gotoNextDay();
                  }}
                >
                  Next. Day <i className="bi bi-chevron-right ms-1"></i>
                </button>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-start align-items-baseline px-3">
        <MyPagination page={page} setPage={setPage} pageCount={pageCount} />
        <div className="d-flex justify-content-start align-items-center w-100 px-3">
          <div className="max-width-24rem">
            <input
              className="form-control"
              placeholder={'type something..'}
              text={'text'}
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>
        </div>

        {caseId && caseId.length ? (
          <div className="d-flex flex-column jusitfy-content-end align-items-end">
            <div className="d-flex justify-content-end align-items-baseline">
              <button
                className="btn btn-sm btn-outline-dark shadow-sm"
                style={{ width: 'max-content' }}
                onClick={() => {
                  gotoPreviousDay();
                }}
              >
                <i className="bi bi-chevron-left ms-1"></i> Prev. Day
              </button>
              <button
                className="btn btn-sm btn-dark shadow-sm mx-2"
                style={{ width: 'max-content' }}
              >
                {dateFormat(new Date(startTime))}{' '}
                <i className="bi bi-clock-history ms-1"></i>
              </button>
              <button
                className="btn btn-sm btn-outline-dark shadow-sm"
                style={{ width: 'max-content' }}
                onClick={() => {
                  gotoNextDay();
                }}
              >
                Next. Day <i className="bi bi-chevron-right ms-1"></i>
              </button>
            </div>
            <div className="d-flex justify-content-end align-items-baseline">
              <div className="max-width-24rem">
                <Select
                  classes={'pe-0'}
                  placeholder={'select status'}
                  value={appointmentStatus}
                  options={TICKLER_STATUSES}
                  onChange={(e) => {
                    setAppointmentStatus(e.target.value);
                  }}
                  err={errors.appointmentStatus || ''}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-end align-items-baseline">
            <div className="max-width-24rem">
              <Select
                classes={'pe-0'}
                placeholder={'select status'}
                value={appointmentStatus}
                options={TICKLER_STATUSES}
                onChange={(e) => {
                  setAppointmentStatus(e.target.value);
                }}
                err={errors.appointmentStatus || ''}
              />
            </div>
          </div>
        )}
      </div>

      <div className="card-body p-0 m-0 w-100 d-flex flex-column justify-content-center align-items-start">
        {dataKeys && dataKeys.length ? (
          <div className="table-head-row">
            <div className="table-head-col flex-pt5">S No.</div>
            {dataKeys.map((dataKey) => (
              <div key={dataKey} className="table-head-col">
                {keyToLabelMapping[dataKey]}
              </div>
            ))}
          </div>
        ) : (
          <React.Fragment />
        )}
        {loading ? (
          <Loading />
        ) : appointments && appointments.length ? (
          appointments.map((appointment, i) => (
            <div
              key={i}
              className="table-row"
              onClick={() => openEditAppointmentModal(appointment.id)}
            >
              <div className="table-col mt-2 flex-pt5">
                <span className="fw-light size14">
                  {(page - 1) * 20 + i + 1}.
                </span>
              </div>
              {Object.keys(appointment)
                .filter((item) => VISIBLE_APPOINTMENT_COLS.includes(item))
                .map((dataKey) =>
                  dataKey === 'startTimeStamp' || dataKey === 'endTimeStamp' ? (
                    <div key={dataKey} className="table-col">
                      <AppointmentCell
                        dataKey={dataKey}
                        data={appointment[dataKey]}
                        aptId={appointment && appointment.appointmentId}
                      />
                    </div>
                  ) : (
                    <div key={dataKey} className="table-col">
                      <AppointmentCell
                        dataKey={dataKey}
                        data={appointment[dataKey]}
                      />
                    </div>
                  )
                )}
            </div>
          ))
        ) : (
          <NoEntry />
        )}
      </div>
    </div>
  );
};

export default AppointmentList;
