import { format, formatDistance } from 'date-fns';
import React from 'react';
import { getUserName } from '../utils/userUtils';

const EmployeeCell = (props) => {
  const { dataKey, data } = props;

  const FullName = (props) => {
    const { data } = props;
    if (!data || !data.firstName || !data.lastName)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <small className="badge rounded px-1 text-dark fw-regular mx-1 capitalize">
        {`${data.firstName} ${data.lastName}`}
      </small>
    );
  };

  const CaseType = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-normal mx-1 capitalize">
          {data}
        </small>
      </div>
    );
  };

  const Designation = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-regular mx-1 capitalize">
          {data}
        </small>
      </div>
    );
  };

  const Text = (props) => {
    const { data, light } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">n/a</small>;
    return (
      <div>
        <small
          className={`badge px-1 ${
            light ? 'fw-normal' : 'fw-regular'
          } capitalize text-dark`}
        >
          {data}
        </small>
      </div>
    );
  };

  const Email = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <small className="badge rounded text-and-border-blue px-1 fw-normal mx-1">
        {data}
      </small>
    );
  };

  return (
    <div className="my-2">

      {dataKey === 'userType' ? <CaseType data={data} /> : <React.Fragment />}

      {dataKey === 'name' ? (
        <FullName data={data} />
      ) : (
        <React.Fragment />
      )}
      {dataKey === 'email' ? <Email data={data} /> : <React.Fragment />}
      {dataKey === 'designation' ? <Text data={data} /> : <React.Fragment />}
    </div>
  );
};

export default EmployeeCell;
