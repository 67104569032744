import React from 'react';

const TextArea = (props) => {
  const {
    label,
    placeholder,
    value,
    err,
    onChange,
    disabled = false,
    classes,
  } = props;

  return (
    <div className="my-input-group">
      {label && <label className="form-label">{label}</label>}
      <textarea
        className={`form-control ${classes ? classes : ''}`}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {err && (
        <div className="form-error">
          <small className="form-error-text">{err}</small>
        </div>
      )}
    </div>
  );
};

export default TextArea;
