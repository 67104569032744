import React, { useEffect, useState } from 'react';
import {
  VISIBLE_APPOINTMENT_COLS,
  VISIBLE_EMPLOYEE_COLS,
} from '../constants/constants';
import EmployeeCell from './EmployeeCell';
import Input from './common/Input';
import Loading from './Loading';
import { useHistory } from 'react-router-dom';
import MyPagination from './common/MyPagination';
import NoEntry from './common/NoEntry';

const EmployeeList = (props) => {
  const { user, openEditUserModal, reinitialize, reinitializing } = props;

  const [employees, setEmployees] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [dataKeys, setDataKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    initDataKeys(employees);
  }, [employees]);

  useEffect(() => {
    initEmployees(page, user, searchQuery);
  }, [page, user, searchQuery, reinitializing]);

  useEffect(() => {
    initPageCount(user, searchQuery);
  }, [user, searchQuery, reinitializing]);

  const initPageCount = async (user, searchQuery) => {
    if (!user || !user.id) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getUsersPageCount(searchQuery);
    if (!res) {
    }
    if (res.error) {
      setPageCount(1);
    }
    if (res.result && res.result.count) {
      setPageCount(res.result.count);
    }
  };

  const initEmployees = async (page, user, searchQuery) => {
    try {
      if (!user || !user.id || !page) return;
      setLoading(true);
      if(reinitializing) {
        setEmployees([]);
        reinitialize(false);
        setPage(1);
        return;
      }
      const configService = require('../store/config/service');
      const res = await configService.default.getPagedUsers(page, searchQuery);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('employees --', res.result);
        setEmployees(res.result);
        setLoading(false);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const initDataKeys = (employees) => {
    if (!employees || !employees.length) return;
    let newKeys = Object.keys(employees[0]).filter((k) =>
      VISIBLE_EMPLOYEE_COLS.includes(k)
    ).sort();
    return setDataKeys(newKeys);
  };

  const keyToLabelMapping = {
    email: 'Email',
    name: 'Name',
    userType: 'User Type',
    designation: 'Designation',
  };

  const openConsult = (consultId) => {
    if (!consultId || !consultId.length) return;
    history.push(`/employees/${consultId}`);
  };

  return (
    <div className="card chart-card pb-2 overflow-hidden">
      <div className="card-head px-3 py-3 pb-0 d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between align-items-center w-100">
          <h5 className="fw-semi-bold">Employees</h5>
          {/* <button
            className="btn btn-dark shadow"
            style={{ width: 'max-content' }}
            onClick={(e) => {
              // console.log('open sort modal');
            }}
          >
            Today <i className="bi bi-clock-history ms-1"></i>
          </button> */}
        </div>
      </div>

      <div className="d-flex justify-content-start align-items-baseline px-3">
        <MyPagination page={page} setPage={setPage} pageCount={pageCount} />
        <div className="d-flex justify-content-start align-items-center w-100 px-3">
          <div className="max-width-24rem">
            <input
              className="form-control"
              placeholder={'type something..'}
              text={'text'}
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>
        </div>

      </div>

      <div className="card-body p-0 m-0 w-100 d-flex flex-column justify-content-start align-items-start overflow-hidden">
        {dataKeys && dataKeys.length ? (
          <div className="table-head-row">
            <div className="table-head-col flex-pt5">S No.</div>
            {dataKeys.map((dataKey) => (
              <div key={dataKey} className="table-head-col">
                {keyToLabelMapping[dataKey]}
              </div>
            ))}
          </div>
        ) : (
          <React.Fragment />
        )}
        {loading ? (
          <Loading />
        ) : employees && employees.length ? (
          employees.map((employee, i) => (
            <div key={i} className="table-row" onClick={() => openEditUserModal(employee.id)}>
              <div className="table-col mt-2 flex-pt5">
                <span className="fw-light size14">
                  {(page - 1) * 20 + i + 1}.
                </span>
              </div>
              {Object.keys(employee).sort()
                .filter((item) => VISIBLE_EMPLOYEE_COLS.includes(item))
                .map((dataKey) => (
                  <div key={dataKey} className="table-col">
                    <EmployeeCell dataKey={dataKey} data={employee[dataKey]} />
                  </div>
                ))}
            </div>
          ))
        ) : (
          <NoEntry />
        )}
      </div>
    </div>
  );
};

export default EmployeeList;
