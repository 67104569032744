import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { myGreenSuperLight } from '../../constants/colors';
import { TICKLER_TYPES } from '../../constants/constants';
import { getUserName } from '../../utils/userUtils';
import Checkbox from '../common/Checkbox';
import Input from '../common/Input';
import MultiSelect from '../common/MultiSelect';
import MyMessage from '../common/MyMessage';
import Select from '../common/Select';
import TextArea from '../common/TextArea';
import Loading from '../Loading';

const EditBillingNoteModal = (props) => {
  const { caseData, visible, onClose, user, employees, reinitialize, noteId } =
    props;

  const [editing, setEditing] = useState(false);
  const [desc, setDesc] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [createdBy, setCreatedBy] = useState('');
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initNoteForEdit();
  }, [visible, noteId]);

  const initNoteForEdit = async () => {
    try {
      if (!visible || !noteId || !noteId.length) return;
      setLoading(true);
      const configService = require('../../store/config/service');
      const res = await configService.default.getNote(noteId);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log(res.result);
        // add data to inputs.
        setDesc(res.result.desc);
        setIsPrivate(res.result.isPrivate);
        setCreatedBy(res.result.createdBy.id);
        setErrors({});
        setMessage({});
        setLoading(false);
      }
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const updateNote = async () => {
    if (!validateNote()) return;
    const configService = require('../../store/config/service');

    let body = {
      isPrivate,
    };

    // console.log('body - ', body);

    const res = await configService.default.updateNote(noteId, body);
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const validateNote = () => {
    let flag = true;
    if (!desc || !desc.length) {
      flag = false;
      handleError('desc', 'add a description');
    }
    if (!noteId || !noteId.length) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (
      !user ||
      !user.id ||
      !createdBy ||
      !createdBy.length ||
      user.id !== createdBy
    ) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (!caseData || !caseData.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    return flag;
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  const doOnClose = () => {
    resetBlankModal();
    onClose();
  };

  const resetBlankModal = () => {
    setDesc('');
    setEditing(false);
    setIsPrivate(false);
    setErrors({});
    setCreatedBy('');
    setMessage({});
    setLoading(false);
  };

  return visible ? (
    <div className="my-modal-container">
      <div className="my-modal-overlay" onClick={doOnClose}></div>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable my-modal z-index-9">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <div>
              <p className="modal-title mb-0">
                {!editing ? 'Viewing' : 'Editing'}
              </p>
              <h3 className="modal-title fw-bold">{'Billing Note'}</h3>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={doOnClose}
            ></button>
          </div>
          <div className="modal-body">
            {loading ? (
              <Loading />
            ) : (
              <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                <div className="mb-2">
                  {!editing ? (
                    <p className="text-muted fs-italic fw-light size15">
                      Here are the details of this note!
                    </p>
                  ) : (
                    <p className="text-muted fs-italic fw-light size15">
                      Change the details below and update this note!
                    </p>
                  )}

                  <div className="row d-flex flex-wrap justify-content-start align-items-start">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                      <TextArea
                        disabled
                        classes={'h-max-content'}
                        label={'Description'}
                        placeholder={'add a description'}
                        value={desc}
                        onChange={(e) => {
                          if (
                            e.target.value &&
                            e.target.value.length &&
                            errors.desc &&
                            errors.desc.length
                          ) {
                            handleError('desc', '');
                          }
                          setDesc(e.target.value);
                        }}
                        err={errors && errors.desc}
                      />
                    </div>

                    <div
                      className={`col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 ${
                        user &&
                        user.id &&
                        user.id.length &&
                        createdBy &&
                        createdBy.length &&
                        user.id === createdBy
                          ? ''
                          : 'v-hidden'
                      }`}
                    >
                      <Checkbox
                        type="checkbox"
                        disabled={!editing}
                        checked={isPrivate}
                        onClick={() => {
                          setIsPrivate((current) => !current);
                        }}
                        label={'Make it Private'}
                        namex="isPrivate"
                        idx="isPrivateYes"
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
              </div>
            )}
          </div>
          <div className="px-3">
            <MyMessage
              visible={!!message}
              message={message}
              onClose={() => {
                setMessage(undefined);
              }}
            />
          </div>
          <div
            className="modal-footer"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            {!editing ? (
              <>
                <button
                  type="button"
                  className="btn btn-outline ms-2"
                  onClick={doOnClose}
                >
                  Close
                </button>

                {user &&
                  user.id &&
                  user.id.length &&
                  createdBy &&
                  createdBy.length &&
                  user.id === createdBy && (
                    <button
                      type="button"
                      className="btn btn-primary ms-2"
                      onClick={() => {
                        if (
                          user &&
                          user.id &&
                          user.id.length &&
                          createdBy &&
                          createdBy.length &&
                          user.id === createdBy
                        ) {
                          setEditing(true);
                        }
                      }}
                    >
                      Edit
                    </button>
                  )}
              </>
            ) : (
              <>
                <button
                  type="button"
                  className="btn btn-outline ms-2"
                  onClick={() => setEditing(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary ms-2"
                  onClick={updateNote}
                >
                  Update Note
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default EditBillingNoteModal;
