import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { myGreenSuperLight } from '../../constants/colors';
import { TICKLER_TYPES } from '../../constants/constants';
import { getUserName } from '../../utils/userUtils';
import Checkbox from '../common/Checkbox';
import Input from '../common/Input';
import MultiSelect from '../common/MultiSelect';
import MyMessage from '../common/MyMessage';
import Select from '../common/Select';
import TextArea from '../common/TextArea';

const AddNoteModal = (props) => {
  const { caseData, visible, onClose, user, employees, reinitialize } = props;

  const [desc, setDesc] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const createNote = async () => {
    if (!validateNote()) return;
    const configService = require('../../store/config/service');

    let body = {
      case: caseData.id,
      desc,
      isPrivate,
      createdBy: user.id,
      createdByName: getUserName(user),
    };

    // console.log('body - ', body);

    const res = await configService.default.createNote(body);
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const validateNote = () => {
    let flag = true;
    if (!desc || !desc.length) {
      flag = false;
      handleError('desc', 'add a description');
    }
    if (!user || !user.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (!caseData || !caseData.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    return flag;
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  const doOnClose = () => {
    resetBlankModal();
    onClose();
  };

  const resetBlankModal = () => {
    setDesc('');
    setIsPrivate(false);
    setErrors({});
    setMessage({});
    setLoading(false);
  };

  return visible ? (
    <div className="my-modal-container">
      <div className="my-modal-overlay" onClick={doOnClose}></div>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable my-modal z-index-9">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <div>
              <p className="modal-title mb-0">{'Adding'}</p>
              <h3 className="modal-title fw-bold">{'New Note'}</h3>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={doOnClose}
            ></button>
          </div>
          <div className="modal-body">
            <div className="container d-flex flex-column justify-content-center align-items-start p-2">
              <div className="mb-2">
                <p className="text-muted fs-italic fw-light size15">
                  Fill in the details below to add a new note!
                </p>

                <div className="row d-flex flex-wrap justify-content-start align-items-start">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <TextArea
                      label={'Description'}
                      placeholder={'add a description'}
                      value={desc}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.desc &&
                          errors.desc.length
                        ) {
                          handleError('desc', '');
                        }
                        setDesc(e.target.value);
                      }}
                      err={errors && errors.desc}
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <Checkbox
                      type="checkbox"
                      checked={isPrivate}
                      onClick={() => {
                        setIsPrivate((current) => !current);
                      }}
                      label={'Make it Private'}
                      namex="isPrivate"
                      idx="isPrivateYes"
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
            </div>
          </div>
          <div className="px-3">
            <MyMessage
              visible={!!message}
              message={message}
              onClose={() => {
                setMessage(undefined);
              }}
            />
          </div>
          <div
            className="modal-footer"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <button
              type="button"
              className="btn btn-outline ms-2"
              onClick={doOnClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary ms-2"
              onClick={createNote}
            >
              Add Note
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default AddNoteModal;
