import React from 'react';

const CollectionListReport = (props) => {
  const { user } = props;
  return <div>
    <h3>CollectionListReport</h3>
  </div>;
};

export default CollectionListReport;
