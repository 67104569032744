import { format, formatDistance } from 'date-fns';
import React from 'react';
import { dateFormat, getUserName } from '../utils/userUtils';

const EmailCell = (props) => {
  const { dataKey, data } = props;

  const PrimaryName = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <small className="badge rounded px-1 text-dark fw-regular mx-1 capitalize">
        {data.fullName}
      </small>
    );
  };

  const EmailType = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-normal mx-1 capitalize">
          {data}
        </small>
      </div>
    );
  };

  const OpeningDate = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-regular mx-1 capitalize">
          {dateFormat(new Date(data))}
        </small>
      </div>
    );
  };

  const EmailId = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 fw-normal mx-1 capitalize bg-light-dark text-white">
          {data.id.substring(0, 4) +
            '...' +
            data.id.substring(data.id.length - 4, data.id.length)}
          <i className="bi bi-box-arrow-up-right ms-1"></i>
        </small>
      </div>
    );
  };

  const EmailNum = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 fw-normal mx-1 capitalize bg-light-dark text-white">
          {data}
          <i className="bi bi-box-arrow-up-right ms-1"></i>
        </small>
      </div>
    );
  };

  const Text = (props) => {
    const { data, light } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small
          className={`badge px-1 ${
            light ? 'fw-normal' : 'fw-regular'
          } capitalize text-dark`}
        >
          {data}
        </small>
      </div>
    );
  };

  const Emails = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge rounded text-and-border-blue px-1 fw-normal mx-1">
          {data[0]}
        </small>
        {/* {data.map((email) => (
          <small className="badge rounded text-and-border-blue px-1 fw-normal mx-1">
            {email}
          </small>
        ))} */}
      </div>
    );
  };

  return (
    <div className="my-2">
      {dataKey === 'openedAt' ? (
        <OpeningDate data={data} />
      ) : (
        <React.Fragment />
      )}

      {dataKey === 'caseType' ? <EmailType data={data} /> : <React.Fragment />}

      {dataKey === 'primaryContact' ? (
        <PrimaryName data={data} />
      ) : (
        <React.Fragment />
      )}
      {dataKey === 'companyName' ? <Text data={data} /> : <React.Fragment />}
      {dataKey === 'emails' ? <Emails data={data} /> : <React.Fragment />}
      {dataKey === 'status' ? <Text data={data} /> : <React.Fragment />}
      {/* {dataKey === 'name' ? <Text data={data} /> : <React.Fragment />}

      {dataKey === 'location' ? <Text data={data} light /> : <React.Fragment />}

      {dataKey === 'case' ? <EmailId data={data} /> : <React.Fragment />} */}
      {dataKey === 'caseNum' ? <EmailNum data={data} /> : <React.Fragment />}
    </div>
  );
};

export default EmailCell;
