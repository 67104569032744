import React, { useState } from 'react';
import { connect } from 'react-redux';
import { login } from '../../actions/authActions';
import { setAuthToken } from '../../services/StorageService';
import MyMessage from '../common/MyMessage';
import { isValidEmail } from '../../utils/userUtils';

const Login = (props) => {
  const { user, isLoggedIn, login } = props;
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState({});

  const loginUser = async () => {
    if (validateUser()) {
      // console.log('User is Valid', email, password);

      // let newUser = {
      //   name: {
      //     firstName: "Himanshu",
      //     lastName: "Jalan",
      //   },
      //   email,
      //   password,
      //   userType: "Employee",
      //   phone: "9521390450",
      // }
      const configService = require('../../store/config/service');
      const res = await configService.default.login({email, password});
      if (!res) {
        setMessage({ type: 'danger', msg: 'no response from server' });
      }
      if (res.error) {
        // console.log(res.error);
        setMessage({ type: 'danger', msg: res.error });
      }
      if (res.result) {
        // console.log('result is - ', res.result);
        setMessage({ type: 'success', msg: 'Logged in successfully!' });
        const token = res.result.jwtToken;
        delete res.result.jwtToken;
        delete res.result.password;
        delete res.result.createdAt;
        delete res.result.updatedAt;
        setAuthToken(token);
        login({ user: res.result, isLoggedIn: true, token: token });
      }
    } else {
      // console.log('User Invalid', email, password);
    }
  };

  const validateUser = () => {
    let flag = true;
    if (!isValidEmail(email)) {
      flag = false;
      setEmailError('Please enter a valid email');
    }
    if (!password) {
      flag = false;
      setPasswordError('Please enter a valid password');
    }
    return flag;
  };

  const toggleShowPass = () => {
    setShowPassword((current) => {
      return !current;
    });
  };

  return (
    <div className="vh100 text-center bg-extra-light d-flex align-items-center justify-content-center">
      <div className="row g-0 w-100 h-100">
        <div className={`col-xl-4 col-lg-5 col-md-6 col-12 my-dark-shadow`}>
          <div className="w-100 h-100 text-left overflow-hidden">
            <div className="w-100 d-flex justify-content-center align-items-center bg-white">
              <img
                src="/assets/img/logo.png"
                className="my-5"
                alt="..."
              />
            </div>
            <div
              className="h-100"
              style={{
                background: 'linear-gradient(to bottom, #fff, #eeeffe)',
              }}
            >
              <div className="mx-3">
                <div className="px-4 py-4 rounded bg-white my-light-border shadow-sm max-width-24rem mx-auto">
                  <h5 className="fw-regular pb-3" style={{ color: '#132' }}>
                    Sign in to your account
                  </h5>
                  <form>
                    <div className="row my-3 mb-0">
                      <div className="col-12 text-left mb-4">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Email address
                        </label>
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => {
                            if (isValidEmail(e.target.value)) setEmailError('');
                            setEmail(e.target.value);
                          }}
                          id="exampleInputEmail1"
                          className={`form-control ${
                            emailError ? 'red-border' : ''
                          }`}
                          placeholder="Enter email"
                          aria-label="Enter email"
                          aria-describedby="emailHelp"
                        />
                        <div className="px-1 pt-1 d-flex justify-content-end align-items-center">
                          {emailError && (
                            <small className="text-red">{emailError}</small>
                          )}
                        </div>
                      </div>

                      <div className="col-12 text-left mb-3">
                        <label
                          htmlFor="exampleInputPassword1"
                          className="form-label"
                        >
                          Password
                        </label>
                        <input
                          type={showPassword ? 'text' : 'password'}
                          value={password}
                          onChange={(e) => {
                            if (e.target.value && e.target.value.length > 5)
                              setPasswordError('');
                            setPassword(e.target.value);
                          }}
                          className={`form-control ${
                            passwordError ? 'red-border' : ''
                          }`}
                          placeholder="Enter password"
                          aria-label="Enter password"
                          id="exampleInputPassword1"
                        />
                        <div className="px-1 pt-1 d-flex justify-content-between align-items-center">
                          <small
                            className=" badge my-badge rounded cursor-pointer min-width-3rem"
                            onClick={toggleShowPass}
                          >
                            {showPassword ? 'Hide' : 'Show'}
                          </small>
                          {passwordError && (
                            <small className="text-red">{passwordError}</small>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>

                  <MyMessage
                    visible={!!message}
                    message={message}
                    onClose={() => {
                      setMessage(undefined);
                    }}
                  />
                  <div className="d-flex justify-content-end align-items-end pt-3">
                    <button
                      type="button"
                      className="btn btn fw-regular shadow"
                      style={{
                        backgroundColor: '#2345cd',
                        color: '#eee',
                        textTransform: 'uppercase',
                        letterSpacing: '.1rem',
                      }}
                      onClick={() => {
                        loginUser();
                      }}
                    >
                      Sign In
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="mt-5 pt-5 w-100 text-center">
              &copy; - 2021
            </div> */}
            </div>
          </div>
        </div>
        <div
          className={`col-xl-8 col-lg-7 col-md-6 col-12 d-none d-md-block`}
          style={{
            backgroundImage: `url("/assets/img/cover.png")`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        >
          <div className="translucent w-100 h-100">
            <div className="p-5 d-flex flex-column justify-content-start align-items-start w-100 h-100">
              <h5 className="fw-light" style={{ color: '#9ab' }}>
                Hello there! Welcome to
              </h5>
              <h2 className="fw-bold text-left" style={{ color: '#fefefe' }}>
                Ahluwalia Law Offices
              </h2>
              <p
                className="size16 pt-3 text-left"
                style={{
                  maxWidth: '35ch',
                  fontStyle: 'italic',
                  color: '#d9dedc',
                }}
              >
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.
                The point of using Lorem Ipsum is that it has a more-or-less
                normal distribution of letters
              </p>
            </div>
            <div className="position-absolute bottom-0 right-0 z-top p-4">
              <div className="chat-icon-cont shadow-lg">
                <i className="bi bi-chat-dots-fill text-white xl-icon chat-icon"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth: { user, isLoggedIn } = {} } = state;
  return {
    user,
    isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload) => {
      dispatch(login(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
