import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { myGreenSuperLight } from '../../constants/colors';
import {
  CASE_SUB_TYPES,
  CONTRACT_TYPES,
  PERCENTAGE_OPTIONS,
} from '../../constants/constants';
import { getUserName } from '../../utils/userUtils';
import Checkbox from '../common/Checkbox';
import Input from '../common/Input';
import MultiSelect from '../common/MultiSelect';
import MyMessage from '../common/MyMessage';
import Select from '../common/Select';
import TextArea from '../common/TextArea';

const AddPercentageModal = (props) => {
  const { caseData, visible, onClose, user, employees, reinitialize } = props;

  const [percentComplete, setPercentComplete] = useState(0);
  const [caseType, setCaseType] = useState('H-1B CAP');
  const [completionDate, setCompletionDate] = useState('');
  const [completedByNames, setCompletedByNames] = useState('');
  const [completedBy, setCompletedBy] = useState([]);
  const [desc, setDesc] = useState('');
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const createPercentage = async () => {
    if (!validatePercentage()) return;
    const configService = require('../../store/config/service');

    let body = {
      case: caseData.id,
      caseType,
      percentComplete: parseInt(percentComplete),
      percentCompleteString: `${percentComplete}%`,
      completionDate: new Date(completionDate),
      completionDateStamp: +new Date(completionDate),
      completedBy: completedBy.map((el) => el.value),
      completedByNames,
      desc,
      createdBy: user.id,
      createdByName: getUserName(user),
    };

    // console.log('body - ', body);

    const res = await configService.default.createPercentage(body);
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const validatePercentage = () => {
    let flag = true;
    if (!caseType || !caseType.length) {
      flag = false;
      handleError('caseType', 'select a valid case type');
    }
    if (!percentComplete || isNaN(percentComplete)) {
      flag = false;
      handleError('percentComplete', 'select a percentage');
    }
    if (!completionDate || !completionDate.length) {
      flag = false;
      handleError('completionDate', 'select a valid completion date');
    }
    if (!completedBy || !completedBy.length) {
      flag = false;
      handleError('completedBy', 'select a valid emlpoyee');
    }
    if (!completedByNames || !completedByNames.length) {
      flag = false;
      handleError('completedBy', 'select a valid employee');
    }
    if (!desc || !desc.length) {
      flag = false;
      handleError('desc', 'select a add description');
    }
    if (!user || !user.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (!caseData || !caseData.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    return flag;
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  // const addEventToList = () => {
  //   if (events && events.length >= 5) {
  //     // console.log('heyyyy');
  //     handleError('event', 'you can add a max of 5 events');
  //     return;
  //   }
  //   if (!eventAmount || eventAmount < 1) {
  //     handleError('eventAmount', 'please add an amount');
  //     return;
  //   }
  //   if (!eventInfo || !eventInfo.length) {
  //     handleError('eventInfo', 'please add event info');
  //     return;
  //   }
  //   let newEvent = {
  //     eventAmount,
  //     eventInfo,
  //   };
  //   // console.log('newEvent - ', newEvent, events);
  //   setEvents((current) => {
  //     if (!current || !current.length) return [newEvent];
  //     return [...current, newEvent];
  //   });
  //   setEventAmount('');
  //   setEventInfo('');
  // };

  // const removeEvent = (index) => {
  //   setEvents(events.filter((_, i) => i !== index));
  // };

  const doOnClose = () => {
    resetBlankModal();
    onClose();
  };

  const resetBlankModal = () => {
    setCaseType('H-1B CAP');
    setPercentComplete('');
    setCompletionDate('');
    setCompletedByNames('');
    setCompletedBy([]);
    setDesc('');
    setErrors({});
    setMessage({});
    setLoading(false);
  };

  return visible ? (
    <div className="my-modal-container">
      <div className="my-modal-overlay" onClick={doOnClose}></div>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable my-modal z-index-9">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <div>
              <p className="modal-title mb-0">{'Adding'}</p>
              <h3 className="modal-title fw-bold">{'New Percentage'}</h3>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={doOnClose}
            ></button>
          </div>
          <div className="modal-body">
            <div className="container d-flex flex-column justify-content-center align-items-start p-2">
              <div className="mb-2">
                <p className="text-muted fs-italic fw-light size15">
                  Fill in the details below to add a new percentage!
                </p>

                <div className="row d-flex flex-wrap justify-content-start align-items-start">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <Select
                      label={'Case Type'}
                      placeholder={'select a case type'}
                      value={caseType}
                      options={CASE_SUB_TYPES}
                      onChange={(e) => {
                        setCaseType(e.target.value);
                        handleError('caseType', '');
                      }}
                      err={errors.caseType || ''}
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                    <Select
                      label={'Percent Complete'}
                      placeholder={'select percentage completed'}
                      value={percentComplete}
                      options={PERCENTAGE_OPTIONS}
                      onChange={(e) => {
                        setPercentComplete(e.target.value);
                        handleError('percentComplete', '');
                      }}
                      err={errors.percentComplete || ''}
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                    <Input
                      label={'Completion Date'}
                      placeholder={'enter completion date'}
                      type={'date'}
                      value={completionDate}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.completionDate &&
                          errors.completionDate.length
                        ) {
                          handleError('completionDate', '');
                        }
                        setCompletionDate(e.target.value);
                      }}
                      err={errors && errors.completionDate}
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <MultiSelect
                      label={'Completed By'}
                      displayValue={'label'}
                      values={completedBy}
                      options={employees}
                      onSelect={(e) => {
                        setCompletedBy(e);
                        // console.log(e);
                        if (e.length) {
                          setCompletedByNames(
                            e.map((el) => el.label).join(',')
                          );
                        }
                        handleError('completedBy', '');
                      }}
                      onRemove={(e) => {
                        setCompletedBy(e);
                      }}
                      err={errors.completedBy || ''}
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <TextArea
                      label={'Description'}
                      placeholder={'add a description'}
                      value={desc}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.desc &&
                          errors.desc.length
                        ) {
                          handleError('desc', '');
                        }
                        setDesc(e.target.value);
                      }}
                      err={errors && errors.desc}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
            </div>
          </div>
          <div className="px-3">
            <MyMessage
              visible={!!message}
              message={message}
              onClose={() => {
                setMessage(undefined);
              }}
            />
          </div>
          <div
            className="modal-footer"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <button
              type="button"
              className="btn btn-outline ms-2"
              onClick={doOnClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary ms-2"
              onClick={createPercentage}
            >
              Add Percentage
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default AddPercentageModal;
