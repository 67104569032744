import React, { useState } from 'react';
import { LineChart, XAxis, YAxis, Tooltip, CartesianGrid, Line, ResponsiveContainer } from 'recharts';
import { myBlueColor, myBlueColorLight, myBrownColor, myGreenColor, myGreenColorLight, myRedColor, myYellowColor } from '../constants/colors';

const LineStatCard = (props) => {
  const { title, percentage, data, dataKey, key1, key2, key3, key4, key5 } = props;

  return (
    <div className="card chart-card pb-2">
      <div className="card-head px-3 py-3 d-flex justify-content-start align-items-center">
        <div>
          <h5 className="fw-semi-bold">{title}</h5>
          <small className="fw-regular">{percentage} from last year</small>
        </div>
      </div>
      <div className="card-body p-0 py-2 m-0" style={{fontSize: '.8rem'}}>
        <ResponsiveContainer width="95%" height={375}>
        <LineChart
          data={data}
          margin={{ top: 5, right: 20, left: 10, bottom: 5 }}
        >
          <XAxis dataKey={dataKey} />
          <YAxis />
          <Tooltip />
          <CartesianGrid stroke="#f5f5f5" />
          <Line type="monotone" dataKey={key1} stroke={myGreenColor} yAxisId={0} />
          <Line type="monotone" dataKey={key2} stroke={myRedColor} yAxisId={0} />
          <Line type="monotone" dataKey={key3} stroke={myBlueColor} yAxisId={0} />
          {key4 ? <Line type="monotone" dataKey={key4} stroke={myYellowColor} yAxisId={0} /> : <React.Fragment />}
          {key5 ? <Line type="monotone" dataKey={key5} stroke={myBrownColor} yAxisId={0} /> : <React.Fragment />}
        </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default LineStatCard;
