import React, { useEffect, useState } from 'react';
import { getUserName } from '../utils/userUtils';
import TimesheetList from './TimesheetList';
import AddTimesheetModal from './modals/AddTimesheetModal';
import EditTimesheetModal from './modals/EditTimesheetModal';

const Timesheets = (props) => {
  const { caseData, user } = props;
  const [employees, setEmployees] = useState([]);
  const [showAddTimesheetModal, setShowAddTimesheetModal] = useState(undefined);
  const [showEditTimesheetModal, setShowEditTimesheetModal] = useState(undefined);
  const [reinitializing, setReinitializing] = useState(false);

  useEffect(() => {
    initEmployees();
  }, []);

  const initEmployees = async () => {
    if (!user || !user.userType) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getUsers(); // change to employees only
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      setEmployees(
        res.result.map((emp) => {
          return {
            label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
            value: emp.id,
          };
        })
      );
    }
  };

  const openAddTimesheetModal = () => {
    setShowAddTimesheetModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowAddTimesheetModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
    });
  };

  const openEditTimesheetModal = (timesheetId, onEdit = false) => {
    setShowEditTimesheetModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowEditTimesheetModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
      timesheetId: timesheetId ? timesheetId : {},
      onEdit,
    });
  };

  const reinitialize = (bool = true) => {
    setReinitializing(bool);
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Timesheets</h4>
        <button
          className="btn btn-sm btn-outline-success shadow-sm ms-2"
          style={{ width: 'max-content' }}
          onClick={openAddTimesheetModal}
        >
          Add New <i className="bi bi-plus ms-1"></i>
        </button>
      </div>
      <div className="w-100">
        <TimesheetList
          caseId={(caseData || {}).id}
          user={user}
          reinitialize={reinitialize}
          reinitializing={reinitializing}
          openEditTimesheetModal={(timesheetId, onEdit) =>
            openEditTimesheetModal(timesheetId, onEdit)
          }
        />

        <AddTimesheetModal
          visible={!!showAddTimesheetModal}
          caseData={showAddTimesheetModal && showAddTimesheetModal.caseData}
          onClose={showAddTimesheetModal && showAddTimesheetModal.onClose}
          user={showAddTimesheetModal && showAddTimesheetModal.user}
          employees={showAddTimesheetModal && showAddTimesheetModal.employees}
          reinitialize={reinitialize}
        />

        <EditTimesheetModal
          visible={!!showEditTimesheetModal}
          caseData={showEditTimesheetModal && showEditTimesheetModal.caseData}
          onClose={showEditTimesheetModal && showEditTimesheetModal.onClose}
          user={showEditTimesheetModal && showEditTimesheetModal.user}
          employees={showEditTimesheetModal && showEditTimesheetModal.employees}
          timesheetId={showEditTimesheetModal && showEditTimesheetModal.timesheetId}
          onEdit={showEditTimesheetModal && showEditTimesheetModal.onEdit}
          reinitialize={reinitialize}
        />
      </div>
    </div>
  );
};

export default Timesheets;
