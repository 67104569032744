import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import EmployeeList from '../EmployeeList';
import EditUserModal from '../modals/EditUserModal';

const Employees = (props) => {
  const { user, isLoggedIn } = props;

  const [showEditUserModal, setShowEditUserModal] = useState(undefined);
  const [reinitializing, setReinitializing] = useState(false);

  const openEditUserModal = (employeeId) => {
    setShowEditUserModal({
      onClose: () => {
        setShowEditUserModal(undefined);
      },
      user: user ? user : {},
      employeeId: employeeId ? employeeId : {},
    });
  };

  const reinitialize = (bool = true) => {
    setReinitializing(bool);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mb-4">
          <EmployeeList
            user={user}
            openEditUserModal={(employeeId) => openEditUserModal(employeeId)}
            reinitialize={reinitialize}
            reinitializing={reinitializing}
          />
        </div>
      </div>
      <EditUserModal
        visible={!!showEditUserModal}
        onClose={showEditUserModal && showEditUserModal.onClose}
        user={showEditUserModal && showEditUserModal.user}
        employeeId={showEditUserModal && showEditUserModal.employeeId}
        reinitialize={reinitialize}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth: { user, isLoggedIn } = {} } = state;
  return {
    user,
    isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload) => {
      dispatch(login(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Employees);
