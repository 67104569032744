import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Input from '../common/Input';
import Select from '../common/Select';
import {
  CASE_SUB_TYPES,
  CASE_TYPES,
  CIS_TYPES,
  COUNTRIES,
  OFFICES,
  PHONE_TYPES,
} from '../../constants/constants';
import {
  getUserName,
  isValidEmail,
  isValidPhone,
  getMahDate,
  createCompany,
} from '../../utils/userUtils';
import TextArea from '../common/TextArea';
import Checkbox from '../common/Checkbox';
import { useHistory } from 'react-router-dom';

const CreateCase = (props) => {
  const { user, isLoggedIn } = props;

  const [openedAt, setOpenedAt] = useState(getMahDate());
  const [office, setOffice] = useState('dallas');
  const [companies, setCompanies] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [caseType, setCaseType] = useState('company');
  const [caseSubType, setCaseSubType] = useState('H-1B CAP');
  const [counsel, setCounsel] = useState('');
  const [paralegal, setParalegal] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyNameForSearch, setCompanyNameForSearch] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [primaryFullName, setPrimaryFullName] = useState('');
  const [primaryAlien, setPrimaryAlien] = useState('');
  const [primaryCisStatus, setPrimaryCisStatus] = useState('none');
  const [secFullName, setSecFullName] = useState('');
  const [secAlien, setSecAlien] = useState('');
  const [secCisStatus, setSecCisStatus] = useState('none');
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [city, setCity] = useState('');
  const [stateRegion, setStateRegion] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState('');
  const [phones, setPhones] = useState([]);
  const [phoneNum, setPhoneNum] = useState('');
  const [phoneType, setPhoneType] = useState('');
  const [phoneNotes, setPhoneNotes] = useState('');
  const [displayImg, setDisplayImg] = useState('');
  const [contractNotes, setContractNotes] = useState('');
  const [activateH1B, setActivateH1B] = useState(false);
  const [activateCriminal, setActivateCriminal] = useState(false);
  const [activatePerm, setActivatePerm] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    getCompanyList(caseType);
  }, [caseType]);

  useEffect(() => {
    initEmployees();
  }, []);

  const initEmployees = async () => {
    if (!user || !user.userType) return;
    const configService = require('../../store/config/service');
    const res = await configService.default.getUsers(); // change to employees only
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      setEmployees(
        res.result.map((emp) => {
          return {
            label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
            value: emp.id,
          };
        })
      );
    }
  };

  const getCompanyList = async (caseType) => {
    if (caseType !== 'beneficiary') return;
    // fetch companies from db
    const configService = require('../../store/config/service');
    const res = await configService.default.getCompanies();
    // console.log('res', res);
    if (res.result) {
      setCompanies(
        res.result.map((company) => {
          return { label: company.companyName, value: company.id };
        })
      );
      setCompanyNameForSearch(res.result[0].companyName);
      setCompanyId(res.result[0].id);
    }
  };

  const validateCompany = () => {
    let flag = true;
    if (!companyName || !companyName.length) {
      handleError('companyName', 'add a valid company name');
    }
    return flag;
  };

  const createCase = async () => {
    if (!validateCase()) return;
    // console.log('Case is valid');
    const configService = require('../../store/config/service');
    let companyIdx;
    if (caseType === 'company') {
      const companyRes = await createCompany(companyName, validateCompany, companies);
      if (companyRes) {
        companyIdx = companyRes;
      }
    } else if (caseType === 'beneficiary') {
      companyIdx = companyId;
    }

    let body = {
      openedAt: new Date(openedAt),
      office,
      status: 'OPEN',
      caseType,
      counsel,
      companyName: '',
      // paralegal,
      primaryContact: {
        fullName: primaryFullName,
        // alien: primaryAlien,
        cisStatus: primaryCisStatus,
      },
      secondaryContact: {
        //   fullName: secFullName,
        //   alien: secAlien,
        cisStatus: secCisStatus,
      },
      address: {},
      emails,
      // phones,
      // displayImg,
      // contractNotes,
      activateH1B,
      activateCriminal,
      activatePerm,
      createdBy: user.id,
    };

    if(caseSubType && caseSubType.length && caseSubType !== 'Select Type') body.caseSubType = caseSubType;

    if (paralegal && paralegal.length) body.paralegal = paralegal;
    if (companyIdx && companyIdx.length) body.company = companyIdx;
    if (
      (caseType === 'company' || caseType === 'beneficiary') &&
      companyNameForSearch &&
      companyNameForSearch.length
    )
      body.companyName = companyNameForSearch;
    if (primaryAlien && primaryAlien.length)
      body.primaryContact.alien = primaryAlien;
    if (secFullName && secFullName.length)
      body.secondaryContact.fullName = secFullName;
    if (secAlien && secAlien.length) body.secondaryContact.alien = secAlien;
    if (line1 && line1.length) body.address.line1 = line1;
    if (line2 && line2.length) body.address.line2 = line2;
    if (city && city.length) body.address.city = city;
    if (stateRegion && stateRegion.length) body.address.state = stateRegion;
    if (zipCode && zipCode.length) body.address.zip = zipCode;
    if (country && country.length) body.address.country = country;
    if (phones && phones.length) body.phones = phones;
    if (displayImg && displayImg.length) body.displayImg = displayImg;
    if (contractNotes && contractNotes.length)
      body.contractNotes = contractNotes;

    // console.log('body - is - ', body);

    const res = await configService.default.createCase(body);
    if (res.result) {
      // console.log('created case - ', res.result);
      let consult = {
        consultationDate: new Date(openedAt),
        caseType,
        office,
        case: res.result.id,
        companyName: '',
        isConverted: true,
        primaryName: primaryFullName,
        address: {},
        email: emails[0],
        consultationAttorney: counsel,
        createdBy: user.id,
      };
      if(caseSubType && caseSubType.length && caseSubType !== 'Select Type') consult.caseSubType = caseSubType;
      if (companyIdx && companyIdx.length) consult.company = companyIdx;
      if (
        (caseType === 'company' || caseType === 'beneficiary') &&
        companyNameForSearch &&
        companyNameForSearch.length
      )
        consult.companyName = companyNameForSearch;
      if (secFullName && secFullName.length)
        consult.secondaryName = secFullName;
      if (line1 && line1.length) consult.address.line1 = line1;
      if (line2 && line2.length) consult.address.line2 = line2;
      if (city && city.length) consult.address.city = city;
      if (stateRegion && stateRegion.length)
        consult.address.state = stateRegion;
      if (zipCode && zipCode.length) consult.address.zip = zipCode;
      if (country && country.length) consult.address.country = country;
      if (phones && phones.length) consult.phone = phones[0].phoneNum;

      const consultRes = await configService.default.createConsultation(
        consult
      );
      if (!consultRes) {
      }
      if (consultRes.error) {
      }
      if (consultRes.result) {
        // console.log('created consult as well!', consultRes.result);
        history.push(`/cases/${res.result.id}`);
      }
    }
  };

  const validateCase = () => {
    let flag = true;
    if (!openedAt || !openedAt.length) {
      flag = false;
      handleError('openedAt', 'select a valid date');
    }
    if (!office || !office.length) {
      flag = false;
      handleError('office', 'select a valid office');
    }
    if (!caseType || !caseType.length) {
      flag = false;
      handleError('caseType', 'select a valid case type');
    }
    if (caseType !== 'individual') {
      if (!companyName && !companyId) {
        flag = false;
        handleError('companyName', 'add a valid company');
      }
    }
    if (!counsel || !counsel.length) {
      flag = false;
      handleError('counsel', 'select a valid counsel');
    }
    // if (!paralegal || !paralegal.length) {
    //   flag = false;
    //   handleError('paralegal', 'select a valid paralegal');
    // }
    if (!primaryFullName || !primaryFullName.length) {
      flag = false;
      handleError('primaryFullName', 'please add a name');
    }
    // if (!primaryAlien || !primaryAlien.length) {
    //   flag = false;
    //   handleError('primaryAlien', 'add a valid alien');
    // }
    if (!primaryCisStatus || !primaryCisStatus.length) {
      flag = false;
      handleError('primaryCisStatus', 'select a valid CIS status');
    }
    // if (caseSubType && caseSubType.length && caseSubType === 'Select Type') {
    //   flag = false;
    //   handleError('caseSubType', 'please add a name');
    // }
    // if (!secAlien || !secAlien.length) {
    //   flag = false;
    //   handleError('secAlien', 'add a valid alien');
    // }
    // if (!secCisStatus || !secCisStatus.length) {
    //   flag = false;
    //   handleError('secCisStatus', 'select a valid CIS status');
    // }
    // if (!line1 || !line1.length) {
    //   flag = false;
    //   handleError('line1', 'please add address line 1');
    // }
    // if (!city || !city.length) {
    //   flag = false;
    //   handleError('city', 'add a valid city');
    // }
    // if (!stateRegion || !stateRegion.length) {
    //   flag = false;
    //   handleError('stateRegion', 'add a valid state');
    // }
    // if (!country || !country.length) {
    //   flag = false;
    //   handleError('country', 'select a country');
    // }
    if (!emails || !emails.length) {
      flag = false;
      handleError('email', 'please atleast one email');
    }
    // if (!phones || !phones.length) {
    //   flag = false;
    //   handleError('phones', 'add atleast one phone');
    // }
    return flag;
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  const addEmailToList = () => {
    if (emails && emails.length >= 5) {
      // console.log('heyyyy');
      handleError('email', 'you can add a max of 5 emails');
      return;
    }
    if (!isValidEmail(email)) {
      handleError('email', 'please add a valid email');
      return;
    }
    // console.log('email - ', email, emails);
    setEmails((current) => {
      if (!current || !current.length) return [email];
      return [...current, email];
    });
    setEmail('');
  };

  const removeEmail = (index) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  const removePhone = (index) => {
    setPhones(phones.filter((_, i) => i !== index));
  };

  const addPhoneInfoToList = () => {
    if (phones && phones.length >= 5) {
      // console.log('heyyyy');
      handleError('phone', 'you can add a max of 5 phones');
      return;
    }
    if (!isValidPhone(phoneNum)) {
      handleError('phoneNum', 'please add a valid phone number');
      return;
    }
    if (!phoneType || !phoneType.length) {
      handleError('phoneType', 'please select a valid phone type');
      return;
    }
    let newPhone = {
      phoneNum,
      type: phoneType,
    };
    if (phoneNotes && phoneNotes.length) {
      newPhone.notes = phoneNotes;
    }
    // console.log('newPhone - ', newPhone, phones);
    setPhones((current) => {
      if (!current || !current.length) return [newPhone];
      return [...current, newPhone];
    });
    setPhoneNum('');
    setPhoneNotes('');
  };
  if (!user || !isLoggedIn) return <React.Fragment />;
  return (
    <div className="row">
      <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 col-12">
        <div className="card p-3">
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <h4>Create New Case</h4>
              <p className="text-muted fs-italic fw-light size15">
                Fill in the details below to initiate a new case!
              </p>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <button className="btn btn-primary" onClick={createCase}>
                Create New Case!
              </button>
            </div>
          </div>
          <div className="row d-flex flex-wrap justify-content-start align-items-start">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Opening Date'}
                placeholder={'enter case opened at date'}
                type={'date'}
                value={openedAt}
                onChange={(e) => {
                  setOpenedAt(e.target.value);
                  handleError('openedAt', '');
                }}
                err={errors.openedAt || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                label={'Office'}
                placeholder={'select an office'}
                value={office}
                options={OFFICES}
                onChange={(e) => {
                  setOffice(e.target.value);
                  handleError('office', '');
                }}
                err={errors.office || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                label={'Case Category'}
                placeholder={'select a case category'}
                value={caseType}
                options={CASE_TYPES}
                onChange={(e) => {
                  setCaseType(e.target.value);
                  handleError('caseType', '');
                }}
                err={errors.caseType || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                label={'Case Type'}
                placeholder={'select a case type'}
                value={caseSubType}
                options={CASE_SUB_TYPES}
                onChange={(e) => {
                  setCaseSubType(e.target.value);
                  handleError('caseSubType', '');
                }}
                err={errors.caseSubType || ''}
              />
            </div>

            <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-xs-12 col-12">
              {caseType === 'company' || caseType === 'individual' ? (
                <Input
                  disabled={caseType === 'individual'}
                  label={'Company / Petitioner name'}
                  placeholder={"enter company or petitioner's name"}
                  type={'text'}
                  autoCapitalize={'words'}
                  autoComplete={'off'}
                  value={companyName}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      e.target.value.length &&
                      errors.companyName &&
                      errors.companyName.length
                    ) {
                      handleError('companyName', '');
                    }
                    setCompanyName(e.target.value);
                    setCompanyNameForSearch(e.target.value);
                    handleError('companyName', '');
                  }}
                  err={errors.companyName || ''}
                />
              ) : caseType === 'beneficiary' ? (
                <Select
                  label={'Company'}
                  placeholder={'select a company'}
                  options={companies}
                  value={companyId}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      e.target.value.length &&
                      errors.companyId &&
                      errors.companyId.length
                    ) {
                      handleError('companyId', '');
                    }
                    setCompanyId(e.target.value);
                    setCompanyNameForSearch(
                      (
                        companies.filter(
                          (cm) => cm.value === e.target.value
                        )[0] || {}
                      ).label || ''
                    );
                  }}
                  err={errors.companyId || ''}
                />
              ) : (
                <React.Fragment />
              )}
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                label={'Counsel'}
                placeholder={'select a counsel'}
                value={counsel}
                options={employees}
                onChange={(e) => {
                  setCounsel(e.target.value);
                  handleError('counsel', '');
                }}
                err={errors.counsel || ''}
              />
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                label={'Paralegal'}
                placeholder={'select a paralegal'}
                value={paralegal}
                options={employees}
                onChange={(e) => {
                  setParalegal(e.target.value);
                  handleError('paralegal', '');
                }}
                err={errors.paralegal || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />


            <h4 className="size16 text-dark fw-semi-bold mb-1">
              Add Primary Contact Info
            </h4>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Full Name'}
                placeholder={'enter their full name'}
                type={'text'}
                value={primaryFullName}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.primaryFullName &&
                    errors.primaryFullName.length
                  ) {
                    handleError('primaryFullName', '');
                  }
                  setPrimaryFullName(e.target.value);
                }}
                err={errors.primaryFullName || ''}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Alien #'}
                placeholder={'enter their alien id'}
                type={'text'}
                value={primaryAlien}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.primaryAlien &&
                    errors.primaryAlien.length
                  ) {
                    handleError('primaryAlien', '');
                  }
                  setPrimaryAlien(e.target.value.toUpperCase());
                }}
                err={errors.primaryAlien || ''}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                label={'CIS Status'}
                placeholder={'select a CIS status'}
                options={CIS_TYPES}
                value={primaryCisStatus}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.primaryCisStatus &&
                    errors.primaryCisStatus.length
                  ) {
                    handleError('primaryCisStatus', '');
                  }
                  setPrimaryCisStatus(e.target.value);
                }}
                err={errors.primaryCisStatus || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <h4 className="size16 text-dark fw-semi-bold mb-1">
              Add Secondary Contact Info
            </h4>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Full Name'}
                placeholder={'enter their full name'}
                type={'text'}
                value={secFullName}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.secFullName &&
                    errors.secFullName.length
                  ) {
                    handleError('secFullName', '');
                  }
                  setSecFullName(e.target.value);
                }}
                err={errors.secFullName || ''}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Alien #'}
                placeholder={'enter their alien id'}
                type={'text'}
                value={secAlien}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.secAlien &&
                    errors.secAlien.length
                  ) {
                    handleError('secAlien', '');
                  }
                  setSecAlien(e.target.value.toUpperCase());
                }}
                err={errors.secAlien || ''}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                label={'CIS Status'}
                placeholder={'select a CIS status'}
                options={CIS_TYPES}
                value={secCisStatus}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.secCisStatus &&
                    errors.secCisStatus.length
                  ) {
                    handleError('secCisStatus', '');
                  }
                  setSecCisStatus(e.target.value);
                }}
                err={errors.secCisStatus || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <h4 className="size16 text-dark fw-semi-bold mb-1">Add Address</h4>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Line 1'}
                placeholder={'enter address line 1'}
                type={'text'}
                value={line1}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.line1 &&
                    errors.line1.length
                  ) {
                    handleError('line1', '');
                  }
                  setLine1(e.target.value);
                }}
                err={errors.line1 || ''}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Line 2'}
                placeholder={'enter address line 2'}
                type={'text'}
                value={line2}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.line2 &&
                    errors.line2.length
                  ) {
                    handleError('line2', '');
                  }
                  setLine2(e.target.value);
                }}
                err={errors.line2 || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'City'}
                placeholder={'enter address city'}
                type={'text'}
                value={city}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.city &&
                    errors.city.length
                  ) {
                    handleError('city', '');
                  }
                  setCity(e.target.value);
                }}
                err={errors.city || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'State'}
                placeholder={'enter address state'}
                type={'text'}
                value={stateRegion}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.stateRegion &&
                    errors.stateRegion.length
                  ) {
                    handleError('stateRegion', '');
                  }
                  setStateRegion(e.target.value);
                }}
                err={errors.stateRegion || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Zipcode'}
                placeholder={'enter address zipcode'}
                type={'text'}
                value={zipCode}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.zipCode &&
                    errors.zipCode.length
                  ) {
                    handleError('zipCode', '');
                  }
                  setZipCode(e.target.value);
                }}
                err={errors.zipCode || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Country / Nationality'}
                placeholder={'enter country / nationality'}
                type={'text'}
                value={country}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.country &&
                    errors.country.length
                  ) {
                    handleError('country', '');
                  }
                  setCountry(e.target.value);
                }}
                err={errors.country || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <h4 className="size16 text-dark fw-semi-bold mb-1">Add Emails</h4>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                // disabled={emails && emails.length >= 5}
                label={'Email'}
                placeholder={'enter email address'}
                type={'text'}
                value={email}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.email &&
                    errors.email.length
                  ) {
                    handleError('email', '');
                  }
                  setEmail(e.target.value);
                }}
                err={errors && errors.email}
              />
              <button
                className="btn btn-sm btn-outline-primary mx-3"
                onClick={addEmailToList}
              >
                Add to list
              </button>
            </div>

            {emails && emails.length ? (
              <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-xs-12 col-12 my-3">
                <label className="form-label d-block">
                  Emails you've added
                </label>
                {emails.map((email, i) => (
                  <small
                    key={i}
                    className="badge my-green-bg size15 fw-normal rounded shadow-sm me-2 mb-1 px-1"
                  >
                    {email}
                    <i
                      onClick={() => removeEmail(i)}
                      className="bi bi-x-circle-fill ms-1"
                    ></i>
                  </small>
                ))}
              </div>
            ) : (
              <React.Fragment />
            )}

            <hr className="hy-light-hr mt-3 mb-4" />

            <h4 className="size16 text-dark fw-semi-bold mb-1">Add Phones</h4>

            <div className="row">
              <div className="order-2 col-xl-8 col-lg-8 col-md-6 col-sm-12 col-xs-12 col-12">
                <div className="row">
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                    <Input
                      // disabled={phones && phones.length >= 5}
                      label={'Phone number'}
                      placeholder={'enter phone number'}
                      type={'tel'}
                      value={phoneNum}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.phoneNum &&
                          errors.phoneNum.length
                        ) {
                          handleError('phoneNum', '');
                        }
                        setPhoneNum(e.target.value);
                      }}
                      err={errors && errors.phoneNum}
                    />
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                    <Select
                      // disabled={phones && phones.length >= 5}
                      label={'Type'}
                      placeholder={'select a type'}
                      options={PHONE_TYPES}
                      value={phoneType}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.phoneType &&
                          errors.phoneType.length
                        ) {
                          handleError('phoneType', '');
                        }
                        setPhoneType(e.target.value);
                      }}
                      err={errors.phoneType || ''}
                    />
                  </div>
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <TextArea
                      // disabled={phones && phones.length >= 5}
                      placeholder={'add notes'}
                      value={phoneNotes}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.phoneNotes &&
                          errors.phoneNotes.length
                        ) {
                          handleError('phoneNotes', '');
                        }
                        setPhoneNotes(e.target.value);
                      }}
                      err={errors && errors.phoneNotes}
                    />
                    <button
                      className="btn btn-sm btn-outline-primary mx-3"
                      onClick={addPhoneInfoToList}
                    >
                      Add to list
                    </button>
                  </div>
                </div>
              </div>
              {phones && phones.length ? (
                <div className="order-1 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 my-3">
                      <label className="form-label d-block">
                        Phones you've added
                      </label>
                      {phones.map((phone, i) => (
                        <div
                          key={i}
                          className="badge w-100 d-flex justify-content-between align-items-center size15 fw-normal rounded shadow-sm my-green-bg mb-2 px-1"
                        >
                          <div className="d-flex flex-column justify-content-center align-items-start overflow-hidden">
                            <p className="fw-semi-bold text-dark size15 mb-1">
                              {phone.type}
                            </p>
                            <p className="mb-2">{phone.phoneNum}</p>
                            <p className="fw-light text-muted size14 mb-1">
                              {phone.notes || ''}
                            </p>
                          </div>
                          <i
                            onClick={() => removePhone(i)}
                            className="bi bi-x-circle-fill ms-1"
                          ></i>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment />
              )}
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
              <TextArea
                label={'Case Notes'}
                placeholder={'add case notes'}
                value={contractNotes}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.contractNotes &&
                    errors.contractNotes.length
                  ) {
                    handleError('contractNotes', '');
                  }
                  setContractNotes(e.target.value);
                }}
                err={errors && errors.contractNotes}
              />
            </div>

            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
              <div className="d-flex justify-content-start align-items-center">
                <Checkbox
                  type="checkbox"
                  checked={activateH1B}
                  onClick={() => {
                    setActivateH1B((current) => !current);
                  }}
                  label={'H1B Module'}
                  namex="activateH1B"
                  idx="activateH1BYes"
                />
                <Checkbox
                  type="checkbox"
                  checked={activateCriminal}
                  onClick={() => {
                    setActivateCriminal((current) => !current);
                  }}
                  label={'Criminal Module'}
                  namex="activateCriminal"
                  idx="activateCriminalYes"
                />
                <Checkbox
                  type="checkbox"
                  checked={activatePerm}
                  onClick={() => {
                    setActivatePerm((current) => !current);
                  }}
                  label={'Perm Module'}
                  namex="activatePerm"
                  idx="activatePermYes"
                />
              </div>
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <button className="btn btn-primary" onClick={createCase}>
              Create New Case
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth: { user, isLoggedIn } = {} } = state;
  return {
    user,
    isLoggedIn,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateCase);
