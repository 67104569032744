import { format, formatDistance } from 'date-fns';
import React from 'react';
import { CONTRACT_TYPES_MAP } from '../constants/constants';
import { dateFormat, getUserName, priceFormat } from '../utils/userUtils';

const TimesheetCell = (props) => {
  const { dataKey, data, openEditTimesheetModal } = props;

  const PrimaryName = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <small className="badge rounded px-1 text-dark fw-regular mx-1 capitalize">
        {data.fullName}
      </small>
    );
  };

  const TimesheetType = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-normal mx-1 capitalize">
          {data}
        </small>
      </div>
    );
  };

  const OpeningDate = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-regular mx-1 capitalize">
          {dateFormat(new Date(data))}
        </small>
      </div>
    );
  };

  const TimesheetId = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 fw-normal mx-1 capitalize bg-light-dark text-white">
          {data.id.substring(0, 4) +
            '...' +
            data.id.substring(data.id.length - 4, data.id.length)}
          <i className="bi bi-box-arrow-up-right ms-1"></i>
        </small>
      </div>
    );
  };

  const TimesheetNum = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 fw-normal mx-1 capitalize bg-light-dark text-white">
          {data}
          <i className="bi bi-box-arrow-up-right ms-1"></i>
        </small>
      </div>
    );
  };

  const Text = (props) => {
    const {
      data,
      light,
      trunc = false,
      longTrunc = false,
      left = false,
    } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small
          className={`badge px-1 ${light ? 'fw-normal' : 'fw-regular'} ${
            trunc ? 'truncated' : ''
          } ${longTrunc ? 'long-trunc' : ''} ${
            left ? 'text-left' : ''
          } capitalize text-dark`}
        >
          {data}
        </small>
      </div>
    );
  };

  const CreationDate = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className={`badge px-1 fw-normal capitalize text-dark`}>
          {dateFormat(new Date(data))}
        </small>
      </div>
    );
  };

  const Times = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <small
        className={`px-1 fw-normal capitalize text-dark d-flex flex-column align-items-start justify-content-start`}
      >
        {data && data.length ? (
          data.map((tm, i) => (
            <div
              key={i}
              className="mb-2 d-flex flex-row justify-content-evenly align-items-center w-100 mt-0 pt-0"
            >
              <small className="flex-one mb-0 fw-semi-bold text-dark px-1">
                <span className="text-muted fw-light">From </span> {tm.start}
              </small>
              <small className="flex-one mb-0 fw-semi-bold text-dark px-1">
                <span className="text-muted fw-light">To </span> {tm.end}
              </small>
              <small className="flex-one mb-0 fw-semi-bold text-dark px-1 d-flex flex-row justify-content-end align-items-center">
                <span className="text-muted fw-light me-1">Hours </span>{' '}
                {tm.timeSpent}
              </small>
            </div>
          ))
        ) : (
          <React.Fragment />
        )}

        {data && data.length ? (
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 d-flex flex-row justify-content-around align-items-center mb-3">
            <small className="mx-2 bt-1 pt-1 flex-one">Total Time Spent</small>
            <small className="mx-2 bt-1 pt-1 fw-semi-bold">
              {data
                .reduce((acc, cur) => acc + parseFloat(cur.timeSpent), 0)
                .toFixed(2)}{' '}
              hrs
            </small>
          </div>
        ) : (
          <React.Fragment />
        )}
      </small>
    );
  };

  const Emails = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge rounded text-and-border-blue px-1 fw-normal mx-1">
          {data[0]}
        </small>
        {/* {data.map((email) => (
          <small className="badge rounded text-and-border-blue px-1 fw-normal mx-1">
            {email}
          </small>
        ))} */}
      </div>
    );
  };

  return (
    <div className="my-2">
      {/* {dataKey === 'openedAt' ? (
        <OpeningDate data={data} />
      ) : (
        <React.Fragment />
      )} */}

      {dataKey === 'caseType' ? (
        <TimesheetType data={data} />
      ) : (
        <React.Fragment />
      )}

      {dataKey === 'times' ? <Times data={data} /> : <React.Fragment />}

      {dataKey === 'completedBy' ? (
        <Text data={data.map((el) => getUserName(el)).join(', ')} />
      ) : (
        <React.Fragment />
      )}
      {dataKey === 'desc' ? (
        <Text data={data} longTrunc left />
      ) : (
        <React.Fragment />
      )}

      {dataKey === 'rate' ? (
        <Text data={data && !isNaN(data) ? priceFormat(data) : 'Flat'} />
      ) : (
        <React.Fragment />
      )}

      {dataKey === 'completionDateStamp' ? (
        <CreationDate data={data} />
      ) : (
        <React.Fragment />
      )}
      {/* {dataKey === 'status' ? <Text data={data} /> : <React.Fragment />} */}
      {/* {dataKey === 'name' ? <Text data={data} /> : <React.Fragment />}

      {dataKey === 'location' ? <Text data={data} light /> : <React.Fragment />}

      {dataKey === 'case' ? <TimesheetId data={data} /> : <React.Fragment />} */}

      {/* {dataKey === 'case' ? <TimesheetId data={data} /> : <React.Fragment />} */}
      {/* {dataKey === 'caseNum' ? <TimesheetNum data={data} /> : <React.Fragment />} */}
    </div>
  );
};

export default TimesheetCell;
