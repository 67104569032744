import React from 'react';
import { Multiselect } from 'multiselect-react-dropdown';

const MultiSelect = (props) => {
  const {
    disabled = false,
    label,
    displayValue,
    options,
    multiple = true,
    values,
    onSelect,
    onRemove,
    err,
  } = props;

  return (
    <div className="my-input-group">
      {label && <label className="form-label">{label}</label>}
      <Multiselect
        disable={disabled}
        singleSelect={!multiple}
        options={options} // Options to display in the dropdown
        selectedValues={values} // Preselected value to persist in dropdown
        onSelect={onSelect} // Function will trigger on select event
        onRemove={onRemove} // Function will trigger on remove event
        displayValue={displayValue} // Property name to display in the dropdown options
      />
      {err && (
        <div className="form-error">
          <small className="form-error-text">{err}</small>
        </div>
      )}
    </div>
  );
};

export default MultiSelect;
