import { format } from 'date-fns';
import React from 'react';
import {
  createH1bReport,
  createPermReport,
  dateFormat,
} from '../utils/userUtils';

const ImmigrationReport = (props) => {
  const { user } = props;

  const runPermReport = async () => {
    // console.log('running report');
    const configService = require('../store/config/service');
    const res = await configService.default.getAllPerms();
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      const data = createPermReport(res.result);
      openPrintPermReport(data);
    }
  };

  const openPrintPermReport = (data) => {
    if (!data || !data.length) return alert('No data found for your filters!');
    // console.log('PERM Data', data);
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');
    mywindow.document.write(`
      <div style="display: flex; justify-content: between; align-items: center">
        <div style="display: flex; flex: 0">
          <img
            height="61"
            width="100"
            alt=""
            src="/assets/img/logo.png"
          />
        </div>
        <div style="display: flex; flex: 1">
          <h3 style="font-weight: 500; padding: 0 1rem; text-transform: capitalize;">Immigration Report - PERM</h3>
        </div>
        <div style="display: flex;">
          <h3 style="font-weight: 400; padding: 0 1rem">${dateFormat(
            Date.now()
          )}</h3>
        </div>
      </div>

      ${
        data.length
          ? `
        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
          <div style="display: flex; flex: 1; background-color: #345; color: #fefefe">
            <h5 style="padding: .5rem; margin: 0;">Case #</h5>
          </div>
          <div style="display: flex; flex: 4; background-color: #345; color: #fefefe">
            <h5 style="padding: .5rem; margin: 0;">Client Info</h5>
          </div>
          <div style="display: flex; flex: 2; background-color: #345; color: #fefefe">
            <h5 style="padding: .5rem; margin: 0;">Creation Date</h5>
          </div>
          <div style="display: flex; flex: 4; background-color: #345; color: #fefefe">
            <h5 style="padding: .5rem; margin: 0;">Last Step Completed</h5>
          </div>
          <div style="display: flex; flex: 2; background-color: #345; color: #fefefe">
            <h5 style="padding: .5rem; margin: 0;">Last Step Date</h5>
          </div>
        </div>
      `
          : ``
      }
      ${data
        .map(
          (perm) => `
      <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
      <div style="display: flex; flex: 1; color: #345">
        <h5 style="padding: .5rem; margin: 0;">${perm.case.caseNum}</h5>
      </div>
      <div style="display: flex; flex-direction: column; flex: 4; color: #345">
        <h5 style="padding: .5rem; margin: 0;">
        <span style="font-weight: 400; font-size: 10px;">
          Company${' '}
        </span>
        ${perm.case.companyName || ''}</h5>
        <h5 style="padding: .5rem; margin: 0;">
        <span style="font-weight: 400; font-size: 10px;">
          Client${' '}
        </span>
        ${perm.case.primaryContact.fullName || ''}</h5>
      </div>
      <div style="display: flex; flex: 2; color: #345">
        <h5 style="padding: .5rem; margin: 0;">${dateFormat(
          new Date(perm.case.openedAt)
        )}</h5>
      </div>
      <div style="display: flex; flex: 4; color: #345">
        <h5 style="padding: .5rem; margin: 0;">${perm.latestStep.title}</h5>
      </div>
      <div style="display: flex; flex: 2; color: #345">
        <h5 style="padding: .5rem; margin: 0;">${dateFormat(
          new Date(perm.latestStep.finishDate)
        )}</h5>
      </div>
    </div>
    <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; border-top: 1px solid #9ab3; ; border-bottom: 2px solid #9ab6;">
    <div style="display: flex; flex: 1; color: #345"></div>
          <div style="display: flex; flex: 2; color: #345">
            <h5 style="padding: .5rem; margin: 0;; font-weight: 400">
              Latest Step Notes
            </h5>
          </div>
          <div style="display: flex; flex: 10; color: #345">
          <h5 style="padding: .5rem; margin: 0">
          ${perm.latestStep.notes}
            </h5>
          </div>
        </div>
      `
        )
        .join('')}
      
    `);
    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const runH1bReport = async () => {
    // console.log('running report');
    const configService = require('../store/config/service');
    const res = await configService.default.getAllH1bs();
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      const data = createH1bReport(res.result);
      openPrintH1bReport(data);
    }
  };

  const openPrintH1bReport = (data) => {
    if (!data || !data.length) return alert('No data found for your filters!');
    // console.log('H1B Data', data);
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');
    mywindow.document.write(`
      <div style="display: flex; justify-content: between; align-items: center">
        <div style="display: flex; flex: 0">
          <img
            height="61"
            width="100"
            alt=""
            src="/assets/img/logo.png"
          />
        </div>
        <div style="display: flex; flex: 1">
          <h3 style="font-weight: 500; padding: 0 1rem; text-transform: capitalize;">Immigration Report - H1B</h3>
        </div>
        <div style="display: flex;">
          <h3 style="font-weight: 400; padding: 0 1rem">${dateFormat(
            Date.now()
          )}</h3>
        </div>
      </div>

      ${
        data.length
          ? `
        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
          <div style="display: flex; flex: 1; background-color: #345; color: #fefefe">
            <h5 style="padding: .5rem; margin: 0;">Case #</h5>
          </div>
          <div style="display: flex; flex: 4; background-color: #345; color: #fefefe">
            <h5 style="padding: .5rem; margin: 0;">Client Info</h5>
          </div>
          <div style="display: flex; flex: 2; background-color: #345; color: #fefefe">
            <h5 style="padding: .5rem; margin: 0;">Creation Date</h5>
          </div>
          <div style="display: flex; flex: 4; background-color: #345; color: #fefefe">
            <h5 style="padding: .5rem; margin: 0;">Last Step Completed</h5>
          </div>
          <div style="display: flex; flex: 2; background-color: #345; color: #fefefe">
            <h5 style="padding: .5rem; margin: 0;">Last Step Date</h5>
          </div>
        </div>
      `
          : ``
      }
      ${data
        .map(
          (h1b) => `
      <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
      <div style="display: flex; flex: 1; color: #345">
        <h5 style="padding: .5rem; margin: 0;">${h1b.case.caseNum}</h5>
      </div>
      <div style="display: flex; flex-direction: column; flex: 4; color: #345">
        <h5 style="padding: .5rem; margin: 0;">
        <span style="font-weight: 400; font-size: 10px;">
          Company${' '}
        </span>
        ${h1b.case.companyName || ''}</h5>
        <h5 style="padding: .5rem; margin: 0;">
        <span style="font-weight: 400; font-size: 10px;">
          Client${' '}
        </span>
        ${h1b.case.primaryContact.fullName || ''}</h5>
      </div>
      <div style="display: flex; flex: 2; color: #345">
        <h5 style="padding: .5rem; margin: 0;">${dateFormat(
          new Date(h1b.case.openedAt)
        )}</h5>
      </div>
      <div style="display: flex; flex: 4; color: #345">
        <h5 style="padding: .5rem; margin: 0;">${h1b.latestStep.title}</h5>
      </div>
      <div style="display: flex; flex: 2; color: #345">
        <h5 style="padding: .5rem; margin: 0;">${dateFormat(
          new Date(h1b.latestStep.finishDate)
        )}</h5>
      </div>
    </div>
    <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; border-top: 1px solid #9ab3; ; border-bottom: 2px solid #9ab6;">
    <div style="display: flex; flex: 1; color: #345"></div>
          <div style="display: flex; flex: 2; color: #345">
            <h5 style="padding: .5rem; margin: 0;; font-weight: 400">
              Latest Step Notes
            </h5>
          </div>
          <div style="display: flex; flex: 10; color: #345">
          <h5 style="padding: .5rem; margin: 0">
          ${h1b.latestStep.notes}
            </h5>
          </div>
        </div>
      `
        )
        .join('')}
      
    `);
    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  return (
    <div className="w-100">
      <h4 className="text-dark">Immigration Reports</h4>

      <div className="mt-4">
        <button
          className="btn btn btn-primary me-2 mb-2"
          onClick={runPermReport}
        >
          {'PERM Status'}
        </button>
        <button
          className="btn btn btn-primary me-2 mb-2"
          onClick={runH1bReport}
        >
          {'H1B Status'}
        </button>
      </div>
    </div>
  );
};

export default ImmigrationReport;
