import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  VISIBLE_APPOINTMENT_COLS,
  VISIBLE_DOC_COLS,
} from '../constants/constants';
import EmailCell from './EmailCell';
import Input from './common/Input';
import Loading from './Loading';
import MyPagination from './common/MyPagination';

const EmailList = (props) => {
  const { user, caseId } = props;

  const [emails, setEmails] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [dataKeys, setDataKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    initDataKeys(emails);
  }, [emails]);

  useEffect(() => {
    initEmails(page, caseId, user, searchQuery);
  }, [page, caseId, user, searchQuery]);

  useEffect(() => {
    initPageCount(caseId, user, searchQuery);
  }, [caseId, user, searchQuery]);

  const initPageCount = async (caseId, user, searchQuery) => {
    if (!user || !user.id) return;
    if (!caseId || !caseId.length) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getEmailsPageCount(caseId, searchQuery);
    if (!res) {
    }
    if (res.error) {
      setPageCount(1);
    }
    if (res.result && res.result.count) {
      setPageCount(res.result.count);
    }
  };

  const initEmails = async (page, caseId, user, searchQuery) => {
    try {
      if (!user || !user.id || !caseId || !caseId.length || !page) return;
      setLoading(true);
      const configService = require('../store/config/service');
      const res = await configService.default.getEmails(caseId, page, searchQuery);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('emails --', res.result);
        setEmails(res.result);
        setLoading(false);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const initDataKeys = (emails) => {
    if (!emails || !emails.length) return;
    let newKeys = Object.keys(emails[0]).filter((k) =>
      VISIBLE_DOC_COLS.includes(k)
    );
    return setDataKeys(newKeys);
  };

  const viewEmail = (emailId) => {
    if (!emailId || !emailId.length) return;
    history.push(`/emails/${emailId}`);
  };

  const keyToLabelMapping = {
    emailNum: 'Email',
    openedAt: 'Opening Date',
    primaryContact: 'Primary name',
    emailType: 'Email Type',
    companyName: 'Company',
    emails: 'Emails',
    status: 'Status',
  };

  return (
    <div className="email-card pb-2">
      {/* <div className="card-head pb-0 d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between align-items-center w-100">
          
        </div>
      </div> */}

      <div className="d-flex justify-content-start align-items-baseline px-2">
        <MyPagination page={page} setPage={setPage} pageCount={pageCount} />
        <div className="d-flex justify-content-start align-items-center w-100 px-3">
          <div className="max-width-24rem">
            <input
              className="form-control"
              placeholder={'type something..'}
              text={'text'}
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="d-flex justify-content-end align-items-baseline">
          <button
            className="btn btn-sm btn-light shadow-sm me-2"
            style={{ width: 'max-content' }}
            onClick={(e) => {
              // console.log('open sort modal');
            }}
          >
            Sort <i className="bi bi-sort-down ms-1"></i>
          </button>

          <button
            className="btn btn-sm btn-light shadow-sm"
            style={{ width: 'max-content' }}
            onClick={(e) => {
              // console.log('open filter modal');
            }}
          >
            Filter <i className="bi bi-filter-left ms-1"></i>
          </button>
        </div>
      </div>

      <div className="card-body p-0 m-0 w-100 d-flex flex-column justify-content-start align-items-start overflow-hidden">
        {dataKeys && dataKeys.length ? (
          <div className="table-head-row">
            {dataKeys.map((dataKey) => (
              <div key={dataKey} className="table-head-col">
                {keyToLabelMapping[dataKey]}
              </div>
            ))}
            <div className="table-head-col">Actions</div>
          </div>
        ) : (
          <React.Fragment />
        )}
        {loading ? (
          <Loading />
        ) : emails && emails.length ? (
          emails.map((emailObj) => (
            <div className="table-row">
              {Object.keys(emailObj)
                .filter((item) => VISIBLE_DOC_COLS.includes(item))
                .map((dataKey) => (
                  <div key={dataKey} className="table-col">
                    <EmailCell dataKey={dataKey} data={emailObj[dataKey]} />
                  </div>
                ))}
              <div className="table-col mt-2">
                <i
                  onClick={() => viewEmail(emailObj.id)}
                  className="bi bi-eye-fill me-2"
                ></i>
                <i className="bi bi-pencil-square"></i>
              </div>
            </div>
          ))
        ) : (
          <React.Fragment />
        )}
      </div>
    </div>
  );
};

export default EmailList;
