import { format, formatDistance } from 'date-fns';
import React from 'react';
import { APPOINTMENT_TYPES_MAP, LOCATIONS_MAP } from '../constants/constants';
import { dateTimeFormat, getUserName } from '../utils/userUtils';

const AppointmentCell = (props) => {
  const { dataKey, data, aptId } = props;

  const Attendees = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div className={`trunc`}>
        <small className="badge rounded bg-primary px-1 text-white fw-regular mx-1 capitalize">
          {data.map((usr) => usr.name.firstName).join(', ')}
        </small>
      </div>
    );
  };

  const AppointmentType = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-normal mx-1 capitalize">
          {data}
        </small>
      </div>
    );
  };

  const DateTime = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-regular mx-1 capitalize">
          {!!aptId
            ? dateTimeFormat(new Date(data))
            : format(new Date(data), 'MMM dd, yyyy, h:mm a')}
        </small>
      </div>
    );
  };

  const CaseId = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 fw-normal mx-1 capitalize bg-light-dark text-white">
          {data.id.substring(0, 4) +
            '...' +
            data.id.substring(data.id.length - 4, data.id.length)}
          <i className="bi bi-box-arrow-up-right ms-1"></i>
        </small>
      </div>
    );
  };

  const Text = (props) => {
    const { data, light } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small
          className={`badge px-1 ${
            light ? 'fw-normal' : 'fw-regular'
          } capitalize text-dark`}
        >
          {data}
        </small>
      </div>
    );
  };

  return (
    <div className="my-2">
      {dataKey === 'appointmentWith' ? (
        <Attendees data={data} />
      ) : (
        <React.Fragment />
      )}

      {dataKey === 'appointmentType' ? (
        <AppointmentType data={APPOINTMENT_TYPES_MAP[data]} />
      ) : (
        <React.Fragment />
      )}

      {dataKey === 'startTimeStamp' || dataKey === 'endTimeStamp' ? (
        <DateTime data={data} />
      ) : (
        <React.Fragment />
      )}

      {dataKey === 'name' ? <Text data={data} /> : <React.Fragment />}

      {dataKey === 'location' ? <Text data={LOCATIONS_MAP[data]} light /> : <React.Fragment />}

      {dataKey === 'case' ? <CaseId data={data} /> : <React.Fragment />}
    </div>
  );
};

export default AppointmentCell;
