import React, { useEffect, useState } from 'react';
import {
  VISIBLE_APPOINTMENT_COLS,
  VISIBLE_CONSULTATION_COLS,
} from '../constants/constants';
import ConsultationCell from './ConsultationCell';
import Input from './common/Input';
import Loading from './Loading';
import { useHistory } from 'react-router-dom';
import MyPagination from './common/MyPagination';

const ConsultationList = (props) => {
  const { user } = props;

  const [consultations, setConsultations] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [dataKeys, setDataKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    initDataKeys(consultations);
  }, [consultations]);

  useEffect(() => {
    initConsultations(page, user, searchQuery);
  }, [page, user, searchQuery]);

  useEffect(() => {
    initPageCount(user, searchQuery);
  }, [user, searchQuery]);

  useEffect(() => {
    goToInitialPage();
  }, [searchQuery]);

  const goToInitialPage = () => {
    setPage(1);
  };

  const initPageCount = async (user, searchQuery) => {
    if (!user || !user.id) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getConsultationsPageCount(
      searchQuery
    );
    if (!res) {
    }
    if (res.error) {
      setPageCount(1);
    }
    if (res.result && res.result.count) {
      setPageCount(res.result.count);
    }
  };

  const initConsultations = async (page, user, searchQuery) => {
    try {
      if (!user || !user.id || !page) return;
      setLoading(true);
      const configService = require('../store/config/service');
      const res = await configService.default.getConsultations(
        page,
        searchQuery
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('consultations --', res.result);
        setConsultations(res.result);
        setLoading(false);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const initDataKeys = (consultations) => {
    if (!consultations || !consultations.length) return;
    // let newKeys = Object.keys(consultations[0])
    //   .filter((k) => VISIBLE_CONSULTATION_COLS.includes(k))
    //   .sort();
    let newKeys = [
      'consultNum',
      'caseType',
      'companyName',
      'primaryName',
      'consultationDate',
      'email',
    ];
    return setDataKeys(newKeys);
  };

  const keyToLabelMapping = {
    consultNum: 'Consult',
    consultationDate: 'Consult Date',
    caseType: 'Case Type',
    companyName: 'Company',
    primaryName: 'Primary Name',
    email: 'Email',
  };

  const openConsult = (consultId) => {
    if (!consultId || !consultId.length) return;
    history.push(`/consultations/${consultId}`);
  };

  return (
    <div className="card chart-card pb-2 overflow-hidden">
      <div className="card-head px-3 py-3 pb-0 d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between align-items-center w-100">
          <h5 className="fw-semi-bold">Consultations</h5>
          {/* <button
            className="btn btn-dark shadow"
            style={{ width: 'max-content' }}
            onClick={(e) => {
              // console.log('open sort modal');
            }}
          >
            Today <i className="bi bi-clock-history ms-1"></i>
          </button> */}
        </div>
      </div>

      <div className="d-flex justify-content-start align-items-baseline px-3">
        <MyPagination page={page} setPage={setPage} pageCount={pageCount} />
        <div className="d-flex justify-content-start align-items-center w-100 px-3">
          <div className="max-width-24rem">
            <input
              className="form-control"
              placeholder={'type something..'}
              text={'text'}
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>
        </div>

        {/* <div className="d-flex justify-content-end align-items-baseline">
          <button
            className="btn btn-sm btn-light shadow-sm me-2"
            style={{ width: 'max-content' }}
            onClick={(e) => {
              // console.log('open sort modal');
            }}
          >
            Sort <i className="bi bi-sort-down ms-1"></i>
          </button>

          <button
            className="btn btn-sm btn-light shadow-sm"
            style={{ width: 'max-content' }}
            onClick={(e) => {
              // console.log('open filter modal');
            }}
          >
            Filter <i className="bi bi-filter-left ms-1"></i>
          </button>
        </div> */}
      </div>

      <div className="card-body p-0 m-0 w-100 d-flex flex-column justify-content-start align-items-start overflow-hidden">
        {dataKeys && dataKeys.length ? (
          <div className="table-head-row">
            <div className="table-head-col flex-pt5">S No.</div>
            {dataKeys.map((dataKey) => (
              <div key={dataKey} className="table-head-col">
                {keyToLabelMapping[dataKey]}
              </div>
            ))}
          </div>
        ) : (
          <React.Fragment />
        )}
        {loading ? (
          <Loading />
        ) : consultations && consultations.length ? (
          consultations.map((consult, i) => (
            <div
              key={i}
              className="table-row"
              onClick={() => openConsult(consult.id)}
            >
              <div className="table-col mt-2 flex-pt5">
                <span className="fw-light size14">
                  {(page - 1) * 20 + i + 1}.
                </span>
              </div>
              {[
                'consultNum',
                'caseType',
                'companyName',
                'primaryName',
                'consultationDate',
                'email',
              ].map((dataKey) => (
                <div key={dataKey} className="table-col">
                  <ConsultationCell dataKey={dataKey} data={consult[dataKey]} />
                </div>
              ))}
            </div>
          ))
        ) : (
          <React.Fragment />
        )}
      </div>
    </div>
  );
};

export default ConsultationList;
