import React from 'react';
import {
  myBlueColor,
  myGreenColor,
  myRedColor,
  myGreenColorLight,
  myRedColorLight,
  myBlueColorLight,
} from '../constants/colors';

const StatCard = (props) => {
  const {
    title = 'This is card title',
    desc = 'This is card desc',
    percentage = '+2.5%',
    count = 100,
    myTintColor = myBlueColor,
    myTintColorLight = '#fff',
    dark = false
  } = props;

  const isPercentUp = () => {
    if (percentage.includes('+')) {
      return true;
    }
    return false;
  };
  return (
    <div className={`card px-3 pb-2 ${dark ? "dark-card" : ""}`}>
      <div className="card-head py-3 pb-1 d-flex justify-content-between align-items-center">
        <p className="fw-semi-bold card-title mb-0">{title} <span className="fw-light ms-1">({desc})</span></p>
      </div>
      <div className="card-body">
        <div className="d-flex justify-content-start align-items-center">
          <div
            className="d-flex justify-content-center align-items-center stat-percent-cont me-2"
            style={{
              backgroundColor: isPercentUp(percentage)
                ? myGreenColorLight
                : myRedColorLight,
              color: isPercentUp(percentage) ? myGreenColor : myRedColor,
              borderRadius: '50%',
            }}
          >
            <i
              className={`bi sm-icon bi-graph-${
                isPercentUp(percentage) ? 'up' : 'down'
              }`}
            ></i>
          </div>
          <small className="fw-bold size14">{percentage} <span className="fw-light text-muted">(from last year)</span></small>
        </div>
        <div className=" px-4" style={{position: 'absolute', right: 0, bottom : 0, opacity: .1}}>
          <i className="bi bi-bar-chart-line-fill super-xl-icon" style={{color: dark ? myTintColorLight : myTintColor}}></i>
        </div>
      </div>
      <h2 className="fw-extra-bold">{count}</h2>
    </div>
  );
};

export default StatCard;
