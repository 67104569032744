import { format, formatDistance } from 'date-fns';
import React from 'react';
import { CASE_STATUS_MAP, CONTRACT_TYPES_MAP } from '../constants/constants';
import { dateFormat, getUserName } from '../utils/userUtils';

const CostCell = (props) => {
  const { dataKey, data, openEditCostModal } = props;

  const PrimaryName = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <small className="badge rounded px-1 text-dark fw-regular mx-1 capitalize">
        {data.fullName}
      </small>
    );
  };

  const CostType = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-normal mx-1 capitalize">
          {data}
        </small>
      </div>
    );
  };

  const OpeningDate = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-regular mx-1 capitalize">
          {dateFormat(new Date(data))}
        </small>
      </div>
    );
  };

  const CostId = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 fw-normal mx-1 capitalize bg-light-dark text-white">
          {data.id.substring(0, 4) +
            '...' +
            data.id.substring(data.id.length - 4, data.id.length)}
          <i className="bi bi-box-arrow-up-right ms-1"></i>
        </small>
      </div>
    );
  };

  const CostNum = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 fw-normal mx-1 capitalize bg-light-dark text-white">
          {data}
          <i className="bi bi-box-arrow-up-right ms-1"></i>
        </small>
      </div>
    );
  };

  const Text = (props) => {
    const {
      data,
      light,
      trunc = false,
      longTrunc = false,
      noCap = false,
      left = false,
    } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small
          className={`badge px-1 ${light ? 'fw-normal' : 'fw-regular'} ${
            trunc ? 'truncated' : ''
          } ${longTrunc ? 'long-trunc' : ''} ${
            left ? 'text-left' : ''
          } ${noCap ? '' : 'capitalize'} text-dark`}
        >
          {data}
        </small>
      </div>
    );
  };

  const CreationDate = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className={`badge px-1 fw-normal capitalize text-dark`}>
          {dateFormat(new Date(data))}
        </small>
      </div>
    );
  };

  const Emails = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge rounded text-and-border-blue px-1 fw-normal mx-1">
          {data[0]}
        </small>
        {/* {data.map((email) => (
          <small className="badge rounded text-and-border-blue px-1 fw-normal mx-1">
            {email}
          </small>
        ))} */}
      </div>
    );
  };

  return (
    <div className="my-2">
      {/* {dataKey === 'openedAt' ? (
        <OpeningDate data={data} />
      ) : (
        <React.Fragment />
      )} */}

      {dataKey === 'caseType' ? (
        <CostType data={data} />
      ) : (
        <React.Fragment />
      )}

      {dataKey === 'statusEnd' ? (
        <Text data={CASE_STATUS_MAP[data]} />
      ) : (
        <React.Fragment />
      )}

      {dataKey === 'completedBy' ? (
        <Text data={data && data.length && data.map((el) => getUserName(el)).join(', ')} />
      ) : (
        <React.Fragment />
      )}
      {dataKey === 'desc' ? (
        <Text data={data} longTrunc noCap left />
      ) : (
        <React.Fragment />
      )}
      {dataKey === 'completionDateStamp' || dataKey === 'openDateStamp' ? (
        <CreationDate data={data} />
      ) : (
        <React.Fragment />
      )}
      {/* {dataKey === 'status' ? <Text data={data} /> : <React.Fragment />} */}
      {/* {dataKey === 'name' ? <Text data={data} /> : <React.Fragment />}

      {dataKey === 'location' ? <Text data={data} light /> : <React.Fragment />}

      {dataKey === 'case' ? <CostId data={data} /> : <React.Fragment />} */}

      {/* {dataKey === 'case' ? <CostId data={data} /> : <React.Fragment />} */}
      {/* {dataKey === 'caseNum' ? <CostNum data={data} /> : <React.Fragment />} */}
    </div>
  );
};

export default CostCell;
