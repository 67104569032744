import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { myGreenSuperLight } from '../../constants/colors';
import {
  CASE_SUB_TYPES,
  CONTRACT_TYPES,
  PERCENTAGE_OPTIONS,
} from '../../constants/constants';
import { getMahDateCustom, getUserName } from '../../utils/userUtils';
import Checkbox from '../common/Checkbox';
import Input from '../common/Input';
import MultiSelect from '../common/MultiSelect';
import MyMessage from '../common/MyMessage';
import Select from '../common/Select';
import Loading from '../Loading';
import TextArea from '../common/TextArea';

const EditTimesheetModal = (props) => {
  const {
    caseData,
    visible,
    onClose,
    user,
    employees,
    reinitialize,
    timesheetId,
    onEdit = false,
  } = props;

  const [editing, setEditing] = useState(onEdit);
  const [isHourly, setIsHourly] = useState(true);
  const [isFlat, setIsFlat] = useState(false);
  const [rate, setRate] = useState(0);
  const [times, setTimes] = useState([]);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [caseType, setCaseType] = useState('H-1B CAP');
  const [completionDate, setCompletionDate] = useState('');
  const [completedByNames, setCompletedByNames] = useState('');
  const [completedBy, setCompletedBy] = useState([]);
  const [desc, setDesc] = useState('');
  const [sureToDelete, setSureToDelete] = useState(false);
  const [showSureSelect, setShowSureSelect] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initTimesheetForEdit();
  }, [visible, timesheetId]);

  const initTimesheetForEdit = async () => {
    try {
      if (!visible || !timesheetId || !timesheetId.length) return;
      setLoading(true);
      const configService = require('../../store/config/service');
      const res = await configService.default.getTimesheet(timesheetId);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log(res.result);
        // add data to inputs.
        if (user && user.userType && user.userType === 'Admin')
          setIsAdmin(true);
        setTimes(res.result.times);
        setCaseType(res.result.caseType);
        setIsHourly(res.result.isHourly);
        setIsFlat(res.result.isFlat);
        if (res.result.isHourly) {
          setRate(res.result.rate);
        }
        setCompletionDate(getMahDateCustom(res.result.completionDate));
        setCompletedByNames(res.result.completedByNames);
        setCompletedBy(
          res.result.completedBy.map((el) => ({
            label: `${getUserName(el)} ${el.designation || el.userType}`,
            value: el.id,
          }))
        );
        setDesc(res.result.desc);
        setErrors({});
        setMessage({});
        setLoading(false);
      }
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const updateTimesheet = async () => {
    if (!validateTimesheet()) return;
    const configService = require('../../store/config/service');

    let body = {
      caseType,
      isHourly,
      isFlat,
      times,
      totalTimeSpent: parseFloat(
        times
          .reduce((acc, cur) => acc + parseFloat(cur.timeSpent), 0)
          .toFixed(2)
      ),
      totalTimeSpentString: times
        .reduce((acc, cur) => acc + parseFloat(cur.timeSpent), 0)
        .toFixed(2),
      completionDate: new Date(completionDate),
      completionDateStamp: +new Date(completionDate),
      completedBy: completedBy.map((el) => el.value),
      completedByNames,
      desc,
      createdBy: user.id,
      createdByName: getUserName(user),
    };
    if (isHourly) {
      body.rate = parseFloat(rate);
    }

    // console.log('body - ', body);

    const res = await configService.default.updateTimesheet(timesheetId, body);
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const validateTimesheet = () => {
    let flag = true;
    if (!caseType || !caseType.length) {
      flag = false;
      handleError('caseType', 'select a valid case type');
    }
    if (isHourly) {
      if (!rate || isNaN(rate)) {
        flag = false;
        handleError('rate', 'add a valid rate');
      }
    }
    if (!times || !times.length) {
      flag = false;
      handleError('times', 'add atleast one time range');
    }
    if (!completionDate || !completionDate.length) {
      flag = false;
      handleError('completionDate', 'select a valid completion date');
    }
    if (!completedBy || !completedBy.length) {
      flag = false;
      handleError('completedBy', 'select a valid emlpoyee');
    }
    if (!completedByNames || !completedByNames.length) {
      flag = false;
      handleError('completedBy', 'select a valid employee');
    }
    if (!desc || !desc.length) {
      flag = false;
      handleError('desc', 'select a add description');
    }
    if (!user || !user.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (!caseData || !caseData.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    return flag;
  };

  const deleteTimesheet = async () => {
    if (!timesheetId || !timesheetId.length) return;
    if (!isAdmin) return;
    if (!showSureSelect) return setShowSureSelect(true);
    if (showSureSelect && !sureToDelete)
      return handleError('sureToDelete', 'you have to check this');

    const configService = require('../../store/config/service');
    const res = await configService.default.deleteTimesheet(timesheetId);

    if (!res) {
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (res.error) {
      setMessage({
        type: 'warning',
        msg: res.error,
      });
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  const addTimeRangeToList = () => {
    if (!from || !from.length) handleError('from', 'select valid time');
    if (!to || !to.length) handleError('to', 'select valid time');
    if (!from || !to || !from.length || !to.length) return;
    // console.log({from, to});

    let start = from.split(':');
    let end = to.split(':');
    var startDate = new Date(0, 0, 0, start[0], start[1], 0);
    var endDate = new Date(0, 0, 0, end[0], end[1], 0);
    var diff = endDate.getTime() - startDate.getTime();
    if (diff <= 0) {
      handleError('times', 'Please select time range in the same day');
      return;
    }
    var hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    var minutes = Math.floor(diff / 1000 / 60);

    // console.log({times});

    setTimes((cur) => {
      return [
        ...cur,
        {
          start: from,
          end: to,
          timeSpent: parseFloat((hours + minutes / 60).toFixed(2)),
        },
      ];
    });

    setFrom('');
    setTo('');
    handleError('times', '');
    return;
  };

  const deleteTime = (index) => {
    setTimes((current) => {
      return current.filter((el, i) => i !== index);
    });
  };

  const doOnClose = () => {
    resetBlankModal();
    onClose();
  };

  const resetBlankModal = () => {
    setTimes([]);
    setFrom('');
    setTo('');
    setIsHourly(true);
    setIsFlat(false);
    setRate(0);
    setCaseType('H-1B CAP');
    setCompletionDate('');
    setCompletedByNames('');
    setCompletedBy([]);
    setDesc('');
    setShowSureSelect(false);
    setSureToDelete(false);
    setIsAdmin(false);
    setErrors({});
    setMessage({});
    setLoading(false);
  };

  return visible ? (
    <div className="my-modal-container">
      <div className="my-modal-overlay" onClick={doOnClose}></div>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable my-modal z-index-9">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <div>
              <p className="modal-title mb-0">
                {showSureSelect ? 'Deleting' : editing ? 'Editing' : 'Viewing'}
              </p>
              <h3 className="modal-title fw-bold">{'Timesheet'}</h3>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={doOnClose}
            ></button>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="modal-body">
              {showSureSelect ? (
                <>
                  <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                    <div>
                      <small className="badge rounded-pill bg-warning text-dark fw-normal mb-1">
                        Important
                      </small>
                      <p className="fw-light text-muted italic size14pt5 px-2">
                        You are about to delete this appointment! This action is
                        irreversible.
                      </p>
                      <div className="row g-md-2">
                        <div className="col-12 my-3 mx-auto px-3">
                          <label htmlFor="splittingWith" className="form-label">
                            Are You Sure?
                          </label>

                          <div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="areWeSure"
                                id="areWeSureYes"
                                checked={sureToDelete}
                                onClick={() => {
                                  if (!sureToDelete)
                                    handleError('sureToDelete', '');
                                  setSureToDelete(!sureToDelete);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="areWeSureYes"
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                          {errors && errors.sureToDelete ? (
                            <div className="mt-1">
                              <small className="text-red size14">
                                {errors && errors.sureToDelete}
                              </small>
                            </div>
                          ) : (
                            <React.Fragment />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {editing ? (
                    <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                      <div className="mb-2">
                        <p className="text-muted fs-italic fw-light size15">
                          Change the details below and update this timesheet!
                        </p>

                        <div className="row d-flex flex-wrap justify-content-start align-items-start">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <Select
                              label={'Case Type'}
                              placeholder={'select a case type'}
                              value={caseType}
                              options={CASE_SUB_TYPES}
                              onChange={(e) => {
                                setCaseType(e.target.value);
                                handleError('caseType', '');
                              }}
                              err={errors.caseType || ''}
                            />
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 px-4 py-3">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="rate"
                                id="rateHourly"
                                checked={isHourly}
                                onClick={() => {
                                  if (!isHourly) handleError('isHourly', '');
                                  setIsHourly(true);
                                  setIsFlat(false);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="rateHourly"
                              >
                                Hourly Rate
                              </label>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 px-4 py-3">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="rate"
                                id="rateFlat"
                                checked={isFlat}
                                onClick={() => {
                                  if (!isFlat) handleError('isFlat', '');
                                  setIsFlat(true);
                                  setIsHourly(false);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="rateFlat"
                              >
                                Flat Rate
                              </label>
                            </div>
                          </div>

                          {isHourly ? (
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                              <Input
                                label={`Hourly Rate`}
                                placeholder={'enter rate'}
                                type={'number'}
                                value={rate}
                                onChange={(e) => {
                                  if (
                                    e.target.value &&
                                    e.target.value.length &&
                                    errors.rate &&
                                    errors.rate.length
                                  ) {
                                    handleError('rate', '');
                                  }
                                  setRate(e.target.value);
                                }}
                                err={errors && errors.rate}
                              />
                            </div>
                          ) : (
                            <React.Fragment />
                          )}

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <Input
                              label={'Completion Date'}
                              placeholder={'enter completion date'}
                              type={'date'}
                              value={completionDate}
                              onChange={(e) => {
                                if (
                                  e.target.value &&
                                  e.target.value.length &&
                                  errors.completionDate &&
                                  errors.completionDate.length
                                ) {
                                  handleError('completionDate', '');
                                }
                                setCompletionDate(e.target.value);
                              }}
                              err={errors && errors.completionDate}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <div className="row d-flex justify-content-center align-items-center">
                              <div className="col-5">
                                <Input
                                  label={'From'}
                                  placeholder={'enter start time'}
                                  type={'time'}
                                  value={from}
                                  onChange={(e) => {
                                    if (
                                      e.target.value &&
                                      e.target.value.length &&
                                      errors.from &&
                                      errors.from.length
                                    ) {
                                      handleError('from', '');
                                    }
                                    setFrom(e.target.value);
                                  }}
                                  err={errors && errors.from}
                                />
                              </div>

                              <div className="col-5">
                                <Input
                                  label={'To'}
                                  placeholder={'enter end time'}
                                  type={'time'}
                                  value={to}
                                  min={from}
                                  onChange={(e) => {
                                    if (
                                      e.target.value &&
                                      e.target.value.length &&
                                      errors.to &&
                                      errors.to.length
                                    ) {
                                      handleError('to', '');
                                    }
                                    setTo(e.target.value);
                                  }}
                                  err={errors && errors.to}
                                />
                              </div>

                              <div className="col-2">
                                <button
                                  onClick={addTimeRangeToList}
                                  className="btn btn-sm btn-primary"
                                >
                                  Add +
                                </button>
                              </div>
                            </div>
                          </div>

                          {errors.times && errors.times.length ? (
                            <div className="form-error mx-2 mb-3">
                              <small className="form-error-text px-1">
                                {errors.times}
                              </small>
                            </div>
                          ) : (
                            <React.Fragment />
                          )}

                          {times && times.length ? (
                            times.map((tm, i) => (
                              <div
                                key={i}
                                className="mb-2 d-flex flex-row justify-content-evenly align-items-center px-4"
                              >
                                <small className="flex-zero mb-0 fw-semi-bold text-muted me-2">
                                  {i + 1}
                                </small>
                                <small className="flex-one mb-0 fw-semi-bold text-dark px-1">
                                  <span className="text-muted fw-light">
                                    From -
                                  </span>{' '}
                                  {tm.start}
                                </small>
                                <small className="flex-two mb-0 fw-semi-bold text-dark px-1">
                                  <span className="text-muted fw-light">
                                    To -
                                  </span>{' '}
                                  {tm.end}
                                </small>
                                <small className="flex-one mb-0 fw-semi-bold text-dark px-1">
                                  <span className="text-muted fw-light">
                                    Hours -
                                  </span>{' '}
                                  {tm.timeSpent}
                                </small>
                                <small
                                  className="flex-zero mb-0 fw-semi-bold text-red px-1 cursor-pointer"
                                  onClick={() => deleteTime(i)}
                                >
                                  <i className="bi bi-x-circle-fill"></i>
                                </small>
                              </div>
                            ))
                          ) : (
                            <React.Fragment />
                          )}

                          {times && times.length ? (
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 d-flex flex-row justify-content-around align-items-center mb-3">
                              <small className="mx-2 bt-1 pt-1 flex-one">
                                Total Time Spent
                              </small>
                              <small className="mx-2 bt-1 pt-1 fw-semi-bold">
                                {times
                                  .reduce(
                                    (acc, cur) =>
                                      acc + parseFloat(cur.timeSpent),
                                    0
                                  )
                                  .toFixed(2)}{' '}
                                hrs
                              </small>
                            </div>
                          ) : (
                            <React.Fragment />
                          )}

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <MultiSelect
                              label={'Completed By'}
                              displayValue={'label'}
                              values={completedBy}
                              options={employees}
                              onSelect={(e) => {
                                setCompletedBy(e);
                                // console.log(e);
                                if (e.length) {
                                  setCompletedByNames(
                                    e.map((el) => el.label).join(',')
                                  );
                                }
                                handleError('completedBy', '');
                              }}
                              onRemove={(e) => {
                                setCompletedBy(e);
                              }}
                              err={errors.completedBy || ''}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <TextArea
                              label={'Description'}
                              placeholder={'add a description'}
                              value={desc}
                              onChange={(e) => {
                                if (
                                  e.target.value &&
                                  e.target.value.length &&
                                  errors.desc &&
                                  errors.desc.length
                                ) {
                                  handleError('desc', '');
                                }
                                setDesc(e.target.value);
                              }}
                              err={errors && errors.desc}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
                    </div>
                  ) : (
                    <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                      <div className="mb-2">
                        <p className="text-muted fs-italic fw-light size15">
                          Click on Edit to edit the data!
                        </p>

                        <div className="row d-flex flex-wrap justify-content-start align-items-start">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <Select
                              disabled
                              label={'Case Type'}
                              placeholder={'select a case type'}
                              value={caseType}
                              options={CASE_SUB_TYPES}
                              onChange={(e) => {
                                setCaseType(e.target.value);
                                handleError('caseType', '');
                              }}
                              err={errors.caseType || ''}
                            />
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 px-4 py-3">
                            <div className="form-check form-check-inline">
                              <input
                                disabled
                                className="form-check-input"
                                type="checkbox"
                                name="rate"
                                id="rateHourly"
                                checked={isHourly}
                                onClick={() => {
                                  if (!isHourly) handleError('isHourly', '');
                                  setIsHourly(true);
                                  setIsFlat(false);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="rateHourly"
                              >
                                Hourly Rate
                              </label>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 px-4 py-3">
                            <div className="form-check form-check-inline">
                              <input
                                disabled
                                className="form-check-input"
                                type="checkbox"
                                name="rate"
                                id="rateFlat"
                                checked={isFlat}
                                onClick={() => {
                                  if (!isFlat) handleError('isFlat', '');
                                  setIsFlat(true);
                                  setIsHourly(false);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="rateFlat"
                              >
                                Flat Rate
                              </label>
                            </div>
                          </div>

                          {isHourly ? (
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                              <Input
                                disabled
                                label={`Hourly Rate`}
                                placeholder={'enter rate'}
                                type={'number'}
                                value={rate}
                                onChange={(e) => {
                                  if (
                                    e.target.value &&
                                    e.target.value.length &&
                                    errors.rate &&
                                    errors.rate.length
                                  ) {
                                    handleError('rate', '');
                                  }
                                  setRate(e.target.value);
                                }}
                                err={errors && errors.rate}
                              />
                            </div>
                          ) : (
                            <React.Fragment />
                          )}

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <Input
                              disabled
                              label={'Completion Date'}
                              placeholder={'enter completion date'}
                              type={'date'}
                              value={completionDate}
                              onChange={(e) => {
                                if (
                                  e.target.value &&
                                  e.target.value.length &&
                                  errors.completionDate &&
                                  errors.completionDate.length
                                ) {
                                  handleError('completionDate', '');
                                }
                                setCompletionDate(e.target.value);
                              }}
                              err={errors && errors.completionDate}
                            />
                          </div>

                          {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <div className="row d-flex justify-content-center align-items-center">
                          <div className="col-5">
                            <Input
                              label={'From'}
                              placeholder={'enter start time'}
                              type={'time'}
                              value={from}
                              onChange={(e) => {
                                if (
                                  e.target.value &&
                                  e.target.value.length &&
                                  errors.from &&
                                  errors.from.length
                                ) {
                                  handleError('from', '');
                                }
                                setFrom(e.target.value);
                              }}
                              err={errors && errors.from}
                            />
                          </div>

                          <div className="col-5">
                            <Input
                              label={'To'}
                              placeholder={'enter end time'}
                              type={'time'}
                              value={to}
                              min={from}
                              onChange={(e) => {
                                if (
                                  e.target.value &&
                                  e.target.value.length &&
                                  errors.to &&
                                  errors.to.length
                                ) {
                                  handleError('to', '');
                                }
                                setTo(e.target.value);
                              }}
                              err={errors && errors.to}
                            />
                          </div>

                          <div className="col-2">
                            <button
                              onClick={addTimeRangeToList}
                              className="btn btn-sm btn-primary"
                            >
                              Add +
                            </button>
                          </div>
                        </div>
                      </div> */}

                          {errors.times && errors.times.length ? (
                            <div className="form-error mx-2 mb-3">
                              <small className="form-error-text px-1">
                                {errors.times}
                              </small>
                            </div>
                          ) : (
                            <React.Fragment />
                          )}

                          {times && times.length ? (
                            times.map((tm, i) => (
                              <div
                                key={i}
                                className="mb-2 d-flex flex-row justify-content-evenly align-items-center px-4"
                              >
                                <small className="flex-zero mb-0 fw-semi-bold text-muted me-2">
                                  {i + 1}
                                </small>
                                <small className="flex-one mb-0 fw-semi-bold text-dark px-1">
                                  <span className="text-muted fw-light">
                                    From -
                                  </span>{' '}
                                  {tm.start}
                                </small>
                                <small className="flex-two mb-0 fw-semi-bold text-dark px-1">
                                  <span className="text-muted fw-light">
                                    To -
                                  </span>{' '}
                                  {tm.end}
                                </small>
                                <small className="flex-one mb-0 fw-semi-bold text-dark px-1">
                                  <span className="text-muted fw-light">
                                    Hours -
                                  </span>{' '}
                                  {tm.timeSpent}
                                </small>
                              </div>
                            ))
                          ) : (
                            <React.Fragment />
                          )}

                          {times && times.length ? (
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 d-flex flex-row justify-content-around align-items-center mb-3">
                              <small className="mx-2 bt-1 pt-1 flex-one">
                                Total Time Spent
                              </small>
                              <small className="mx-2 bt-1 pt-1 fw-semi-bold">
                                {times
                                  .reduce(
                                    (acc, cur) =>
                                      acc + parseFloat(cur.timeSpent),
                                    0
                                  )
                                  .toFixed(2)}{' '}
                                hrs
                              </small>
                            </div>
                          ) : (
                            <React.Fragment />
                          )}

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <MultiSelect
                              disabled
                              label={'Completed By'}
                              displayValue={'label'}
                              values={completedBy}
                              options={employees}
                              onSelect={(e) => {
                                setCompletedBy(e);
                                // console.log(e);
                                if (e.length) {
                                  setCompletedByNames(
                                    e.map((el) => el.label).join(',')
                                  );
                                }
                                handleError('completedBy', '');
                              }}
                              onRemove={(e) => {
                                setCompletedBy(e);
                              }}
                              err={errors.completedBy || ''}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <TextArea
                              disabled
                              label={'Description'}
                              placeholder={'add a description'}
                              value={desc}
                              onChange={(e) => {
                                if (
                                  e.target.value &&
                                  e.target.value.length &&
                                  errors.desc &&
                                  errors.desc.length
                                ) {
                                  handleError('desc', '');
                                }
                                setDesc(e.target.value);
                              }}
                              err={errors && errors.desc}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          <div className="px-3">
            <MyMessage
              visible={!!message}
              message={message}
              onClose={() => {
                setMessage(undefined);
              }}
            />
          </div>
          <div
            className="modal-footer d-flex justify-content-between align-items-center"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <div>
              {isAdmin && !editing && !showSureSelect ? (
                <span
                  className="btn btn-danger m-0 py-0 px-1 cursor-pointer"
                  onClick={deleteTimesheet}
                >
                  <i className="bi bi-trash md-icon"></i>
                </span>
              ) : (
                <React.Fragment />
              )}
            </div>

            <>
              {!showSureSelect ? (
                <>
                  <div className="d-flex flex-one justify-content-end align-items-center">
                    {editing ? (
                      <button
                        type="button"
                        className="btn btn-outline ms-2"
                        onClick={() => setEditing(false)}
                      >
                        Cancel
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-outline ms-2"
                        onClick={doOnClose}
                      >
                        Close
                      </button>
                    )}
                  </div>

                  {editing ? (
                    <button
                      type="button"
                      className="btn btn-primary ms-2 capitalize"
                      onClick={updateTimesheet}
                    >
                      Update Timesheet
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary ms-2"
                      onClick={() => {
                        setEditing(true);
                      }}
                    >
                      Edit
                    </button>
                  )}
                </>
              ) : (
                <div className="d-flex align-tems-center justify-content-end">
                  <button
                    type="button"
                    className="btn btn-outline ms-2"
                    onClick={() => {
                      setShowSureSelect(false);
                      setSureToDelete(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger ms-2 capitalize"
                    onClick={deleteTimesheet}
                  >
                    Delete Timesheet
                  </button>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default EditTimesheetModal;
