import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  VISIBLE_APPOINTMENT_COLS,
  VISIBLE_TIMESHEET_COLS,
} from '../constants/constants';
import TimesheetCell from './TimesheetCell';
import Input from './common/Input';
import Loading from './Loading';
import MyPagination from './common/MyPagination';
import NoEntry from './common/NoEntry';

const TimesheetList = (props) => {
  const { user, caseId, reinitialize, reinitializing, openEditTimesheetModal } =
    props;

  const [timesheets, setTimesheets] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [dataKeys, setDataKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  // useEffect(() => {
  //   initDataKeys(timesheets);
  // }, [timesheets]);

  useEffect(() => {
    initPage(reinitializing, caseId, user);
  }, [reinitializing, caseId, user]);

  const initPage = (reinitializing, caseId, user) => {
    if (reinitializing) {
      reinitialize(false);
      // setting things back to empty before reinitializing
      setTimesheets([]);
      setPageCount(1);
      setSearchQuery('');
      // setting page to activate reinitialization
      setPage(1);
      initTimesheets(1, caseId, user, '');
      initPageCount(caseId, user, '');
    }
  };

  useEffect(() => {
    initTimesheets(page, caseId, user, searchQuery);
  }, [page, caseId, user, searchQuery]);

  useEffect(() => {
    initPageCount(caseId, user, searchQuery);
  }, [caseId, user, searchQuery]);

  const initPageCount = async (caseId, user, searchQuery) => {
    if (!user || !user.id) return;
    if (!caseId || !caseId.length) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getTimesheetsPageCount(
      caseId,
      searchQuery
    );
    if (!res) {
    }
    if (res.error) {
      setPageCount(1);
    }
    if (res.result && res.result.count) {
      setPageCount(res.result.count);
    }
  };

  const initTimesheets = async (page, caseId, user, searchQuery) => {
    try {
      if (!user || !user.id || !caseId || !caseId.length || !page) return;
      setLoading(true);
      const configService = require('../store/config/service');
      const res = await configService.default.getTimesheets(
        caseId,
        page,
        searchQuery
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('timesheets --', res.result);
        setTimesheets(res.result);
        initDataKeys(res.result);
        initPageCount(page, caseId, user, searchQuery);
        setLoading(false);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const initDataKeys = (timesheets) => {
    if (!timesheets || !timesheets.length) return;
    let newKeys = VISIBLE_TIMESHEET_COLS;
    return setDataKeys(newKeys);
  };

  const viewTimesheet = (timesheetId) => {
    if (!timesheetId || !timesheetId.length) return;
    history.push(`/timesheets/${timesheetId}`);
  };

  const keyToLabelMapping = {
    'caseType': 'Case Type',
    'desc': 'Description',
    'completionDateStamp': 'Completed At',
    'times': 'Timings',
    'completedBy': 'Completed By',
    'rate': 'Case Rate'
  };

  return (
    <div className="timesheet-card pb-2">
      {/* <div className="card-head pb-0 d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between align-items-center w-100">
          
        </div>
      </div> */}

      <div className="d-flex justify-content-start align-items-baseline px-2">
        <MyPagination page={page} setPage={setPage} pageCount={pageCount} />
        <div className="d-flex justify-content-start align-items-center w-100 px-3">
          <div className="max-width-24rem">
            <input
              className="form-control"
              placeholder={'type something..'}
              text={'text'}
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>
        </div>

        {/* <div className="d-flex justify-content-end align-items-baseline">
          <button
            className="btn btn-sm btn-light shadow-sm me-2"
            style={{ width: 'max-content' }}
            onClick={(e) => {
              // console.log('open sort modal');
            }}
          >
            Sort <i className="bi bi-sort-down ms-1"></i>
          </button>

          <button
            className="btn btn-sm btn-light shadow-sm"
            style={{ width: 'max-content' }}
            onClick={(e) => {
              // console.log('open filter modal');
            }}
          >
            Filter <i className="bi bi-filter-left ms-1"></i>
          </button>
        </div> */}
      </div>

      <div className="card-body p-0 m-0 w-100 d-flex flex-column justify-content-start align-items-start overflow-hidden">
        {dataKeys && dataKeys.length ? (
          <div className="table-head-row">
            <div className="table-head-col flex-pt5">S No.</div>
            {dataKeys.map((dataKey) => (
              <div key={dataKey} className={`table-head-col ${
                dataKey === 'times' ? 'flex-two' : dataKey === 'desc' ? 'flex-1pt5' : 'flex-one'
              }`}>
                {keyToLabelMapping[dataKey]}
              </div>
            ))}
          </div>
        ) : (
          <React.Fragment />
        )}
        {loading ? (
          <Loading />
        ) : timesheets && timesheets.length ? (
          timesheets.map((timesheetObj, i) => (
            <div key={i} className="table-row" onClick={() => openEditTimesheetModal(timesheetObj.id, false)}>
              <div className="table-col mt-2 flex-pt5">
                <span className="fw-light size14">
                  {(page - 1) * 20 + i + 1}.
                </span>
              </div>
              {VISIBLE_TIMESHEET_COLS
                .map((dataKey) => (
                  <div key={dataKey} className={`table-col ${
                    dataKey === 'times' ? 'flex-two' : dataKey === 'desc' ? 'flex-1pt5' : 'flex-one'
                  }`}>
                    <TimesheetCell
                      dataKey={dataKey}
                      data={timesheetObj[dataKey]}
                      openEditTimesheetModal={() =>
                        openEditTimesheetModal(timesheetObj.id)
                      }
                    />
                  </div>
                ))}
            </div>
          ))
        ) : (
          <NoEntry />
        )}
      </div>
    </div>
  );
};

export default TimesheetList;
