import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  VISIBLE_APPOINTMENT_COLS,
  VISIBLE_NOTE_COLS,
} from '../constants/constants';
import NoteCell from './NoteCell';
import Input from './common/Input';
import Loading from './Loading';
import MyPagination from './common/MyPagination';
import NoEntry from './common/NoEntry';

const NoteList = (props) => {
  const { user, caseId, reinitialize, reinitializing, openEditNoteModal } =
    props;

  const [notes, setNotes] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [dataKeys, setDataKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [onPublic, setOnPublic] = useState(true);

  const history = useHistory();

  // useEffect(() => {
  //   initDataKeys(notes);
  // }, [notes]);

  useEffect(() => {
    initPage(reinitializing, caseId, user);
  }, [reinitializing, caseId, user]);

  const initPage = (reinitializing, caseId, user) => {
    // console.log('Reiniting notes!!');
    if (reinitializing) {
      // console.log('Reiniting true!!');
      // console.log('Reiniting onPublic -', onPublic);
      reinitialize(false);
      // setting things back to empty before reinitializing
      setNotes([]);
      setPageCount(1);
      setSearchQuery('');
      // setting page to activate reinitialization
      setPage(1);
      initNotes(1, caseId, user, '', onPublic);
      initPageCount(caseId, user, '', onPublic);
    }
  };

  useEffect(() => {
    initNotes(page, caseId, user, searchQuery, onPublic);
  }, [page, caseId, user, searchQuery, onPublic]);

  useEffect(() => {
    initPageCount(caseId, user, searchQuery, onPublic);
  }, [caseId, user, searchQuery, onPublic]);

  const initPageCount = async (caseId, user, searchQuery, onPublic) => {
    if (!user || !user.id) return;
    if (!caseId || !caseId.length) return;
    const configService = require('../store/config/service');
    let res;
    if (onPublic) {
      res = await configService.default.getNotesPageCount(caseId, searchQuery);
    } else {
      res = await configService.default.getMyNotesPageCount(
        caseId,
        searchQuery,
        user.id
      );
    }
    if (!res) {
    }
    if (res.error) {
      setPageCount(1);
    }
    if (res.result && res.result.count) {
      setPageCount(res.result.count);
    }
  };

  const initNotes = async (page, caseId, user, searchQuery, onPublic) => {
    try {
      if (!user || !user.id || !caseId || !caseId.length || !page) return;
      setLoading(true);
      const configService = require('../store/config/service');
      let res;
      if (onPublic) {
        res = await configService.default.getNotes(caseId, page, searchQuery);
      } else {
        res = await configService.default.getMyNotes(
          caseId,
          page,
          searchQuery,
          user.id
        );
      }
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('notes --', res.result);
        setNotes(res.result);
        initDataKeys(res.result);
        initPageCount(page, caseId, user, searchQuery, onPublic);
        setLoading(false);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const initDataKeys = (notes) => {
    if (!notes || !notes.length) return;
    let newKeys = Object.keys(notes[0])
      .filter((k) => VISIBLE_NOTE_COLS.includes(k))
      .sort();
    return setDataKeys(newKeys);
  };

  const viewNote = (noteId) => {
    if (!noteId || !noteId.length) return;
    history.push(`/notes/${noteId}`);
  };

  const keyToLabelMapping = {
    desc: 'Description',
    isPrivate: 'Is Private',
    createdBy: 'Created By',
    createdAt: 'Creation Date',
  };

  return (
    <div className="note-card pb-2">
      {/* <div className="card-head pb-0 d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between align-items-center w-100">
          
        </div>
      </div> */}

      <div className="d-flex justify-content-start align-items-baseline px-2">
        <MyPagination page={page} setPage={setPage} pageCount={pageCount} />
        <div className="d-flex justify-content-start align-items-center w-100 px-3">
          <div className="max-width-24rem">
            <input
              className="form-control"
              placeholder={'type something..'}
              text={'text'}
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="d-flex justify-content-end align-items-baseline">
          <button
            className={`btn btn-sm btn-${
              !onPublic ? 'dark' : 'light'
            } shadow-sm me-2`}
            style={{ width: 'max-content' }}
            onClick={() => {
              setOnPublic(false);
            }}
          >
            Mine
          </button>
          <button
            className={`btn btn-sm btn-${
              onPublic ? 'dark' : 'light'
            } shadow-sm me-2`}
            style={{ width: 'max-content' }}
            onClick={() => {
              setOnPublic(true);
            }}
          >
            Public
          </button>
        </div>
      </div>

      <div className="card-body p-0 m-0 w-100 d-flex flex-column justify-content-start align-items-start overflow-hidden">
        {dataKeys && dataKeys.length ? (
          <div className="table-head-row">
            <div className="table-head-col flex-pt5">S No.</div>
            {dataKeys.map((dataKey) => (
              <div
                key={dataKey}
                className={`table-head-col ${
                  dataKey === 'desc' ? 'flex-three' : 'flex-one'
                }`}
              >
                {keyToLabelMapping[dataKey]}
              </div>
            ))}
          </div>
        ) : (
          <React.Fragment />
        )}
        {loading ? (
          <Loading />
        ) : notes && notes.length ? (
          notes.map((noteObj, i) => (
            <div
              key={i}
              className="table-row"
              onClick={() => openEditNoteModal(noteObj.id)}
            >
              <div className="table-col mt-2 flex-pt5">
                <span className="fw-light size14">
                  {(page - 1) * 20 + i + 1}.
                </span>
              </div>
              {Object.keys(noteObj)
                .sort()
                .filter((item) => VISIBLE_NOTE_COLS.includes(item))
                .map((dataKey) => (
                  <div
                    key={dataKey}
                    className={`table-col ${
                      dataKey === 'desc' ? 'flex-three' : 'flex-one'
                    }`}
                  >
                    <NoteCell dataKey={dataKey} data={noteObj[dataKey]} noteId={noteObj.noteId} />
                  </div>
                ))}
            </div>
          ))
        ) : (
          <NoEntry />
        )}
      </div>
    </div>
  );
};

export default NoteList;
