import React from 'react';

const Select = (props) => {
  const {
    disabled = false,
    label,
    placeholder,
    options,
    multiple = false,
    value,
    onChange,
    err,
    classes = ''
  } = props;

  return (
    <div className={`my-input-group ${classes ? classes : ''}`}>
      {label && <label className="form-label capitalize">{label}</label>}
      <select className="form-select" value={value} onChange={onChange} disabled={disabled}>
        {options.map((option, i) => (
          <option key={i} value={option.value}>{option.label}</option>
        ))}
      </select>
      {err && (
        <div className="form-error">
          <small className="form-error-text">{err}</small>
        </div>
      )}
    </div>
  );
};

export default Select;
