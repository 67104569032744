import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IOLTA_TYPES_MAP,
  VISIBLE_APPOINTMENT_COLS,
  VISIBLE_IOLTA_COLS,
} from '../constants/constants';
import FilingIoltaCell from './FilingIoltaCell';
import Input from './common/Input';
import Loading from './Loading';
import format from 'date-fns/format';
import { dateFormat, getMahDateCustom, priceFormat } from '../utils/userUtils';
import MyPagination from './common/MyPagination';
import NoEntry from './common/NoEntry';

const FilingIoltaList = (props) => {
  const {
    user,
    caseId,
    caseData,
    reinitialize,
    reinitializing,
    openEditFilingIoltaModal,
  } = props;

  const [filingIoltas, setFilingIoltas] = useState([]);
  const [allFilingIoltas, setAllFilingIoltas] = useState([]);
  const [stats, setStats] = useState({});
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState(
    getMahDateCustom(caseData.openedAt)
  );
  const [endDate, setEndDate] = useState(getMahDateCustom(new Date()));
  const [startStamp, setStartStamp] = useState(+new Date(caseData.openedAt));
  const [endStamp, setEndStamp] = useState(+new Date());
  const [dataKeys, setDataKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const history = useHistory();

  // useEffect(() => {
  //   initDataKeys(filingIoltas);
  // }, [filingIoltas]);

  useEffect(() => {
    initPage(reinitializing, caseId, user, startStamp, endStamp);
  }, [reinitializing, caseId, user]);

  const initPage = (reinitializing, caseId, user, startStamp, endStamp) => {
    if (reinitializing) {
      reinitialize(false);
      // setting things back to empty before reinitializing
      setFilingIoltas([]);
      setPageCount(1);
      setSearchQuery('');
      // setting page to activate reinitialization
      setPage(1);
      initFilingIoltas(1, caseId, user, '', startStamp, endStamp);
      initPageCount(caseId, user, '', startStamp, endStamp);
      initStats();
    }
  };

  useEffect(() => {
    initFilingIoltas(page, caseId, user, searchQuery, startStamp, endStamp);
  }, [page, caseId, user, searchQuery, startStamp, endStamp]);

  useEffect(() => {
    initPageCount(caseId, user, searchQuery, startStamp, endStamp);
  }, [caseId, user, searchQuery, startStamp, endStamp]);

  const initPageCount = async (
    caseId,
    user,
    searchQuery,
    startStamp,
    endStamp
  ) => {
    if (!user || !user.id) return;
    if (!caseId || !caseId.length) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getFilingIoltasPageCount(
      caseId,
      searchQuery,
      startStamp,
      endStamp
    );
    if (!res) {
    }
    if (res.error) {
      setPageCount(1);
    }
    if (res.result && res.result.count) {
      setPageCount(res.result.count);
    }
  };

  const initFilingIoltas = async (
    page,
    caseId,
    user,
    searchQuery,
    startStamp,
    endStamp
  ) => {
    try {
      if (!user || !user.id || !caseId || !caseId.length || !page) return;
      setLoading(true);
      const configService = require('../store/config/service');

      const res = await configService.default.getFilingIoltas(
        caseId,
        page,
        searchQuery,
        startStamp,
        endStamp
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('filingIoltas --', res.result);
        setFilingIoltas(res.result);
        initDataKeys(res.result);
        initPageCount(page, caseId, user, searchQuery, startStamp, endStamp);
        setLoading(false);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initStats();
  }, [startStamp, endStamp]);

  const initStats = async () => {
    try {
      if (!caseId || !caseId.length || !startStamp || !endStamp) return;
      setLoading(true);
      const configService = require('../store/config/service');
      const res = await configService.default.getAllFilingIoltasByCase(
        caseId,
        startStamp,
        endStamp
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (!res.result || !res.result.length) {
        return setStats({
          totalDeposits: 0,
          totalTransfers: 0,
          remainingBalance: 0,
        });
      }
      setAllFilingIoltas(res.result);
      const totalDeposits = res.result
        .filter((el) => el.type === 'deposit')
        .reduce((acc, cur) => {
          return acc + cur.amount;
        }, 0);
      const totalTransfers = res.result
        .filter((el) => el.type === 'transfer')
        .reduce((acc, cur) => {
          return acc + cur.amount;
        }, 0);
      const remainingBalance = totalDeposits - totalTransfers;
      setStats({ totalDeposits, totalTransfers, remainingBalance });
      setLoading(false);
    } catch (e) {
      // console.log(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const initDataKeys = (filingIoltas) => {
    if (!filingIoltas || !filingIoltas.length) return;
    // let newKeys = Object.keys(filingIoltas[0])
    //   .filter((k) => VISIBLE_IOLTA_COLS.includes(k))
    //   .sort();
    let newKeys = ['ioltaDate', 'caseType', 'type', 'amount', 'desc'];
    return setDataKeys(newKeys);
  };

  const keyToLabelMapping = {
    type: 'Type',
    caseType: 'Case Type',
    amount: 'Amount',
    desc: 'Description',
    ioltaDate: 'Date',
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  const openPrintView = (docType, data, caseData) => {
    if (!caseData) return;
    if (!startStamp) handleError('startDate', 'select start date');
    if (!endStamp) handleError('endDate', 'select end date');
    if (!data || !data.length)
      return alert('No data found for selected date range.');

    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `
      <style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>
      
      `
    );

    mywindow.document.write('</head><body>');
    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
        <div style="display: flex; flex: 0">
          <img
            height="61"
            width="100"
            alt=""
            src="/assets/img/logo.png"
          />
        </div>
        <div style="display: flex; flex: 1">
          <h1 style="padding: 0 1rem">${docType}</h1>
        </div>
        <div style="display: flex;">
          <h3 style="padding: 0 1rem">${dateFormat(Date.now())}</h3>
        </div>
      </div>

      <div style="display: flex; justify-content: start; align-items: center">
        <div style="display: flex; flex: 1; flex-direction: column">
          <h4 style="font-weight: 600; margin: 0; padding: 0;">Ahluwalia Law Offices</h4>

          <h4 style="font-weight: 400; margin: 0; padding: 0;">Your 1st Choice & 2nd Opinion Law Firm</h4>
          ${
            caseData && caseData.office && caseData.office.length
              ? `
            ${
              caseData.office === 'dallas'
                ? `
            <h4 style="font-weight: 400; margin: 0; padding: 0;">14180 Dallas Parkway, Suite 720</h4>
            <h4 style="font-weight: 400; margin: 0; padding: 0;">Dallas, TX 75254</h4>    
            <h4 style="font-weight: 400; margin: 0; padding: 0;">Phone:972-361-0606 Fax:972-361-0999</h4>
            `
                : caseData.office === 'houston'
                ? `
            <h4 style="font-weight: 400; margin: 0; padding: 0;">5444 Westheimer</h4>
          <h4 style="font-weight: 400; margin: 0; padding: 0;">Houston, TX 77056</h4>
          <h4 style="font-weight: 400; margin: 0; padding: 0;">Phone: 713-600-4338 Fax: 972-361-0999</h4>
          <h4 style="font-weight: 400; margin: 0; padding: 0;">Main Office 972-361-0606</h4>
            `
                : ''
            }
          `
              : `
          <h4 style="font-weight: 400; margin: 0; padding: 0;">14180 Dallas Parkway, Suite 720</h4>
          <h4 style="font-weight: 400; margin: 0; padding: 0;">Dallas, TX 75254</h4>
          <h4 style="font-weight: 400; margin: 0; padding: 0;">Phone: 972-361-0606 Fax: 972-361-0999</h4>
          `
          }
          <h5>www.ahluwalialaw.com</h5>
        </div>
        <div
          style="
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex: 1;
          "
        >
        <div style="display: flex; flex: 1; flex-direction: column; justify-content: flex-start; align-items: flex-start;">
            <h5 style="font-weight: 400; margin: 0; padding: 0;">To</h5>
            ${
              caseData.caseType === 'individual'
                ? ''
                : `<h5 style="font-weight: 600; margin: 0; padding: 0;">${caseData.companyName}</h5>`
            }
            <h5 style="font-weight: 400; margin: 0; padding: 0;">${
              caseData.primaryContact.fullName
            }</h5>
          </div>
        </div>
      </div>

      <div style="display: flex; justify-content: start; align-items: center">
        <div style="display: flex; flex: 1; flex-direction: column">
          <h4 style="font-weight: 600; margin: 0; padding: 1rem 0;">Case - #${
            caseData.caseNum
          }</h4>
        </div>
      </div>
      <div style="display: flex; justify-content: space-evenly; align-items: center">
        <div style="display: flex; flex: 2; border: 1px solid #9ab">
          <h5 style="padding: .5rem; margin: 0;">Date</h5>
        </div>
        <div style="display: flex; flex: 2; border: 1px solid #9ab">
          <h5 style="padding: .5rem; margin: 0;">Type</h5>
        </div>
        <div style="display: flex; flex: 3; border: 1px solid #9ab">
          <h5 style="padding: .5rem; margin: 0;">Method</h5>
        </div>
        <div style="display: flex; flex: 3; border: 1px solid #9ab">
          <h5 style="padding: .5rem; margin: 0;">Description</h5>
        </div>
        <div style="display: flex; flex: 2; border: 1px solid #9ab">
          <h5 style="padding: .5rem; margin: 0;">Amount (USD)</h5>
        </div>
      </div>
      ${data
        .map(
          (pt) => `
      <div style="display: flex; justify-content: space-evenly; align-items: center">
      <div style="display: flex; flex: 2; border: 1px solid #fafafa; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${dateFormat(
          new Date(pt.ioltaDate)
        )}</h5>
      </div>
      <div style="display: flex; flex: 2; border: 1px solid #fafafa; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
          pt.type
        }</h5>
      </div>
      <div style="display: flex; flex: 3; border: 1px solid #fafafa; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
          IOLTA_TYPES_MAP[pt.subType]
        }</h5>
      </div>
      <div style="display: flex; flex: 3; border: 1px solid #fafafa; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${pt.desc}</h5>
      </div>
      <div style="display: flex; flex: 2; border: 1px solid #fafafa; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${priceFormat(
          pt.amount
        )}</h5>
      </div>
    </div>
      `
        )
        .join('')}
        <div style="display: flex; justify-content: space-evenly; align-items: center">
        <div style="display: flex; flex: 7;">
        </div>
        <div style="display: flex; flex: 3; border: 1px solid #9ab">
          <h5 style="font-weight: 600; padding: .5rem; margin: 0; text-align: right;">Total (USD)</h5>
        </div>
        <div style="display: flex; flex: 2; border: 1px solid #9ab">
          <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${
            data
              .reduce((acc, cur) => {
                if (cur.type === 'deposit') return acc + cur.amount;
                else if (cur.type === 'transfer' && docType !== 'Transfers')
                  return acc - cur.amount;
                else if (cur.type === 'transfer' && docType === 'Transfers')
                  return acc + cur.amount;
                else return acc;
              }, 0)
              .toFixed(2) == -0.0
              ? priceFormat(0.0)
              : priceFormat(
                  data
                    .reduce((acc, cur) => {
                      if (cur.type === 'deposit') return acc + cur.amount;
                      else if (
                        cur.type === 'transfer' &&
                        docType !== 'Transfers'
                      )
                        return acc - cur.amount;
                      else if (
                        cur.type === 'transfer' &&
                        docType === 'Transfers'
                      )
                        return acc + cur.amount;
                      else return acc;
                    }, 0)
                    .toFixed(2)
                )
          }</h5>
        </div>
      </div>
    `);
    mywindow.document.write(
      `<script
      src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/js/bootstrap.bundle.min.js"
      integrity="sha384-b5kHyXgcpbZJO/tY9Ul7kGkf1S0CWuKcCD38l8YkeH8z8QjE0GmW1gYU5S9FOnJ0"
      crossorigin="anonymous"
    ></script>`
    );
    mywindow.document.write('</body></html>');

    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
  };

  return (
    <div className="filingIolta-card">
      {/* <div className="card-head pb-0 d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between align-items-center w-100">
          
        </div>
      </div> */}

      <div className="d-flex justify-content-start align-items-center my-1">
        <div className="d-flex justify-content-start align-items-start flex-one">
          <Input
            label={'Start Date'}
            placeholder={'enter start date'}
            type={'date'}
            value={startDate}
            onChange={(e) => {
              setStartDate(e.target.value);
              setStartStamp(+new Date(e.target.value));
              handleError('startDate', '');
            }}
            err={errors.startDate || ''}
          />

          <Input
            label={'End Date'}
            placeholder={'enter end date'}
            type={'date'}
            value={endDate}
            onChange={(e) => {
              setEndDate(e.target.value);
              setEndStamp(
                +new Date(new Date(e.target.value).setHours(23, 59, 59))
              );
              handleError('endDate', '');
            }}
            err={errors.endDate || ''}
          />
        </div>

        <div className="d-flex flex-column justify-content-end align-items-end my-2">
          <button
            className="btn btn-sm btn-success shadow-sm my-1"
            style={{ width: 'max-content' }}
            onClick={() => {
              openPrintView(
                'Deposits',
                allFilingIoltas.filter((el) => el.type === 'deposit'),
                caseData
              );
            }}
          >
            Print Deposits <i className="bi bi-info-square ms-1"></i>
          </button>

          <button
            className="btn btn-sm btn-success shadow-sm my-1"
            style={{ width: 'max-content' }}
            onClick={() => {
              openPrintView(
                'Transfers',
                allFilingIoltas.filter((el) => el.type === 'transfer'),
                caseData
              );
            }}
          >
            Print Transfers <i className="bi bi-receipt ms-1"></i>
          </button>

          <button
            className="btn btn-sm btn-success shadow-sm my-1"
            style={{ width: 'max-content' }}
            onClick={() => {
              openPrintView('Statement', allFilingIoltas, caseData);
            }}
          >
            Print Statement <i className="bi bi-card-list ms-1"></i>
          </button>
        </div>
      </div>

      <div className="d-flex justify-content-start align-items-center px-2">
        <MyPagination page={page} setPage={setPage} pageCount={pageCount} />
        <div className="d-flex justify-content-start align-items-center w-100 px-3">
          <div className="max-width-24rem">
            <input
              className="form-control"
              placeholder={'type something..'}
              text={'text'}
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="d-flex flex-column juftify-content-center align=items-end my-2"></div>
      </div>

      <div
        id="filingIoltaList"
        className="card-body p-0 m-0 w-100 d-flex flex-column justify-content-start align-items-start overflow-hidden"
      >
        {dataKeys && dataKeys.length ? (
          <div className="table-head-row">
            <div className="table-head-col flex-pt5">S No.</div>
            {dataKeys.map((dataKey) => (
              <div key={dataKey} className="table-head-col">
                {keyToLabelMapping[dataKey]}
              </div>
            ))}
          </div>
        ) : (
          <React.Fragment />
        )}
        {loading ? (
          <Loading />
        ) : filingIoltas && filingIoltas.length ? (
          filingIoltas.map((filingIoltaObj, i) => (
            <div
              key={i}
              className="table-row"
              onClick={() =>
                openEditFilingIoltaModal(filingIoltaObj.type, filingIoltaObj.id)
              }
            >
              <div className="table-col mt-2 flex-pt5">
                <span className="fw-light size14">
                  {(page - 1) * 20 + i + 1}.
                </span>
              </div>
              {['ioltaDate', 'caseType', 'type', 'amount', 'desc'].map(
                (dataKey) => (
                  <div key={dataKey} className="table-col">
                    <FilingIoltaCell
                      dataKey={dataKey}
                      data={
                        dataKey === 'type'
                          ? {
                              type: filingIoltaObj[dataKey],
                              subType: filingIoltaObj['subType'],
                            }
                          : filingIoltaObj[dataKey]
                      }
                    />
                  </div>
                )
              )}
            </div>
          ))
        ) : (
          <NoEntry />
        )}
      </div>
      <div id="filingIoltaStats" className="card-footer bg-white p-0">
        <div className="d-flex justify-content-evenly align-items-center">
          <div className="fw-bold text-center text-dark bg-extra-light p-2 size16 flex-one mx-1">
            <span className="fw-light">Total Deposits: </span>
            <span>{priceFormat((stats || {}).totalDeposits || 0)}</span>
          </div>
          <div className="fw-bold text-center text-dark bg-extra-light p-2 size16 flex-one mx-1">
            <span className="fw-light">Total Transfers: </span>
            <span>{priceFormat((stats || {}).totalTransfers || 0)}</span>
          </div>
          <div className="fw-bold text-center text-dark bg-extra-light p-2 size16 flex-one mx-1">
            <span className="fw-light">Remaining Balance: </span>
            <span>{priceFormat((stats || {}).remainingBalance || 0)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilingIoltaList;
