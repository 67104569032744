import React, { useEffect, useState } from 'react';
import { getUserName } from '../utils/userUtils';
import AppointmentList from './AppointmentList';
import AddCaseAppointmentModal from './modals/AddCaseAppointmentModal';
import EditAppointmentModal from './modals/EditAppointmentModal';
// import AddAppointmentModal from './modals/AddAppointmentModal';
// import EditAppointmentModal from './modals/EditAppointmentModal';

const CaseAppointments = (props) => {
  const { caseData, user } = props;
  const [employees, setEmployees] = useState([]);
  const [showAddCaseAppointmentModal, setShowAddCaseAppointmentModal] =
    useState(undefined);
  const [showEditAppointmentModal, setShowEditAppointmentModal] =
    useState(undefined);
  const [reinitializing, setReinitializing] = useState(false);

  useEffect(() => {
    initEmployees();
  }, []);

  const initEmployees = async () => {
    if (!user || !user.userType) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getUsers(); // change to employees only
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      setEmployees(
        res.result.map((emp) => {
          return {
            label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
            value: emp.id,
          };
        })
      );
    }
  };

  const openAddCaseAppointmentModal = () => {
    setShowAddCaseAppointmentModal({
      onClose: () => {
        setShowAddCaseAppointmentModal(undefined);
      },
      user: user ? user : {},
      caseId:
        caseData && caseData.id && caseData.id.length ? caseData.id : undefined,
    });
  };

  const openEditAppointmentModal = (appointmentId) => {
    setShowEditAppointmentModal({
      onClose: () => {
        setShowEditAppointmentModal(undefined);
      },
      user: user ? user : {},
      appointmentId: appointmentId ? appointmentId : undefined,
      caseId:
        caseData && caseData.id && caseData.id.length ? caseData.id : undefined,
    });
  };

  const reinitialize = (bool = true) => {
    setReinitializing(bool);
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Case Appointments</h4>
        <button
          className="btn btn-sm btn-outline-success shadow-sm ms-2"
          style={{ width: 'max-content' }}
          onClick={openAddCaseAppointmentModal}
        >
          Add New <i className="bi bi-plus ms-1"></i>
        </button>
      </div>
      <div className="w-100">
        <AppointmentList
          caseId={(caseData || {}).id}
          user={user}
          reinitialize={reinitialize}
          reinitializing={reinitializing}
          openEditAppointmentModal={(aptId) =>
            openEditAppointmentModal(aptId)
          }
          caseAll={true}
        />

        <EditAppointmentModal
          visible={!!showEditAppointmentModal}
          onClose={showEditAppointmentModal && showEditAppointmentModal.onClose}
          user={showEditAppointmentModal && showEditAppointmentModal.user}
          appointmentId={
            showEditAppointmentModal && showEditAppointmentModal.appointmentId
          }
          currentCaseId={
            showEditAppointmentModal && showEditAppointmentModal.caseId
          }
          reinitialize={reinitialize}
        />

        <AddCaseAppointmentModal
          visible={!!showAddCaseAppointmentModal}
          onClose={showAddCaseAppointmentModal && showAddCaseAppointmentModal.onClose}
          user={showAddCaseAppointmentModal && showAddCaseAppointmentModal.user}
          appointmentId={
            showAddCaseAppointmentModal && showAddCaseAppointmentModal.appointmentId
          }
          currentCaseId={
            showAddCaseAppointmentModal && showAddCaseAppointmentModal.caseId
          }
          reinitialize={reinitialize}
        />
      </div>
    </div>
  );
};

export default CaseAppointments;
