import { format, formatDistance } from 'date-fns';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { TICKLER_TYPES_MAP } from '../constants/constants';
import { dateFormat, getUserName } from '../utils/userUtils';

const TicklerReportCell = (props) => {
  const { dataKey, data } = props;

  // const history = useHistory();

  const openCase = (caseId) => {
    if (!caseId || !caseId.length) return;
    const win = window.open(`/cases/${caseId}`, '_blank');
    win.focus();
  };

  const PrimaryName = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <small className="badge rounded px-1 text-dark fw-regular mx-1 capitalize">
        {data.fullName}
      </small>
    );
  };

  const TicklerType = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-normal mx-1 capitalize">
          {data}
        </small>
      </div>
    );
  };

  const OpeningDate = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-regular mx-1 capitalize">
          {dateFormat(new Date(data))}
        </small>
      </div>
    );
  };

  const TicklerId = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 fw-normal mx-1 capitalize bg-light-dark text-white">
          {data.id.substring(0, 4) +
            '...' +
            data.id.substring(data.id.length - 4, data.id.length)}
          <i className="bi bi-box-arrow-up-right ms-1"></i>
        </small>
      </div>
    );
  };

  const TicklerNum = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 fw-normal mx-1 capitalize bg-light-dark text-white">
          {data}
          <i className="bi bi-box-arrow-up-right ms-1"></i>
        </small>
      </div>
    );
  };

  const Text = (props) => {
    const {
      data,
      light,
      trunc = false,
      truncSm = false,
      fullWidth = false,
    } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small
          className={`badge px-1 ${light ? 'fw-normal' : 'fw-regular'} ${
            trunc ? 'truncated' : ''
          } ${
            fullWidth
              ? 'w-100 d-flex justify-content-start align-items-center px-3'
              : ''
          } ${truncSm ? 'truncated-sm' : ''} capitalize text-dark`}
        >
          {data}
        </small>
      </div>
    );
  };

  const Desc = (props) => {
    const { data, fullWidth = false } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div className="desc-cont">
        <div
          className={`btl-1 w-100 d-flex justify-content-start align-items-center px-3 py-2 mb-3 text-dark`}
        >
          <small className="text-muted me-2 fw-light no-trunc">Description</small>
          {data}
        </div>
      </div>
    );
  };

  const CreationDate = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className={`badge px-1 fw-normal capitalize text-dark`}>
          {dateFormat(new Date(data))}
        </small>
      </div>
    );
  };

  const Emails = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge rounded text-and-border-blue px-1 fw-normal mx-1">
          {data[0]}
        </small>
        {/* {data.map((email) => (
          <small className="badge rounded text-and-border-blue px-1 fw-normal mx-1">
            {email}
          </small>
        ))} */}
      </div>
    );
  };

  const CaseInfo = ({ data }) => {
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div className="">
        <div className="d-flex justify-content-center align-items-center position-relative">
          <small
            className="badge rounded-pill bg-secondary ms-1 me-2 my-1 no-trunc cursor-pointer my-tooltip"
            title="Open Case"
            onClick={() => openCase(data.id)}
          >
            {data.caseNum}
          </small>

          <div className="d-flex flex-column justify-content-start align-items-start my-1 w-100">
            <p className="size14 fw-bold mb-0 text-dark">
              <span className="size12 me-1 text-muted fw-light">
                Primary Name
              </span>
              {data.primaryContact.fullName}
            </p>
            {data.companyName && data.companyName.length ? (
              <p className="size14 fw-bold mb-0 text-dark">
                <span className="size12 me-1 text-muted fw-light">Company</span>
                {data.companyName || ''}
              </p>
            ) : (
              <React.Fragment />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="my-2">
      {/* {dataKey === 'openedAt' ? (
        <OpeningDate data={data} />
      ) : (
        <React.Fragment />
      )} */}

      {dataKey === 'assignedTo' ? (
        <Text data={data.map((usr) => getUserName(usr)).join(', ')} truncSm />
      ) : (
        <React.Fragment />
      )}

      {dataKey === 'type' ? <TicklerType data={TICKLER_TYPES_MAP[data]} /> : <React.Fragment />}

      {dataKey === 'dueDate' ? (
        <CreationDate data={data} />
      ) : (
        <React.Fragment />
      )}
      {dataKey === 'case' ? <CaseInfo data={data} /> : <React.Fragment />}
      {dataKey === 'createdAt' ? (
        <CreationDate data={data} />
      ) : (
        <React.Fragment />
      )}

      {dataKey === 'createdBy' ? (
        <Text data={getUserName(data)} />
      ) : (
        <React.Fragment />
      )}

      {dataKey === 'desc' ? <Desc data={data} fullWidth noCap /> : <React.Fragment />}

      {/* {dataKey === 'status' ? <Text data={data} /> : <React.Fragment />} */}
      {/* {dataKey === 'location' ? <Text data={data} light /> : <React.Fragment />}

      {dataKey === 'case' ? <TicklerId data={data} /> : <React.Fragment />} */}

      {/* {dataKey === 'case' ? <TicklerId data={data} /> : <React.Fragment />} */}
      {/* {dataKey === 'caseNum' ? <TicklerNum data={data} /> : <React.Fragment />} */}
    </div>
  );
};

export default TicklerReportCell;
