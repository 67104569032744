import format from 'date-fns/format';
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { myGreenSuperLight } from '../../constants/colors';
import {
  APPOINTMENT_TYPES,
  LOCATIONS,
  TICKLER_TYPES,
} from '../../constants/constants';
import {
  getCurrentDateTime,
  getDateTimeFromDate,
  getNextQuarterHourDateTime,
  getUserName,
} from '../../utils/userUtils';
import Checkbox from '../common/Checkbox';
import Input from '../common/Input';
import MultiSelect from '../common/MultiSelect';
import MyMessage from '../common/MyMessage';
import Select from '../common/Select';
import TextArea from '../common/TextArea';
import Loading from '../Loading';

const AddCaseAppointmentModal = (props) => {
  const { caseData, visible, onClose, user, reinitialize, currentCaseId } =
    props;
  const [appointmentData, setAppointmentData] = useState({});
  const [cases, setCases] = useState([]);
  const [caseNum, setCaseNum] = useState('');
  const [names, setNames] = useState([]);
  const [phones, setPhones] = useState([]);
  const [onOtherName, setOnOtherName] = useState(true);
  const [onOtherPhone, setOnOtherPhone] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [caseId, setCaseId] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [otherName, setOtherName] = useState('');
  const [otherPhone, setOtherPhone] = useState('');
  const [appointmentType, setAppointmentType] = useState('interview');
  const [location, setLocation] = useState('office');
  const [details, setDetails] = useState('');
  const [startTime, setStartTime] = useState(getCurrentDateTime());
  const [endTime, setEndTime] = useState(getNextQuarterHourDateTime());
  const [charge, setCharge] = useState(0);
  const [appointmentWith, setAppointmentWith] = useState([]);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCaseList();
  }, [visible]);

  useEffect(() => {
    initEmployees();
  }, [visible]);

  useEffect(() => {
    initNamesAndPhones(caseId);
  }, [caseId]);

  const initNamesAndPhones = (csId) => {
    if (!csId || !csId.length) return;
    if (!cases || !cases.length) return;
    if (csId === 'select-a-case') {
      setName('');
      setNames([]);
      setOtherName('');
      setOnOtherName(true);
      setPhone('');
      setPhones([]);
      setOtherPhone('');
      setOnOtherPhone(true);
      return;
    }
    setName('');
    setPhone('');
    setOnOtherName(false);
    setOnOtherPhone(false);
    let selected = cases.filter((cs) => cs.id === csId)[0];
    let newNames = [];
    if (selected.primaryContact && selected.primaryContact.fullName) {
      newNames.push({
        label: selected.primaryContact.fullName,
        value: selected.primaryContact.fullName,
      });
    }
    if (selected.secondaryContact && selected.secondaryContact.fullName) {
      newNames.push({
        label: selected.secondaryContact.fullName,
        value: selected.secondaryContact.fullName,
      });
    }
    let newPhones =
      selected.phones &&
      selected.phones.map((ph) => {
        return {
          label: `${ph.phoneNum} (${ph.type})`,
          value: `${ph.phoneNum} (${ph.type})`,
        };
      });
    if (!newNames.length) {
      setName('');
      setNames([]);
      setOtherName('');
      setOnOtherName(true);
    } else {
      setNames(newNames);
      setName(newNames[0].value);
      handleError('otherName', '');
    }
    if (!newPhones.length) {
      setPhone('');
      setPhones([]);
      setOtherPhone('');
      setOnOtherPhone(true);
    } else {
      setPhones(newPhones);
      setPhone(newPhones[0].value);
      handleError('otherPhone', '');
    }
    // console.log(names);
    // console.log(phones);
  };

  useEffect(() => {
    initAppointmentForCreation();
  }, [visible, currentCaseId]);

  const initAppointmentForCreation = async () => {
    try {
      if (!visible || !currentCaseId || !currentCaseId.length) return;
      setLoading(true);
      setCaseId(currentCaseId);
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const initEmployees = async () => {
    if (!user || !user.userType) return;
    const configService = require('../../store/config/service');
    const res = await configService.default.getUsers(); // change to employees only
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      setEmployees(
        res.result.map((emp) => {
          return {
            label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
            value: emp.id,
          };
        })
      );
    }
  };

  const getCaseList = async () => {
    // fetch companies from db
    const configService = require('../../store/config/service');
    const res = await configService.default.getAllCases();
    // console.log('res', res);
    if (res.result && res.result.length) {
      setCases([
        { label: 'Select a case', value: 'select-a-case' },
        ...res.result.map((cs) => {
          return { label: getCaseLabel(cs), value: cs.id, ...cs };
        }),
      ]);
    }
  };

  const getCaseLabel = (cs) => {
    // console.log(cs.openedAt);
    let o = format(new Date(cs.openedAt), 'do MMM yy');
    // console.log(o);
    let p = cs.primaryContact.fullName;
    let t = cs.caseType;
    let caseNum = cs.caseNum;
    return `(${caseNum}) - ${p} (${t}) (${o})`;
  };

  const createAppointment = async () => {
    if (!validateAppointment()) return;
    const configService = require('../../store/config/service');

    let body = {
      appointmentType,
      startTime,
      startTimeStamp: +new Date(startTime),
      endTime,
      endTimeStamp: +new Date(endTime),
      charge,
      createdBy: user.id,
    };

    if (caseId && caseId.length) {
      body.case = caseId;
    }
    if (onOtherPhone) {
      body.phone = otherPhone;
    } else {
      body.phone = phone;
    }
    if (onOtherName) {
      body.name = otherName;
    } else {
      body.name = name;
    }
    if (details && details.length) body.details = details;
    if (location && location.length) body.location = location;
    if (appointmentWith && appointmentWith.length) {
      body.appointmentWith = appointmentWith.map((usr) => usr.value);
    }

    // console.log('body - is - ', body);

    const res = await configService.default.createAppointment(body);
    if (!res) {
      setMessage({ type: 'warning', msg: 'no response from server' });
    }
    if (res.error) {
      setMessage({ type: 'danger', msg: res.error });
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const validateAppointment = () => {
    let flag = true;
    if (!appointmentType || !appointmentType.length) {
      flag = false;
      handleError('appointmentType', 'select a valid appointment type');
    }
    if (!caseId || !caseId.length) {
      flag = false;
      handleError('caseId', 'select a valid case');
    }
    if (onOtherName && (!otherName || !otherName.length)) {
      flag = false;
      handleError('otherName', 'add a valid name');
    }
    if (onOtherPhone && (!otherPhone || !otherPhone.length)) {
      flag = false;
      handleError('otherPhone', 'add a valid phone number');
    }

    if (!onOtherName && (!name || !name.length)) {
      flag = false;
      handleError('name', 'select a valid name');
    }
    if (!onOtherPhone && (!phone || !phone.length)) {
      flag = false;
      handleError('phone', 'select a valid phone');
    }

    if (!location || !location.length) {
      flag = false;
      handleError('location', 'select a valid location');
    }
    if (!startTime || !startTime.length) {
      flag = false;
      handleError('startTime', 'select a valid start time');
    }
    if (!endTime || !endTime.length) {
      flag = false;
      handleError('endTime', 'select a valid end time');
    }
    // if (!appointmentWith || !appointmentWith.length) {
    //   flag = false;
    //   handleError('appointmentWith', 'select a valid employee');
    // }
    if (!user || !user.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    return flag;
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  const doOnClose = () => {
    resetBlankModal();
    onClose();
  };

  const resetBlankModal = () => {
    setCases([]);
    setCaseNum('');
    setNames([]);
    setPhones([]);
    setOnOtherName(true);
    setOnOtherPhone(true);
    setEmployees([]);
    setCaseId('');
    setName('');
    setPhone('');
    setOtherName('');
    setOtherPhone('');
    setAppointmentType('interview');
    setLocation('office');
    setDetails('');
    setStartTime(getCurrentDateTime());
    setEndTime(getNextQuarterHourDateTime());
    setCharge(0);
    setAppointmentWith([]);
    setErrors({});
    setMessage({});
    setLoading(false);
  };

  return visible ? (
    <div className="my-modal-container">
      <div className="my-modal-overlay" onClick={doOnClose}></div>
      <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable my-modal z-index-9">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <div>
              <h3 className="modal-title fw-bold">{'Create Appointment'}</h3>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={doOnClose}
            ></button>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="modal-body">
              <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                  <div className="mb-2">
                    <p className="text-muted fs-italic fw-light size15">
                      Add the details below to create a new appointment!
                    </p>

                    <div className="row d-flex flex-wrap justify-content-start align-items-start">
                      <div
                        className={`${
                          currentCaseId && currentCaseId.length
                            ? 'col-xl-8 col-lg-8'
                            : 'col-xl-4 col-lg-4'
                        } col-md-6 col-sm-12 col-xs-12 col-12`}
                      >
                        <Select
                          disabled={currentCaseId && currentCaseId.length}
                          label={
                            currentCaseId && currentCaseId.length
                              ? 'Case'
                              : 'Choose Case'
                          }
                          placeholder={'select a case'}
                          value={caseId}
                          options={cases}
                          onChange={(e) => {
                            setCaseId(e.target.value);
                            handleError('caseId', '');
                          }}
                          err={errors.caseId || ''}
                        />
                      </div>

                      {!currentCaseId ? (
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                          <Input
                            label={'Search by Case Number'}
                            placeholder={'type case number'}
                            type={'text'}
                            value={caseNum}
                            onChange={(e) => {
                              setCaseNum(e.target.value);
                              let matches = [];
                              matches = cases.filter(
                                (cs) => cs.caseNumForSearch === e.target.value
                              );
                              if (matches.length === 1) {
                                // console.log('matched!!');
                                setCaseId(matches[0].id);
                              }
                              handleError('caseNum', '');
                            }}
                            err={errors.caseNum || ''}
                          />
                        </div>
                      ) : (
                        <React.Fragment />
                      )}

                      <hr className="hy-light-hr mt-3 mb-4" />

                      <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12 col-12">
                        <Input
                          label={'Start Time'}
                          placeholder={'add start time'}
                          type={'datetime-local'}
                          min={getCurrentDateTime()}
                          value={startTime}
                          onChange={(e) => {
                            setStartTime(e.target.value);
                            setEndTime(
                              getNextQuarterHourDateTime(
                                new Date(e.target.value)
                              )
                            );
                            handleError('startTime', '');
                          }}
                          err={errors.startTime || ''}
                        />
                      </div>

                      <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12 col-12">
                        <Input
                          label={'End Time'}
                          placeholder={'add end time'}
                          type={'datetime-local'}
                          min={startTime}
                          value={endTime}
                          onChange={(e) => {
                            setEndTime(e.target.value);
                            handleError('endTime', '');
                          }}
                          err={errors.endTime || ''}
                        />
                      </div>

                      <hr className="hy-light-hr mt-3 mb-4" />

                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                        <Select
                          disabled={onOtherName}
                          label={'Choose Name'}
                          placeholder={'select a name'}
                          value={name}
                          options={names}
                          onChange={(e) => {
                            setName(e.target.value);
                            handleError('name', '');
                          }}
                          err={errors.name || ''}
                        />
                      </div>

                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                        <Input
                          disabled={!onOtherName}
                          label={'Other'}
                          placeholder={'enter full name'}
                          type={'text'}
                          value={otherName}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              e.target.value.length &&
                              errors.otherName &&
                              errors.otherName.length
                            ) {
                              handleError('otherName', '');
                            }
                            setOtherName(e.target.value);
                          }}
                          err={errors.otherName || ''}
                        />
                      </div>

                      <hr className="hy-light-hr mt-3 mb-4" />

                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                        <Select
                          disabled={onOtherPhone}
                          label={'Choose Phone'}
                          placeholder={'select a phone'}
                          value={phone}
                          options={phones}
                          onChange={(e) => {
                            setPhone(e.target.value);
                            handleError('phone', '');
                          }}
                          err={errors.phone || ''}
                        />
                      </div>

                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                        <Input
                          disabled={!onOtherPhone}
                          label={'Other'}
                          placeholder={'enter phone number'}
                          type={'tel'}
                          value={otherPhone}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              e.target.value.length &&
                              errors.otherPhone &&
                              errors.otherPhone.length
                            ) {
                              handleError('otherPhone', '');
                            }
                            setOtherPhone(e.target.value);
                          }}
                          err={errors.otherPhone || ''}
                        />
                      </div>

                      <hr className="hy-light-hr mt-3 mb-4" />

                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                        <Input
                          label={'Charge'}
                          placeholder={'enter charge'}
                          type={'number'}
                          value={charge}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              e.target.value.length &&
                              errors.charge &&
                              errors.charge.length
                            ) {
                              handleError('charge', '');
                            }
                            setCharge(e.target.value);
                          }}
                          err={errors && errors.charge}
                        />
                      </div>

                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
                        <MultiSelect
                          label={'Appointment With'}
                          displayValue={'label'}
                          values={appointmentWith}
                          options={employees}
                          onSelect={(e) => {
                            setAppointmentWith(e);
                            handleError('appointmentWith', '');
                          }}
                          onRemove={(e) => {
                            setAppointmentWith(e);
                          }}
                          err={errors.appointmentWith || ''}
                        />
                      </div>

                      <hr className="hy-light-hr mt-3 mb-4" />

                      <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
                        <TextArea
                          label={'Details'}
                          placeholder={'add details'}
                          value={details}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              e.target.value.length &&
                              errors.details &&
                              errors.details.length
                            ) {
                              handleError('details', '');
                            }
                            setDetails(e.target.value);
                          }}
                          err={errors && errors.details}
                        />
                      </div>

                      <hr className="hy-light-hr mt-3 mb-4" />

                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                        <Select
                          label={'Appointment Type'}
                          placeholder={'select an appointment type'}
                          value={appointmentType}
                          options={APPOINTMENT_TYPES}
                          onChange={(e) => {
                            setAppointmentType(e.target.value);
                            handleError('appointmentType', '');
                          }}
                          err={errors.appointmentType || ''}
                        />
                      </div>

                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                        <Select
                          label={'Location'}
                          placeholder={'select a location'}
                          value={location}
                          options={LOCATIONS}
                          onChange={(e) => {
                            setLocation(e.target.value);
                            handleError('location', '');
                          }}
                          err={errors.location || ''}
                        />
                      </div>

                      <hr className="hy-light-hr mt-3 mb-4" />
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
                </div>
            </div>
          )}
          <div className="px-3">
            <MyMessage
              visible={!!message}
              message={message}
              onClose={() => {
                setMessage(undefined);
              }}
            />
          </div>
          <div
            className="modal-footer"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <button
              type="button"
              className="btn btn-primary ms-2"
              onClick={createAppointment}
            >
              Create Appointment
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default AddCaseAppointmentModal;
