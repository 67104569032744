import React from 'react';

const ClientBalancesReport = (props) => {
  const { user } = props;
  return <div>
    <h3>ClientBalancesReport</h3>
  </div>;
};

export default ClientBalancesReport;
