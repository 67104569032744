import React from 'react'

const Loading = () => {
  return (
    <div className="min-h-200px w-100 d-flex text-center align-items-center justify-content-center">
      <img src={require("../assets/img/91.gif")} />
    </div>
  )
}

export default Loading
