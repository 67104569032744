import React from 'react';

const Checkbox = (props) => {
  const {
    label,
    type,
    checked = false,
    idx,
    namex,
    err,
    onClick,
    disabled = false,
  } = props;

  return (
    <div className="my-input-group">
      <div className="form-check form-check-inline">
        <input
        disabled={disabled}
          className="form-check-input"
          type={type}
          name={namex}
          id={idx}
          checked={checked}
          onClick={onClick}
        />
        <label className="form-check-label" htmlFor={idx}>
          {label}
        </label>
      </div>
      {err && (
        <div className="form-error">
          <small className="form-error-text">{err}</small>
        </div>
      )}
    </div>
  );
};

export default Checkbox;
