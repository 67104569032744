import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AppointmentList from '../AppointmentList';
import EditAppointmentModal from '../modals/EditAppointmentModal';

const Appointments = (props) => {
  const { user, isLoggedIn } = props;
  const [showEditAppointmentModal, setShowEditAppointmentModal] =
    useState(undefined);
  const [reinitializing, setReinitializing] = useState(false);

  const openEditAppointmentModal = (appointmentId) => {
    setShowEditAppointmentModal({
      onClose: () => {
        setShowEditAppointmentModal(undefined);
      },
      user: user ? user : {},
      appointmentId: appointmentId ? appointmentId : undefined,
    });
  };

  const reinitialize = (bool = true) => {
    setReinitializing(bool);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mb-4">
          <AppointmentList
            user={user}
            all={true}
            openEditAppointmentModal={(appointmentId) =>
              openEditAppointmentModal(appointmentId)
            }
            reinitializing={reinitializing}
            reinitialize={reinitialize}
          />
        </div>
      </div>
      <EditAppointmentModal
        visible={!!showEditAppointmentModal}
        onClose={showEditAppointmentModal && showEditAppointmentModal.onClose}
        user={showEditAppointmentModal && showEditAppointmentModal.user}
        appointmentId={
          showEditAppointmentModal && showEditAppointmentModal.appointmentId
        }
        reinitialize={reinitialize}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth: { user, isLoggedIn } = {} } = state;
  return {
    user,
    isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload) => {
      dispatch(login(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
