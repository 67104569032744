import React from 'react';

const H1BStatusReport = (props) => {
  const { user } = props;
  return <div>
    <h3>H1BStatusReport</h3>
  </div>;
};

export default H1BStatusReport;
