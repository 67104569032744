import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Input from '../common/Input';
import Select from '../common/Select';
import {
  CASE_TYPES,
  CIS_TYPES,
  COUNTRIES,
  PAYMENT_TYPES,
  PHONE_TYPES,
  REFERRED_BY_TYPES,
  USER_TYPES,
} from '../../constants/constants';
import {
  getUserName,
  isValidEmail,
  isValidPhone,
  getMahDate,
  createCompany,
} from '../../utils/userUtils';
import TextArea from '../common/TextArea';
import Checkbox from '../common/Checkbox';
import { useHistory } from 'react-router-dom';

const CreateUser = (props) => {
  const { user, isLoggedIn } = props;

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userCreationType, setUserCreationType] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [designation, setDesignation] = useState('');

  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const createUser = async () => {
    if (!validateUser()) return;
    // console.log('User is valid');
    const configService = require('../../store/config/service');

    let body = {
      name: {
        firstName, lastName
      },
      email,
      password,
      userType: userCreationType,
      designation,
    };

    // console.log('body - is - ', body);

    const res = await configService.default.signUp(body);
    if (res.result) {
      // console.log('created user - ', res.result);
      history.push(`/users`);
    }
  };

  const validateUser = () => {
    let flag = true;
    if (!firstName || !firstName.length) {
      flag = false;
      handleError('firstName', 'select a valid case type');
    }
    if (!lastName || !lastName.length) {
      flag = false;
      handleError('lastName', 'select a valid case type');
    }
    if (!password || !password.length) {
      flag = false;
      handleError('password', 'select a valid case type');
    }
    if (!email || !email.length || !isValidEmail(email)) {
      flag = false;
      handleError('email', 'select a valid date');
    }
    if (!userCreationType || !userCreationType.length) {
      flag = false;
      handleError('userCreationType', 'select a valid case type');
    }
    if (!designation || !designation.length) {
      flag = false;
      handleError('designation', 'select a valid case type');
    }
    if (!user || !user.userType || user.userType !== 'Admin') {
      flag = false;
      setMessage({ type: 'warning', msg: 'Something went wrong!' });
    }

    return flag;
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  if (!user || !isLoggedIn) return <React.Fragment />;
  return (
    <div className="row">
      <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 col-12">
        <div className="card p-3">
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <h4>Create New User</h4>
              <p className="text-muted fs-italic fw-light size15">
                Fill in the details below to create a new user!
              </p>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <button className="btn btn-primary" onClick={createUser}>
                Create New User!
              </button>
            </div>
          </div>
          <div className="row d-flex flex-wrap justify-content-start align-items-start">
            
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                label={'User Type'}
                placeholder={'select a user type'}
                value={userCreationType}
                options={USER_TYPES}
                onChange={(e) => {
                  setUserCreationType(e.target.value);
                  handleError('userCreationType', '');
                }}
                err={errors.userCreationType || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Designation'}
                placeholder={'enter designation'}
                type={'text'}
                value={designation}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.designation &&
                    errors.designation.length
                  ) {
                    handleError('designation', '');
                  }
                  setDesignation(e.target.value);
                }}
                err={errors.designation || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'First Name'}
                placeholder={'enter first name'}
                type={'text'}
                value={firstName}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.firstName &&
                    errors.firstName.length
                  ) {
                    handleError('firstName', '');
                  }
                  setFirstName(e.target.value);
                }}
                err={errors.firstName || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Last Name'}
                placeholder={'enter last name'}
                type={'text'}
                value={lastName}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.lastName &&
                    errors.lastName.length
                  ) {
                    handleError('lastName', '');
                  }
                  setLastName(e.target.value);
                }}
                err={errors.lastName || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Email'}
                placeholder={'enter email'}
                type={'text'}
                value={email}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.email &&
                    errors.email.length && isValidEmail(e.target.value)
                  ) {
                    handleError('email', '');
                  }
                  setEmail(e.target.value);
                }}
                err={errors.email || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Password'}
                placeholder={'enter password'}
                type={'text'}
                value={password}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.password &&
                    errors.password.length
                  ) {
                    handleError('password', '');
                  }
                  setPassword(e.target.value);
                }}
                err={errors.password || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <button className="btn btn-primary" onClick={createUser}>
              Create New User!
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth: { user, isLoggedIn } = {} } = state;
  return {
    user,
    isLoggedIn,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
