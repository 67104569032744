import { formatDistance, formatDistanceStrict } from 'date-fns';
import {
  CASE_SUB_TYPES,
  CHARGE_TYPES,
  DEPOSIT_TYPES,
} from '../constants/constants';

const priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const priceFormat = priceFormatter.format;

const dateOptions = {
  timeZone: 'UTC',
  month: 'short',
  day: 'numeric',
  year: 'numeric',
};
const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);
export const dateFormat = dateFormatter.format;

const dateShortOptions = {
  timeZone: 'UTC',
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};
const dateShortFormatter = new Intl.DateTimeFormat('en-US', dateShortOptions);
export const dateShortFormat = dateShortFormatter.format;

const dateTimeOptions = {
  timeZone: 'UTC',
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};
const dateTimeFormatter = new Intl.DateTimeFormat('en-US', dateTimeOptions);
export const dateTimeFormat = dateTimeFormatter.format;

const dateTimeForCustomOptions = {
  timeZone: 'UTC',
  month: '2-digit',
  day: '2-digit',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};
const dateTimeForCustomFormatter = new Intl.DateTimeFormat(
  'en-US',
  dateTimeForCustomOptions
);
export const dateTimeForCustomFormat = dateTimeForCustomFormatter.format;

export const getUserName = (user) => {
  let fullName = '';
  if (user.name.firstName.length) {
    fullName = capitalize(user.name.firstName.trim());
  }
  if (user.name.lastName.length) {
    fullName += ` ${capitalize(user.name.lastName.trim())}`;
  }
  return fullName;
};

export const capitalize = (word) => {
  return word[0].toUpperCase() + word.substring(1).toLowerCase();
};

export const firstFive = (crumb, isView) => {
  if (crumb.length > 5) {
    return isView
      ? 'View'
      : `${crumb[0]}${crumb[1]}${crumb[2]}${crumb[3]}${crumb[4]}...`;
  }
};

export const sleep = async (time = 100) => {
  await new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};

export const isValidEmail = (value) => {
  if (value && value.length && value.indexOf('@') > 0 && value.indexOf('.') > 0)
    return true;
  return false;
};

export const isValidPhone = (ph) => {
  if (!ph.length || ph.length < 10) return false;
  return true;
};

export const getMahDate = () => {
  var now = new Date();
  var month = now.getMonth() + 1;
  var day = now.getDate();
  if (month < 10) month = '0' + month;
  if (day < 10) day = '0' + day;
  var today = now.getFullYear() + '-' + month + '-' + day;
  return today;
};

export const getMahDateCustom = (dt) => {
  let [month, day, year] =
    dt && dt.length
      ? dateShortFormat(new Date(dt)).split('/')
      : dateShortFormat(new Date()).split('/');
  if (month < 10) month = '0' + month;
  if (day < 10) day = '0' + day;
  var today = year + '-' + month + '-' + day;
  return today;
};

export const getMahDateCustomPlusOne = (dt) => {
  let [month, day, year] =
    dt && dt.length
      ? dateShortFormat(new Date(dt)).split('/')
      : dateShortFormat(new Date()).split('/');
  if (month < 10) month = '0' + month;
  if (day < 10) day = '0' + day;
  var today = year + '-' + month + '-' + day;
  return today;
};

export const getDateTimeFromDate = (myDate) => {
  if (!myDate || !myDate.length) return;
  const [dt, tm] = dateTimeForCustomFormat(new Date(myDate)).split(', ');
  const [month, day, year] = dt.split('/');
  return `${year}-${month}-${day}T${tm}`;
};

export const getCurrentDateTime = () => {
  var today = new Date();
  var date =
    today.getFullYear() +
    '-' +
    (today.getMonth() + 1 < 10
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1) +
    '-' +
    (today.getDate() < 10 ? `0${today.getDate()}` : today.getDate());
  var time =
    (today.getHours() < 10 ? `0${today.getHours()}` : today.getHours()) +
    ':' +
    (today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes());
  return date + 'T' + time;
};

export const getNextHourDateTime = (dt = new Date()) => {
  var today = dt;
  today.setHours(today.getHours() + 1);
  var date =
    today.getFullYear() +
    '-' +
    (today.getMonth() + 1 < 10
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1) +
    '-' +
    (today.getDate() < 10 ? `0${today.getDate()}` : today.getDate());
  var time =
    (today.getHours() < 10 ? `0${today.getHours()}` : today.getHours()) +
    ':' +
    (today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes());
  return date + 'T' + time;
};

export const getNextQuarterHourDateTime = (dt = new Date()) => {
  var today = dt;
  today.setMinutes(today.getMinutes() + 15);
  var date =
    today.getFullYear() +
    '-' +
    (today.getMonth() + 1 < 10
      ? `0${today.getMonth() + 1}`
      : today.getMonth() + 1) +
    '-' +
    `${today.getDate() < 10 ? `0${today.getDate()}` : `${today.getDate()}`}`;
  var time =
    (today.getHours() < 10 ? `0${today.getHours()}` : today.getHours()) +
    ':' +
    (today.getMinutes() < 10 ? `0${today.getMinutes()}` : today.getMinutes());
  return date + 'T' + time;
};

export const getTodayStartDate = (dt = new Date()) => {
  var start = new Date(dt);
  start.setHours(0, 0, 0);
  return start;
};

export const getTodayEndDate = (dt = new Date()) => {
  var end = new Date(dt);
  end.setHours(23, 59, 59);
  return end;
};

export const getMonthStartDate = (date = new Date()) => {
  return new Date(date.getFullYear(), date.getMonth() - 1, 1);
};

export const getMonthEndDate = (date = new Date()) => {
  return new Date(date.getFullYear(), date.getMonth(), 0);
};

export const daysInMonth = (iMonth, iYear) => {
  return 32 - new Date(iYear, iMonth, 32).getDate();
};

export const createDailyRevenueMap = (start, end, data) => {
  let startRunTime = new Date().getTime();
  let s = start,
    e = end,
    dateMap = {};
  while (s < e) {
    // looping for every date from start date to end date
    // console.log(new Date(s));
    data.map((payment) => {
      if (
        payment.paymentDateStamp >= s &&
        payment.paymentDateStamp < s + 86400000
      ) {
        //payment belongs to current date in loop
        let paymentCaseId = payment.case.id;
        if (dateMap[`${s}`]) {
          //current date exists in dateMap
          if (payment.type === 'charge') {
            if (dateMap[`${s}`].charges) {
              //current date in dateMap has existing charges
              if (dateMap[`${s}`].charges[paymentCaseId]) {
                //list of charges in current date in dateMap already has current payment case id
                dateMap[`${s}`].charges[paymentCaseId] = [
                  ...dateMap[`${s}`].charges[paymentCaseId],
                  payment,
                ];
              } else {
                //list of charges in current date in dateMap doest not have current payment case id
                dateMap[`${s}`].charges[paymentCaseId] = [payment];
              }
            } else {
              //current date in dateMap does not have existing charges
              dateMap[`${s}`].charges = {};
              dateMap[`${s}`].charges[paymentCaseId] = [payment];
            }
          } else if (payment.type === 'payment') {
            if (dateMap[`${s}`].payments) {
              //current date in dateMap has existing payments
              if (dateMap[`${s}`].payments[paymentCaseId]) {
                //list of payments in current date in dateMap already has current payment case id
                dateMap[`${s}`].payments[paymentCaseId] = [
                  ...dateMap[`${s}`].payments[paymentCaseId],
                  payment,
                ];
              } else {
                //list of payments in current date in dateMap doest not have current payment case id
                dateMap[`${s}`].payments[paymentCaseId] = [payment];
              }
            } else {
              //current date in dateMap does not have existing payments
              dateMap[`${s}`].payments = {};
              dateMap[`${s}`].payments[paymentCaseId] = [payment];
            }
          }
        } else {
          //current date does not exist in dateMap
          if (payment.type === 'charge') {
            dateMap[`${s}`] = { charges: {} };
            dateMap[`${s}`].charges[paymentCaseId] = [payment];
          } else if (payment.type === 'payment') {
            dateMap[`${s}`] = { payments: {} };
            dateMap[`${s}`].payments[paymentCaseId] = [payment];
          }
        }
      }
    });
    s += 86400000;
  }
  let endRunTime = new Date().getTime();
  if (endRunTime > startRunTime) {
    // console.log(`Run took ${endRunTime - startRunTime}ms`);
  }
  // console.log({ dateMap });
  return dateMap;
};

export const createDailyRevenueByTypeMap = (start, end, data) => {
  let startRunTime = new Date().getTime();
  let s = start,
    e = end,
    dateMap = {};
  while (s < e) {
    // looping for every date from start date to end date
    // console.log(new Date(s));
    data.map((payment) => {
      if (
        payment.paymentDateStamp >= s &&
        payment.paymentDateStamp < s + 86400000
      ) {
        //payment belongs to current date in loop
        // let paymentCaseId = payment.case.id;
        let paymentType = payment.subType;
        if (dateMap[`${getPaymentStampByOldOrNew(s, payment, start, end)}`]) {
          //current date exists in dateMap
          if (payment.type === 'charge') {
            dateMap[
              `${getPaymentStampByOldOrNew(s, payment, start, end)}`
            ].totalCharges += payment.amount;
            if (
              dateMap[`${getPaymentStampByOldOrNew(s, payment, start, end)}`]
                .charges
            ) {
              //current date in dateMap has existing charges
              if (
                dateMap[`${getPaymentStampByOldOrNew(s, payment, start, end)}`]
                  .charges[paymentType]
              ) {
                //list of charges in current date in dateMap already has current payment case id
                dateMap[
                  `${getPaymentStampByOldOrNew(s, payment, start, end)}`
                ].charges[paymentType] = [
                  ...dateMap[
                    `${getPaymentStampByOldOrNew(s, payment, start, end)}`
                  ].charges[paymentType],
                  payment,
                ];
              } else {
                //list of charges in current date in dateMap doest not have current payment case id
                dateMap[
                  `${getPaymentStampByOldOrNew(s, payment, start, end)}`
                ].charges[paymentType] = [payment];
              }
            } else {
              //current date in dateMap does not have existing charges
              dateMap[
                `${getPaymentStampByOldOrNew(s, payment, start, end)}`
              ].charges = {};
              dateMap[
                `${getPaymentStampByOldOrNew(s, payment, start, end)}`
              ].charges[paymentType] = [payment];
            }
          } else if (payment.type === 'payment') {
            dateMap[
              `${getPaymentStampByOldOrNew(s, payment, start, end)}`
            ].totalPayments += payment.amount;
            if (
              dateMap[`${getPaymentStampByOldOrNew(s, payment, start, end)}`]
                .payments
            ) {
              //current date in dateMap has existing payments
              if (
                dateMap[`${getPaymentStampByOldOrNew(s, payment, start, end)}`]
                  .payments[paymentType]
              ) {
                //list of payments in current date in dateMap already has current payment case id
                dateMap[
                  `${getPaymentStampByOldOrNew(s, payment, start, end)}`
                ].payments[paymentType] = [
                  ...dateMap[
                    `${getPaymentStampByOldOrNew(s, payment, start, end)}`
                  ].payments[paymentType],
                  payment,
                ];
              } else {
                //list of payments in current date in dateMap doest not have current payment case id
                dateMap[
                  `${getPaymentStampByOldOrNew(s, payment, start, end)}`
                ].payments[paymentType] = [payment];
              }
            } else {
              //current date in dateMap does not have existing payments
              dateMap[
                `${getPaymentStampByOldOrNew(s, payment, start, end)}`
              ].payments = {};
              dateMap[
                `${getPaymentStampByOldOrNew(s, payment, start, end)}`
              ].payments[paymentType] = [payment];
            }
          }
        } else {
          //current date does not exist in dateMap
          if (
            payment.type === 'charge' &&
            getPaymentStampByOldOrNew(s, payment, start, end)
          ) {
            dateMap[`${getPaymentStampByOldOrNew(s, payment, start, end)}`] = {
              charges: {},
              totalCharges: payment.amount,
              totalPayments: 0,
            };
            dateMap[
              `${getPaymentStampByOldOrNew(s, payment, start, end)}`
            ].charges[paymentType] = [payment];
          } else if (
            payment.type === 'payment' &&
            getPaymentStampByOldOrNew(s, payment, start, end)
          ) {
            dateMap[`${getPaymentStampByOldOrNew(s, payment, start, end)}`] = {
              payments: {},
              totalPayments: payment.amount,
              totalCharges: 0,
            };
            dateMap[
              `${getPaymentStampByOldOrNew(s, payment, start, end)}`
            ].payments[paymentType] = [payment];
          }
        }
      }
    });
    s += 86400000;
  }
  let endRunTime = new Date().getTime();
  if (endRunTime > startRunTime) {
    // console.log(`Run took ${endRunTime - startRunTime}ms`);
  }
  // console.log({ dateMap });
  return dateMap;
};

export const getPaymentStampByOldOrNew = (s, payment, start, end) => {
  if (!s || !payment) {
    // console.log('no payment or s');
    return (
      +new Date(s) -
      (new Date(s).getHours() * 60 * 60 * 1000 +
        new Date(s).getMinutes() * 60 * 1000) +
      new Date(s).getSeconds() * 60
    );
  }
  if (!payment.paymentId && !payment.chargeId) {
    const offsetted = s + new Date().getTimezoneOffset() * 360000;
    if (offsetted < start || offsetted > end) return 0;
    // console.log('in the mains');
    // console.log(
    //   dateFormat(
    //     new Date(
    //       +new Date(offsetted) -
    //         (new Date(offsetted).getHours() * 60 * 60 * 1000 +
    //           new Date(offsetted).getMinutes() * 60 * 1000) +
    //         new Date(offsetted).getSeconds() * 60
    //     )
    //   )
    // );
    return (
      +new Date(offsetted) -
      (new Date(offsetted).getHours() * 60 * 60 * 1000 +
        new Date(offsetted).getMinutes() * 60 * 1000) +
      new Date(offsetted).getSeconds() * 60
    );
  }
  // console.log('in end');
  return (
    +new Date(s) -
    (new Date(s).getHours() * 60 * 60 * 1000 +
      new Date(s).getMinutes() * 60 * 1000) +
    new Date(s).getSeconds() * 60
  );
};

export const getIoltaStampByOldOrNew = (s, iolta, start, end) => {
  if (!s || !iolta) {
    // console.log('no iolta or s');
    return (
      +new Date(s) -
      (new Date(s).getHours() * 60 * 60 * 1000 +
        new Date(s).getMinutes() * 60 * 1000) +
      new Date(s).getSeconds() * 60
    );
  }
  if (!iolta.depositId && !iolta.transferId) {
    const offsetted = s + new Date().getTimezoneOffset() * 360000;
    if (offsetted < start || offsetted > end) return 0;
    // console.log('in the mains');
    return (
      +new Date(offsetted) -
      (new Date(offsetted).getHours() * 60 * 60 * 1000 +
        new Date(offsetted).getMinutes() * 60 * 1000) +
      new Date(offsetted).getSeconds() * 60
    );
  }
  // console.log('in end');
  return (
    +new Date(s) -
    (new Date(s).getHours() * 60 * 60 * 1000 +
      new Date(s).getMinutes() * 60 * 1000) +
    new Date(s).getSeconds() * 60
  );
};

export const createClientBalancesMap = (
  start,
  end,
  data,
  excludeZero,
  excludeZeroToOne
) => {
  let caseMap = {};
  data.map((payment) => {
    if (caseMap[payment.case.id]) {
      caseMap[payment.case.id].payments = [
        ...caseMap[payment.case.id].payments,
        payment,
      ];
    } else {
      caseMap[payment.case.id] = { case: payment.case, payments: [payment] };
    }
  });
  if (Object.keys(caseMap).length) {
    let newMap = {};
    Object.keys(caseMap).map((csId) => {
      newMap[csId] = caseMap[csId];
      newMap[csId].balance = caseMap[csId].payments.reduce((acc, cur) => {
        if (cur.type === 'charge') {
          return acc + cur.amount;
        } else if (cur.type === 'payment') {
          return acc - cur.amount;
        }
      }, 0);
      if (newMap[csId] && newMap[csId].balance === 0 && excludeZero) {
        delete newMap[csId];
      }
      if (
        newMap[csId] &&
        newMap[csId].balance > 0 &&
        newMap[csId].balance < 1 &&
        excludeZeroToOne
      ) {
        delete newMap[csId];
      }
    });
    if (Object.keys(newMap).length) {
      // console.log('Case Map - ', newMap);
      return newMap;
    } else {
      return {};
    }
  } else {
    return {};
  }
};

export const createMonthlyRevenueMap = (start, end, data) => {
  let startRunTime = new Date().getTime();
  let s = start,
    e = end,
    monthMap = {};
  while (s < e) {
    // looping for every date from start date to end date
    // console.log(new Date(s));
    data.map((payment) => {
      if (
        payment.paymentDateStamp >= s &&
        payment.paymentDateStamp <
          +new Date(
            new Date(
              new Date(s).setFullYear(
                new Date(s).getFullYear(),
                new Date(s).getMonth() + 1,
                0
              )
            ).setHours(23, 59, 59)
          )
      ) {
        //payment belongs to current month in loop
        let paymentCaseId = payment.case.id;
        if (monthMap[`${s}`]) {
          //current month exists in monthMap
          if (payment.type === 'charge') {
            if (monthMap[`${s}`].charges) {
              //current month in monthMap has existing charges
              if (monthMap[`${s}`].charges[paymentCaseId]) {
                //list of charges in current month in monthMap already has current payment case id
                monthMap[`${s}`].charges[paymentCaseId] = [
                  ...monthMap[`${s}`].charges[paymentCaseId],
                  payment,
                ];
              } else {
                //list of charges in current month in monthMap doest not have current payment case id
                monthMap[`${s}`].charges[paymentCaseId] = [payment];
              }
            } else {
              //current month in monthMap does not have existing charges
              monthMap[`${s}`].charges = {};
              monthMap[`${s}`].charges[paymentCaseId] = [payment];
            }
          } else if (payment.type === 'payment') {
            if (monthMap[`${s}`].payments) {
              //current month in monthMap has existing payments
              if (monthMap[`${s}`].payments[paymentCaseId]) {
                //list of payments in current month in monthMap already has current payment case id
                monthMap[`${s}`].payments[paymentCaseId] = [
                  ...monthMap[`${s}`].payments[paymentCaseId],
                  payment,
                ];
              } else {
                //list of payments in current month in monthMap doest not have current payment case id
                monthMap[`${s}`].payments[paymentCaseId] = [payment];
              }
            } else {
              //current month in monthMap does not have existing payments
              monthMap[`${s}`].payments = {};
              monthMap[`${s}`].payments[paymentCaseId] = [payment];
            }
          }
        } else {
          //current month does not exist in monthMap
          if (payment.type === 'charge') {
            monthMap[`${s}`] = { charges: {} };
            monthMap[`${s}`].charges[paymentCaseId] = [payment];
          } else if (payment.type === 'payment') {
            monthMap[`${s}`] = { payments: {} };
            monthMap[`${s}`].payments[paymentCaseId] = [payment];
          }
        }
      }
    });
    s = new Date(s).setMonth(new Date(s).getMonth() + 1);
  }
  let endRunTime = new Date().getTime();
  if (endRunTime > startRunTime) {
    // console.log(`Run took ${endRunTime - startRunTime}ms`);
  }
  // console.log({ monthMap });
  return monthMap;
};

export const createMonthlyRevenueByTypeMap = (start, end, data) => {
  let startRunTime = new Date().getTime();
  let s = start,
    e = end,
    monthMap = {};
  while (s < e) {
    // looping for every date from start date to end date
    // console.log(new Date(s));
    data.map((payment) => {
      if (
        payment.paymentDateStamp >= s &&
        payment.paymentDateStamp <
          +new Date(
            new Date(
              new Date(s).setFullYear(
                new Date(s).getFullYear(),
                new Date(s).getMonth() + 1,
                0
              )
            ).setHours(23, 59, 0) -
              new Date().getTimezoneOffset() * 60000
          )
      ) {
        //payment belongs to current month in loop
        let paymentType = payment.subType;
        if (monthMap[`${s}`]) {
          //current month exists in monthMap
          if (payment.type === 'charge') {
            monthMap[`${s}`].totalCharges += payment.amount;
            if (monthMap[`${s}`].charges) {
              //current month in monthMap has existing charges
              if (monthMap[`${s}`].charges[paymentType]) {
                //list of charges in current month in monthMap already has current payment case id
                monthMap[`${s}`].charges[paymentType] = [
                  ...monthMap[`${s}`].charges[paymentType],
                  payment,
                ];
              } else {
                //list of charges in current month in monthMap doest not have current payment case id
                monthMap[`${s}`].charges[paymentType] = [payment];
              }
            } else {
              //current month in monthMap does not have existing charges
              monthMap[`${s}`].charges = {};
              monthMap[`${s}`].charges[paymentType] = [payment];
            }
          } else if (payment.type === 'payment') {
            monthMap[`${s}`].totalPayments += payment.amount;
            if (monthMap[`${s}`].payments) {
              //current month in monthMap has existing payments
              if (monthMap[`${s}`].payments[paymentType]) {
                //list of payments in current month in monthMap already has current payment case id
                monthMap[`${s}`].payments[paymentType] = [
                  ...monthMap[`${s}`].payments[paymentType],
                  payment,
                ];
              } else {
                //list of payments in current month in monthMap doest not have current payment case id
                monthMap[`${s}`].payments[paymentType] = [payment];
              }
            } else {
              //current month in monthMap does not have existing payments
              monthMap[`${s}`].payments = {};
              monthMap[`${s}`].payments[paymentType] = [payment];
            }
          }
        } else {
          //current month does not exist in monthMap
          if (payment.type === 'charge') {
            monthMap[`${s}`] = {
              charges: {},
              totalCharges: payment.amount,
              totalPayments: 0,
            };
            monthMap[`${s}`].charges[paymentType] = [payment];
          } else if (payment.type === 'payment') {
            monthMap[`${s}`] = {
              payments: {},
              totalCharges: 0,
              totalPayments: payment.amount,
            };
            monthMap[`${s}`].payments[paymentType] = [payment];
          }
        }
      }
    });
    s =
      new Date(s).setMonth(new Date(s).getMonth() + 1) -
      new Date().getTimezoneOffset() * 60000;
  }
  let endRunTime = new Date().getTime();
  if (endRunTime > startRunTime) {
    // console.log(`Run took ${endRunTime - startRunTime}ms`);
  }
  // console.log({ monthMap });
  return monthMap;
};

export const createIoltaDailyRevenueMap = (start, end, data) => {
  let startRunTime = new Date().getTime();
  let s = start,
    e = end,
    dateMap = {};
  while (s < e) {
    // looping for every date from start date to end date
    // console.log(new Date(s));
    data.map((iolta) => {
      if (iolta.ioltaDateStamp >= s && iolta.ioltaDateStamp < s + 86400000) {
        //iolta belongs to current date in loop
        let ioltaCaseId = iolta.case.id;
        if (dateMap[`${s}`]) {
          //current date exists in dateMap
          if (iolta.type === 'deposit') {
            if (dateMap[`${s}`].deposits) {
              //current date in dateMap has existing deposits
              if (dateMap[`${s}`].deposits[ioltaCaseId]) {
                //list of deposits in current date in dateMap already has current iolta case id
                dateMap[`${s}`].deposits[ioltaCaseId] = [
                  ...dateMap[`${s}`].deposits[ioltaCaseId],
                  iolta,
                ];
              } else {
                //list of deposits in current date in dateMap doest not have current iolta case id
                dateMap[`${s}`].deposits[ioltaCaseId] = [iolta];
              }
            } else {
              //current date in dateMap does not have existing deposits
              dateMap[`${s}`].deposits = {};
              dateMap[`${s}`].deposits[ioltaCaseId] = [iolta];
            }
          } else if (iolta.type === 'transfer') {
            if (dateMap[`${s}`].transfers) {
              //current date in dateMap has existing ioltas
              if (dateMap[`${s}`].transfers[ioltaCaseId]) {
                //list of ioltas in current date in dateMap already has current iolta case id
                dateMap[`${s}`].transfers[ioltaCaseId] = [
                  ...dateMap[`${s}`].transfers[ioltaCaseId],
                  iolta,
                ];
              } else {
                //list of ioltas in current date in dateMap doest not have current iolta case id
                dateMap[`${s}`].transfers[ioltaCaseId] = [iolta];
              }
            } else {
              //current date in dateMap does not have existing ioltas
              dateMap[`${s}`].transfers = {};
              dateMap[`${s}`].transfers[ioltaCaseId] = [iolta];
            }
          }
        } else {
          //current date does not exist in dateMap
          if (iolta.type === 'deposit') {
            dateMap[`${s}`] = { deposits: {} };
            dateMap[`${s}`].deposits[ioltaCaseId] = [iolta];
          } else if (iolta.type === 'transfer') {
            dateMap[`${s}`] = { transfers: {} };
            dateMap[`${s}`].transfers[ioltaCaseId] = [iolta];
          }
        }
      }
    });
    s += 86400000;
  }
  let endRunTime = new Date().getTime();
  if (endRunTime > startRunTime) {
    // console.log(`Run took ${endRunTime - startRunTime}ms`);
  }
  // console.log({ dateMap });
  return dateMap;
};

export const createIoltaDailyRevenueByTypeMap = (start, end, data) => {
  let startRunTime = new Date().getTime();
  let s = start,
    e = end,
    dateMap = {};
  while (s < e) {
    // looping for every date from start date to end date
    // console.log(new Date(s));
    data.map((iolta) => {
      if (iolta.ioltaDateStamp >= s && iolta.ioltaDateStamp < s + 86400000) {
        //iolta belongs to current date in loop
        let ioltaType = iolta.subType;
        if (dateMap[`${getIoltaStampByOldOrNew(s, iolta, start, end)}`]) {
          //current date exists in dateMap
          if (iolta.type === 'deposit') {
            dateMap[
              `${getIoltaStampByOldOrNew(s, iolta, start, end)}`
            ].totalDeposits += iolta.amount;
            if (
              dateMap[`${getIoltaStampByOldOrNew(s, iolta, start, end)}`]
                .deposits
            ) {
              //current date in dateMap has existing deposits
              if (
                dateMap[`${getIoltaStampByOldOrNew(s, iolta, start, end)}`]
                  .deposits[ioltaType]
              ) {
                //list of deposits in current date in dateMap already has current iolta case id
                dateMap[
                  `${getIoltaStampByOldOrNew(s, iolta, start, end)}`
                ].deposits[ioltaType] = [
                  ...dateMap[`${getIoltaStampByOldOrNew(s, iolta, start, end)}`]
                    .deposits[ioltaType],
                  iolta,
                ];
              } else {
                //list of deposits in current date in dateMap doest not have current iolta case id
                dateMap[
                  `${getIoltaStampByOldOrNew(s, iolta, start, end)}`
                ].deposits[ioltaType] = [iolta];
              }
            } else {
              //current date in dateMap does not have existing deposits
              dateMap[
                `${getIoltaStampByOldOrNew(s, iolta, start, end)}`
              ].deposits = {};
              dateMap[
                `${getIoltaStampByOldOrNew(s, iolta, start, end)}`
              ].deposits[ioltaType] = [iolta];
            }
          } else if (iolta.type === 'transfer') {
            dateMap[
              `${getIoltaStampByOldOrNew(s, iolta, start, end)}`
            ].totalTransfers += iolta.amount;
            if (
              dateMap[`${getIoltaStampByOldOrNew(s, iolta, start, end)}`]
                .transfers
            ) {
              //current date in dateMap has existing ioltas
              if (
                dateMap[`${getIoltaStampByOldOrNew(s, iolta, start, end)}`]
                  .transfers[ioltaType]
              ) {
                //list of ioltas in current date in dateMap already has current iolta case id
                dateMap[
                  `${getIoltaStampByOldOrNew(s, iolta, start, end)}`
                ].transfers[ioltaType] = [
                  ...dateMap[`${getIoltaStampByOldOrNew(s, iolta, start, end)}`]
                    .transfers[ioltaType],
                  iolta,
                ];
              } else {
                //list of ioltas in current date in dateMap doest not have current iolta case id
                dateMap[
                  `${getIoltaStampByOldOrNew(s, iolta, start, end)}`
                ].transfers[ioltaType] = [iolta];
              }
            } else {
              //current date in dateMap does not have existing ioltas
              dateMap[
                `${getIoltaStampByOldOrNew(s, iolta, start, end)}`
              ].transfers = {};
              dateMap[
                `${getIoltaStampByOldOrNew(s, iolta, start, end)}`
              ].transfers[ioltaType] = [iolta];
            }
          }
        } else {
          //current date does not exist in dateMap
          if (
            iolta.type === 'deposit' &&
            getIoltaStampByOldOrNew(s, iolta, start, end)
          ) {
            dateMap[`${getIoltaStampByOldOrNew(s, iolta, start, end)}`] = {
              deposits: {},
              totalDeposits: iolta.amount,
              totalTransfers: 0,
            };
            dateMap[
              `${getIoltaStampByOldOrNew(s, iolta, start, end)}`
            ].deposits[ioltaType] = [iolta];
          } else if (
            iolta.type === 'transfer' &&
            getIoltaStampByOldOrNew(s, iolta, start, end)
          ) {
            dateMap[`${getIoltaStampByOldOrNew(s, iolta, start, end)}`] = {
              transfers: {},
              totalDeposits: 0,
              totalTransfers: iolta.amount,
            };
            dateMap[
              `${getIoltaStampByOldOrNew(s, iolta, start, end)}`
            ].transfers[ioltaType] = [iolta];
          }
        }
      }
    });
    s += 86400000;
  }
  let endRunTime = new Date().getTime();
  if (endRunTime > startRunTime) {
    // console.log(`Run took ${endRunTime - startRunTime}ms`);
  }
  // console.log({ dateMap });
  return dateMap;
};

export const createIoltaClientBalancesMap = (
  start,
  end,
  data,
  excludeZero,
  excludeZeroToOne
) => {
  let caseMap = {};
  data.map((iolta) => {
    if (caseMap[iolta.case.id]) {
      caseMap[iolta.case.id].ioltas = [...caseMap[iolta.case.id].ioltas, iolta];
    } else {
      caseMap[iolta.case.id] = { case: iolta.case, ioltas: [iolta] };
    }
  });
  if (Object.keys(caseMap).length) {
    let newMap = {};
    Object.keys(caseMap).map((csId) => {
      newMap[csId] = caseMap[csId];
      newMap[csId].balance = caseMap[csId].ioltas.reduce((acc, cur) => {
        if (cur.type === 'deposit') {
          return acc + cur.amount;
        } else if (cur.type === 'transfer') {
          return acc - cur.amount;
        }
      }, 0);
      if (newMap[csId] && newMap[csId].balance === 0 && excludeZero) {
        delete newMap[csId];
      }
      if (
        newMap[csId] &&
        newMap[csId].balance > 0 &&
        newMap[csId].balance < 1 &&
        excludeZeroToOne
      ) {
        delete newMap[csId];
      }
    });
    if (Object.keys(newMap).length) {
      // console.log('Case Map - ', newMap);
      return newMap;
    } else {
      return {};
    }
  } else {
    return {};
  }
};

export const createBillingNotesMap = (data) => {
  let caseMap = {};

  data.map((note) => {
    if (caseMap[note.case.id]) {
      caseMap[note.case.id].notes = [...caseMap[note.case.id].notes, note];
    } else {
      caseMap[note.case.id] = { case: note.case, notes: [note] };
    }
  });
  return caseMap;
};

export const createPercentagesMap = (start, end, data) => {
  let caseMap = {};
  data.map((percentage) => {
    if (caseMap[percentage.case.id]) {
      caseMap[percentage.case.id].percentages = [
        ...caseMap[percentage.case.id].percentages,
        percentage,
      ];
    } else {
      caseMap[percentage.case.id] = {
        case: percentage.case,
        percentages: [percentage],
      };
    }
  });
  if (Object.keys(caseMap).length) {
    let newMap = {};
    Object.keys(caseMap).map((csId) => {
      newMap[csId] = caseMap[csId];
      newMap[csId].caseTypes = {};
      for (let type of CASE_SUB_TYPES) {
        let caseType = type.value;
        let currentPercentages = newMap[csId].percentages.filter(
          (el) => el.caseType === caseType
        );
        if (currentPercentages.length) {
          newMap[csId].caseTypes[caseType] = {
            percentages: currentPercentages,
            subTotal: currentPercentages.reduce(
              (acc, cur) => acc + cur.percentComplete,
              0
            ),
          };
        }
      }

      newMap[csId].total = caseMap[csId].percentages.reduce((acc, cur) => {
        return acc + cur.percentComplete;
      }, 0);
    });
    if (Object.keys(newMap).length) {
      // console.log('Case Map - ', newMap);
      return newMap;
    } else {
      return {};
    }
  } else {
    return {};
  }
};

export const createCostsMap = (start, end, data) => {
  let caseMap = {};
  data.map((cost) => {
    if (caseMap[cost.case.id]) {
      caseMap[cost.case.id].costs = [...caseMap[cost.case.id].costs, cost];
    } else {
      caseMap[cost.case.id] = { case: cost.case, costs: [cost] };
    }
  });
  if (Object.keys(caseMap).length) {
    let newMap = {};
    Object.keys(caseMap).map((csId) => {
      newMap[csId] = caseMap[csId];
      newMap[csId].total = caseMap[csId].costs.reduce((acc, cur) => {
        if (cur.completionDateStamp && cur.completionDateStamp > 0) {
          return acc + cur.completionDateStamp - cur.openDateStamp;
        } else if (cur.openDateStamp < new Date().getTime()) {
          return acc + new Date().getTime() - cur.openDateStamp;
        } else {
          return acc;
        }
      }, 0);
    });
    if (Object.keys(newMap).length) {
      // console.log('Case Map - ', newMap);
      return newMap;
    } else {
      return {};
    }
  } else {
    return {};
  }
};

export const createTimesheetsMap = (start, end, data) => {
  let caseMap = {};
  data.map((timesheet) => {
    if (caseMap[timesheet.case.id]) {
      caseMap[timesheet.case.id].timesheets = [
        ...caseMap[timesheet.case.id].timesheets,
        timesheet,
      ];
    } else {
      caseMap[timesheet.case.id] = {
        case: timesheet.case,
        timesheets: [timesheet],
      };
    }
  });
  if (Object.keys(caseMap).length) {
    let newMap = {};
    Object.keys(caseMap).map((csId) => {
      newMap[csId] = caseMap[csId];
      newMap[csId].total = caseMap[csId].timesheets.reduce((acc, cur) => {
        return acc + cur.totalTimeSpent;
      }, 0);
      newMap[csId].totalRate = caseMap[csId].timesheets.reduce((acc, cur) => {
        if (cur.isHourly && cur.rate) {
          return acc + cur.totalTimeSpent * cur.rate;
        }
        return acc;
      }, 0);
    });
    if (Object.keys(newMap).length) {
      // console.log('Case Map - ', newMap);
      return newMap;
    } else {
      return {};
    }
  } else {
    return {};
  }
};

export const getCaseStatus = (val) => {
  if (!val || !val.length) return '';
  return val
    .split('_')
    .map(
      (word) =>
        word[0].toUpperCase() + word.substring(1, word.length).toLowerCase()
    )
    .join(' ');
};

export const createCompany = async (
  companyName,
  validateCompany,
  companies
) => {
  if (!companies.length) return;
  if (!validateCompany(companyName)) return;
  // check if companies has companyName, if it does, return company id of matched company.
  let match = companies
    .map((cmp) =>
      cmp.label.toLowerCase() === companyName.toLowerCase() ? cmp : undefined
    )
    .filter((el) => !!el);
  if (match && match.length) {
    return match[0].value;
  }
  // replace all definitions of this function with imports of createCompany.
  // replace all function calls with function calls passing in arguments, turning it into a pure function :)
  const configService = require('../store/config/service');
  const res = await configService.default.createCompany({ companyName });
  if (res.result) {
    // console.log('company - ', res.result);
    return res.result.id;
  }
  return false;
};

export const createPermReport = (data) => {
  let permReport = [];
  data.map((perm) => {
    let latestStep;
    for (let step of Object.values(perm.steps)) {
      // console.log(step);
      if (step.finishDate && step.finishDate.length)
        latestStep = Object.assign({}, step);
    }
    if (!!latestStep) {
      permReport = [...permReport, { ...perm, latestStep }];
    }
  });
  return permReport;
};

export const createH1bReport = (data) => {
  let h1bReport = [];
  data.map((h1b) => {
    let latestStep;
    for (let step of Object.values(h1b.steps)) {
      // console.log(step);
      if (step.finishDate && step.finishDate.length)
        latestStep = Object.assign({}, step);
    }
    if (!!latestStep) {
      h1bReport = [...h1bReport, { ...h1b, latestStep }];
    }
  });
  return h1bReport;
};

export const createCollectionListMap = (
  start,
  end,
  charges,
  deposits,
  billNotes,
  excludeZero,
  excludeZeroToOne
) => {
  // console.log('initial charges', charges);
  // console.log('initial deposits', deposits);
  let caseMap = {};
  charges.map((charge) => {
    if (
      caseMap[charge.case.id] &&
      caseMap[charge.case.id].charges &&
      caseMap[charge.case.id].charges.length
    ) {
      caseMap[charge.case.id].charges = [
        ...caseMap[charge.case.id].charges,
        charge,
      ];
    } else {
      caseMap[charge.case.id] = {
        ...caseMap[charge.case.id],
        case: charge.case,
        charges: [charge],
      };
    }
  });
  deposits.map((deposit) => {
    if (
      caseMap[deposit.case.id] &&
      caseMap[deposit.case.id].deposits &&
      caseMap[deposit.case.id].deposits.length
    ) {
      caseMap[deposit.case.id].deposits = [
        ...caseMap[deposit.case.id].deposits,
        deposit,
      ];
    } else {
      caseMap[deposit.case.id] = {
        ...caseMap[deposit.case.id],
        case: deposit.case,
        deposits: [deposit],
      };
    }
  });
  if (Object.keys(caseMap).length) {
    let newMap = {};
    let grandTotalCharges = 0;
    let grandTotalDeposits = 0;
    Object.keys(caseMap).map((csId) => {
      newMap[csId] = caseMap[csId];
      newMap[csId].totalCharges = 0;
      newMap[csId].totalDeposits = 0;
      newMap[csId].total = 0;
      newMap[csId].chargeTypes = {};
      newMap[csId].depositTypes = {};
      newMap[csId].total = 0;
      newMap[csId].billNotes = billNotes.filter(
        (note) => note.case.id === csId
      );
      for (let type of CHARGE_TYPES) {
        let chargeType = type.value;
        let sum = 0;
        sum =
          caseMap[csId].charges && caseMap[csId].charges.length
            ? caseMap[csId].charges.reduce((acc, cur) => {
                if (cur.subType === chargeType) {
                  return acc + cur.amount;
                } else return acc;
              }, 0)
            : 0;
        if (sum !== 0 && !isNaN(sum)) {
          newMap[csId].chargeTypes[`${chargeType}`] = sum;
          newMap[csId].totalCharges += sum;
          grandTotalCharges += sum;
          newMap[csId].total += sum;
        }
      }
      for (let type of DEPOSIT_TYPES) {
        let depositType = type.value;
        let sum = 0;
        sum =
          caseMap[csId].deposits && caseMap[csId].deposits.length
            ? caseMap[csId].deposits.reduce((acc, cur) => {
                if (cur.subType === depositType) {
                  return acc + cur.amount;
                } else return acc;
              }, 0)
            : 0;
        if (sum !== 0 && !isNaN(sum)) {
          newMap[csId].depositTypes[`${depositType}`] = sum;
          newMap[csId].totalDeposits += sum;
          grandTotalDeposits += sum;
          newMap[csId].total -= sum;
        }
      }
    });
    if (Object.keys(newMap).length) {
      // console.log('Case Map - ', newMap);
      return { newMap, grandTotalCharges, grandTotalDeposits };
    } else {
      return {};
    }
  } else {
    return {};
  }
};

export const createCaseCostMap = (
  start,
  end,
  charges,
  deposits,
  excludeZero,
  excludeZeroToOne
) => {
  // console.log('initial charges', charges);
  // console.log('initial deposits', deposits);
  let caseMap = {};
  charges.map((charge) => {
    if (
      caseMap[charge.case.id] &&
      caseMap[charge.case.id].charges &&
      caseMap[charge.case.id].charges.length
    ) {
      caseMap[charge.case.id].charges = [
        ...caseMap[charge.case.id].charges,
        charge,
      ];
    } else {
      caseMap[charge.case.id] = {
        ...caseMap[charge.case.id],
        case: charge.case,
        charges: [charge],
      };
    }
  });
  deposits.map((deposit) => {
    if (
      caseMap[deposit.case.id] &&
      caseMap[deposit.case.id].deposits &&
      caseMap[deposit.case.id].deposits.length
    ) {
      caseMap[deposit.case.id].deposits = [
        ...caseMap[deposit.case.id].deposits,
        deposit,
      ];
    } else {
      caseMap[deposit.case.id] = {
        ...caseMap[deposit.case.id],
        case: deposit.case,
        deposits: [deposit],
      };
    }
  });
  if (Object.keys(caseMap).length) {
    let newMap = {};
    Object.keys(caseMap).map((csId) => {
      newMap[csId] = caseMap[csId];
      newMap[csId].totalCharges = 0;
      newMap[csId].totalDeposits = 0;
      newMap[csId].total = 0;
      newMap[csId].chargeTypes = {};
      newMap[csId].depositTypes = {};
      newMap[csId].total = 0;
      for (let type of CHARGE_TYPES) {
        let chargeType = type.value;
        let sum = 0;
        sum =
          caseMap[csId].charges && caseMap[csId].charges.length
            ? caseMap[csId].charges.reduce((acc, cur) => {
                if (cur.subType === chargeType) {
                  return acc + cur.amount;
                } else return acc;
              }, 0)
            : 0;
        if (sum !== 0 && !isNaN(sum)) {
          newMap[csId].chargeTypes[`${chargeType}`] = sum;
          newMap[csId].totalCharges += sum;
          newMap[csId].total += sum;
        }
      }
      for (let type of DEPOSIT_TYPES) {
        let depositType = type.value;
        let sum = 0;
        sum =
          caseMap[csId].deposits && caseMap[csId].deposits.length
            ? caseMap[csId].deposits.reduce((acc, cur) => {
                if (cur.subType === depositType) {
                  return acc + cur.amount;
                } else return acc;
              }, 0)
            : 0;
        if (sum !== 0 && !isNaN(sum)) {
          newMap[csId].depositTypes[`${depositType}`] = sum;
          newMap[csId].totalDeposits += sum;
          newMap[csId].total -= sum;
        }
      }
    });
    if (Object.keys(newMap).length) {
      // console.log('Case Map - ', newMap);
      return newMap;
    } else {
      return {};
    }
  } else {
    return {};
  }
};

export const getDurationFromOpenCloseDates = (cost) => {
  if (cost.completionDate && cost.completionDate.length) {
    return formatDistanceStrict(
      new Date(cost.completionDateStamp),
      new Date(cost.openDateStamp)
    );
  } else if (cost.openDateStamp < new Date().getTime()) {
    return formatDistanceStrict(new Date(), new Date(cost.openDateStamp));
  } else {
    return '0 days';
  }
};

export const getDurationFromMills = (mil) => {
  const dt = new Date();
  return formatDistanceStrict(dt, new Date(dt.getTime() - mil));
};

export default {
  getUserName,
  capitalize,
  firstFive,
  sleep,
  isValidEmail,
  isValidPhone,
  getMahDate,
  getDateTimeFromDate,
  getCurrentDateTime,
  getNextHourDateTime,
  getNextQuarterHourDateTime,
  getTodayStartDate,
  getTodayEndDate,
  getMonthStartDate,
  getMonthEndDate,
  daysInMonth,
  createDailyRevenueMap,
  createDailyRevenueByTypeMap,
  createClientBalancesMap,
  createMonthlyRevenueMap,
  createMonthlyRevenueByTypeMap,
  createIoltaDailyRevenueMap,
  createIoltaDailyRevenueByTypeMap,
  createIoltaClientBalancesMap,
  createBillingNotesMap,
  createPercentagesMap,
  createCostsMap,
  createTimesheetsMap,
  getCaseStatus,
  createCompany,
  createPermReport,
  createH1bReport,
  createCollectionListMap,
  createCaseCostMap,
  dateFormat,
  dateTimeFormat,
  dateShortFormat,
  dateTimeForCustomFormat,
  priceFormat,
  getDurationFromOpenCloseDates,
  getDurationFromMills,
};
