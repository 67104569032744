import { format, formatDistance } from 'date-fns';
import React from 'react';
import { dateFormat, getUserName } from '../utils/userUtils';

const ConsultationCell = (props) => {
  const { dataKey, data } = props;

  const PrimaryName = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <small className="badge rounded px-1 text-dark fw-regular mx-1 capitalize">
        {data}
      </small>
    );
  };

  const CaseType = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-normal mx-1 capitalize">
          {data}
        </small>
      </div>
    );
  };

  const ConsulDate = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-regular mx-1 capitalize">
          {dateFormat(new Date(data))}
        </small>
      </div>
    );
  };

  const Text = (props) => {
    const { data, light } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">n/a</small>;
    return (
      <div>
        <small
          className={`badge px-1 ${
            light ? 'fw-normal' : 'fw-regular'
          } capitalize text-dark`}
        >
          {data}
        </small>
      </div>
    );
  };

  const Email = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <small className="badge rounded text-and-border-blue px-1 fw-normal mx-1">
        {data}
      </small>
    );
  };

  const ConsultNum = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 fw-normal mx-1 capitalize bg-light-dark text-white">
          {data}
          <i className="bi bi-box-arrow-up-right ms-1"></i>
        </small>
      </div>
    );
  };

  return (
    <div className="my-2">
      {dataKey === 'consultationDate' ? (
        <ConsulDate data={data} />
      ) : (
        <React.Fragment />
      )}

      {dataKey === 'caseType' ? <CaseType data={data} /> : <React.Fragment />}

      {dataKey === 'primaryName' ? (
        <PrimaryName data={data} />
      ) : (
        <React.Fragment />
      )}
      {dataKey === 'companyName' ? <Text data={data} /> : <React.Fragment />}
      {dataKey === 'email' ? <Email data={data} /> : <React.Fragment />}
      {dataKey === 'consultNum' ? <ConsultNum data={data} /> : <React.Fragment />}
    </div>
  );
};

export default ConsultationCell;
