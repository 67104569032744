import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  SEARCH_TYPES,
  VISIBLE_APPOINTMENT_COLS,
  VISIBLE_CASE_COLS,
} from '../constants/constants';
import CaseCell from './CaseCell';
import Checkbox from './common/Checkbox';
import Input from './common/Input';
import MyPagination from './common/MyPagination';
import NoEntry from './common/NoEntry';
import Select from './common/Select';
import Loading from './Loading';

const CaseList = (props) => {
  const { user } = props;

  const [cases, setCases] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [isAdvanced, setIsAdvanced] = useState(false);
  const [advanceType, setAdvanceType] = useState('by_notes');
  const [dataKeys, setDataKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    initDataKeys(cases);
  }, [cases]);

  useEffect(() => {
    initCases(page, user, searchQuery, isAdvanced, advanceType);
  }, [page, user, searchQuery, isAdvanced, advanceType]);

  useEffect(() => {
    initPageCount(user, searchQuery, isAdvanced, advanceType);
  }, [user, searchQuery, isAdvanced, advanceType]);

  useEffect(() => {
    goToInitialPage();
  }, [searchQuery]);

  const goToInitialPage = () => {
    setPage(1);
  };

  const initPageCount = async (user, searchQuery, isAdvanced, advanceType) => {
    if (!user || !user.id) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getCasesPageCount(
      searchQuery,
      isAdvanced,
      advanceType
    );
    if (!res) {
    }
    if (res.error) {
      setPageCount(1);
    }
    if (res.result && res.result.count) {
      setPageCount(res.result.count);
    }
  };

  const initCases = async (
    page,
    user,
    searchQuery,
    isAdvanced,
    advanceType
  ) => {
    try {
      if (!user || !user.id || !page) return;
      setLoading(true);
      const configService = require('../store/config/service');
      const res = await configService.default.getCases(
        page,
        searchQuery,
        isAdvanced,
        advanceType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('cases --', res.result);
        setCases(res.result);
        setLoading(false);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const initDataKeys = (cases) => {
    if (!cases || !cases.length) return;
    // let newKeys = Object.keys(cases[0])
    //   .filter((k) => VISIBLE_CASE_COLS.includes(k))
    //   .sort();
    let newKeys = [
      'caseNum',
      'caseType',
      'companyName',
      'openedAt',
      'primaryContact',
      'status',
    ];
    return setDataKeys(newKeys);
  };

  const viewCase = (caseId) => {
    if (!caseId || !caseId.length) return;
    history.push(`/cases/${caseId}`);
  };

  const keyToLabelMapping = {
    caseNum: 'Case',
    openedAt: 'Opening Date',
    primaryContact: 'Primary name',
    caseType: 'Case Type',
    companyName: 'Company',
    // emails: 'Emails',
    status: 'Status',
  };

  return (
    <div className="card pb-2">
      <div className="card-head px-3 py-3 pb-0 d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between align-items-center w-100">
          <h5 className="fw-semi-bold">Cases</h5>
          {/* <button
            className="btn btn-dark shadow"
            style={{ width: 'max-content' }}
            onClick={(e) => {
              // console.log('open sort modal');
            }}
          >
            Today <i className="bi bi-clock-history ms-1"></i>
          </button> */}
        </div>
      </div>

      <div className="d-flex justify-content-start align-items-baseline px-3">
        <MyPagination page={page} setPage={setPage} pageCount={pageCount} />
        <div className="d-flex justify-content-start align-items-center w-100 px-3">
          <div className="max-width-24rem">
            <input
              className="form-control"
              placeholder={'type something..'}
              text={'text'}
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>

          <div className="max-width-20rem ms-2">
            <Checkbox
              type="checkbox"
              checked={isAdvanced}
              onClick={() => {
                setIsAdvanced((current) => !current);
              }}
              label={'Advanced'}
              namex="isAdvanced"
              idx="isAdvancedYes"
            />
          </div>
          {isAdvanced ? (
            <div className="max-width-24rem">
              <Select
                placeholder={'select a type'}
                value={advanceType}
                options={SEARCH_TYPES}
                onChange={(e) => {
                  setAdvanceType(e.target.value);
                }}
              />
            </div>
          ) : (
            <React.Fragment />
          )}
        </div>

        {/* <div className="d-flex justify-content-end align-items-baseline">
          <button
            className="btn btn-sm btn-light shadow-sm me-2"
            style={{ width: 'max-content' }}
            onClick={(e) => {
              // console.log('open sort modal');
            }}
          >
            Sort <i className="bi bi-sort-down ms-1"></i>
          </button>

          <button
            className="btn btn-sm btn-light shadow-sm"
            style={{ width: 'max-content' }}
            onClick={(e) => {
              // console.log('open filter modal');
            }}
          >
            Filter <i className="bi bi-filter-left ms-1"></i>
          </button>
        </div> */}
      </div>

      <div className="card-body p-0 m-0 w-100 d-flex flex-column justify-content-start align-items-start overflow-hidden">
        {dataKeys && dataKeys.length ? (
          <div className="table-head-row">
            <div className="table-head-col flex-pt5">S No.</div>
            {dataKeys.map((dataKey) => (
              <div key={dataKey} className="table-head-col">
                {keyToLabelMapping[dataKey]}
              </div>
            ))}
          </div>
        ) : (
          <React.Fragment />
        )}
        {loading ? (
          <Loading />
        ) : cases && cases.length ? (
          cases.map((caseObj, i) => (
            <div
              key={i}
              className="table-row"
              onClick={() => viewCase(caseObj.id)}
            >
              <div className="table-col mt-2 flex-pt5">
                <span className="fw-light size14">
                  {(page - 1) * 20 + i + 1}.
                </span>
              </div>
              {[
                'caseNum',
                'caseType',
                'companyName',
                'openedAt',
                'primaryContact',
                'status',
              ].map((dataKey) => (
                <div key={dataKey} className="table-col">
                  <CaseCell dataKey={dataKey} data={caseObj[dataKey]} />
                </div>
              ))}
            </div>
          ))
        ) : (
          <NoEntry />
        )}
      </div>
    </div>
  );
};

export default CaseList;
