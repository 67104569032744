import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { login } from '../../actions/authActions';
import { setAuthToken } from '../../services/StorageService';
import MyMessage from '../common/MyMessage';
import { isValidEmail } from '../../utils/userUtils';
import {
  CARDS,
  RADIAL_STAT_CARDS,
  STAT_LINE_CARDS,
} from '../../constants/constants';
import Card from '../Card';
import StatCard from '../StatCard';
import LineStatCard from '../LineStatCard';
import RadialStatCard from '../RadialStatCard';
import CaseList from '../CaseList';
import AppointmentList from '../AppointmentList';
import Calender from '../Calender';
import EditAppointmentModal from '../modals/EditAppointmentModal';
import Loading from '../Loading';

const Dashboard = (props) => {
  const { user, isLoggedIn, login } = props;

  const [showEditAppointmentModal, setShowEditAppointmentModal] =
    useState(undefined);
  const [reinitializing, setReinitializing] = useState(false);
  const [dallasStatCards, setDallasStatCards] = useState([]);
  const [houstonStatCards, setHoustonStatCards] = useState([]);
  const [dallasClientStatCards, setDallasClientStatCards] = useState([]);
  const [houstonClientStatCards, setHoustonClientStatCards] = useState([]);
  const [showInsights, setShowInsights] = useState(false);
  const [message, setMessage] = useState({});

  useEffect(() => {
    initStats();
  }, []);

  // const initStats = async () => {
  //   if (!user || !user.userType) return;
  //   const configService = require('../../store/config/service');
  //   const res = await configService.default.getAllCases();
  //   if (!res) {
  //   }
  //   if (res.error) {
  //     // console.log(res.error);
  //   }
  //   if (res.result) {
  //     let active = 0,
  //       closed = 0,
  //       prevActive = 0,
  //       prevClosed = 0;
  //     let s = new Date(
  //       new Date().setFullYear(
  //         new Date().getFullYear() - 2,
  //         new Date().getMonth(),
  //         new Date().getDate()
  //       )
  //     ).setHours(0, 0, 0);
  //     // console.log('prevStart', new Date(s));
  //     let e = new Date(
  //       new Date().setFullYear(
  //         new Date().getFullYear() - 1,
  //         new Date().getMonth(),
  //         new Date().getDate()
  //       )
  //     ).setHours(23, 59, 59);
  //     // console.log('prevEnd', new Date(e));

  //     let prevYearCases = res.result.filter((cs) => {
  //       let createdAt = +new Date(cs.openedAt);
  //       if (createdAt >= s && createdAt <= e) return true;
  //       return false;
  //     });

  //     s = new Date(
  //       new Date().setFullYear(
  //         new Date().getFullYear() - 1,
  //         new Date().getMonth(),
  //         new Date().getDate()
  //       )
  //     ).setHours(0, 0, 0);
  //     // console.log('thisStart', new Date(s));
  //     e = new Date(
  //       new Date().setFullYear(
  //         new Date().getFullYear(),
  //         new Date().getMonth(),
  //         new Date().getDate()
  //       )
  //     ).setHours(23, 59, 59);
  //     // console.log('thisEnd', new Date(e));

  //     let thisYearCases = res.result.filter((cs) => {
  //       let createdAt = +new Date(cs.openedAt);

  //       if (createdAt >= s && createdAt <= e) return true;
  //       return false;
  //     });
  //     thisYearCases.map((cs) => {
  //       if (cs.status === 'OPEN') {
  //         active += 1;
  //       }
  //       if (cs.status === 'CLOSED') {
  //         closed += 1;
  //       }
  //     });
  //     prevYearCases.map((cs) => {
  //       if (cs.status === 'OPEN') {
  //         prevActive += 1;
  //       }
  //       if (cs.status === 'CLOSED') {
  //         prevClosed += 1;
  //       }
  //     });
  //     // console.log({
  //     //   active,
  //     //   closed,
  //     //   prevActive,
  //     //   prevClosed,
  //     //   prevTotal: prevYearCases.length,
  //     //   thisTotal: thisYearCases.length,
  //     // });
  //     setStatCards([
  //       {
  //         title: 'Active Cases',
  //         desc: 'this year',
  //         percentage:
  //           prevActive > 0
  //             ? `${active >= prevActive ? '+' : '-'}${(
  //                 ((prevActive >= active
  //                   ? prevActive - active
  //                   : active - prevActive) /
  //                   prevActive) *
  //                 100
  //               ).toFixed(2)}%`
  //             : '+0%',
  //         count: active,
  //         myTintColor: '#33f945',
  //       },
  //       {
  //         title: 'Total Cases',
  //         desc: 'this year',
  //         percentage: prevYearCases.length
  //           ? `${thisYearCases.length >= prevYearCases.length ? '+' : '-'}${(
  //               ((prevYearCases.length >= thisYearCases.length
  //                 ? prevYearCases.length - thisYearCases.length
  //                 : thisYearCases.length - prevYearCases.length) /
  //                 prevYearCases.length) *
  //               100
  //             ).toFixed(2)}%`
  //           : '+0%',
  //         count: thisYearCases.length,
  //         myTintColor: '#f93345',
  //       },
  //       {
  //         title: 'Closed Cases',
  //         desc: 'this year',
  //         percentage:
  //           prevClosed > 0
  //             ? `${closed >= prevClosed ? '+' : '-'}${(
  //                 ((prevClosed >= closed
  //                   ? prevClosed - closed
  //                   : closed - prevClosed) /
  //                   prevClosed) *
  //                 100
  //               ).toFixed(2)}%`
  //             : '+0%',
  //         count: closed,
  //         myTintColor: '#3345f9',
  //         dark: true,
  //       },
  //     ]);
  //   }
  // };

  const initStats = async () => {
    if (!user || !user.userType) return;
    const configService = require('../../store/config/service');
    let prevStart = new Date(
      new Date().setFullYear(
        new Date().getFullYear() - 2,
        new Date().getMonth(),
        new Date().getDate()
      )
    ).setHours(0, 0, 0);
    let prevEnd = new Date(
      new Date().setFullYear(
        new Date().getFullYear() - 1,
        new Date().getMonth(),
        new Date().getDate()
      )
    ).setHours(23, 59, 59);
    let thisStart = new Date(
      new Date().setFullYear(
        new Date().getFullYear() - 1,
        new Date().getMonth(),
        new Date().getDate()
      )
    ).setHours(0, 0, 0);
    let thisEnd = new Date(
      new Date().setFullYear(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
    ).setHours(23, 59, 59);
    const prevRes = await configService.default.getAllCosts(prevStart, prevEnd);
    const thisRes = await configService.default.getAllCosts(thisStart, thisEnd);

    if (prevRes.result && thisRes.result) {
      let activeDallas = 0,
        closedDallas = 0,
        prevActiveDallas = 0,
        prevClosedDallas = 0;
      let activeHouston = 0,
        closedHouston = 0,
        prevActiveHouston = 0,
        prevClosedHouston = 0;

      thisRes.result.map((cs) => {
        if (cs.case.office && cs.case.office.length) {
          if (cs.case.office === 'dallas') {
            if (cs.completionDateStamp && cs.completionDateStamp > 0) {
              closedDallas += 1;
            } else {
              activeDallas += 1;
            }
          } else if (cs.case.office === 'houston') {
            if (cs.completionDateStamp && cs.completionDateStamp > 0) {
              closedHouston += 1;
            } else {
              activeHouston += 1;
            }
          }
        }
      });
      prevRes.result.map((cs) => {
        if (cs.case.office && cs.case.office.length) {
          if (cs.case.office === 'dallas') {
            if (cs.completionDateStamp && cs.completionDateStamp > 0) {
              prevClosedDallas += 1;
            } else {
              prevActiveDallas += 1;
            }
          } else if (cs.case.office === 'houston') {
            if (cs.completionDateStamp && cs.completionDateStamp > 0) {
              prevClosedHouston += 1;
            } else {
              prevActiveHouston += 1;
            }
          }
        }
      });
      setDallasStatCards([
        {
          title: 'Active Cases',
          desc: 'this year',
          percentage:
            prevActiveDallas > 0
              ? `${activeDallas >= prevActiveDallas ? '+' : '-'}${(
                  ((prevActiveDallas >= activeDallas
                    ? prevActiveDallas - activeDallas
                    : activeDallas - prevActiveDallas) /
                    prevActiveDallas) *
                  100
                ).toFixed(2)}%`
              : '+0%',
          count: activeDallas,
          myTintColor: '#33f945',
        },
        {
          title: 'Closed Cases',
          desc: 'this year',
          percentage:
            prevClosedDallas > 0
              ? `${closedDallas >= prevClosedDallas ? '+' : '-'}${(
                  ((prevClosedDallas >= closedDallas
                    ? prevClosedDallas - closedDallas
                    : closedDallas - prevClosedDallas) /
                    prevClosedDallas) *
                  100
                ).toFixed(2)}%`
              : '+0%',
          count: closedDallas,
          myTintColor: '#3345f9',
          dark: true,
        },
      ]);

      setHoustonStatCards([
        {
          title: 'Active Cases',
          desc: 'this year',
          percentage:
            prevActiveHouston > 0
              ? `${activeHouston >= prevActiveHouston ? '+' : '-'}${(
                  ((prevActiveHouston >= activeHouston
                    ? prevActiveHouston - activeHouston
                    : activeHouston - prevActiveHouston) /
                    prevActiveHouston) *
                  100
                ).toFixed(2)}%`
              : '+0%',
          count: activeHouston,
          myTintColor: '#33f945',
        },
        {
          title: 'Closed Cases',
          desc: 'this year',
          percentage:
            prevClosedHouston > 0
              ? `${closedHouston >= prevClosedHouston ? '+' : '-'}${(
                  ((prevClosedHouston >= closedHouston
                    ? prevClosedHouston - closedHouston
                    : closedHouston - prevClosedHouston) /
                    prevClosedHouston) *
                  100
                ).toFixed(2)}%`
              : '+0%',
          count: closedHouston,
          myTintColor: '#3345f9',
          dark: true,
        },
      ]);
    }
    const res = await configService.default.getAllCases();
    if (!res) {
    }
    if (res.error) {
      // console.log(res.error);
    }
    if (res.result) {
      let closedDallasCases = 0,
        prevClosedDallasCases = 0;
      let closedHoustonCases = 0,
        prevClosedHoustonCases = 0;
      let s = new Date(
        new Date().setFullYear(
          new Date().getFullYear() - 2,
          new Date().getMonth(),
          new Date().getDate()
        )
      ).setHours(0, 0, 0);
      // console.log('prevStart', new Date(s));
      let e = new Date(
        new Date().setFullYear(
          new Date().getFullYear() - 1,
          new Date().getMonth(),
          new Date().getDate()
        )
      ).setHours(23, 59, 59);
      // console.log('prevEnd', new Date(e));

      let prevYearDallasCases = res.result.filter((cs) => {
        let createdAt = +new Date(cs.openedAt);
        if (
          cs.office &&
          cs.office.length &&
          cs.office === 'dallas' &&
          createdAt >= s &&
          createdAt <= e
        )
          return true;
        return false;
      });

      let prevYearHoustonCases = res.result.filter((cs) => {
        let createdAt = +new Date(cs.openedAt);
        if (
          cs.office &&
          cs.office.length &&
          cs.office === 'houston' &&
          createdAt >= s &&
          createdAt <= e
        )
          return true;
        return false;
      });

      s = new Date(
        new Date().setFullYear(
          new Date().getFullYear() - 1,
          new Date().getMonth(),
          new Date().getDate()
        )
      ).setHours(0, 0, 0);
      e = new Date(
        new Date().setFullYear(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate()
        )
      ).setHours(23, 59, 59);

      let thisYearDallasCases = res.result.filter((cs) => {
        let createdAt = +new Date(cs.openedAt);

        if (
          cs.office &&
          cs.office.length &&
          cs.office === 'dallas' &&
          createdAt >= s &&
          createdAt <= e
        )
          return true;
        return false;
      });
      let thisYearHoustonCases = res.result.filter((cs) => {
        let createdAt = +new Date(cs.openedAt);

        if (
          cs.office &&
          cs.office.length &&
          cs.office === 'houston' &&
          createdAt >= s &&
          createdAt <= e
        )
          return true;
        return false;
      });
      thisYearDallasCases.map((cs) => {
        if (cs.status === 'CLOSED') {
          closedDallasCases += 1;
        }
      });
      thisYearHoustonCases.map((cs) => {
        if (cs.status === 'CLOSED') {
          closedHoustonCases += 1;
        }
      });
      prevYearDallasCases.map((cs) => {
        if (cs.status === 'CLOSED') {
          prevClosedDallasCases += 1;
        }
      });
      prevYearHoustonCases.map((cs) => {
        if (cs.status === 'CLOSED') {
          prevClosedHoustonCases += 1;
        }
      });
      // console.log({
      //   active,
      //   closed,
      //   prevActive,
      //   prevClosed,
      //   prevTotal: prevYearCases.length,
      //   thisTotal: thisYearCases.length,
      // });

      setDallasClientStatCards([
        {
          title: 'Total Clients',
          desc: 'this year',
          percentage: prevYearDallasCases.length
            ? `${
                thisYearDallasCases.length >= prevYearDallasCases.length
                  ? '+'
                  : '-'
              }${(
                ((prevYearDallasCases.length >= thisYearDallasCases.length
                  ? prevYearDallasCases.length - thisYearDallasCases.length
                  : thisYearDallasCases.length - prevYearDallasCases.length) /
                  prevYearDallasCases.length) *
                100
              ).toFixed(2)}%`
            : '+0%',
          count: thisYearDallasCases.length,
          myTintColor: '#33f945',
        },
        {
          title: 'Closed Clients',
          desc: 'this year',
          percentage:
            prevClosedDallasCases > 0
              ? `${closedDallasCases >= prevClosedDallasCases ? '+' : '-'}${(
                  ((prevClosedDallasCases >= closedDallasCases
                    ? prevClosedDallasCases - closedDallasCases
                    : closedDallasCases - prevClosedDallasCases) /
                    prevClosedDallasCases) *
                  100
                ).toFixed(2)}%`
              : '+0%',
          count: closedDallasCases,
          myTintColor: '#3345f9',
          dark: true,
        },
      ]);

      setHoustonClientStatCards([
        {
          title: 'Total Clients',
          desc: 'this year',
          percentage: prevYearHoustonCases.length
            ? `${
                thisYearHoustonCases.length >= prevYearHoustonCases.length
                  ? '+'
                  : '-'
              }${(
                ((prevYearHoustonCases.length >= thisYearHoustonCases.length
                  ? prevYearHoustonCases.length - thisYearHoustonCases.length
                  : thisYearHoustonCases.length - prevYearHoustonCases.length) /
                  prevYearHoustonCases.length) *
                100
              ).toFixed(2)}%`
            : '+0%',
          count: thisYearHoustonCases.length,
          myTintColor: '#33f945',
        },
        {
          title: 'Closed Clients',
          desc: 'this year',
          percentage:
            prevClosedHoustonCases > 0
              ? `${closedHoustonCases >= prevClosedHoustonCases ? '+' : '-'}${(
                  ((prevClosedHoustonCases >= closedHoustonCases
                    ? prevClosedHoustonCases - closedHoustonCases
                    : closedHoustonCases - prevClosedHoustonCases) /
                    prevClosedHoustonCases) *
                  100
                ).toFixed(2)}%`
              : '+0%',
          count: closedHoustonCases,
          myTintColor: '#3345f9',
          dark: true,
        },
      ]);
    }
  };

  const areChartsVisible = (el) => {
    if (user && user.userType && el.roles.includes(user.userType)) {
      return true;
    } else return false;
  };

  const openEditAppointmentModal = (appointmentId) => {
    setShowEditAppointmentModal({
      onClose: () => {
        setShowEditAppointmentModal(undefined);
      },
      user: user ? user : {},
      appointmentId: appointmentId ? appointmentId : undefined,
    });
  };

  const reinitialize = (bool = true) => {
    setReinitializing(bool);
  };

  return (
    <div className="container">
      <div className="row">
        {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mb-4">
          <CaseList
            user={user}
          />
        </div> */}

        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mb-4">
          <AppointmentList
            user={user}
            reinitialize={reinitialize}
            reinitializing={reinitializing}
            openEditAppointmentModal={(aptId) =>
              openEditAppointmentModal(aptId)
            }
          />
        </div>

        <EditAppointmentModal
          visible={!!showEditAppointmentModal}
          onClose={showEditAppointmentModal && showEditAppointmentModal.onClose}
          user={showEditAppointmentModal && showEditAppointmentModal.user}
          appointmentId={
            showEditAppointmentModal && showEditAppointmentModal.appointmentId
          }
          reinitialize={reinitialize}
        />
      </div>
      <div className="d-flex justify-content-end align-items-center w-100">
        <button
          onClick={() => setShowInsights(!showInsights)}
          className="btn btn-sm btn-light"
        >
          {showInsights ? 'Hide' : 'View'} Insights{' '}
          <i className={`bi bi-chevron-${showInsights ? 'up' : 'down'}`}></i>
        </button>
      </div>
      {showInsights ? (
        <div>
          {dallasStatCards &&
          dallasStatCards.length &&
          houstonStatCards &&
          houstonStatCards.length &&
          dallasClientStatCards &&
          dallasClientStatCards.length &&
          houstonClientStatCards &&
          houstonClientStatCards.length ? (
            <div className="insights-cont">
              <div className="p-3 mb-4">
                <h4>Dallas</h4>
                <div className="row mt-4">
                  {dallasStatCards && dallasStatCards.length ? (
                    dallasStatCards.map((card, i) => (
                      <div
                        key={i}
                        className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 col-12 mb-1"
                      >
                        <StatCard
                          title={card.title}
                          desc={card.desc}
                          percentage={card.percentage}
                          count={card.count}
                          myTintColor={card.myTintColor}
                          dark={card.dark}
                        />
                      </div>
                    ))
                  ) : (
                    <React.Fragment />
                  )}
                  {dallasClientStatCards && dallasClientStatCards.length ? (
                    dallasClientStatCards.map((card, i) => (
                      <div
                        key={i}
                        className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 col-12 mb-1"
                      >
                        <StatCard
                          title={card.title}
                          desc={card.desc}
                          percentage={card.percentage}
                          count={card.count}
                          myTintColor={card.myTintColor}
                          dark={card.dark}
                        />
                      </div>
                    ))
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </div>

              <div className="p-3">
                <h4>Houston</h4>
                <div className="row mt-4">
                  {houstonStatCards && houstonStatCards.length ? (
                    houstonStatCards.map((card, i) => (
                      <div
                        key={i}
                        className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 col-12 mb-1"
                      >
                        <StatCard
                          title={card.title}
                          desc={card.desc}
                          percentage={card.percentage}
                          count={card.count}
                          myTintColor={card.myTintColor}
                          dark={card.dark}
                        />
                      </div>
                    ))
                  ) : (
                    <React.Fragment />
                  )}
                  {houstonClientStatCards && houstonClientStatCards.length ? (
                    houstonClientStatCards.map((card, i) => (
                      <div
                        key={i}
                        className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-xs-12 col-12 mb-1"
                      >
                        <StatCard
                          title={card.title}
                          desc={card.desc}
                          percentage={card.percentage}
                          count={card.count}
                          myTintColor={card.myTintColor}
                          dark={card.dark}
                        />
                      </div>
                    ))
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <Loading />
            )}
        </div>
      ) : (
        <React.Fragment />
      )}

      <div className="row">
        {/* {[RADIAL_STAT_CARDS[0]].map((card) => (
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12 mb-4">
            <RadialStatCard
              title={card.title}
              data={card.data}
              dataKey={card.dataKey}
            />
          </div>
        ))} */}

        {/* <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 col-12 mb-4">
          <Calender user={user} />
        </div> */}

        {/* {STAT_LINE_CARDS.filter(areChartsVisible).map((card) => (
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mb-4">
            <LineStatCard
              title={card.title}
              percentage={card.percentage}
              data={card.data}
              dataKey={card.dataKey}
              key1={card.key1}
              key2={card.key2}
              key3={card.key3}
              key4={card.key4}
              key5={card.key5}
            />
          </div>
        ))} */}
      </div>

      {/* <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 col-12 mb-4">
          <CaseList data={cases} />
        </div>
        {[RADIAL_STAT_CARDS[0]].map((card) => (
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12 mb-4">
            <RadialStatCard
              title={card.title}
              data={card.data}
              dataKey={card.dataKey}
            />
          </div>
        ))}
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth: { user, isLoggedIn } = {} } = state;
  return {
    user,
    isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload) => {
      dispatch(login(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
