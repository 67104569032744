import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AppBar from './AppBar';
import { NavLink } from 'react-router-dom';
import { toggleStickyDrawer } from '../actions/stickyDrawerActions';
import {
  myGreenColor,
  myGreenColorLight,
  myGreenColorDark,
  myBlueColor,
  myBlueColorDark,
  myBlueColorLight,
} from '../constants/colors';

const AppDrawer = (props) => {
  const {
    items: myDrawerItems,
    user,
    isLoggedIn,
    sticky,
    stickyDrawerVisible,
    toggleAppDrawer,
  } = props;
  const drawerLinkStyle = {
    width: '100%',
    color: '#000536',
    textDecoration: 'none',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '.5rem',
    paddingBottom: '.5rem',
  };
  const drawerLinkActiveStyle = {
    fontWeight: '500',
    textDecoration: 'none',
    backgroundColor: myBlueColorLight,
    color: myBlueColorDark,
    borderRight: `5px solid ${myBlueColor}`,
  };

  return (
    <div
      className={`${
        sticky
          ? stickyDrawerVisible
            ? 'mySticky app-drawer'
            : 'd-none'
          : 'app-drawer'
      } bg-my-dark my-app-drawer-shadow br-1 z-top`}
    >
      <div
        className={`${
          sticky
            ? stickyDrawerVisible
              ? 'd-flex pe-2 mb-0 mt-1 pb-0 align-items-end justify-content-end'
              : 'd-none'
            : 'd-none'
        }`}
      >
        <button
          type="button"
          className="btn align-self-end my-0 mt-1"
          onClick={() => {
            if (!stickyDrawerVisible) {
              toggleAppDrawer({ stickyDrawerVisible: true });
            } else {
              toggleAppDrawer({ stickyDrawerVisible: false });
            }
          }}
        >
          <i className="bi bi-filter-right md-icon colorDark bg-white br-10 p-1 pt-0 pb-1 my-border my-light-shadow"></i>
        </button>
      </div>
      <AppBar user={user} isLoggedIn={isLoggedIn} />
      <div className="">
        {myDrawerItems.length
          ? myDrawerItems
              .filter((item) => {
                if (item.route === '/create-user') {
                  return user.userType === 'Admin' ? item : false;
                } else {
                  return item;
                }
              })
              .map((item, i) => (
                <div
                  key={i}
                  className={`d-flex flex-column justify-content-start align-items-start ${
                    item.showHr && item.indent !== 'ms-0' ? 'pt-0' : ''
                  }`}
                >
                  <NavLink
                    to={item.route}
                    onClick={() => {
                      if (window.innerWidth < 768) {
                        toggleAppDrawer({ stickyDrawerVisible: false });
                      }
                    }}
                    style={drawerLinkStyle}
                    activeStyle={drawerLinkActiveStyle}
                  >
                    <span className={`${item.itemSize} text-light-2`}>
                      <i
                        className={`bi ${item.icon} md-icon color-my-dark me-2 ${item.iconSize} ${item.indent}`}
                      ></i>
                      {item.label}
                    </span>
                  </NavLink>
                  {item.showHr ? (
                    <hr className="colorWhite mb-0 mt-2 bg-transparent" />
                  ) : null}
                </div>
              ))
          : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    auth: { user, isLoggedIn } = {},
    stickyDrawer: { stickyDrawerVisible } = {},
  } = state;
  return {
    user,
    isLoggedIn,
    stickyDrawerVisible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleAppDrawer: (payload) => {
      dispatch(toggleStickyDrawer(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppDrawer);
