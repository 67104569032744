import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { myGreenSuperLight } from '../../constants/colors';
import { CONTRACT_TYPES, TICKLER_TYPES } from '../../constants/constants';
import { getMahDateCustom, getUserName } from '../../utils/userUtils';
import Checkbox from '../common/Checkbox';
import Input from '../common/Input';
import MultiSelect from '../common/MultiSelect';
import MyMessage from '../common/MyMessage';
import Select from '../common/Select';
import Loading from '../Loading';
import TextArea from '../common/TextArea';

const EditTicklerModal = (props) => {
  const {
    caseData,
    visible,
    onClose,
    user,
    employees,
    reinitialize,
    ticklerId,
  } = props;
  const [editing, setEditing] = useState(false);
  const [type, setType] = useState('');
  const [assignedTo, setAssignedTo] = useState([]);
  const [desc, setDesc] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [isCompleted, setIsCompleted] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initTicklerForEdit();
  }, [visible, ticklerId]);

  const initTicklerForEdit = async () => {
    try {
      if (!visible || !ticklerId || !ticklerId.length) return;
      setLoading(true);
      const configService = require('../../store/config/service');
      const res = await configService.default.getTickler(ticklerId);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log(res.result);
        // console.log('tickelrObj', res.result.type);
        // add data to inputs.
        setType(res.result.type);
        setDueDate(getMahDateCustom(res.result.dueDate));
        setIsCompleted(res.result.isCompleted);
        setAssignedTo(
          res.result.assignedTo.map((usr) => {
            return {
              label: `${getUserName(usr)} (${usr.designation})`,
              value: usr.id,
            };
          })
        );
        setDesc(res.result.desc);
        setErrors({});
        setMessage({});
        setLoading(false);
      }
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const updateTickler = async () => {
    if (!validateTickler()) return;
    const configService = require('../../store/config/service');

    let body = {
      type,
      dueDate: new Date(dueDate),
      dueDateStamp: +new Date(dueDate),
      isCompleted,
      desc,
      assignedTo: assignedTo.map((el) => el.value),
      assignedToNames: assignedTo.map((el) => el.label).join(','),
    };
    if(isCompleted) {
      body.completionDate = new Date();
      body.completedBy = user.id;
      body.completedByName = getUserName(user);
    }

    // console.log('body - ', body);

    const res = await configService.default.updateTickler(ticklerId, body);
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const validateTickler = () => {
    let flag = true;
    if (!editing) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'Click on edit to change the data',
      });
    }
    if (!type || !type.length) {
      flag = false;
      handleError('type', 'select a valid tickler type');
    }
    if (!assignedTo || !assignedTo.length) {
      flag = false;
      handleError('assignedTo', 'select a valid emlpoyee');
    }
    if (!desc || !desc.length) {
      flag = false;
      handleError('desc', 'add a description');
    }
    if (!dueDate || !dueDate.length) {
      flag = false;
      handleError('dueDate', 'select a due date');
    }
    if (!ticklerId || !ticklerId.length) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (!user || !user.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (!caseData || !caseData.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    return flag;
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  const doOnClose = () => {
    resetBlankModal();
    onClose();
  };

  const resetBlankModal = () => {
    setEditing(false);
    setDueDate('');
    setIsCompleted(false);
    setType('');
    setAssignedTo([]);
    setDesc('');
    setErrors({});
    setMessage({});
    setLoading(false);
  };

  return visible ? (
    <div className="my-modal-container">
      <div className="my-modal-overlay" onClick={doOnClose}></div>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable my-modal z-index-9">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <div>
              <p className="modal-title mb-0">
                {editing ? 'Editing' : 'Viewing'}
              </p>
              <h3 className="modal-title fw-bold">{'Tickler'}</h3>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={doOnClose}
            ></button>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="modal-body">
              {editing ? (
                <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                  <div className="mb-2">
                    <p className="text-muted fs-italic fw-light size15">
                      Change the details below and update this tickler!
                    </p>

                    <div className="row d-flex flex-wrap justify-content-start align-items-start">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <Select
                          label={'Tickler Type'}
                          placeholder={'select a tickler type'}
                          value={type}
                          options={TICKLER_TYPES}
                          onChange={(e) => {
                            setType(e.target.value);
                            handleError('type', '');
                          }}
                          err={errors.type || ''}
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                        <Input
                          label={'Due Date'}
                          placeholder={'enter due date'}
                          type={'date'}
                          value={dueDate}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              e.target.value.length &&
                              errors.dueDate &&
                              errors.dueDate.length
                            ) {
                              handleError('dueDate', '');
                            }
                            setDueDate(e.target.value);
                          }}
                          err={errors && errors.dueDate}
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <Checkbox
                          type="checkbox"
                          checked={isCompleted}
                          onClick={() => {
                            setIsCompleted((current) => !current);
                          }}
                          label={'Completed'}
                          namex="isCompleted"
                          idx="isCompletedYes"
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <MultiSelect
                          label={'Assigned To'}
                          displayValue={'label'}
                          values={assignedTo}
                          options={employees}
                          onSelect={(e) => {
                            setAssignedTo(e);
                            handleError('assignedTo', '');
                          }}
                          onRemove={(e) => {
                            setAssignedTo(e);
                          }}
                          err={errors.assignedTo || ''}
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <TextArea
                          label={'Description'}
                          placeholder={'add a description'}
                          value={desc}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              e.target.value.length &&
                              errors.desc &&
                              errors.desc.length
                            ) {
                              handleError('desc', '');
                            }
                            setDesc(e.target.value);
                          }}
                          err={errors && errors.desc}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
                </div>
              ) : (
                <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                  <div className="mb-2">
                    <p className="text-muted fs-italic fw-light size15">
                      Click on Edit to edit this data!
                    </p>

                    <div className="row d-flex flex-wrap justify-content-start align-items-start">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <Select
                          disabled
                          label={'Tickler Type'}
                          placeholder={'select a tickler type'}
                          value={type}
                          options={TICKLER_TYPES}
                          onChange={(e) => {
                            setType(e.target.value);
                            handleError('type', '');
                          }}
                          err={errors.type || ''}
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                        <Input
                          disabled
                          label={'Due Date'}
                          placeholder={'enter due date'}
                          type={'date'}
                          value={dueDate}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              e.target.value.length &&
                              errors.dueDate &&
                              errors.dueDate.length
                            ) {
                              handleError('dueDate', '');
                            }
                            setDueDate(e.target.value);
                          }}
                          err={errors && errors.dueDate}
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <Checkbox
                          disabled
                          type="checkbox"
                          checked={isCompleted}
                          onClick={() => {
                            setIsCompleted((current) => !current);
                          }}
                          label={'Completed'}
                          namex="isCompleted"
                          idx="isCompletedYes"
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <MultiSelect
                          disabled
                          label={'Assigned To'}
                          displayValue={'label'}
                          values={assignedTo}
                          options={employees}
                          onSelect={(e) => {
                            setAssignedTo(e);
                            handleError('assignedTo', '');
                          }}
                          onRemove={(e) => {
                            setAssignedTo(e);
                          }}
                          err={errors.assignedTo || ''}
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <TextArea
                          disabled
                          label={'Description'}
                          placeholder={'add a description'}
                          value={desc}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              e.target.value.length &&
                              errors.desc &&
                              errors.desc.length
                            ) {
                              handleError('desc', '');
                            }
                            setDesc(e.target.value);
                          }}
                          err={errors && errors.desc}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
                </div>
              )}
            </div>
          )}
          <div className="px-3">
            <MyMessage
              visible={!!message}
              message={message}
              onClose={() => {
                setMessage(undefined);
              }}
            />
          </div>
          <div
            className="modal-footer"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            {editing ? (
              <button
                type="button"
                className="btn btn-outline ms-2"
                onClick={() => setEditing(false)}
              >
                Cancel
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-outline ms-2"
                onClick={doOnClose}
              >
                Cancel
              </button>
            )}
            {editing ? (
              <button
                type="button"
                className="btn btn-primary ms-2"
                onClick={updateTickler}
              >
                Update Tickler
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary ms-2"
                onClick={() => {
                  setEditing(true);
                }}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default EditTicklerModal;
