import format from 'date-fns/format';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CASE_TYPES,
  COUNTRIES,
  OFFICES,
  PAYMENT_CHARGE_TYPES_MAP,
  PAYMENT_TYPES,
  PAYMENT_TYPES_MAP,
  REFERRED_BY_TYPES,
} from '../constants/constants';
import {
  createCompany,
  dateFormat,
  getMahDateCustom,
  getUserName,
  priceFormat,
} from '../utils/userUtils';
import Input from './common/Input';
import Select from './common/Select';
import TextArea from './common/TextArea';
import Loading from './Loading';

const ConsultInfo = (props) => {
  const { consultData, user, reinitialize, onCase = false } = props;
  const [onEdit, setOnEdit] = useState(false);
  const [consultationDate, setConsultationDate] = useState('');
  const [office, setOffice] = useState('dallas');
  const [companies, setCompanies] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [caseType, setCaseType] = useState('company');
  const [consultationAttorney, setConsultationAttorney] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyNameForSearch, setCompanyNameForSearch] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [primaryName, setPrimaryName] = useState('');
  const [secondaryName, setSecondaryName] = useState('');
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [city, setCity] = useState('');
  const [stateRegion, setStateRegion] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [consultationFee, setConsultationFee] = useState(0);
  const [referredBy, setReferredBy] = useState('');
  const [paymentType, setPaymentType] = useState('cash');
  // const [displayImg, setDisplayImg] = useState('');
  const [consultationNotes, setConsultationNotes] = useState('');
  const [attorneyNotes, setAttorneyNotes] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const toggleEdit = async () => {
    try {
      if (!consultData) return;
      setLoading(true);
      setOnEdit((current) => !current);
      setConsultationDate(getMahDateCustom(consultData.consultationDate));
      setOffice(consultData.office || 'dallas');
      if (!user || !user.userType) return;
      const configService = require('../store/config/service');
      const res = await configService.default.getUsers(); // change to employees only
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        setEmployees(
          res.result.map((emp) => {
            return {
              label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
              value: emp.id,
            };
          })
        );
        setConsultationAttorney(
          ((consultData || {}).consultationAttorney || {}).id || ''
        );
      }
      // fetch companies from db
      const companyRes = await configService.default.getCompanies();
      // console.log('companyRes', companyRes);
      if (companyRes.result) {
        setCompanies(
          companyRes.result.map((company) => {
            return { label: company.companyName, value: company.id };
          })
        );
      }
      if (consultData.caseType === 'beneficiary') {
        setCompanyId(consultData.company);
        setCompanyNameForSearch(consultData.companyName);
        setCompanyName(consultData.companyName);
      } else if (consultData.caseType === 'company') {
        setCompanyId(consultData.company);
        setCompanyNameForSearch(consultData.companyName);
        setCompanyName(consultData.companyName);
      }
      setCaseType(consultData.caseType);
      setPrimaryName(consultData.primaryName);
      setSecondaryName(consultData.secondaryName || '');
      if (consultData.address) {
        setLine1(consultData.address.line1 || '');
        setLine2(consultData.address.line2 || '');
        setCity(consultData.address.city || '');
        setStateRegion(consultData.address.state || '');
        setZipCode(consultData.address.zip || '');
        setCountry(consultData.address.country || '');
      }
      setEmail(consultData.email || '');
      setPhone(consultData.phone || '');
      setConsultationFee(consultData.consultationFee || 0);
      setReferredBy(consultData.referredBy || '');
      setPaymentType(consultData.paymentType || '');
      setConsultationNotes(consultData.consultationNotes || '');
      setAttorneyNotes(consultData.attorneyNotes || '');
      setLoading(false);
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const validateCompany = () => {
    let flag = true;
    if (!companyName || !companyName.length) {
      handleError('companyName', 'add a valid company name');
    }
    return flag;
  };

  const updateConsultation = async () => {
    if (!validateConsultation()) return;
    // console.log('Consultation is valid');
    const configService = require('../store/config/service');
    let companyIdx;
    if (caseType === 'company') {
      const companyRes = await createCompany(
        companyName,
        validateCompany,
        companies
      );
      if (companyRes) {
        companyIdx = companyRes;
      }
    } else if (caseType === 'beneficiary') {
      companyIdx = companyId;
    }

    let body = {
      consultationDate,
      office,
      caseType,
      companyName: '',
      consultationAttorney,
      consultationFee: parseFloat(consultationFee),
      consultationFeeString: `${consultationFee}`,
      primaryName,
      address: {},
      email,
    };

    if (companyIdx && companyIdx.length) body.company = companyIdx;
    if (
      (caseType === 'company' || caseType === 'beneficiary') &&
      companyNameForSearch &&
      companyNameForSearch.length
    )
      body.companyName = companyNameForSearch;
    if (secondaryName && secondaryName.length)
      body.secondaryName = secondaryName;
    if (line1 && line1.length) body.address.line1 = line1;
    if (line2 && line2.length) body.address.line2 = line2;
    if (city && city.length) body.address.city = city;
    if (stateRegion && stateRegion.length) body.address.state = stateRegion;
    if (zipCode && zipCode.length) body.address.zip = zipCode;
    if (country && country.length) body.address.country = country;
    if (phone && phone.length) body.phone = phone;
    if (consultationNotes && consultationNotes.length)
      body.consultationNotes = consultationNotes;
    if (attorneyNotes && attorneyNotes.length)
      body.attorneyNotes = attorneyNotes;
    if (referredBy && referredBy.length) body.referredBy = referredBy;
    if (paymentType && paymentType.length) body.paymentType = paymentType;

    // console.log('body - is - ', body);
    const res = await configService.default.updateConsultation(
      consultData.id,
      body
    );
    if (res.result) {
      // console.log('updated consultation - ', res.result);
      reinitialize();
      setOnEdit(false);
      clearInputs();
    }
  };

  const validateConsultation = () => {
    let flag = true;
    if (!consultationDate || !consultationDate.length) {
      flag = false;
      handleError('consultationDate', 'select a valid date');
    }
    if (!office || !office.length) {
      flag = false;
      handleError('office', 'select a valid office');
    }
    if (!caseType || !caseType.length) {
      flag = false;
      handleError('caseType', 'select a valid case category');
    }
    if (caseType !== 'individual') {
      if (!companyName && !companyId) {
        flag = false;
        handleError('companyName', 'add a valid company');
      }
    }
    if (!consultationAttorney || !consultationAttorney.length) {
      flag = false;
      handleError(
        'consultationAttorney',
        'select a valid consultation attorney'
      );
    }
    if (!consultationFee || consultationFee <= 0) {
      flag = false;
      handleError('consultationFee', 'add a valid consultation fee');
    }
    if (!primaryName || !primaryName.length) {
      flag = false;
      handleError('primaryName', 'please add a name');
    }
    if (!email || !email.length) {
      flag = false;
      handleError('email', 'please add a valid email');
    }
    if (!consultData || !consultData.id) {
      flag = false;
    }
    return flag;
  };

  const goBack = () => {
    history.push('/consultations');
  };

  const printConsult = (docType, data, consultData) => {
    if (!consultData) return;
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `
      <style>
        body {
          font-family: 'Lato', sans-serif;
        }
      </style>
      
      `
    );

    mywindow.document.write('</head><body>');
    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
        <div style="display: flex; flex: 0">
          <img
            height="61"
            width="100"
            alt=""
            src="/assets/img/logo.png"
          />
        </div>
        <div style="display: flex; flex: 1">
          <h1 style="padding: 0 1rem">${docType}</h1>
        </div>
        <div style="display: flex;">
          <h3 style="padding: 0 1rem">${format(Date.now(), 'M/dd/yyyy')}</h3>
        </div>
      </div>

      <div style="display: flex; justify-content: start; align-items: center">
        <div style="display: flex; flex: 1; flex-direction: column">
          <h4 style="font-weight: 600; margin: 0; padding: 0;">Ahluwalia Law Offices</h4>

          <h4 style="font-weight: 400; margin: 0; padding: 0;">Your 1st Choice & 2nd Opinion Law Firm</h4>
          <h4 style="font-weight: 400; margin: 0; padding: 0;">14180 Dallas Parkway, Suite 720</h4>
          <h4 style="font-weight: 400; margin: 0; padding: 0;">Dallas, TX 75254</h4>
          <h4 style="font-weight: 400; margin: 0; padding: 0;">Phone:972-361-0606 Fax:972-361-0999</h4>
          <h5>www.ahluwalialaw.com</h5>
        </div>
        <div
          style="
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex: 1;
          "
        >
        <div style="display: flex; flex: 1; flex-direction: column; justify-content: flex-start; align-items: flex-start;">
            <h5 style="font-weight: 400; margin: 0; padding: 0;">To</h5>
            ${
              consultData.caseType === 'individual'
                ? ''
                : `<h5 style="font-weight: 600; margin: 0; padding: 0;">${consultData.companyName}</h5>`
            }
            <h5 style="font-weight: 400; margin: 0; padding: 0;">${
              consultData.primaryName
            }</h5>
          </div>
        </div>
      </div>

      <div style="display: flex; justify-content: start; align-items: center">
        <div style="display: flex; flex: 1; flex-direction: column">
          <h4 style="font-weight: 600; margin: 0; padding: 1rem 0;">Case - #${
            consultData.consultNum
          }</h4>
        </div>
      </div>
      <div style="display: flex; justify-content: space-evenly; align-items: center">
        <div style="display: flex; flex: 2; border: 1px solid #9ab">
          <h5 style="padding: .5rem; margin: 0;">Date</h5>
        </div>
        <div style="display: flex; flex: 2; border: 1px solid #9ab">
          <h5 style="padding: .5rem; margin: 0;">Type</h5>
        </div>
        <div style="display: flex; flex: 3; border: 1px solid #9ab">
          <h5 style="padding: .5rem; margin: 0;">Method</h5>
        </div>
        <div style="display: flex; flex: 3; border: 1px solid #9ab">
          <h5 style="padding: .5rem; margin: 0;">Description</h5>
        </div>
        <div style="display: flex; flex: 2; border: 1px solid #9ab">
          <h5 style="padding: .5rem; margin: 0;">Amount (USD)</h5>
        </div>
      </div>
      ${data
        .map(
          (pt) => `
      <div style="display: flex; justify-content: space-evenly; align-items: center">
      <div style="display: flex; flex: 2; border: 1px solid #fafafa; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${dateFormat(
          new Date(pt.paymentDate)
        )}</h5>
      </div>
      <div style="display: flex; flex: 2; border: 1px solid #fafafa; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
          pt.type
        }</h5>
      </div>
      <div style="display: flex; flex: 3; border: 1px solid #fafafa; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${PAYMENT_TYPES_MAP[pt.subType]}</h5>
      </div>
      <div style="display: flex; flex: 3; border: 1px solid #fafafa; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${pt.desc}</h5>
      </div>
      <div style="display: flex; flex: 2; border: 1px solid #fafafa; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${priceFormat(
          pt.amount
        )}</h5>
      </div>
    </div>
      `
        )
        .join('')}
        <div style="display: flex; justify-content: space-evenly; align-items: center">
        <div style="display: flex; flex: 7;">
        </div>
        <div style="display: flex; flex: 3; border: 1px solid #9ab">
          <h5 style="font-weight: 600; padding: .5rem; margin: 0; text-align: right;">Total (USD)</h5>
        </div>
        <div style="display: flex; flex: 2; border: 1px solid #9ab">
          <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
            data.reduce((acc, cur) => acc + cur.amount, 0)
          )}</h5>
        </div>
      </div>
    `);
    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
    mywindow.print();
    // mywindow.close();
    // return true;
  };
  const printReceipt = (docType, data, consultData) => {
    // console.log('calling receipt', docType, data, consultData);
    if (!consultData) return;

    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `
      <style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>
      
      `
    );

    mywindow.document.write('</head><body>');
    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
        <div style="display: flex; flex: 0">
          <img
            height="61"
            width="100"
            alt=""
            src="/assets/img/logo.png"
          />
        </div>
        <div style="display: flex; flex: 1">
          <h1 style="padding: 0 1rem">${docType}</h1>
        </div>
        <div style="display: flex;">
          <h3 style="padding: 0 1rem">${dateFormat(Date.now())}</h3>
        </div>
      </div>

      <div style="display: flex; justify-content: start; align-items: center">
        <div style="display: flex; flex: 1; flex-direction: column">
          <h4 style="font-weight: 600; margin: 0; padding: 0;">Ahluwalia Law Offices</h4>

          <h4 style="font-weight: 400; margin: 0; padding: 0;">Your 1st Choice & 2nd Opinion Law Firm</h4>
          <h4 style="font-weight: 400; margin: 0; padding: 0;">14180 Dallas Parkway, Suite 720</h4>
          <h4 style="font-weight: 400; margin: 0; padding: 0;">Dallas, TX 75254</h4>
          <h4 style="font-weight: 400; margin: 0; padding: 0;">Phone:972-361-0606 Fax:972-361-0999</h4>
          <h5>www.ahluwalialaw.com</h5>
        </div>
        <div
          style="
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex: 1;
          "
        >
        <div style="display: flex; flex: 1; flex-direction: column; justify-content: flex-start; align-items: flex-start;">
            <h5 style="font-weight: 400; margin: 0; padding: 0;">To</h5>
            ${
              consultData.caseType === 'individual'
                ? ''
                : `<h5 style="font-weight: 600; margin: 0; padding: 0;">${consultData.companyName}</h5>`
            }
            <h5 style="font-weight: 400; margin: 0; padding: 0;">${
              consultData.primaryName
            }</h5>
            ${
              consultData.address && consultData.address.line1
                ? `<h5 style="font-weight: 400; margin: 0; padding: 0;">${consultData.address.line1}</h5>`
                : ''
            }

            ${
              consultData.address && consultData.address.line2
                ? `<h5 style="font-weight: 400; margin: 0; padding: 0;">${consultData.address.line2}</h5>`
                : ''
            }

            ${
              consultData.address && consultData.address.city
                ? `<h5 style="font-weight: 400; margin: 0; padding: 0;">${consultData.address.city}</h5>`
                : ''
            }

            ${
              consultData.address && consultData.address.state
                ? `<h5 style="font-weight: 400; margin: 0; padding: 0;">${consultData.address.state}</h5>`
                : ''
            }

            ${
              consultData.address && consultData.address.zip
                ? `<h5 style="font-weight: 400; margin: 0; padding: 0;">${consultData.address.zip}</h5>`
                : ''
            }

            ${
              consultData.address && consultData.address.country
                ? `<h5 style="font-weight: 400; margin: 0; padding: 0;">${consultData.address.country}</h5>`
                : ''
            }
          </div>
        </div>
      </div>

      <div style="display: flex; justify-content: start; align-items: center">
        <div style="display: flex; flex: 1; flex-direction: column">
          <h4 style="font-weight: 600; margin: 0; padding: 1rem 0;">Consultation Number - C-${
            consultData.consultNum
          }</h4>
        </div>
      </div>
      <div style="display: flex; justify-content: space-evenly; align-items: center">
        <div style="display: flex; flex: 2; border: 1px solid #9ab">
          <h5 style="padding: .5rem; margin: 0;">Date</h5>
        </div>
        <div style="display: flex; flex: 2; border: 1px solid #9ab">
          <h5 style="padding: .5rem; margin: 0;">Type</h5>
        </div>
        <div style="display: flex; flex: 3; border: 1px solid #9ab">
          <h5 style="padding: .5rem; margin: 0;">Method</h5>
        </div>
        <div style="display: flex; flex: 3; border: 1px solid #9ab">
          <h5 style="padding: .5rem; margin: 0;">Description</h5>
        </div>
        <div style="display: flex; flex: 2; border: 1px solid #9ab">
          <h5 style="padding: .5rem; margin: 0;">Amount (USD)</h5>
        </div>
      </div>
      ${data
        .map(
          (pt) => `
      <div style="display: flex; justify-content: space-evenly; align-items: center">
      <div style="display: flex; flex: 2; border: 1px solid #fafafa; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${dateFormat(
          new Date(pt.paymentDate)
        )}</h5>
      </div>
      <div style="display: flex; flex: 2; border: 1px solid #fafafa; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
          pt.type
        }</h5>
      </div>
      <div style="display: flex; flex: 3; border: 1px solid #fafafa; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${PAYMENT_CHARGE_TYPES_MAP[pt.subType]}</h5>
      </div>
      <div style="display: flex; flex: 3; border: 1px solid #fafafa; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${pt.desc}</h5>
      </div>
      <div style="display: flex; flex: 2; border: 1px solid #fafafa; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${priceFormat(
          pt.amount
        )}</h5>
      </div>
    </div>
      `
        )
        .join('')}
        <div style="display: flex; justify-content: space-evenly; align-items: center">
        <div style="display: flex; flex: 7;">
        </div>
        <div style="display: flex; flex: 3; border: 1px solid #9ab">
          <h5 style="font-weight: 600; padding: .5rem; margin: 0; text-align: right;">Total (USD)</h5>
        </div>
        <div style="display: flex; flex: 2; border: 1px solid #9ab">
          <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
            data.reduce((acc, cur) => acc + cur.amount, 0)
          )}</h5>
        </div>
      </div>
    `);
    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  const clearInputs = () => {
    setConsultationDate('');
    setOffice('dallas');
    setCompanies([]);
    setEmployees([]);
    setCaseType('company');
    setConsultationAttorney('');
    setCompanyName('');
    setCompanyNameForSearch('');
    setCompanyId('');
    setPrimaryName('');
    setSecondaryName('');
    setLine1('');
    setLine2('');
    setCity('');
    setStateRegion('');
    setZipCode('');
    setCountry('');
    setEmail('');
    setPhone('');
    setConsultationFee(0);
    setReferredBy('');
    setPaymentType('cash');
    setConsultationNotes('');
    setAttorneyNotes('');
    setErrors({});
  };

  const convertConsult = async () => {
    if (!consultData) return;
    if (consultData && consultData.isConverted) return;
    const configService = require('../store/config/service');
    let body = {
      openedAt: format(new Date(), 'yyyy-MM-dd'),
      status: 'OPEN',
      caseType: consultData.caseType,
      office: consultData.office,
      counsel: consultData.consultationAttorney.id,
      companyName:
        consultData.caseType === 'individual' ? '' : consultData.companyName,
      primaryContact: {
        fullName: consultData.primaryName,
        cisStatus: 'none',
      },
      secondaryContact: {
        fullName: consultData.secondaryName,
        cisStatus: 'none',
      },
      emails: [consultData.email],
      contractNotes: consultData.consultationNotes,
      attorneyNotes: consultData.attorneyNotes,
      activateCriminal: false,
      activateH1B: false,
      createdBy: user.id,
      createdByName: getUserName(user),
    };
    if (consultData.caseSubType && consultData.caseSubType.length)
      body.caseSubType = consultData.caseSubType;
    if (consultData.caseType !== 'individual')
      body.company = consultData.company;
    if (consultData.phone) {
      body.phones = [
        {
          phoneNum: consultData.phone,
          type: 'work',
        },
      ];
    }
    if (consultData.address) {
      body.address = {};
      if (consultData.address.line1) {
        body.address.line1 = consultData.address.line1;
      }
      if (consultData.address.line2) {
        body.address.line2 = consultData.address.line2;
      }
      if (consultData.address.city) {
        body.address.city = consultData.address.city;
      }
      if (consultData.address.state) {
        body.address.state = consultData.address.state;
      }
      if (consultData.address.zip) {
        body.address.zip = consultData.address.zip;
      }
      if (consultData.address.country) {
        body.address.country = consultData.address.country;
      }
    }

    // console.log('body - ', body);
    const res = await configService.default.createCase(body);
    if (res.result && res.result.id) {
      // console.log('created case - ', res.result);
      let cBody = {
        case: res.result.id,
        isConverted: true,
      };
      const cRes = await configService.default.updateConsultation(
        consultData.id,
        cBody
      );
      if (cRes && cRes.result) {
        history.push(`/cases/${res.result.id}`);
      }
    }
  };

  const goToCase = () => {
    if (!consultData || !consultData.case || !consultData.case.length) return;
    history.push(`/cases/${consultData.case}`);
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-start">
        <h4>
          Consult No.{' '}
          {consultData && consultData.consultNum ? (
            <span className="fw-bold">{`C-${consultData.consultNum}`}</span>
          ) : (
            <React.Fragment />
          )}
        </h4>
        <div className="d-flex flex-column align-items-end justify-content-end">
          <div className="d-flex align-items-end justify-content-end">
            {onCase ||
            !consultData ||
            (consultData && !!consultData.id === false) ? (
              <React.Fragment />
            ) : consultData && !consultData.isConverted ? (
              <button
                onClick={convertConsult}
                className="btn btn-outline-success ms-2"
              >
                <i className="bi bi-chevron-contract"></i> Convert
              </button>
            ) : consultData && consultData.case && consultData.case.length ? (
              <button
                onClick={goToCase}
                className="btn btn-outline-success ms-2"
              >
                <i className="bi bi-chevron-contract"></i> Go to Case
              </button>
            ) : (
              <React.Fragment />
            )}
            {/* <button
              onClick={printConsult}
              className="btn btn-outline-info ms-2"
            >
              <i className="bi bi-printer"></i> Print
            </button> */}
            <button
              onClick={() =>
                printReceipt(
                  'Receipt',
                  [
                    {
                      paymentDate: consultData.consultationDate,
                      type: 'Payment',
                      subType: consultData.paymentType,
                      desc: 'Consultation Fee',
                      amount: consultData.consultationFee,
                    },
                  ],
                  consultData
                )
              }
              className="btn btn-outline-info ms-2"
            >
              <i className="bi bi-receipt-cutoff"></i> Receipt
            </button>
            <button onClick={goBack} className="btn btn-outline-info ms-2">
              <i className="bi bi-chevron-left"></i>
              {onCase ? 'Go to Consults' : 'Back to List'}
            </button>
          </div>

          <div className="d-flex align-items-end justify-content-end mt-2">
            {onEdit ? (
              <button
                onClick={updateConsultation}
                className="btn btn-outline-success ms-2"
              >
                <i className="bi bi-printer"></i> Save
              </button>
            ) : (
              <React.Fragment />
            )}
            <button onClick={toggleEdit} className="btn btn-outline-dark ms-2">
              <i className={`bi bi-${onEdit ? 'x-circle' : 'pencil'}`}></i>{' '}
              {onEdit ? 'Cancel' : 'Edit'}
            </button>
          </div>
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <div className="d-flex justify-content-start align-items-start my-3">
            <div className="info-card p-2 me-2 flex-one">
              <h6 className="fw-light">Case Category</h6>
              {!onEdit ? (
                <h5 className="mb-0 fw-semi-bold capitalize">
                  {(consultData || {}).caseType || ''}
                </h5>
              ) : (
                <div>
                  <Select
                    placeholder={'select a case category'}
                    value={caseType}
                    options={CASE_TYPES}
                    onChange={(e) => {
                      setCaseType(e.target.value);
                      handleError('caseType', '');
                    }}
                    err={errors.caseType || ''}
                  />
                </div>
              )}
            </div>

            <div className="info-card p-2 me-2 flex-one">
              <h6 className="fw-light">Office</h6>
              {!onEdit ? (
                <h5 className="mb-0 fw-semi-bold capitalize">
                  {(consultData || {}).office || ''}
                </h5>
              ) : (
                <div>
                  <Select
                    placeholder={'select an office'}
                    value={office}
                    options={OFFICES}
                    onChange={(e) => {
                      setOffice(e.target.value);
                      handleError('office', '');
                    }}
                    err={errors.office || ''}
                  />
                </div>
              )}
            </div>

            <div className="info-card p-2 me-2 flex-one">
              <h6 className="fw-light">Consultation Attorney</h6>
              {!onEdit ? (
                <h5 className="mb-0 fw-semi-bold">
                  {consultData.consultationAttorney &&
                    getUserName(consultData.consultationAttorney)}
                </h5>
              ) : (
                <div>
                  <Select
                    placeholder={'select an atorney'}
                    value={consultationAttorney}
                    options={employees}
                    onChange={(e) => {
                      setConsultationAttorney(e.target.value);
                      handleError('consultationAttorney', '');
                    }}
                    err={errors.consultationAttorney || ''}
                  />
                </div>
              )}
            </div>

            <div className="info-card p-2 me-2 flex-one">
              <h6 className="fw-light">Primary Name</h6>
              {!onEdit ? (
                <h5 className="mb-0 fw-semi-bold">
                  {(consultData || {}).primaryName}
                </h5>
              ) : (
                <div>
                  <Input
                    placeholder={'enter primary full name'}
                    type={'text'}
                    value={primaryName}
                    onChange={(e) => {
                      if (
                        e.target.value &&
                        e.target.value.length &&
                        errors.primaryName &&
                        errors.primaryName.length
                      ) {
                        handleError('primaryName', '');
                      }
                      setPrimaryName(e.target.value);
                    }}
                    err={errors.primaryName || ''}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-start align-items-start my-3">
            <div className="info-card p-2 me-2 flex-one">
              <h6 className="fw-light">Company Name</h6>
              {!onEdit ? (
                <h5 className="mb-0 fw-semi-bold">
                  {(consultData || {}).companyName}
                </h5>
              ) : (
                <div>
                  {caseType === 'company' || caseType === 'individual' ? (
                    <Input
                      disabled={caseType === 'individual'}
                      placeholder={"enter company or petitioner's name"}
                      type={'text'}
                      autoCapitalize={'words'}
                      autoComplete={'off'}
                      value={companyName}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.companyName &&
                          errors.companyName.length
                        ) {
                          handleError('companyName', '');
                        }
                        setCompanyName(e.target.value);
                        setCompanyNameForSearch(e.target.value);
                        handleError('companyName', '');
                      }}
                      err={errors.companyName || ''}
                    />
                  ) : caseType === 'beneficiary' ? (
                    <Select
                      placeholder={'select a company'}
                      options={companies}
                      value={companyId}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.companyId &&
                          errors.companyId.length
                        ) {
                          handleError('companyId', '');
                        }
                        setCompanyId(e.target.value);
                        setCompanyNameForSearch(
                          (
                            companies.filter(
                              (cm) => cm.value === e.target.value
                            )[0] || {}
                          ).label || ''
                        );
                      }}
                      err={errors.companyId || ''}
                    />
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              )}
            </div>

            <div className="info-card p-2 me-2 flex-one">
              <h6 className="fw-light">Consult Date</h6>
              {!onEdit ? (
                <h5 className="mb-0 fw-semi-bold">
                  {consultData &&
                  consultData.consultationDate &&
                  consultData.consultationDate.length
                    ? dateFormat(new Date((consultData || {}).consultationDate))
                    : ``}
                </h5>
              ) : (
                <div>
                  <Input
                    placeholder={'enter consultation date'}
                    type={'date'}
                    value={consultationDate}
                    onChange={(e) => {
                      setConsultationDate(e.target.value);
                      handleError('consultationDate', '');
                    }}
                    err={errors.consultationDate || ''}
                  />
                </div>
              )}
            </div>

            <div className="info-card p-2 me-2 flex-one">
              <h6 className="fw-light">Secondary Name</h6>
              {!onEdit ? (
                <h5 className="mb-0 fw-semi-bold">
                  {(consultData || {}).secondaryName}
                </h5>
              ) : (
                <div>
                  <Input
                    placeholder={'enter secondary full name'}
                    type={'text'}
                    value={secondaryName}
                    onChange={(e) => {
                      if (
                        e.target.value &&
                        e.target.value.length &&
                        errors.secondaryName &&
                        errors.secondaryName.length
                      ) {
                        handleError('secondaryName', '');
                      }
                      setSecondaryName(e.target.value);
                    }}
                    err={errors.secondaryName || ''}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="d-flex justify-content-start align-items-start my-3">
            <div className="info-card p-2 me-2 d-flex flex-column justify-content-start align-items-start flex-pt5">
              <h6 className="fw-light">Consultation Fee</h6>

              {!onEdit ? (
                <div className="d-flex flex-wrap">
                  {consultData && consultData.consultationFee ? (
                    <h5 className="mb-0 fw-semi-bold">
                      {priceFormat(consultData.consultationFee)}
                    </h5>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              ) : (
                <Input
                  classes={'w-100'}
                  placeholder={'enter consultation fee'}
                  type={'number'}
                  value={consultationFee}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      e.target.value.length &&
                      errors.consultationFee &&
                      errors.consultationFee.length
                    ) {
                      handleError('consultationFee', '');
                    }
                    setConsultationFee(e.target.value);
                  }}
                  err={errors && errors.consultationFee}
                />
              )}
            </div>

            <div className="info-card p-2 me-2 d-flex flex-column justify-content-start align-items-start flex-pt5">
              <h6 className="fw-light">Referred By</h6>

              {!onEdit ? (
                <div className="d-flex flex-wrap">
                  {consultData &&
                  consultData.referredBy &&
                  consultData.referredBy.length ? (
                    <h5 className="mb-0 fw-semi-bold">
                      {consultData.referredBy}
                    </h5>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              ) : (
                <Input
                  classes={'w-100'}
                  placeholder={'add referred by'}
                  value={referredBy}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      e.target.value.length &&
                      errors.country &&
                      errors.country.length
                    ) {
                      handleError('referredBy', '');
                    }
                    setReferredBy(e.target.value);
                  }}
                  err={errors.referredBy || ''}
                />
              )}
            </div>

            <div className="info-card p-2 me-2 d-flex flex-column justify-content-start align-items-start flex-pt5">
              <h6 className="fw-light">Payment Type</h6>

              {!onEdit ? (
                <div className="d-flex flex-wrap">
                  {consultData &&
                  consultData.paymentType &&
                  consultData.paymentType.length ? (
                    <h5 className="mb-0 fw-semi-bold">
                      {consultData.paymentType}
                    </h5>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              ) : (
                <Select
                  classes={'w-100'}
                  placeholder={'select payment type'}
                  options={PAYMENT_TYPES}
                  value={paymentType}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      e.target.value.length &&
                      errors.country &&
                      errors.country.length
                    ) {
                      handleError('paymentType', '');
                    }
                    setPaymentType(e.target.value);
                  }}
                  err={errors.paymentType || ''}
                />
              )}
            </div>
          </div>

          <div className="d-flex justify-content-start align-items-start my-3">
            {!onEdit ? (
              <>
                {consultData.consultationNotes ? (
                  <div className="info-card p-2 me-2 flex-one">
                    <h6 className="fw-light">Consultation Notes</h6>
                    <h5 className="mb-0 fw-semi-bold">
                      {(consultData || {}).consultationNotes || ''}
                    </h5>
                  </div>
                ) : (
                  <React.Fragment />
                )}
              </>
            ) : (
              <div className="info-card p-2 me-2 flex-one">
                <h6 className="fw-light">Consultation Notes</h6>

                <div className="row d-flex flex-one">
                  <div className="col-12 w-100">
                    <TextArea
                      placeholder={'add consultation notes'}
                      value={consultationNotes}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.consultationNotes &&
                          errors.consultationNotes.length
                        ) {
                          handleError('consultationNotes', '');
                        }
                        setConsultationNotes(e.target.value);
                      }}
                      err={errors && errors.consultationNotes}
                    />
                  </div>
                </div>
              </div>
            )}

            {!onEdit ? (
              <>
                {consultData.attorneyNotes ? (
                  <div className="info-card p-2 me-2 flex-one">
                    <h6 className="fw-light">Attorney Notes</h6>
                    <h5 className="mb-0 fw-semi-bold">
                      {(consultData || {}).attorneyNotes || ''}
                    </h5>
                  </div>
                ) : (
                  <React.Fragment />
                )}
              </>
            ) : (
              <div className="info-card p-2 me-2 flex-one">
                <h6 className="fw-light">Attorney Notes</h6>
                <TextArea
                  placeholder={'add attorney notes'}
                  value={attorneyNotes}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      e.target.value.length &&
                      errors.attorneyNotes &&
                      errors.attorneyNotes.length
                    ) {
                      handleError('attorneyNotes', '');
                    }
                    setAttorneyNotes(e.target.value);
                  }}
                  err={errors && errors.attorneyNotes}
                />
              </div>
            )}
          </div>

          <div className="d-flex justify-content-start align-items-start my-3">
            <div className="info-card p-2 me-2 flex-one">
              <h6 className="fw-light">Address</h6>

              {!onEdit ? (
                <div className="d-flex flex-column">
                  <small className="mb-0 fw-semi-bold">
                    {Object.keys((consultData || {}).address || {}).length ? (
                      Object.keys(consultData.address).map((key, i) => (
                        <h5 className="mb-0 fw-semi-bold d-inline" key={key}>
                          {consultData.address[key]}
                          {i === Object.keys(consultData.address).length - 1
                            ? ''
                            : ', '}
                        </h5>
                      ))
                    ) : (
                      <React.Fragment />
                    )}
                  </small>
                </div>
              ) : (
                <div className="row">
                  <div className="col-12">
                    <Input
                      placeholder={'enter address line 1'}
                      type={'text'}
                      value={line1}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.line1 &&
                          errors.line1.length
                        ) {
                          handleError('line1', '');
                        }
                        setLine1(e.target.value);
                      }}
                      err={errors.line1 || ''}
                    />
                  </div>
                  <div className="col-12">
                    <Input
                      placeholder={'enter address line 2'}
                      type={'text'}
                      value={line2}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.line2 &&
                          errors.line2.length
                        ) {
                          handleError('line2', '');
                        }
                        setLine2(e.target.value);
                      }}
                      err={errors.line2 || ''}
                    />
                  </div>

                  <div className="col-12">
                    <Input
                      placeholder={'enter address city'}
                      type={'text'}
                      value={city}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.city &&
                          errors.city.length
                        ) {
                          handleError('city', '');
                        }
                        setCity(e.target.value);
                      }}
                      err={errors.city || ''}
                    />
                  </div>

                  <div className="col-12">
                    <Input
                      placeholder={'enter address state'}
                      type={'text'}
                      value={stateRegion}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.stateRegion &&
                          errors.stateRegion.length
                        ) {
                          handleError('stateRegion', '');
                        }
                        setStateRegion(e.target.value);
                      }}
                      err={errors.stateRegion || ''}
                    />
                  </div>

                  <div className="col-12">
                    <Input
                      placeholder={'enter address zipcode'}
                      type={'text'}
                      value={zipCode}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.zipCode &&
                          errors.zipCode.length
                        ) {
                          handleError('zipCode', '');
                        }
                        setZipCode(e.target.value);
                      }}
                      err={errors.zipCode || ''}
                    />
                  </div>

                  <div className="col-12">
                    <Input
                      placeholder={'enter country / nationality'}
                      type={'text'}
                      value={country}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.country &&
                          errors.country.length
                        ) {
                          handleError('country', '');
                        }
                        setCountry(e.target.value);
                      }}
                      err={errors.country || ''}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="info-card p-2 me-2 flex-one">
              <h6 className="fw-light">Email</h6>
              {!onEdit ? (
                <div className="d-flex flex-wrap">
                  <small className="badge rounded text-and-border-blue px-1 fw-normal me-2 size15">
                    {(consultData || {}).email}
                  </small>
                </div>
              ) : (
                <div>
                  <Input
                    placeholder={'enter email address'}
                    type={'text'}
                    value={email}
                    onChange={(e) => {
                      if (
                        e.target.value &&
                        e.target.value.length &&
                        errors.email &&
                        errors.email.length
                      ) {
                        handleError('email', '');
                      }
                      setEmail(e.target.value);
                    }}
                    err={errors && errors.email}
                  />
                </div>
              )}
            </div>

            <div className="info-card p-2 me-2 flex-one">
              <h6 className="fw-light">Phone</h6>

              {!onEdit ? (
                <div className="d-flex flex-wrap">
                  <small className="badge rounded text-and-border-blue px-1 fw-normal me-2 size15">
                    {(consultData || {}).phone}
                  </small>
                </div>
              ) : (
                <div>
                  <Input
                    placeholder={'enter phone number'}
                    type={'tel'}
                    value={phone}
                    onChange={(e) => {
                      if (
                        e.target.value &&
                        e.target.value.length &&
                        errors.phone &&
                        errors.phone.length
                      ) {
                        handleError('phone', '');
                      }
                      setPhone(e.target.value);
                    }}
                    err={errors && errors.phone}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConsultInfo;
