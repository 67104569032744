import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { myGreenSuperLight } from '../../constants/colors';
import { CONTRACT_TYPES } from '../../constants/constants';
import { getUserName } from '../../utils/userUtils';
import Checkbox from '../common/Checkbox';
import Input from '../common/Input';
import MultiSelect from '../common/MultiSelect';
import MyMessage from '../common/MyMessage';
import Select from '../common/Select';
import TextArea from '../common/TextArea';

const AddContractModal = (props) => {
  const { caseData, visible, onClose, user, employees, reinitialize } = props;

  const [totalAmount, setTotalAmount] = useState('');
  const [type, setType] = useState('n-400');
  const [retainerAmount, setRetainerAmount] = useState(0);
  const [retainerByName, setRetainerByName] = useState('');
  const [retainerBy, setRetainerBy] = useState([]);
  const [desc, setDesc] = useState('');
  // const [isMonthly, setIsMonthly] = useState(true);
  // const [amountRecurring, setAmountRecurring] = useState(0);
  // const [dayRecurring, setDayRecurring] = useState('');
  // const [isEvent, setIsEvent] = useState(false);
  // const [events, setEvents] = useState([]);
  // const [eventAmount, setEventAmount] = useState('');
  // const [eventInfo, setEventInfo] = useState('');
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const createContact = async () => {
    if (!validateContract()) return;
    const configService = require('../../store/config/service');

    let body = {
      type,
      case: caseData.id,
      totalAmount,
      retainerAmount,
      retainerBy: retainerBy[0].value,
      retainerByName,
      createdBy: user.id,
      desc,
      // isMonthly,
      // isEvent,
      createdByName: getUserName(user),
    };

    // if (isMonthly) {
    //   body.amountRecurring = amountRecurring;
    //   body.dayRecurring = dayRecurring;
    // } else if (isEvent) {
    //   body.events = events;
    // }

    // if (desc && desc.length) {
    //   body.desc = desc;
    // }

    // console.log('body - ', body);

    const res = await configService.default.createContract(body);
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const validateContract = () => {
    let flag = true;
    if (!type || !type.length) {
      flag = false;
      handleError('type', 'select a valid contract type');
    }
    if (!totalAmount || !totalAmount.length) {
      flag = false;
      handleError('totalAmount', 'select a valid contract total amount');
    }
    if (!retainerAmount || !retainerAmount.length) {
      flag = false;
      handleError('retainerAmount', 'select a valid contract retainer amount');
    }
    if (!retainerBy || !retainerBy.length) {
      flag = false;
      handleError('retainerBy', 'select a valid contract emlpoyee');
    }
    if (!retainerByName || !retainerByName.length) {
      flag = false;
      handleError('retainerBy', 'select a valid employee');
    }
    if (!desc || !desc.length) {
      flag = false;
      handleError('desc', 'select a add description');
    }
    // if (isMonthly) {
    //   if (!amountRecurring || !amountRecurring.length) {
    //     flag = false;
    //     handleError('amountRecurring', 'add a valid amount');
    //   }
    //   if (!dayRecurring || !dayRecurring.length) {
    //     flag = false;
    //     handleError('dayRecurring', 'add a valid date');
    //   }
    // }
    // if (isEvent && (!events || !events.length)) {
    //   flag = false;
    //   handleError('events', 'add a valid event');
    // }
    if (!user || !user.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (!caseData || !caseData.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    return flag;
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  // const addEventToList = () => {
  //   if (events && events.length >= 5) {
  //     // console.log('heyyyy');
  //     handleError('event', 'you can add a max of 5 events');
  //     return;
  //   }
  //   if (!eventAmount || eventAmount < 1) {
  //     handleError('eventAmount', 'please add an amount');
  //     return;
  //   }
  //   if (!eventInfo || !eventInfo.length) {
  //     handleError('eventInfo', 'please add event info');
  //     return;
  //   }
  //   let newEvent = {
  //     eventAmount,
  //     eventInfo,
  //   };
  //   // console.log('newEvent - ', newEvent, events);
  //   setEvents((current) => {
  //     if (!current || !current.length) return [newEvent];
  //     return [...current, newEvent];
  //   });
  //   setEventAmount('');
  //   setEventInfo('');
  // };

  // const removeEvent = (index) => {
  //   setEvents(events.filter((_, i) => i !== index));
  // };

  const doOnClose = () => {
    resetBlankModal();
    onClose();
  };

  const resetBlankModal = () => {
    setTotalAmount('');
    setType('n-400');
    setRetainerAmount(0);
    setRetainerByName('');
    setRetainerBy([]);
    setDesc('');
    // setIsMonthly(true);
    // setAmountRecurring(0);
    // setDayRecurring('');
    // setIsEvent(false);
    // setEvents([]);
    // setEventAmount('');
    // setEventInfo('');
    setErrors({});
    setMessage({});
    setLoading(false);
  };

  return visible ? (
    <div className="my-modal-container">
      <div className="my-modal-overlay" onClick={doOnClose}></div>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable my-modal z-index-9">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <div>
              <p className="modal-title mb-0">{'Adding'}</p>
              <h3 className="modal-title fw-bold">{'New Contract'}</h3>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={doOnClose}
            ></button>
          </div>
          <div className="modal-body">
            <div className="container d-flex flex-column justify-content-center align-items-start p-2">
              <div className="mb-2">
                <p className="text-muted fs-italic fw-light size15">
                  Fill in the details below to add a new contract!
                </p>

                <div className="row d-flex flex-wrap justify-content-start align-items-start">
                  <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                    <Input
                      label={'Total Amount'}
                      placeholder={'enter total amount'}
                      type={'number'}
                      value={totalAmount}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.totalAmount &&
                          errors.totalAmount.length
                        ) {
                          handleError('totalAmount', '');
                        }
                        setTotalAmount(e.target.value);
                      }}
                      err={errors && errors.totalAmount}
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                    <Input
                      label={'Retainer Amount'}
                      placeholder={'enter retainer amount'}
                      type={'number'}
                      value={retainerAmount}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.retainerAmount &&
                          errors.retainerAmount.length
                        ) {
                          handleError('retainerAmount', '');
                        }
                        setRetainerAmount(e.target.value);
                      }}
                      err={errors && errors.retainerAmount}
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <Select
                      label={'Contract Type'}
                      placeholder={'select a contract type'}
                      value={type}
                      options={CONTRACT_TYPES}
                      onChange={(e) => {
                        setType(e.target.value);
                        handleError('type', '');
                      }}
                      err={errors.type || ''}
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <MultiSelect
                      label={'Retainer By'}
                      displayValue={'label'}
                      values={retainerBy}
                      options={employees}
                      onSelect={(e) => {
                        setRetainerBy(e);
                        // console.log(e);
                        if (e.length) {
                          setRetainerByName(e[e.length - 1].label);
                        }
                        handleError('retainerBy', '');
                      }}
                      onRemove={(e) => {
                        setRetainerBy(e);
                      }}
                      err={errors.retainerBy || ''}
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <TextArea
                      label={'Description'}
                      placeholder={'add a description'}
                      value={desc}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.desc &&
                          errors.desc.length
                        ) {
                          handleError('desc', '');
                        }
                        setDesc(e.target.value);
                      }}
                      err={errors && errors.desc}
                    />
                  </div>

                  {/* <hr className="hy-light-hr mt-3 mb-4" />

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <label className="form-label px-2">Choose One</label>
                    <div className="d-flex justify-content-start align-items-center">
                      <Checkbox
                        type="checkbox"
                        checked={isMonthly}
                        onClick={() => {
                          setIsMonthly(true);
                          setIsEvent(false);
                        }}
                        label={'Monthly Option'}
                        namex="isMonthly"
                        idx="isMonthlyYes"
                      />
                      <Checkbox
                        type="checkbox"
                        checked={isEvent}
                        onClick={() => {
                          setIsEvent(true);
                          setIsMonthly(false);
                        }}
                        label={'Event Option'}
                        namex="isEvent"
                        idx="isEventYes"
                      />
                    </div>
                  </div>

                  {isMonthly ? (
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                      <Input
                        label={'Payment Amount Recurring'}
                        placeholder={'enter payment amount recurring'}
                        type={'number'}
                        value={amountRecurring}
                        onChange={(e) => {
                          if (
                            e.target.value &&
                            e.target.value.length &&
                            errors.amountRecurring &&
                            errors.amountRecurring.length
                          ) {
                            handleError('amountRecurring', '');
                          }
                          setAmountRecurring(e.target.value);
                        }}
                        err={errors && errors.amountRecurring}
                      />

                      <Input
                        label={'Payment Day Recurring'}
                        placeholder={'enter payment day recurring'}
                        type={'date'}
                        value={dayRecurring}
                        onChange={(e) => {
                          if (
                            e.target.value &&
                            e.target.value.length &&
                            errors.dayRecurring &&
                            errors.dayRecurring.length
                          ) {
                            handleError('dayRecurring', '');
                          }
                          setDayRecurring(e.target.value);
                        }}
                        err={errors && errors.dayRecurring}
                      />
                    </div>
                  ) : (
                    <React.Fragment />
                  )}

                  {isEvent ? (
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                      <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                          <Input
                            disabled={events && events.length >= 5}
                            label={'Event Amount'}
                            placeholder={'enter event amount'}
                            type={'number'}
                            value={eventAmount}
                            onChange={(e) => {
                              if (
                                e.target.value &&
                                e.target.value.length &&
                                errors.eventAmount &&
                                errors.eventAmount.length
                              ) {
                                handleError('eventAmount', '');
                              }
                              setEventAmount(e.target.value);
                            }}
                            err={errors && errors.eventAmount}
                          />
                        </div>
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                          <TextArea
                            disabled={events && events.length >= 5}
                            label={'Event Info'}
                            placeholder={'add event info'}
                            value={eventInfo}
                            onChange={(e) => {
                              if (
                                e.target.value &&
                                e.target.value.length &&
                                errors.eventInfo &&
                                errors.eventInfo.length
                              ) {
                                handleError('eventInfo', '');
                              }
                              setEventInfo(e.target.value);
                            }}
                            err={errors && errors.eventInfo}
                          />
                          <button
                            className="btn btn-sm btn-outline-primary mx-3"
                            onClick={addEventToList}
                          >
                            Add to list
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <React.Fragment />
                  )}

                  {isEvent && events && events.length ? (
                    <div>
                      {events && events.length ? (
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                          <div className="row">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 my-3">
                              <label className="form-label d-block">
                                Events you've added
                              </label>
                              {events.map((event, i) => (
                                <div
                                  key={i}
                                  className="badge w-100 d-flex justify-content-between align-items-center size15 fw-normal rounded shadow-sm my-green-bg mb-2 p-3"
                                >
                                  <div className="d-flex flex-column justify-content-center align-items-start overflow-hidden">
                                    <p className="fw-semi-bold text-dark size15 mb-1">
                                      <span className="fw-light mb-2">
                                        amount |{' '}
                                      </span>
                                      {event.eventAmount}
                                    </p>
                                    <p className="mb-2">
                                      <span className="fw-light">info | </span>
                                      {event.eventInfo}
                                    </p>
                                  </div>
                                  <i
                                    onClick={() => removeEvent(i)}
                                    className="bi bi-x-circle-fill ms-1"
                                  ></i>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <React.Fragment />
                      )}
                    </div>
                  ) : (
                    <React.Fragment />
                  )} */}
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
            </div>
          </div>
          <div className="px-3">
            <MyMessage
              visible={!!message}
              message={message}
              onClose={() => {
                setMessage(undefined);
              }}
            />
          </div>
          <div
            className="modal-footer"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <button
              type="button"
              className="btn btn-outline ms-2"
              onClick={doOnClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary ms-2"
              onClick={createContact}
            >
              Add Contract
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default AddContractModal;
