import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CASE_STATUSES,
  CASE_SUB_TYPES,
  CASE_TYPES,
  CIS_TYPES,
  COUNTRIES,
  OFFICES,
  PHONE_TYPES,
} from '../constants/constants';
import {
  createCompany,
  dateFormat,
  getCaseStatus,
  getMahDateCustom,
  getUserName,
  isValidEmail,
  isValidPhone,
} from '../utils/userUtils';
import Checkbox from './common/Checkbox';
import Input from './common/Input';
import Select from './common/Select';
import TextArea from './common/TextArea';

const ClientInfo = (props) => {
  const { caseData, user, reinitialize } = props;
  const [onEdit, setOnEdit] = useState(false);
  const [openedAt, setOpenedAt] = useState('');
  const [caseCompanyNum, setCaseCompanyNum] = useState('');
  const [caseCompanyId, setCaseCompanyId] = useState('');
  const [companies, setCompanies] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [caseType, setCaseType] = useState('company');
  const [caseSubType, setCaseSubType] = useState('H-1B CAP');
  const [caseStatus, setCaseStatus] = useState('ACTIVE');
  const [office, setOffice] = useState('dallas');
  const [counsel, setCounsel] = useState('');
  const [paralegal, setParalegal] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyNameForSearch, setCompanyNameForSearch] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [primaryFullName, setPrimaryFullName] = useState('');
  const [primaryAlien, setPrimaryAlien] = useState('');
  const [primaryCisStatus, setPrimaryCisStatus] = useState('none');
  const [secFullName, setSecFullName] = useState('');
  const [secAlien, setSecAlien] = useState('');
  const [secCisStatus, setSecCisStatus] = useState('none');
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [city, setCity] = useState('');
  const [stateRegion, setStateRegion] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');
  const [emails, setEmails] = useState([]);
  const [email, setEmail] = useState('');
  const [phones, setPhones] = useState([]);
  const [phoneNum, setPhoneNum] = useState('');
  const [phoneType, setPhoneType] = useState('');
  const [phoneNotes, setPhoneNotes] = useState('');
  const [displayImg, setDisplayImg] = useState('');
  const [contractNotes, setContractNotes] = useState('');
  const [activateH1B, setActivateH1B] = useState(false);
  const [activateCriminal, setActivateCriminal] = useState(false);
  const [activatePerm, setActivatePerm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const history = useHistory();

  useEffect(() => {
    initCaseCompany();
  }, [caseData]);

  const initCaseCompany = async () => {
    try {
      if (!caseData) return;
      if (!caseData.caseType || !caseData.company) return;
      if (caseData.caseType !== 'beneficiary') return;
      setLoading(true);
      const configService = require('../store/config/service');
      console.log(caseData.companyName);
      const cRes = await configService.default.getCompaniesByName(
        caseData.companyName.replace('&', 'ampersandSymbol')
      );
      console.log(cRes.result);
      if (!cRes || !cRes.result || !cRes.result.length) return;
      const res = await configService.default.getAllCasesByCompanyList(
        cRes.result.map((c) => c.id).join(',')
      );
      if (res.result && res.result.length) {
        console.log(res.result);
        let genesis;
        let genesisId;
        res.result.map((cs) => {
          if (cs.caseType === 'company') {
            if (!genesis) {
              genesis = cs.caseNumForSearch;
              genesisId = cs.id;
            } else if (cs.caseNum < parseInt(genesis)) {
              genesis = cs.caseNumForSearch;
              genesisId = cs.id;
            }
          }
        });
        if (genesis && genesisId) {
          setCaseCompanyNum(genesis);
          setCaseCompanyId(genesisId);
          setLoading(false);
        }
      }
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const goToCase = (csId) => {
    if (!csId || !csId.length) return;
    history.push(`/cases/${csId}`);
  };

  const toggleEdit = async () => {
    try {
      if (!caseData) return;
      setLoading(true);
      setOnEdit((current) => !current);
      setOpenedAt(getMahDateCustom(caseData.openedAt));
      if (!user || !user.userType) return;
      const configService = require('../store/config/service');
      const res = await configService.default.getUsers(); // change to employees only
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        setEmployees(
          res.result.map((emp) => {
            return {
              label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
              value: emp.id,
            };
          })
        );
      }
      if (caseData.counsel && caseData.counsel.id) {
        setCounsel(caseData.counsel.id);
      }
      if (caseData.paralegal && caseData.paralegal.id) {
        setParalegal(caseData.paralegal.id);
      }
      // fetch companies from db
      const companyRes = await configService.default.getCompanies();
      // console.log('companyRes', companyRes);
      if (companyRes.result) {
        setCompanies(
          companyRes.result.map((company) => {
            return { label: company.companyName, value: company.id };
          })
        );
      }
      if (caseData.caseType === 'beneficiary') {
        setCompanyId(caseData.company);
        setCompanyNameForSearch(caseData.companyName);
        setCompanyName(caseData.companyName);
      } else if (caseData.caseType === 'company') {
        setCompanyId(caseData.company);
        setCompanyNameForSearch(caseData.companyName);
        setCompanyName(caseData.companyName);
      }
      setCaseType(caseData.caseType);
      setCaseSubType(caseData.caseSubType || '');
      setCaseStatus(caseData.status);
      setOffice(caseData.office || 'dallas');
      setPrimaryFullName(caseData.primaryContact.fullName);
      setPrimaryAlien((caseData || {}.primaryContact || {}).alien || '');
      setPrimaryCisStatus(caseData.primaryContact.cisStatus);
      setSecFullName(((caseData || {}).secondaryContact || {}).fullName || '');
      setSecAlien(((caseData || {}).secondaryContact || {}).alien || '');
      setSecCisStatus(
        ((caseData || {}).secondaryContact || {}).cisStatus || ''
      );
      if (caseData.address) {
        setLine1(((caseData || {}).address || {}).line1 || '');
        setLine2(((caseData || {}).address || {}).line2 || '');
        setCity(((caseData || {}).address || {}).city || '');
        setStateRegion(((caseData || {}).address || {}).state || '');
        setZipCode(((caseData || {}).address || {}).zip || '');
        setCountry(((caseData || {}).address || {}).country || '');
      }
      setEmails(caseData.emails || []);
      setPhones(caseData.phones || []);
      setContractNotes((caseData || {}).contractNotes || '');
      setActivateCriminal((caseData || {}).activateCriminal || false);
      setActivateH1B((caseData || {}).activateH1B || false);
      setActivatePerm((caseData || {}).activatePerm || false);
      setLoading(false);
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const validateCompany = (companyName) => {
    let flag = true;
    if (!companyName || !companyName.length) {
      handleError('companyName', 'add a valid company name');
    }
    return flag;
  };

  const updateCase = async () => {
    if (!validateCase()) return;
    // console.log('Case is valid');
    const configService = require('../store/config/service');
    let companyIdx;
    if (caseType === 'company') {
      const companyRes = await createCompany(
        companyName,
        validateCompany,
        companies
      );
      if (companyRes) {
        companyIdx = companyRes;
      }
    } else if (caseType === 'beneficiary') {
      companyIdx = companyId;
    }

    let body = {
      status: caseStatus,
      openedAt,
      caseType,
      office,
      counsel,
      companyName: '',
      // paralegal,
      primaryContact: {
        fullName: primaryFullName,
        // alien: primaryAlien,
        cisStatus: primaryCisStatus,
      },
      secondaryContact: {
        //   fullName: secFullName,
        //   alien: secAlien,
        cisStatus: secCisStatus,
      },
      address: {},
      emails,
      // phones,
      // displayImg,
      // contractNotes,
      activateH1B,
      activateCriminal,
      activatePerm,
    };

    if (caseSubType && caseSubType.length) body.caseSubType = caseSubType;

    if (
      caseStatus &&
      caseStatus.length &&
      caseStatus !== 'ACTIVE' &&
      caseData &&
      !caseData.completionDate
    ) {
      body.completionDate = new Date();
    }

    if (paralegal && paralegal.length) body.paralegal = paralegal;
    if (companyIdx && companyIdx.length) body.company = companyIdx;
    if (
      (caseType === 'company' || caseType === 'beneficiary') &&
      companyNameForSearch &&
      companyNameForSearch.length
    )
      body.companyName = companyNameForSearch;
    if (primaryAlien && primaryAlien.length)
      body.primaryContact.alien = primaryAlien;
    if (secFullName && secFullName.length)
      body.secondaryContact.fullName = secFullName;
    if (secAlien && secAlien.length) body.secondaryContact.alien = secAlien;
    if (line1 && line1.length) body.address.line1 = line1;
    if (line2 && line2.length) body.address.line2 = line2;
    if (city && city.length) body.address.city = city;
    if (stateRegion && stateRegion.length) body.address.state = stateRegion;
    if (zipCode && zipCode.length) body.address.zip = zipCode;
    if (country && country.length) body.address.country = country;
    if (phones && phones.length) body.phones = phones;
    if (displayImg && displayImg.length) body.displayImg = displayImg;
    if (contractNotes && contractNotes.length)
      body.contractNotes = contractNotes;

    // console.log('body - is - ', body);

    const res = await configService.default.updateCase(caseData.id, body);
    if (res.result) {
      // console.log('created case - ', res.result);
      reinitialize();
      setOnEdit(false);
      clearInputs();
    }
  };

  const clearInputs = () => {
    setOpenedAt('');
    setCompanies([]);
    setEmployees([]);
    setCaseType('company');
    setCaseStatus('ACTIVE');
    setOffice('dallas');
    setCounsel('');
    setParalegal('');
    setCompanyName('');
    setCompanyNameForSearch('');
    setCompanyId('');
    setPrimaryFullName('');
    setPrimaryAlien('');
    setPrimaryCisStatus('none');
    setSecFullName('');
    setSecAlien('');
    setSecCisStatus('none');
    setLine1('');
    setLine2('');
    setCity('');
    setStateRegion('');
    setZipCode('');
    setCountry('');
    setEmails([]);
    setEmail('');
    setPhones([]);
    setPhoneNum('');
    setPhoneType('');
    setPhoneNotes('');
    setDisplayImg('');
    setContractNotes('');
    setActivateH1B(false);
    setActivateCriminal(false);
    setActivatePerm(false);
    setLoading(false);
    setErrors({});
  };

  const validateCase = () => {
    let flag = true;
    if (!caseStatus || !caseStatus.length) {
      flag = false;
      handleError('caseStatus', 'select a valid status');
    }
    if (!office || !office.length) {
      flag = false;
      handleError('office', 'select a valid office');
    }
    if (!openedAt || !openedAt.length) {
      flag = false;
      handleError('openedAt', 'select a valid date');
    }
    if (!caseType || !caseType.length) {
      flag = false;
      handleError('caseType', 'select a valid case category');
    }
    if (caseType !== 'individual') {
      if (!companyName && !companyId) {
        flag = false;
        handleError('companyName', 'add a valid company');
      }
    }
    if (!counsel || !counsel.length) {
      flag = false;
      handleError('counsel', 'select a valid counsel');
    }
    // if (!paralegal || !paralegal.length) {
    //   flag = false;
    //   handleError('paralegal', 'select a valid paralegal');
    // }
    if (!primaryFullName || !primaryFullName.length) {
      flag = false;
      handleError('primaryFullName', 'please add a name');
    }
    // if (!primaryAlien || !primaryAlien.length) {
    //   flag = false;
    //   handleError('primaryAlien', 'add a valid alien');
    // }
    if (!primaryCisStatus || !primaryCisStatus.length) {
      flag = false;
      handleError('primaryCisStatus', 'select a valid CIS status');
    }
    // if (!secFullName || !secFullName.length) {
    //   flag = false;
    //   handleError('secFullName', 'please add a name');
    // }
    // if (!secAlien || !secAlien.length) {
    //   flag = false;
    //   handleError('secAlien', 'add a valid alien');
    // }
    // if (!secCisStatus || !secCisStatus.length) {
    //   flag = false;
    //   handleError('secCisStatus', 'select a valid CIS status');
    // }
    // if (!line1 || !line1.length) {
    //   flag = false;
    //   handleError('line1', 'please add address line 1');
    // }
    // if (!city || !city.length) {
    //   flag = false;
    //   handleError('city', 'add a valid city');
    // }
    // if (!stateRegion || !stateRegion.length) {
    //   flag = false;
    //   handleError('stateRegion', 'add a valid state');
    // }
    // if (!country || !country.length) {
    //   flag = false;
    //   handleError('country', 'select a country');
    // }
    if (!emails || !emails.length) {
      flag = false;
      handleError('email', 'please atleast one email');
    }
    // if (!phones || !phones.length) {
    //   flag = false;
    //   handleError('phones', 'add atleast one phone');
    // }
    return flag;
  };

  const addEmailToList = () => {
    if (emails && emails.length >= 5) {
      // console.log('heyyyy');
      handleError('email', 'you can add a max of 5 emails');
      return;
    }
    if (!isValidEmail(email)) {
      handleError('email', 'please add a valid email');
      return;
    }
    // console.log('email - ', email, emails);
    setEmails((current) => {
      if (!current || !current.length) return [email];
      return [...current, email];
    });
    setEmail('');
  };

  const removeEmail = (index) => {
    setEmails(emails.filter((_, i) => i !== index));
  };

  const removePhone = (index) => {
    setPhones(phones.filter((_, i) => i !== index));
  };

  const addPhoneInfoToList = () => {
    if (phones && phones.length >= 5) {
      // console.log('heyyyy');
      handleError('phone', 'you can add a max of 5 phones');
      return;
    }
    if (!isValidPhone(phoneNum)) {
      handleError('phoneNum', 'please add a valid phone number');
      return;
    }
    if (!phoneType || !phoneType.length) {
      handleError('phoneType', 'please select a valid phone type');
      return;
    }
    let newPhone = {
      phoneNum,
      type: phoneType,
    };
    if (phoneNotes && phoneNotes.length) {
      newPhone.notes = phoneNotes;
    }
    // console.log('newPhone - ', newPhone, phones);
    setPhones((current) => {
      if (!current || !current.length) return [newPhone];
      return [...current, newPhone];
    });
    setPhoneNum('');
    setPhoneNotes('');
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  const goBack = () => {
    history.push('/cases');
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-start">
        <h4>Client Info</h4>
        <div>
          {onEdit ? (
            <button
              onClick={updateCase}
              className="btn btn-outline-success ms-2"
            >
              <i className="bi bi-save"></i> Save
            </button>
          ) : (
            <React.Fragment />
          )}
          <button onClick={toggleEdit} className="btn btn-outline-info ms-2">
            <i className={`bi bi-${onEdit ? 'x-circle' : 'pencil'}`}></i>{' '}
            {onEdit ? 'Cancel' : 'Edit'}
          </button>
          <button onClick={goBack} className="btn btn-outline-info ms-2">
            <i className="bi bi-chevron-left"></i> Back to List
          </button>
        </div>
      </div>
      <div className="row d-flex justify-content-start align-items-start my-3">
        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 info-card my-3">
          <h6 className="fw-light">Case Status</h6>
          {!onEdit ? (
            <h5 className="mb-0 fw-semi-bold capitalize">
              {getCaseStatus((caseData || {}).status || '')}
            </h5>
          ) : (
            <>
              <Select
                placeholder={'select a case type'}
                value={caseStatus}
                options={CASE_STATUSES}
                onChange={(e) => {
                  setCaseStatus(e.target.value);
                  handleError('caseStatus', '');
                }}
                err={errors.caseStatus || ''}
              />
            </>
          )}
        </div>

        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 info-card my-3">
          <h6 className="fw-light">Case Category</h6>
          {!onEdit ? (
            <h5 className="mb-0 fw-semi-bold capitalize">
              {(caseData || {}).caseType || ''}
            </h5>
          ) : (
            <>
              <Select
                placeholder={'select a case category'}
                value={caseType}
                options={CASE_TYPES}
                onChange={(e) => {
                  setCaseType(e.target.value);
                  handleError('caseType', '');
                }}
                err={errors.caseType || ''}
              />
            </>
          )}
        </div>

        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 info-card my-3">
          <h6 className="fw-light">Case Type</h6>
          {!onEdit ? (
            <h5 className="mb-0 fw-semi-bold capitalize">
              {(caseData || {}).caseSubType || ''}
            </h5>
          ) : (
            <>
              <Select
                placeholder={'select a case category'}
                value={caseSubType}
                options={CASE_SUB_TYPES}
                onChange={(e) => {
                  setCaseSubType(e.target.value);
                  handleError('caseSubType', '');
                }}
                err={errors.caseSubType || ''}
              />
            </>
          )}
        </div>

        <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 info-card my-3">
          <h6 className="fw-light">Opened At</h6>
          {!onEdit ? (
            <h5 className="mb-0 fw-semi-bold">
              {caseData && caseData.openedAt && caseData.openedAt.length
                ? dateFormat(new Date((caseData || {}).openedAt))
                : ''}
            </h5>
          ) : (
            <>
              <Input
                placeholder={'enter case opened at date'}
                type={'date'}
                value={openedAt}
                onChange={(e) => {
                  setOpenedAt(e.target.value);
                  handleError('openedAt', '');
                }}
                err={errors.openedAt || ''}
              />
            </>
          )}
        </div>

        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 info-card my-3">
          <h6 className="fw-light">Company Name</h6>
          {!onEdit ? (
            <h5 className="mb-0 fw-semi-bold d-flex justify-content-start align-items-center">
              {(caseData || {}).companyName || ''}
              {caseCompanyNum &&
              caseCompanyId &&
              caseCompanyId &&
              caseData &&
              caseCompanyId !== caseData.id ? (
                <small
                  onClick={() => goToCase(caseCompanyId)}
                  className="badge px-1 fw-normal mx-1 capitalize bg-light-dark text-white ms-2 cursor-pointer hover-bg-dark"
                >
                  {caseCompanyNum}
                  <i className="bi bi-box-arrow-up-right ms-1"></i>
                </small>
              ) : (
                <React.Fragment />
              )}
            </h5>
          ) : (
            <>
              {caseType === 'company' || caseType === 'individual' ? (
                <Input
                  disabled={caseType === 'individual'}
                  placeholder={"enter company or petitioner's name"}
                  type={'text'}
                  autoCapitalize={'words'}
                  autoComplete={'off'}
                  value={companyName}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      e.target.value.length &&
                      errors.companyName &&
                      errors.companyName.length
                    ) {
                      handleError('companyName', '');
                    }
                    setCompanyName(e.target.value);
                    setCompanyNameForSearch(e.target.value);
                    handleError('companyName', '');
                  }}
                  err={errors.companyName || ''}
                />
              ) : caseType === 'beneficiary' ? (
                <Select
                  placeholder={'select a company'}
                  options={companies}
                  value={companyId}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      e.target.value.length &&
                      errors.companyId &&
                      errors.companyId.length
                    ) {
                      handleError('companyId', '');
                    }
                    setCompanyId(e.target.value);
                    setCompanyNameForSearch(
                      (
                        companies.filter(
                          (cm) => cm.value === e.target.value
                        )[0] || {}
                      ).label || ''
                    );
                  }}
                  err={errors.companyId || ''}
                />
              ) : (
                <React.Fragment />
              )}
            </>
          )}
        </div>

        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 info-card my-3">
          <h6 className="fw-light">Paralegal</h6>
          {!onEdit ? (
            <h5 className="mb-0 fw-semi-bold">
              {caseData.paralegal && getUserName(caseData.paralegal)}
            </h5>
          ) : (
            <>
              <Select
                placeholder={'select a paralegal'}
                value={paralegal}
                options={employees}
                onChange={(e) => {
                  setParalegal(e.target.value);
                  handleError('paralegal', '');
                }}
                err={errors.paralegal || ''}
              />
            </>
          )}
        </div>

        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 info-card my-3">
          <h6 className="fw-light">Counsel</h6>
          {!onEdit ? (
            <h5 className="mb-0 fw-semi-bold">
              {caseData.counsel && getUserName(caseData.counsel)}
            </h5>
          ) : (
            <>
              <Select
                placeholder={'select a counsel'}
                value={counsel}
                options={employees}
                onChange={(e) => {
                  setCounsel(e.target.value);
                  handleError('counsel', '');
                }}
                err={errors.counsel || ''}
              />
            </>
          )}
        </div>
      </div>

      <hr />

      <div className="row d-flex justify-content-start align-items-start my-3">
        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 info-card my-3">
          <h6 className="fw-light">Primary Contact</h6>
          {!onEdit ? (
            <>
              <h5 className="mb-0 fw-semi-bold">
                {((caseData || {}).primaryContact || {}).fullName}{' '}
                <small className="fw-light text-muted">
                  {' '}
                  {((caseData || {}).primaryContact || {}).alien || ''}
                </small>
              </h5>
              <small className="text-muted capitalize">
                {((caseData || {}).primaryContact || {}).cisStatus}
              </small>
            </>
          ) : (
            <>
              <Input
                placeholder={'enter their full name'}
                type={'text'}
                value={primaryFullName}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.primaryFullName &&
                    errors.primaryFullName.length
                  ) {
                    handleError('primaryFullName', '');
                  }
                  setPrimaryFullName(e.target.value);
                }}
                err={errors.primaryFullName || ''}
              />
              <Input
                placeholder={'enter their alien id'}
                type={'text'}
                value={primaryAlien}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.primaryAlien &&
                    errors.primaryAlien.length
                  ) {
                    handleError('primaryAlien', '');
                  }
                  setPrimaryAlien(e.target.value.toUpperCase());
                }}
                err={errors.primaryAlien || ''}
              />
              <Select
                placeholder={'select a CIS status'}
                options={CIS_TYPES}
                value={primaryCisStatus}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.primaryCisStatus &&
                    errors.primaryCisStatus.length
                  ) {
                    handleError('primaryCisStatus', '');
                  }
                  setPrimaryCisStatus(e.target.value);
                }}
                err={errors.primaryCisStatus || ''}
              />
            </>
          )}
        </div>

        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 info-card my-3">
          <h6 className="fw-light">Secondary Contact</h6>
          {!onEdit ? (
            <>
              <h5 className="mb-0 fw-semi-bold">
                {((caseData || {}).secondaryContact || {}).fullName}{' '}
                <small className="fw-light text-muted">
                  {' '}
                  {((caseData || {}).secondaryContact || {}).alien || ''}
                </small>
              </h5>
              <small className="text-muted capitalize">
                {((caseData || {}).secondaryContact || {}).cisStatus}
              </small>
            </>
          ) : (
            <>
              <Input
                placeholder={'enter their full name'}
                type={'text'}
                value={secFullName}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.secFullName &&
                    errors.secFullName.length
                  ) {
                    handleError('secFullName', '');
                  }
                  setSecFullName(e.target.value);
                }}
                err={errors.secFullName || ''}
              />
              <Input
                placeholder={'enter their alien id'}
                type={'text'}
                value={secAlien}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.secAlien &&
                    errors.secAlien.length
                  ) {
                    handleError('secAlien', '');
                  }
                  setSecAlien(e.target.value.toUpperCase());
                }}
                err={errors.secAlien || ''}
              />

              <Select
                placeholder={'select a CIS status'}
                options={CIS_TYPES}
                value={secCisStatus}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.secCisStatus &&
                    errors.secCisStatus.length
                  ) {
                    handleError('secCisStatus', '');
                  }
                  setSecCisStatus(e.target.value);
                }}
                err={errors.secCisStatus || ''}
              />
            </>
          )}
        </div>

        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 info-card my-3">
          <h6 className="fw-light">Address</h6>
          <div className="d-flex flex-column">
            {!onEdit ? (
              <small className="mb-0 fw-semi-bold">
                {Object.keys((caseData || {}).address || {}).length ? (
                  Object.keys(caseData.address).map((key, i) => (
                    <h5 className="mb-0 fw-semi-bold d-inline" key={key}>
                      {caseData.address[key]}
                      {i === Object.keys(caseData.address).length - 1
                        ? ''
                        : ', '}
                    </h5>
                  ))
                ) : (
                  <React.Fragment />
                )}
              </small>
            ) : (
              <>
                <Input
                  placeholder={'enter address line 1'}
                  type={'text'}
                  value={line1}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      e.target.value.length &&
                      errors.line1 &&
                      errors.line1.length
                    ) {
                      handleError('line1', '');
                    }
                    setLine1(e.target.value);
                  }}
                  err={errors.line1 || ''}
                />
                <Input
                  placeholder={'enter address line 2'}
                  type={'text'}
                  value={line2}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      e.target.value.length &&
                      errors.line2 &&
                      errors.line2.length
                    ) {
                      handleError('line2', '');
                    }
                    setLine2(e.target.value);
                  }}
                  err={errors.line2 || ''}
                />
                <Input
                  placeholder={'enter address city'}
                  type={'text'}
                  value={city}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      e.target.value.length &&
                      errors.city &&
                      errors.city.length
                    ) {
                      handleError('city', '');
                    }
                    setCity(e.target.value);
                  }}
                  err={errors.city || ''}
                />
                <Input
                  placeholder={'enter address state'}
                  type={'text'}
                  value={stateRegion}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      e.target.value.length &&
                      errors.stateRegion &&
                      errors.stateRegion.length
                    ) {
                      handleError('stateRegion', '');
                    }
                    setStateRegion(e.target.value);
                  }}
                  err={errors.stateRegion || ''}
                />
                <Input
                  placeholder={'enter address zipcode'}
                  type={'text'}
                  value={zipCode}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      e.target.value.length &&
                      errors.zipCode &&
                      errors.zipCode.length
                    ) {
                      handleError('zipCode', '');
                    }
                    setZipCode(e.target.value);
                  }}
                  err={errors.zipCode || ''}
                />
                <Input
                  placeholder={'enter country / nationality'}
                  type={'text'}
                  value={country}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      e.target.value.length &&
                      errors.country &&
                      errors.country.length
                    ) {
                      handleError('country', '');
                    }
                    setCountry(e.target.value);
                  }}
                  err={errors.country || ''}
                />
              </>
            )}
          </div>
        </div>

        <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 info-card my-3">
          <h6 className="fw-light">Emails</h6>

          {!onEdit ? (
            <div className="d-flex flex-wrap">
              {caseData && caseData.emails && caseData.emails.length ? (
                caseData.emails.map((email, i) => (
                  <small
                    key={i}
                    className="badge rounded text-and-border-blue px-1 fw-normal me-2 mb-2 size15"
                  >
                    {email}
                  </small>
                ))
              ) : (
                <React.Fragment />
              )}
            </div>
          ) : (
            <>
              {emails && emails.length ? (
                <div className="col-xl-8 col-lg-8 col-md-6 col-sm-12 col-xs-12 col-12 my-3">
                  <label className="form-label d-block">
                    Emails you've added
                  </label>
                  {emails.map((email, i) => (
                    <small
                      key={i}
                      className="badge bg-white text-dark size15 fw-normal rounded shadow-sm me-2 mb-1 px-1"
                    >
                      {email}
                      <i
                        onClick={() => removeEmail(i)}
                        className="bi bi-x-circle-fill ms-1"
                      ></i>
                    </small>
                  ))}
                </div>
              ) : (
                <React.Fragment />
              )}
              <Input
                placeholder={'enter email address'}
                type={'text'}
                value={email}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.email &&
                    errors.email.length
                  ) {
                    handleError('email', '');
                  }
                  setEmail(e.target.value);
                }}
                err={errors && errors.email}
              />
              <button
                className="btn btn-sm btn-outline-primary mx-3"
                onClick={addEmailToList}
              >
                Add to list
              </button>
            </>
          )}
        </div>

        <div className="col-xl-8 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 info-card my-3 d-flex flex-column justify-content-start align-items-start">
          <h6 className="fw-light">Phones</h6>

          {!onEdit ? (
            <div className="d-flex flex-wrap">
              {caseData && caseData.phones && caseData.phones.length ? (
                caseData.phones.map((phone, i) => (
                  <div
                    key={i}
                    className="rounded text-and-border-blue p-2 fw-normal me-2 mb-2 max-width-10rem"
                  >
                    {phone.phoneNum}{' '}
                    {phone.type ? (
                      <span className="fw-normal size12 text-dark uppercase">
                        {phone.type}
                      </span>
                    ) : (
                      <React.Fragment />
                    )}
                    {phone.notes ? (
                      <p className="text-left size14pt5 fw-light text-dark mt-1 mb-0">
                        {phone.notes}
                      </p>
                    ) : (
                      <React.Fragment />
                    )}
                  </div>
                ))
              ) : (
                <React.Fragment />
              )}
            </div>
          ) : (
            <>
              {phones && phones.length ? (
                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 my-3 p-2">
                    <label className="form-label d-block">
                      Phones you've added
                    </label>
                    <div className="d-flex flex-wrap">
                      {phones.map((phone, i) => (
                        <div
                          key={i}
                          className="size15 fw-normal rounded shadow-sm bg-white mb-2 px-1 py-1 max-width-10rem me-1 position-relative"
                        >
                          <div className="d-flex flex-column justify-content-center align-items-start overflow-hidden">
                            <p className="fw-semi-bold text-dark size15 mb-1">
                              {phone.type}
                            </p>
                            <p className="mb-2">{phone.phoneNum}</p>
                            <p className="fw-light text-muted size14 mb-1">
                              {phone.notes || ''}
                            </p>
                          </div>
                          <i
                            onClick={() => removePhone(i)}
                            className="bi bi-x-circle-fill ms-1 position-absolute top-0 right-0 me-1"
                          ></i>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <React.Fragment />
              )}

              <div className="row">
                <div className="col-8">
                  <Input
                    placeholder={'enter phone number'}
                    type={'tel'}
                    value={phoneNum}
                    onChange={(e) => {
                      if (
                        e.target.value &&
                        e.target.value.length &&
                        errors.phoneNum &&
                        errors.phoneNum.length
                      ) {
                        handleError('phoneNum', '');
                      }
                      setPhoneNum(e.target.value);
                    }}
                    err={errors && errors.phoneNum}
                  />
                </div>
                <div className="col-4">
                  <Select
                    placeholder={'select a type'}
                    options={PHONE_TYPES}
                    value={phoneType}
                    onChange={(e) => {
                      if (
                        e.target.value &&
                        e.target.value.length &&
                        errors.phoneType &&
                        errors.phoneType.length
                      ) {
                        handleError('phoneType', '');
                      }
                      setPhoneType(e.target.value);
                    }}
                    err={errors.phoneType || ''}
                  />
                </div>
                <div className="col-12">
                  <TextArea
                    placeholder={'add notes'}
                    value={phoneNotes}
                    onChange={(e) => {
                      if (
                        e.target.value &&
                        e.target.value.length &&
                        errors.phoneNotes &&
                        errors.phoneNotes.length
                      ) {
                        handleError('phoneNotes', '');
                      }
                      setPhoneNotes(e.target.value);
                    }}
                    err={errors && errors.phoneNotes}
                  />
                </div>
              </div>

              <button
                className="btn btn-sm btn-outline-primary mx-3"
                onClick={addPhoneInfoToList}
              >
                Add to list
              </button>
            </>
          )}
        </div>

        {!onEdit ? (
          <>
            {caseData.contractNotes ? (
              <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12 info-card my-3">
                <h6 className="fw-light">Case Notes</h6>
                <p className="mb-0 fw-semi-bold size16">
                  {(caseData || {}).contractNotes || ''}
                </p>
              </div>
            ) : (
              <React.Fragment />
            )}
          </>
        ) : (
          <>
            <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-xs-12 col-12 info-card my-3">
              <h6 className="fw-light">Case Notes</h6>
              <TextArea
                placeholder={'add case notes'}
                value={contractNotes}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.contractNotes &&
                    errors.contractNotes.length
                  ) {
                    handleError('contractNotes', '');
                  }
                  setContractNotes(e.target.value);
                }}
                err={errors && errors.contractNotes}
              />
            </div>
          </>
        )}

        <hr />

        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 info-card my-3">
          <h6 className="fw-light">Office</h6>
          {!onEdit ? (
            <h5 className="mb-0 fw-semi-bold capitalize">
              {(caseData || {}).office || ''}
            </h5>
          ) : (
            <>
              <Select
                placeholder={'select an office'}
                value={office}
                options={OFFICES}
                onChange={(e) => {
                  setOffice(e.target.value);
                  handleError('office', '');
                }}
                err={errors.office || ''}
              />
            </>
          )}
        </div>

        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 info-card my-3">
          <h6 className="fw-light">Activate H1B</h6>
          {!onEdit ? (
            <h5 className="mb-0 fw-semi-bold">
              {caseData.activateH1B ? 'Yes' : 'No'}
            </h5>
          ) : (
            <>
              <Checkbox
                type="checkbox"
                checked={activateH1B}
                onClick={() => {
                  setActivateH1B((current) => !current);
                }}
                label={'H1B Module'}
                namex="activateH1B"
                idx="activateH1BYes"
              />
            </>
          )}
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12 info-card my-3">
          <h6 className="fw-light">Activate PERM</h6>
          {!onEdit ? (
            <h5 className="mb-0 fw-semi-bold">
              {caseData.activatePerm ? 'Yes' : 'No'}
            </h5>
          ) : (
            <>
              <Checkbox
                type="checkbox"
                checked={activatePerm}
                onClick={() => {
                  setActivatePerm((current) => !current);
                }}
                label={'PERM Module'}
                namex="activatePerm"
                idx="activatePermYes"
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClientInfo;
