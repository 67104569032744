import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { myGreenSuperLight } from '../../constants/colors';
import {
  TRANSFER_TYPES,
  DEPOSIT_TYPES,
  TICKLER_TYPES,
  CASE_SUB_TYPES,
} from '../../constants/constants';
import { getMahDateCustom, getUserName } from '../../utils/userUtils';
import Checkbox from '../common/Checkbox';
import Input from '../common/Input';
import MultiSelect from '../common/MultiSelect';
import MyMessage from '../common/MyMessage';
import Select from '../common/Select';
import TextArea from '../common/TextArea';
import Loading from '../Loading';

const EditIoltaModal = (props) => {
  const {
    caseData,
    visible,
    onClose,
    user,
    employees,
    reinitialize,
    type,
    ioltaId,
  } = props;

  const [editing, setEditing] = useState(false);
  const [amount, setAmount] = useState(0);
  const [subType, setSubType] = useState('');
  const [caseType, setCaseType] = useState('H-1B CAP');
  const [desc, setDesc] = useState('');
  const [ioltaDate, setIoltaDate] = useState('');
  const [sureToDelete, setSureToDelete] = useState(false);
  const [showSureSelect, setShowSureSelect] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initIoltaForEdit();
  }, [visible, ioltaId]);

  const initIoltaForEdit = async () => {
    try {
      if (!visible || !ioltaId || !ioltaId.length) return;
      setLoading(true);
      const configService = require('../../store/config/service');
      const res = await configService.default.getIolta(ioltaId);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log(res.result);
        // add data to inputs.
        setAmount(res.result.amount);
        setSubType(res.result.subType);
        setCaseType(res.result.caseType || 'H-1B CAP');
        setDesc(res.result.desc);
        setIoltaDate(getMahDateCustom(res.result.ioltaDate));
        setErrors({});
        setMessage({});
        setLoading(false);
      }
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const updateIolta = async () => {
    if (!validateIolta()) return;
    const configService = require('../../store/config/service');

    let body = {
      subType,
      caseType,
      amount: parseFloat(amount),
      amountString: `${amount}`,
      ioltaDate: new Date(ioltaDate),
      ioltaDateStamp: +new Date(ioltaDate),
      desc,
    };

    // console.log('body - ', body);

    const res = await configService.default.updateIolta(ioltaId, body);
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const validateIolta = () => {
    let flag = true;
    if (!editing) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'Click on edit to change the data',
      });
    }
    if (!subType || !subType.length) {
      flag = false;
      handleError('subType', 'select a valid tickler subType');
    }
    if (!amount || isNaN(amount)) {
      flag = false;
      handleError('amount', 'add a valid amount');
    }
    if (!desc || !desc.length) {
      flag = false;
      handleError('desc', 'add a description');
    }
    if (!ioltaDate || !ioltaDate.length) {
      flag = false;
      handleError('ioltaDate', 'select a date');
    }
    if (!type || !type.length) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (!ioltaId || !ioltaId.length) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (!user || !user.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (!caseData || !caseData.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    return flag;
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  const deleteIolta = async () => {
    if (!ioltaId || !ioltaId.length) return;
    if (!user || !user.userType || user.userType !== 'Admin')
      return setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    if (!showSureSelect) return setShowSureSelect(true);
    if (showSureSelect && !sureToDelete)
      return handleError('sureToDelete', 'you have to check this');

    const configService = require('../../store/config/service');
    const res = await configService.default.deleteIolta(ioltaId);

    if (!res) {
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (res.error) {
      setMessage({
        type: 'warning',
        msg: res.error,
      });
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const doOnClose = () => {
    resetBlankModal();
    onClose();
  };

  const resetBlankModal = () => {
    setEditing(false);
    setAmount(0);
    setIoltaDate('');
    setSubType(
      type === 'deposit' ? 'cash' : type === 'transfer' ? 'cis-fee' : ''
    );
    setCaseType('H-1B CAP');
    setDesc('');
    setShowSureSelect(false);
    setSureToDelete(false);
    setErrors({});
    setMessage({});
    setLoading(false);
  };

  return visible ? (
    <div className="my-modal-container">
      <div className="my-modal-overlay" onClick={doOnClose}></div>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable my-modal z-index-9">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <div>
              <p className="modal-title mb-0">
                {showSureSelect ? 'Deleting' : editing ? 'Editing' : 'Viewing'}
              </p>
              <h3 className="modal-title fw-bold capitalize">{`${type}`}</h3>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={doOnClose}
            ></button>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="modal-body">
              {showSureSelect ? (
                <>
                  <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                    <div>
                      <small className="badge rounded-pill bg-warning text-dark fw-normal mb-1">
                        Important
                      </small>
                      <p className="fw-light text-muted italic size14pt5 px-2">
                        You are about to delete this{' '}
                        {type === 'deposit' ? 'Deposit' : 'Transfer'}! This
                        action is irreversible.
                      </p>
                      <div className="row g-md-2">
                        <div className="col-12 my-3 mx-auto px-3">
                          <label htmlFor="splittingWith" className="form-label">
                            Are You Sure?
                          </label>

                          <div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="areWeSure"
                                id="areWeSureYes"
                                checked={sureToDelete}
                                onClick={() => {
                                  if (!sureToDelete)
                                    handleError('sureToDelete', '');
                                  setSureToDelete(!sureToDelete);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="areWeSureYes"
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                          {errors && errors.sureToDelete ? (
                            <div className="mt-1">
                              <small className="text-red size14">
                                {errors && errors.sureToDelete}
                              </small>
                            </div>
                          ) : (
                            <React.Fragment />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {editing ? (
                    <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                      <div className="mb-2">
                        <p className="text-muted fs-italic fw-light size15">
                          Fill in the details below to add a new {type}!
                        </p>

                        <div className="row d-flex flex-wrap justify-content-start align-items-start">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <Select
                              label={`${type} Type`}
                              placeholder={`select a ${type} type`}
                              value={subType}
                              options={
                                type === 'deposit'
                                  ? DEPOSIT_TYPES
                                  : type === 'transfer'
                                  ? TRANSFER_TYPES
                                  : []
                              }
                              onChange={(e) => {
                                setSubType(e.target.value);
                                handleError('subType', '');
                              }}
                              err={errors.subType || ''}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <Select
                              label={`Case Type`}
                              placeholder={`select a case type`}
                              value={caseType}
                              options={CASE_SUB_TYPES}
                              onChange={(e) => {
                                setCaseType(e.target.value);
                                handleError('caseType', '');
                              }}
                              err={errors.caseType || ''}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <Input
                              label={'Amount'}
                              placeholder={'enter amount'}
                              type={'number'}
                              value={amount}
                              onChange={(e) => {
                                if (
                                  e.target.value &&
                                  e.target.value.length &&
                                  errors.amount &&
                                  errors.amount.length
                                ) {
                                  handleError('amount', '');
                                }
                                setAmount(e.target.value);
                              }}
                              err={errors && errors.amount}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                            <Input
                              label={`${
                                type === 'deposit' ? 'Deposit' : 'Transfer'
                              } Date`}
                              placeholder={'enter date'}
                              type={'date'}
                              value={ioltaDate}
                              onChange={(e) => {
                                if (
                                  e.target.value &&
                                  e.target.value.length &&
                                  errors.ioltaDate &&
                                  errors.ioltaDate.length
                                ) {
                                  handleError('ioltaDate', '');
                                }
                                setIoltaDate(e.target.value);
                              }}
                              err={errors && errors.ioltaDate}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <TextArea
                              label={'Description'}
                              placeholder={'add a description'}
                              value={desc}
                              onChange={(e) => {
                                if (
                                  e.target.value &&
                                  e.target.value.length &&
                                  errors.desc &&
                                  errors.desc.length
                                ) {
                                  handleError('desc', '');
                                }
                                setDesc(e.target.value);
                              }}
                              err={errors && errors.desc}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
                    </div>
                  ) : (
                    <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                      <div className="mb-2">
                        <p className="text-muted fs-italic fw-light size15">
                          Click on Edit to edit data of this {type}!
                        </p>

                        <div className="row d-flex flex-wrap justify-content-start align-items-start">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <Select
                              disabled
                              label={`${type} Type`}
                              placeholder={`select a ${type} type`}
                              value={subType}
                              options={
                                type === 'deposit'
                                  ? DEPOSIT_TYPES
                                  : type === 'transfer'
                                  ? TRANSFER_TYPES
                                  : []
                              }
                              onChange={(e) => {
                                setSubType(e.target.value);
                                handleError('subType', '');
                              }}
                              err={errors.subType || ''}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <Select
                              disabled
                              label={`Case Type`}
                              placeholder={`select a case type`}
                              value={caseType}
                              options={CASE_SUB_TYPES}
                              onChange={(e) => {
                                setCaseType(e.target.value);
                                handleError('caseType', '');
                              }}
                              err={errors.caseType || ''}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <Input
                              disabled
                              label={'Amount'}
                              placeholder={'enter amount'}
                              type={'number'}
                              value={amount}
                              onChange={(e) => {
                                if (
                                  e.target.value &&
                                  e.target.value.length &&
                                  errors.amount &&
                                  errors.amount.length
                                ) {
                                  handleError('amount', '');
                                }
                                setAmount(e.target.value);
                              }}
                              err={errors && errors.amount}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                            <Input
                              disabled
                              label={`${
                                type === 'deposit' ? 'Deposit' : 'Transfer'
                              } Date`}
                              placeholder={'enter date'}
                              type={'date'}
                              value={ioltaDate}
                              onChange={(e) => {
                                if (
                                  e.target.value &&
                                  e.target.value.length &&
                                  errors.ioltaDate &&
                                  errors.ioltaDate.length
                                ) {
                                  handleError('ioltaDate', '');
                                }
                                setIoltaDate(e.target.value);
                              }}
                              err={errors && errors.ioltaDate}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <TextArea
                              disabled
                              label={'Description'}
                              placeholder={'add a description'}
                              value={desc}
                              onChange={(e) => {
                                if (
                                  e.target.value &&
                                  e.target.value.length &&
                                  errors.desc &&
                                  errors.desc.length
                                ) {
                                  handleError('desc', '');
                                }
                                setDesc(e.target.value);
                              }}
                              err={errors && errors.desc}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          <div className="px-3">
            <MyMessage
              visible={!!message}
              message={message}
              onClose={() => {
                setMessage(undefined);
              }}
            />
          </div>
          <div
            className="modal-footer d-flex justify-content-between align-items-center"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <div>
              {user &&
              user.userType &&
              user.userType === 'Admin' &&
              !editing &&
              !showSureSelect ? (
                <span
                  className="btn btn-danger m-0 py-0 px-1 cursor-pointer"
                  onClick={deleteIolta}
                >
                  <i className="bi bi-trash md-icon"></i>
                </span>
              ) : (
                <React.Fragment />
              )}
            </div>

            <>
              {!showSureSelect ? (
                <>
                  <div className="d-flex flex-one justify-content-end align-items-center">
                    {editing ? (
                      <button
                        type="button"
                        className="btn btn-outline ms-2"
                        onClick={() => setEditing(false)}
                      >
                        Cancel
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-outline ms-2"
                        onClick={doOnClose}
                      >
                        Close
                      </button>
                    )}
                  </div>

                  {editing ? (
                    <button
                      type="button"
                      className="btn btn-primary ms-2 capitalize"
                      onClick={updateIolta}
                    >
                      Update {type}
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary ms-2"
                      onClick={() => {
                        setEditing(true);
                      }}
                    >
                      Edit
                    </button>
                  )}
                </>
              ) : (
                <div className="d-flex align-tems-center justify-content-end">
                  <button
                    type="button"
                    className="btn btn-outline ms-2"
                    onClick={() => {
                      setShowSureSelect(false);
                      setSureToDelete(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger ms-2 capitalize"
                    onClick={deleteIolta}
                  >
                    Delete {type}
                  </button>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default EditIoltaModal;
