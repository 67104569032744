/* eslint-disable import/no-named-as-default */
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import NotFoundPage from './NotFoundPage';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AppDrawer from './AppDrawer';
import TopBar from './TopBar';
import { routeList, TOKEN_NOT_FOUND } from '../constants/constants';
import Login from './containers/Login';
import Dashboard from './containers/Dashboard';
import { login } from '../actions/authActions';
import Loading from './Loading';
import { getAuthToken } from '../services/StorageService';
import CreateCase from './containers/CreateCase';
import CreateConsultation from './containers/CreateConsultation';
import CreateAppointment from './containers/CreateAppointment';
import Consultations from './containers/Consultations';
import Cases from './containers/Cases';
import Appointments from './containers/Appointments';
import ViewCase from './containers/ViewCase';
import ViewConsult from './containers/ViewConsult';
import Reports from './containers/Reports';
import Employees from './containers/Employees';
import CreateUser from './containers/CreateUser';

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

const App = (props) => {
  const { user, isLoggedIn, login, stickyDrawerVisible } = props;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    initialise();
    return () => {
      // console.log('Cleanup fn');
    };
  }, []);

  const initialise = async () => {
    try {
      setLoading(true);
      const token = getAuthToken();
      if (token && token !== TOKEN_NOT_FOUND) {
        // console.log('Token exists', token);
        let startTime = new Date().getTime();
        const configService = require('../store/config/service');
        let endTime = new Date().getTime();
        const res = await configService.default.login({ token });
        if (!res) {
          throw new Error('No Response from Server');
        }
        if (res.error) {
          throw new Error(res.error);
        }
        if (res.result) {
          // console.log('result is - ', res.result);
          login({ user: res.result, isLoggedIn: true, token: token });
          // console.log(
          //   'time tooke to get configService - ',
          //   endTime - startTime
          // );
        }
      } else {
        // console.log('No Token found, redirecting to login');
      }
    } catch (e) {
      throw new Error(e);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <Loading />;

  return (
    <>
      {isLoggedIn && user ? (
        <div className="row g-0">
          <div
            className={`col-xl-2 col-lg-3 col-md-3 col-sm-3 col-xs-3 col-2 ${
              !stickyDrawerVisible ? 'd-none' : 'd-none d-md-block'
            }`}
          >
            <AppDrawer items={routeList} />
          </div>
          <div className={'col-sm-8 col-xs-9 col-10 d-md-none'}>
            <AppDrawer sticky={true} items={routeList} />
          </div>
          <div
            className={`col-xl-10 col-lg-9 col-md-9 col-sm-9 col-xs-9 col-10 ${
              !stickyDrawerVisible
                ? 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12'
                : 'col-xl-10 col-lg-9 col-md-9 col-sm-12 col-xs-12 col-12'
            }`}
          >
            <TopBar
              bg={'bg-my-dark-light'}
              currentRoute={useLocation().pathname}
            />
            <div className="app-cont p-2 p-lg-4 bg-white">
              <div className="container">
                <Switch>
                  <Route exact path="/">
                    {isLoggedIn ? <Redirect to="/dashboard" /> : <Login />}
                  </Route>
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/create-case" component={CreateCase} />
                  <Route exact path="/cases" component={Cases} />
                  <Route
                    exact
                    path="/create-consultation"
                    component={CreateConsultation}
                  />
                  <Route
                    exact
                    path="/consultations"
                    component={Consultations}
                  />
                  <Route exact path="/create-user" component={CreateUser} />
                  <Route exact path="/users" component={Employees} />
                  <Route
                    exact
                    path="/create-appointment"
                    component={CreateAppointment}
                  />
                  <Route exact path="/appointments" component={Appointments} />
                  <Route exact path="/cases/:id" component={ViewCase} />
                  <Route exact path="/reports" component={Reports} />
                  <Route
                    exact
                    path="/consultations/:id"
                    component={ViewConsult}
                  />
                  {/* <Route exact path="/profile" component={Profile} /> */}

                  <Route component={NotFoundPage} />
                </Switch>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Login />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    auth: { user, isLoggedIn } = {},
    stickyDrawer: { stickyDrawerVisible } = {},
  } = state;
  return {
    user,
    isLoggedIn,
    stickyDrawerVisible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload) => {
      dispatch(login(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
