import React, { useEffect, useState } from 'react';
import { getUserName } from '../utils/userUtils';
import FilingIoltaList from './FilingIoltaList';
import AddFilingIoltaModal from './modals/AddFilingIoltaModal';
import EditFilingIoltaModal from './modals/EditFilingIoltaModal';

const FilingIoltas = (props) => {
  const { caseData, user } = props;
  const [employees, setEmployees] = useState([]);
  const [showAddFilingIoltaModal, setShowAddFilingIoltaModal] = useState(undefined);
  const [showEditFilingIoltaModal, setShowEditFilingIoltaModal] = useState(undefined);
  const [reinitializing, setReinitializing] = useState(false);

  useEffect(() => {
    initEmployees();
  }, []);

  const initEmployees = async () => {
    if (!user || !user.userType) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getUsers(); // change to employees only
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      setEmployees(
        res.result.map((emp) => {
          return {
            label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
            value: emp.id,
          };
        })
      );
    }
  };

  const openAddFilingIoltaModal = (type) => {
    setShowAddFilingIoltaModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowAddFilingIoltaModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
      type: type ? type : '',
    });
  };
  
  const openEditFilingIoltaModal = (type, ioltaId) => {
    setShowEditFilingIoltaModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowEditFilingIoltaModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
      type: type ? type : '',
      ioltaId: ioltaId ? ioltaId : '',
    });
  };

  const reinitialize = (bool = true) => {
    setReinitializing(bool);
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mb-0">IOLTA (Filing Fees)</h4>
        <div className="flex-one"></div>
        <div>
          <button
            className="btn btn-sm btn-outline-success shadow-sm ms-2"
            style={{ width: 'max-content' }}
            onClick={() => openAddFilingIoltaModal('deposit')}
          >
            Add New Deposit<i className="bi bi-plus ms-1"></i>
          </button>
          <button
            className="btn btn-sm btn-outline-success shadow-sm ms-2"
            style={{ width: 'max-content' }}
            onClick={() => openAddFilingIoltaModal('transfer')}
          >
            Add New Transfer<i className="bi bi-plus ms-1"></i>
          </button>
        </div>
      </div>
      <div className="w-100">
        <FilingIoltaList
          caseId={(caseData || {}).id}
          caseData={caseData}
          user={user}
          reinitialize={reinitialize}
          reinitializing={reinitializing}
          openEditFilingIoltaModal={(type, ioltaId) => openEditFilingIoltaModal(type, ioltaId)}
        />

        <AddFilingIoltaModal
          visible={!!showAddFilingIoltaModal}
          caseData={showAddFilingIoltaModal && showAddFilingIoltaModal.caseData}
          onClose={showAddFilingIoltaModal && showAddFilingIoltaModal.onClose}
          user={showAddFilingIoltaModal && showAddFilingIoltaModal.user}
          employees={showAddFilingIoltaModal && showAddFilingIoltaModal.employees}
          type={showAddFilingIoltaModal && showAddFilingIoltaModal.type}
          reinitialize={reinitialize}
        />

        <EditFilingIoltaModal
          visible={!!showEditFilingIoltaModal}
          caseData={showEditFilingIoltaModal && showEditFilingIoltaModal.caseData}
          onClose={showEditFilingIoltaModal && showEditFilingIoltaModal.onClose}
          user={showEditFilingIoltaModal && showEditFilingIoltaModal.user}
          employees={showEditFilingIoltaModal && showEditFilingIoltaModal.employees}
          type={showEditFilingIoltaModal && showEditFilingIoltaModal.type}
          ioltaId={showEditFilingIoltaModal && showEditFilingIoltaModal.ioltaId}
          reinitialize={reinitialize}
        />
      </div>
    </div>
  );
};

export default FilingIoltas;
