import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { myGreenSuperLight } from '../../constants/colors';
import { CONTRACT_TYPES } from '../../constants/constants';
import { getUserName } from '../../utils/userUtils';
import Checkbox from '../common/Checkbox';
import Input from '../common/Input';
import MultiSelect from '../common/MultiSelect';
import MyMessage from '../common/MyMessage';
import Select from '../common/Select';
import Loading from '../Loading';
import TextArea from '../common/TextArea';

const EditContractModal = (props) => {
  const {
    caseData,
    visible,
    onClose,
    user,
    employees,
    reinitialize,
    contractId,
    onEdit = false,
  } = props;

  const [editing, setEditing] = useState(onEdit);
  const [totalAmount, setTotalAmount] = useState('');
  const [type, setType] = useState('n-400');
  const [retainerAmount, setRetainerAmount] = useState(0);
  const [retainerByName, setRetainerByName] = useState('');
  const [retainerBy, setRetainerBy] = useState([]);
  const [desc, setDesc] = useState('');
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initContractForEdit();
  }, [visible, contractId]);

  const initContractForEdit = async () => {
    try {
      if (!visible || !contractId || !contractId.length) return;
      setLoading(true);
      const configService = require('../../store/config/service');
      const res = await configService.default.getContract(contractId);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log(res.result);
        // add data to inputs.
        setTotalAmount(res.result.totalAmount);
        setType(res.result.type);
        setRetainerAmount(res.result.retainerAmount);
        setRetainerByName(res.result.retainerByName);
        setRetainerBy([
          {
            label: `${getUserName(res.result.retainerBy)} (${
              res.result.retainerBy.designation
            })`,
            value: res.result.retainerBy.id,
          },
        ]);
        setDesc(res.result.desc);
        setErrors({});
        setMessage({});
        setLoading(false);
      }
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const updateContract = async () => {
    if (!validateContract()) return;
    const configService = require('../../store/config/service');

    let body = {
      type,
      case: caseData.id,
      totalAmount,
      retainerAmount,
      retainerBy: retainerBy[0].value,
      retainerByName,
      createdBy: user.id,
      desc,
      createdByName: getUserName(user),
    };

    // console.log('body - ', body);

    const res = await configService.default.updateContract(contractId, body);
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const validateContract = () => {
    let flag = true;
    if (!editing) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'Click on edit to change the data',
      });
    }
    if (!type || !type.length) {
      flag = false;
      handleError('type', 'select a valid contract type');
    }
    if (!totalAmount || totalAmount < 1) {
      flag = false;
      handleError('totalAmount', 'select a valid contract total amount');
    }
    if (!retainerAmount || retainerAmount < 1) {
      flag = false;
      handleError('retainerAmount', 'select a valid contract retainer amount');
    }
    if (!retainerBy || !retainerBy.length) {
      flag = false;
      handleError('retainerBy', 'select a valid contract emlpoyee');
    }
    if (!retainerByName || !retainerByName.length) {
      flag = false;
      handleError('retainerBy', 'select a valid employee');
    }
    if (!desc || !desc.length) {
      flag = false;
      handleError('desc', 'select a add description');
    }
    if (!contractId || !contractId.length) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (!user || !user.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (!caseData || !caseData.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    return flag;
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  const doOnClose = () => {
    resetBlankModal();
    onClose();
  };

  const resetBlankModal = () => {
    setEditing(false);
    setTotalAmount('');
    setType('n-400');
    setRetainerAmount(0);
    setRetainerByName('');
    setRetainerBy([]);
    setDesc('');
    setErrors({});
    setMessage({});
    setLoading(false);
  };

  return visible ? (
    <div className="my-modal-container">
      <div className="my-modal-overlay" onClick={doOnClose}></div>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable my-modal z-index-9">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <div>
              <p className="modal-title mb-0">
                {editing ? 'Editing' : 'Viewing'}
              </p>
              <h3 className="modal-title fw-bold">{'Contract'}</h3>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={doOnClose}
            ></button>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="modal-body">
              {editing ? (
                <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                  <div className="mb-2">
                    <p className="text-muted fs-italic fw-light size15">
                      Change the details below and update this contract!
                    </p>

                    <div className="row d-flex flex-wrap justify-content-start align-items-start">
                      <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                        <Input
                          label={'Total Amount'}
                          placeholder={'enter total amount'}
                          type={'number'}
                          value={totalAmount}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              e.target.value.length &&
                              errors.totalAmount &&
                              errors.totalAmount.length
                            ) {
                              handleError('totalAmount', '');
                            }
                            setTotalAmount(e.target.value);
                          }}
                          err={errors && errors.totalAmount}
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                        <Input
                          label={'Retainer Amount'}
                          placeholder={'enter retainer amount'}
                          type={'number'}
                          value={retainerAmount}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              e.target.value.length &&
                              errors.retainerAmount &&
                              errors.retainerAmount.length
                            ) {
                              handleError('retainerAmount', '');
                            }
                            setRetainerAmount(e.target.value);
                          }}
                          err={errors && errors.retainerAmount}
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <Select
                          label={'Contract Type'}
                          placeholder={'select a contract type'}
                          value={type}
                          options={CONTRACT_TYPES}
                          onChange={(e) => {
                            setType(e.target.value);
                            handleError('type', '');
                          }}
                          err={errors.type || ''}
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <MultiSelect
                          label={'Retainer By'}
                          displayValue={'label'}
                          values={retainerBy}
                          options={employees}
                          onSelect={(e) => {
                            setRetainerBy(e);
                            // console.log(e);
                            if (e.length) {
                              setRetainerByName(e[e.length - 1].label);
                            }
                            handleError('retainerBy', '');
                          }}
                          onRemove={(e) => {
                            setRetainerBy(e);
                          }}
                          err={errors.retainerBy || ''}
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <TextArea
                          label={'Description'}
                          placeholder={'add a description'}
                          value={desc}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              e.target.value.length &&
                              errors.desc &&
                              errors.desc.length
                            ) {
                              handleError('desc', '');
                            }
                            setDesc(e.target.value);
                          }}
                          err={errors && errors.desc}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
                </div>
              ) : (
                <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                  <div className="mb-2">
                    <p className="text-muted fs-italic fw-light size15">
                      Click on Edit to edit the data!
                    </p>

                    <div className="row d-flex flex-wrap justify-content-start align-items-start">
                      <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                        <Input
                          disabled
                          label={'Total Amount'}
                          placeholder={'enter total amount'}
                          type={'number'}
                          value={totalAmount}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              e.target.value.length &&
                              errors.totalAmount &&
                              errors.totalAmount.length
                            ) {
                              handleError('totalAmount', '');
                            }
                            setTotalAmount(e.target.value);
                          }}
                          err={errors && errors.totalAmount}
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                        <Input
                          disabled
                          label={'Retainer Amount'}
                          placeholder={'enter retainer amount'}
                          type={'number'}
                          value={retainerAmount}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              e.target.value.length &&
                              errors.retainerAmount &&
                              errors.retainerAmount.length
                            ) {
                              handleError('retainerAmount', '');
                            }
                            setRetainerAmount(e.target.value);
                          }}
                          err={errors && errors.retainerAmount}
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <Select
                          disabled
                          label={'Contract Type'}
                          placeholder={'select a contract type'}
                          value={type}
                          options={CONTRACT_TYPES}
                          onChange={(e) => {
                            setType(e.target.value);
                            handleError('type', '');
                          }}
                          err={errors.type || ''}
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <MultiSelect
                          disabled
                          label={'Retainer By'}
                          displayValue={'label'}
                          values={retainerBy}
                          options={employees}
                          onSelect={(e) => {
                            setRetainerBy(e);
                            // console.log(e);
                            if (e.length) {
                              setRetainerByName(e[e.length - 1].label);
                            }
                            handleError('retainerBy', '');
                          }}
                          onRemove={(e) => {
                            setRetainerBy(e);
                          }}
                          err={errors.retainerBy || ''}
                        />
                      </div>

                      <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <TextArea
                          disabled
                          label={'Description'}
                          placeholder={'add a description'}
                          value={desc}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              e.target.value.length &&
                              errors.desc &&
                              errors.desc.length
                            ) {
                              handleError('desc', '');
                            }
                            setDesc(e.target.value);
                          }}
                          err={errors && errors.desc}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
                </div>
              )}
            </div>
          )}
          <div className="px-3">
            <MyMessage
              visible={!!message}
              message={message}
              onClose={() => {
                setMessage(undefined);
              }}
            />
          </div>
          <div
            className="modal-footer"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            {editing ? (
              <button
                type="button"
                className="btn btn-outline ms-2"
                onClick={() => setEditing(false)}
              >
                Cancel
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-outline ms-2"
                onClick={doOnClose}
              >
                Cancel
              </button>
            )}
            {editing ? (
              <button
                type="button"
                className="btn btn-primary ms-2"
                onClick={updateContract}
              >
                Update Contract
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary ms-2"
                onClick={() => {
                  setEditing(true);
                }}
              >
                Edit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default EditContractModal;
