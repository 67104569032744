import { formatDistance } from 'date-fns';
import format from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import {
  ACTIVE_TYPES_MAP,
  CASE_STATUSES,
  CASE_STATUS_MAP,
  CASE_SUB_TYPES,
  CASE_TYPES,
  CHARGE_TYPES,
  CHARGE_TYPES_LABEL_MAP,
  DEPOSIT_TYPES,
  DEPOSIT_TYPES_MAP,
  OFFICES,
  PAYMENT_IOLTA_TYPES_MAP,
} from '../constants/constants';
import {
  createBillingNotesMap,
  createCaseCostMap,
  createClientBalancesMap,
  createCollectionListMap,
  createCostsMap,
  createDailyRevenueByTypeMap,
  createDailyRevenueMap,
  createIoltaClientBalancesMap,
  createIoltaDailyRevenueByTypeMap,
  createIoltaDailyRevenueMap,
  createMonthlyRevenueByTypeMap,
  createMonthlyRevenueMap,
  createPercentagesMap,
  createTimesheetsMap,
  dateFormat,
  dateShortFormat,
  dateTimeFormat,
  getCaseStatus,
  getDurationFromMills,
  getDurationFromOpenCloseDates,
  getUserName,
  priceFormat,
} from '../utils/userUtils';
import Checkbox from './common/Checkbox';
import Input from './common/Input';
import Select from './common/Select';

const FinancialsReport = (props) => {
  const { user } = props;

  const [activeType, setActiveType] = useState('all');
  const [officeType, setOfficeType] = useState('all');
  const [cases, setCases] = useState([]);
  const [caseId, setCaseId] = useState('');
  const [companies, setCompanies] = useState([]);
  const [companyId, setCompanyId] = useState('');
  const [caseType, setCaseType] = useState('company');
  const [caseSubType, setCaseSubType] = useState('H-1B CAP');
  const [caseStatus, setCaseStatus] = useState('ACTIVE');
  const [office, setOffice] = useState('dallas');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [startStamp, setStartStamp] = useState(0);
  const [endStamp, setEndStamp] = useState(0);
  const [excludeZero, setExcludeZero] = useState(true);
  const [excludeZeroToOne, setExcludeZeroToOne] = useState(true);
  const [printToMail, setPrintToMail] = useState(false);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initInfo();
  }, []);

  const initInfo = async () => {
    try {
      setLoading(true);
      const configService = require('../store/config/service');
      let res = await configService.default.getAllCases();
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        setCases(
          res.result.map((cs) => {
            return {
              caseNumForSearch: cs.caseNumForSearch,
              label: cs.caseNumForSearch,
              value: cs.id,
            };
          })
        );
        setCaseId(res.result[0].id);
      }

      res = await configService.default.getCompanies();
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log({companies: res.result})
        setCompanies(
          res.result.map((cmp) => {
            return {
              label: cmp.companyName,
              value: cmp.id,
            };
          })
        );
        setCompanyId(res.result[0].id);
      }
      setLoading(false);
    } catch (e) {
      // console.log(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  const validateInfo = () => {
    let flag = true;
    if (!startDate || !startDate.length) {
      flag = false;
      handleError('startDate', 'select start date');
    }
    if (!endDate || !endDate.length) {
      flag = false;
      handleError('endDate', 'select end date');
    }
    if (!activeType || !activeType.length) {
      flag = false;
    }
    if (activeType === 'case') {
      if (!caseId || !caseId.length) {
        flag = false;
        handleError('caseId', 'select a case');
      }
    }
    if (activeType === 'company') {
      if (!companyId || !companyId.length) {
        flag = false;
        handleError('companyId', 'select a company');
      }
    }
    if (activeType === 'case_type') {
      if (!caseType || !caseType.length) {
        flag = false;
        handleError('caseType', 'select a case type');
      }
    }
    if (activeType === 'case_status') {
      if (!caseStatus || !caseStatus.length) {
        flag = false;
        handleError('caseStatus', 'select a case status');
      }
    }
    if (activeType === 'office') {
      if (!office || !office.length) {
        flag = false;
        handleError('office', 'select an office');
      }
    }
    return flag;
  };

  const runDailyRevenueReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let caseRes;
    let case_ids;
    let [ms, ds, ys] = dateShortFormat(new Date(startDate)).split('/');
    let [me, de, ye] = dateShortFormat(new Date(endDate)).split('/');
    let start = new Date(`${ys}-${ms}-${ds}`).setHours(0, 0, 0) - 86400000;
    let end = new Date(`${ye}-${me}-${de}`).setHours(23, 59, 59);
    if (activeType === 'all') {
      //get all payments
      res = await configService.default.getAllPayments(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all payments - ', res.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(
        companyId,
        officeType
      );
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPaymentsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'case') {
      // get payments with caseId
      res = await configService.default.getAllPaymentsByCase(
        caseId,
        start,
        end
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all payments by Case - ', res.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(
        caseType,
        officeType
      );
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPaymentsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'case_sub_type') {
      res = await configService.default.getAllPaymentsByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('Got payments for case_sub_type', res.result);
      }
      // get payments with case_sub_type
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(
        caseStatus,
        officeType
      );
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPaymentsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPaymentsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.error) {
      alert(res.error);
    }
    if (res.result) {
      const dateMap = createDailyRevenueByTypeMap(start, end, res.result);
      // console.log('Returned DateMap');
      openPrintDailyRevenueByTypeReport(activeType, dateMap, officeType);
    }
    // alert(
    //   `Running Daily Report! with data - ${JSON.stringify(
    //     {
    //       startDate,
    //       endDate,
    //       activeType,
    //       caseId,
    //       companyId,
    //       excludeZero,
    //       excludeZeroToOne,
    //       printToMail,
    //       result: res.result,
    //     },
    //     null,
    //     2
    //   )}`
    // );
  };

  const openPrintDailyRevenueByTypeReport = (
    activeType,
    dateMap,
    officeType
  ) => {
    if (!validateInfo()) return;
    if (!dateMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');
    mywindow.document
      .write(`<div style="display: flex; justify-content: between; align-items: flex-start">
        <div style="display: flex; flex: 0">
          <img
            height="61"
            width="100"
            alt=""
            src="/assets/img/logo.png"
          />
        </div>
        <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">Daily Transactions Report - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
      </div>

      ${Object.keys(dateMap)
        .map(
          (currentDate) => `
        <div style="display: flex; justify-content: start; align-items: center; margin-top: 1rem; padding-top: 2rem;">
          <div style="display: flex; flex: 1; flex-direction: column">
            <h4 style="font-weight: 600; margin: 0; padding: 0; text-transform: capitalize;">${format(
              new Date(parseInt(currentDate)),
              'do MMMM yyyy'
            )}</h4>
          </div>
        </div>

        ${
          dateMap[currentDate].charges
            ? `
          <div style="display: flex; justify-content: start; align-items: center; margin-bottom: 10px;">
            <div style="display: flex; flex: 1; flex-direction: column">
              <h4 style="font-weight: 500; margin: 0; padding: 1rem 0; text-transform: capitalize; text-style: italic;">Charges</h4>
            </div>
          </div>

          ${
            Object.keys(dateMap[currentDate].charges).length
              ? `
            <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; background-color: #345; color: #fefefe">
              <div style="display: flex; flex: 1;">
                <h5 style="padding: .5rem; margin: 0;">Case #</h5>
              </div>
              <div style="display: flex; flex: 3;">
                <h5 style="padding: .5rem; margin: 0;">Company Name</h5>
              </div>
              <div style="display: flex; flex: 2.5;">
                <h5 style="padding: .5rem; margin: 0;">Primary Name</h5>
              </div>
              <div style="display: flex; flex: 2;">
                <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
              </div>
              <div style="display: flex; flex: 2;">
                <h5 style="padding: .5rem; margin: 0;">Charge Type</h5>
              </div>
              <div style="display: flex; flex: 1.5;">
                <h5 style="padding: .5rem; margin: 0;">Amount</h5>
              </div>
            </div>
            ${Object.keys(dateMap[currentDate].charges)
              .map(
                (pType) => `
                  ${dateMap[currentDate].charges[pType]
                    .map(
                      (pt, i) => `
                  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
                  <div style="display: flex; flex: 1; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.caseNum
                  }</h5>
                </div>
                <div style="display: flex; flex: 3; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.companyName || ''
                  }</h5>
                </div>
                <div style="display: flex; flex: 2.5; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                    pt.case.primaryContact.fullName
                  }</h5>
                </div>
                <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      pt.caseType || 'n/a'
                    }</h5>
                  </div>
                  <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      PAYMENT_IOLTA_TYPES_MAP[pt.subType]
                    }</h5>
                  </div>
                  <div style="display: flex; flex: 1.5; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${priceFormat(
                      pt.amount
                    )}</h5>
                  </div>
                </div>
              `
                    )
                    .join('')}
                  <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab;">
                  <div style="display: flex; flex: 8.5"></div>
                  <div style="display: flex; flex: 2">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${`${PAYMENT_IOLTA_TYPES_MAP[pType]} Subtotal`}</h5>
                  </div>
                  <div style="display: flex; flex: 1.5">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                      Object.keys(dateMap[currentDate].charges[pType]).reduce(
                        (acc, cur) => {
                          return (
                            acc +
                            dateMap[currentDate].charges[pType][cur].amount
                          );
                        },
                        0
                      )
                    )}</h5>
                  </div>
                </div>
            `
              )
              .join('')}
              <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab; margin-bottom: 20px;">
                <div style="display: flex; flex: 8.5"></div>
                <div style="display: flex; flex: 2">
                  <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${`Total Charges`}</h5>
                </div>
                <div style="display: flex; flex: 1.5">
                  <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                    dateMap[currentDate].totalCharges
                  )}</h5>
                </div>
              </div>
          `
              : ``
          }
        `
            : ''
        }

        ${
          dateMap[currentDate].payments
            ? `
          <div style="display: flex; justify-content: start; align-items: center; margin-bottom: 10px;">
            <div style="display: flex; flex: 1; flex-direction: column">
              <h4 style="font-weight: 500; margin: 0; padding: 1rem 0; text-transform: capitalize; text-style: italic;">Payments</h4>
            </div>
          </div>

          ${
            Object.keys(dateMap[currentDate].payments).length
              ? `
            <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
              <div style="display: flex; flex: 1; background-color: #345; color: #fefefe">
                <h5 style="padding: .5rem; margin: 0;">Case #</h5>
              </div>
              <div style="display: flex; flex: 3; background-color: #345; color: #fefefe">
                <h5 style="padding: .5rem; margin: 0;">Company Name</h5>
              </div>
              <div style="display: flex; flex: 2.5; background-color: #345; color: #fefefe">
                <h5 style="padding: .5rem; margin: 0;">Primary Name</h5>
              </div>
              <div style="display: flex; flex: 2; background-color: #345; color: #fefefe">
                <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
              </div>
              <div style="display: flex; flex: 2; background-color: #345; color: #fefefe">
                <h5 style="padding: .5rem; margin: 0;">Payment Type</h5>
              </div>
              <div style="display: flex; flex: 1.5; background-color: #345; color: #fefefe">
                <h5 style="padding: .5rem; margin: 0;">Amount</h5>
              </div>
            </div>
            ${Object.keys(dateMap[currentDate].payments)
              .map(
                (pType) => `
                  ${dateMap[currentDate].payments[pType]
                    .map(
                      (pt, i) => `
                  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
                  <div style="display: flex; flex: 1; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.caseNum
                  }</h5>
                </div>
                <div style="display: flex; flex: 3; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.companyName || ''
                  }</h5>
                </div>
                <div style="display: flex; flex: 2.5; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                    pt.case.primaryContact.fullName
                  }</h5>
                </div>
                <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      pt.caseType || 'n/a'
                    }</h5>
                  </div>
                  <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      PAYMENT_IOLTA_TYPES_MAP[pt.subType]
                    }</h5>
                  </div>
                  <div style="display: flex; flex: 1.5; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${priceFormat(
                      pt.amount
                    )}</h5>
                  </div>
                </div>
              `
                    )
                    .join('')}
                  <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab">
                  <div style="display: flex; flex: 8.5"></div>
                  <div style="display: flex; flex: 2">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${`${PAYMENT_IOLTA_TYPES_MAP[pType]} Subtotal`}</h5>
                  </div>
                  <div style="display: flex; flex: 1.5">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                      Object.keys(dateMap[currentDate].payments[pType]).reduce(
                        (acc, cur) => {
                          return (
                            acc +
                            dateMap[currentDate].payments[pType][cur].amount
                          );
                        },
                        0
                      )
                    )}</h5>
                  </div>
                </div>
            `
              )
              .join('')}
              <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab; margin-bottom: 20px;">
                <div style="display: flex; flex: 8.5"></div>
                <div style="display: flex; flex: 2">
                  <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${`Total Payments`}</h5>
                </div>
                <div style="display: flex; flex: 1.5">
                  <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                    dateMap[currentDate].totalPayments
                  )}</h5>
                </div>
              </div>
            
          `
              : ``
          }
        `
            : ''
        }
      `
        )
        .join('')}
    `);
    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const runClientBalancesReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let caseRes;
    let case_ids;
    let [ms, ds, ys] = dateShortFormat(new Date(startDate)).split('/');
    let [me, de, ye] = dateShortFormat(new Date(endDate)).split('/');
    let start = new Date(`${ys}-${ms}-${ds}`).setHours(0, 0, 0) - 86400000;
    let end = new Date(`${ye}-${me}-${de}`).setHours(23, 59, 59);
    if (activeType === 'all') {
      //get all payments
      res = await configService.default.getAllPayments(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all payments - ', res.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(companyId, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPaymentsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'case') {
      // get payments with caseId
      res = await configService.default.getAllPaymentsByCase(
        caseId,
        start,
        end
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all payments by Case - ', res.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(caseType, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPaymentsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'case_sub_type') {
      res = await configService.default.getAllPaymentsByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('Got payments for case_sub_type', res.result);
      }
      // get payments with case_sub_type
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(caseStatus, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPaymentsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPaymentsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.result) {
      const caseMap = createClientBalancesMap(
        start,
        end,
        res.result,
        excludeZero,
        excludeZeroToOne
      );
      // console.log('Returned CaseMap', caseMap);
      openPrintClientBalancesNewReport(activeType, caseMap);
    }
  };

  const openPrintClientBalancesNewReport = (activeType, caseMap) => {
    if (!validateInfo()) return;
    if (!caseMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');

    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
    <div style="display: flex; flex: 0">
      <img
        height="61"
        width="100"
        alt=""
        src="/assets/img/logo.png"
      />
    </div>
    <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">Client Balances - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
  </div>

  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; margin-top: 1rem; background-color: #345; color: #fefefe">
    <div style="display: flex; flex: 0.5;">
      <h5 style="padding: .5rem; margin: 0;">SL</h5>
    </div>
    <div style="display: flex; flex: 1;">
      <h5 style="padding: .5rem; margin: 0;">Case #</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Category</h5>
    </div>
    <div style="display: flex; flex: 3;">
      <h5 style="padding: .5rem; margin: 0;">Company Name</h5>
    </div>
    <div style="display: flex; flex: 3;">
      <h5 style="padding: .5rem; margin: 0;">Primary Name</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
    </div>
    <div style="display: flex; flex: 1.5;">
      <h5 style="padding: .5rem; margin: 0;">Balance</h5>
    </div>
  </div>

  ${Object.keys(caseMap)
    .map(
      (csId, i) => `
  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
  <div style="display: flex; flex: 0.5; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${i + 1}</h5>
  </div>
  <div style="display: flex; flex: 1; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
      caseMap[csId].case.caseNum
    }</h5>
  </div>
  <div style="display: flex; flex: 2; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${
      caseMap[csId].case.caseType
    }</h5>
  </div>
  <div style="display: flex; flex: 3; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
      caseMap[csId].case.companyName || ''
    }</h5>
  </div>
  <div style="display: flex; flex: 3; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
      caseMap[csId].case.primaryContact.fullName
    }</h5>
  </div>
  <div style="display: flex; flex: 2; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
      caseMap[csId].case.caseSubType || ''
    }</h5>
  </div>
  <div style="display: flex; flex: 1.5; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${priceFormat(
      caseMap[csId].balance
    )}</h5>
  </div>
</div>
  `
    )
    .join('')}

<div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; border-top: 1px solid #9ab9;">
  <div style="display: flex; flex: 10.5; margin: auto;">
  </div>
  <div style="display: flex; flex: 1; margin: auto;">
  <h5 style="padding: .5rem; margin: 0;">Total:</h5>
  </div>
  <div style="display: flex; flex: 1.5; margin: auto;">
    <h5 style="padding: .5rem; margin: 0;">${priceFormat(
      Object.keys(caseMap).reduce((acc, cur) => {
        return acc + caseMap[cur].balance;
      }, 0)
    )}</h5>
  </div>
</div>

    `);

    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const runClientStatementReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let caseRes;
    let case_ids;
    let [ms, ds, ys] = dateShortFormat(new Date(startDate)).split('/');
    let [me, de, ye] = dateShortFormat(new Date(endDate)).split('/');
    let start = new Date(`${ys}-${ms}-${ds}`).setHours(0, 0, 0) - 86400000;
    let end = new Date(`${ye}-${me}-${de}`).setHours(23, 59, 59);
    if (activeType === 'all') {
      //get all payments
      res = await configService.default.getAllPayments(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all payments - ', res.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(companyId, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPaymentsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'case') {
      // get payments with caseId
      res = await configService.default.getAllPaymentsByCase(
        caseId,
        start,
        end
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all payments by Case - ', res.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(caseType, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPaymentsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'case_sub_type') {
      res = await configService.default.getAllPaymentsByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('Got payments for case_sub_type', res.result);
      }
      // get payments with case_sub_type
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(caseStatus, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPaymentsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPaymentsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.result) {
      const caseMap = createClientBalancesMap(
        start,
        end,
        res.result,
        excludeZero,
        excludeZeroToOne
      );
      // console.log('Returned CaseMap', caseMap);
      openPrintClientStatementNewReport(activeType, caseMap);
    }
  };

  const openPrintClientStatementNewReport = (activeType, caseMap) => {
    if (!validateInfo()) return;
    if (!caseMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');

    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
    <div style="display: flex; flex: 0">
      <img
        height="61"
        width="100"
        alt=""
        src="/assets/img/logo.png"
      />
    </div>
    <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">Client Statement - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
  </div>

  ${Object.keys(caseMap)
    .map(
      (csId) => `
      <div style="display: flex; flex-direction: row; justify-content: start; align-items: baseline; margin-top: 1rem;">
      <h3 style="font-weight: 500; padding: 0; margin: 5px 10px; text-transform: capitalize;">Case #${
        caseMap[csId].case.caseNum
      }
      <span style="font-weight: 400; font-size: 14px; margin-left: 5px; text-transform: capitalize">(${
        caseMap[csId].case.caseType
      })</span>    
      </h3>
  
      ${
        caseMap[csId].case.companyName
          ? `<h4 style="font-weight: 500; font-size: 14px; padding: 0; margin: 5px 10px; text-transform: capitalize;"><span style="font-weight: 400">Company -</span> ${caseMap[csId].case.companyName}</h4>`
          : ``
      }
  
      <h4 style="font-weight: 500; font-size: 14px; padding: 0; margin: 5px 10px; text-transform: capitalize;"><span style="font-weight: 400">Client -</span> ${
        caseMap[csId].case.primaryContact.fullName || ''
      }</h4>
    </div>

  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; margin-top: 1rem; background-color: #345; color: #fefefe">
    <div style="display: flex; flex: 0.5;">
      <h5 style="padding: .5rem; margin: 0;">SL</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Date</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Sub Type</h5>
    </div>
    <div style="display: flex; flex: 4;">
    <h5 style="padding: .5rem; margin: 0;">Description</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Type</h5>
    </div>
    <div style="display: flex; flex: 1.5;">
      <h5 style="padding: .5rem; margin: 0;">Amount</h5>
    </div>
  </div>

  ${caseMap[csId].payments
    .map(
      (pt, i) => `
        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
        <div style="display: flex; flex: 0.5; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${i + 1}</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${dateFormat(
            new Date(pt.paymentDate)
          )}</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            pt.caseType || ''
          }</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${
            PAYMENT_IOLTA_TYPES_MAP[pt.subType]
          }</h5>
        </div>
        <div style="display: flex; flex: 4; margin: auto;">
        <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            pt.desc
          }</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${
            pt.type
          }</h5>
        </div>
        <div style="display: flex; flex: 1.5; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${priceFormat(
            pt.amount
          )}</h5>
        </div>
        </div>
        `
    )
    .join('')}

        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; border-top: 1px solid #9ab9;">
          <div style="display: flex; flex: 10.5; margin: auto;">
          </div>
          <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0;">Total Balance:</h5>
          </div>
          <div style="display: flex; flex: 1.5; margin: auto;">
            <h5 style="padding: .5rem; margin: 0;">${priceFormat(
              caseMap[csId].balance
            )}</h5>
          </div>
        </div>
      `
    )
    .join('')}`);

    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const runMonthlyRevenueReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let caseRes;
    let case_ids;
    // let [ms, ds, ys] = dateShortFormat(
    //   new Date(+new Date(new Date(startDate).setDate(1)))
    // ).split('/');
    // let [me, de, ye] = dateShortFormat(
    //   new Date(
    //     +new Date(
    //       new Date(
    //         new Date(endDate).setFullYear(
    //           new Date(endDate).getFullYear(),
    //           new Date(endDate).getMonth() + 1,
    //           new Date(
    //             new Date(endDate).getFullYear(),
    //             new Date(endDate).getMonth() + 1,
    //             0
    //           ).getDate()
    //         )
    //       ).setHours(23, 59, 59)
    //     )
    //   )
    // ).split('/');
    let start = new Date(startDate).setDate(1);
    let end = new Date(
      new Date(endDate).setFullYear(
        new Date(endDate).getFullYear(),
        new Date(endDate).getMonth(),
        new Date(
          new Date(endDate).getFullYear(),
          new Date(endDate).getMonth(),
          0
        ).getDate()
      )
    ).setHours(23, 59, 59);
    console.log(new Date(start), new Date(end));
    if (activeType === 'all') {
      //get all payments
      res = await configService.default.getAllPayments(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all payments - ', res.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(companyId, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPaymentsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'case') {
      // get payments with caseId
      res = await configService.default.getAllPaymentsByCase(
        caseId,
        start,
        end
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all payments by Case - ', res.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(caseType, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPaymentsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'case_sub_type') {
      res = await configService.default.getAllPaymentsByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('Got payments for case_sub_type', res.result);
      }
      // get payments with case_sub_type
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(caseStatus, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPaymentsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPaymentsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.result) {
      const monthMap = createMonthlyRevenueByTypeMap(start, end, res.result);
      // console.log('Returned MonthMap');
      openPrintMonthlyRevenueByTypeReport(activeType, monthMap);
    }
  };

  const openPrintMonthlyRevenueByTypeReport = (activeType, monthMap) => {
    if (!validateInfo()) return;
    if (!monthMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');
    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
        <div style="display: flex; flex: 0">
          <img
            height="61"
            width="100"
            alt=""
            src="/assets/img/logo.png"
          />
        </div>
        <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">Monthly Revenue Report - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
      </div>

      ${Object.keys(monthMap)
        .map(
          (currentMonth) => `
        <div style="display: flex; justify-content: start; align-items: center; margin-top: 1rem; padding-top: 2rem;">
          <div style="display: flex; flex: 1; flex-direction: column">
            <h4 style="font-weight: 600; margin: 0; padding: 0; text-transform: capitalize;">${format(
              new Date(parseInt(currentMonth)),
              'MMMM yyyy'
            )}</h4>
          </div>
        </div>

        ${
          monthMap[currentMonth].charges
            ? `
          <div style="display: flex; justify-content: start; align-items: center">
            <div style="display: flex; flex: 1; flex-direction: column">
              <h4 style="font-weight: 500; margin: 0; padding: 1rem 0; text-transform: capitalize; text-style: italic;">Charges</h4>
            </div>
          </div>

          ${
            Object.keys(monthMap[currentMonth].charges).length
              ? `
            <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; background-color: #345; color: #fefefe">
              <div style="display: flex; flex: 1;">
                <h5 style="padding: .5rem; margin: 0;">Case #</h5>
              </div>
              <div style="display: flex; flex: 3;">
                <h5 style="padding: .5rem; margin: 0;">Company Name</h5>
              </div>
              <div style="display: flex; flex: 2.5;">
                <h5 style="padding: .5rem; margin: 0;">Primary Name</h5>
              </div>
              <div style="display: flex; flex: 2;">
                <h5 style="padding: .5rem; margin: 0;">Creation Date</h5>
              </div>
              <div style="display: flex; flex: 2;">
                <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
              </div>
              <div style="display: flex; flex: 2;">
                <h5 style="padding: .5rem; margin: 0;">Charge Type</h5>
              </div>
              <div style="display: flex; flex: 1.5;">
                <h5 style="padding: .5rem; margin: 0;">Amount</h5>
              </div>
            </div>
            ${Object.keys(monthMap[currentMonth].charges)
              .map(
                (pType) => `
                  ${monthMap[currentMonth].charges[pType]
                    .map(
                      (pt, i) => `
                  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
                  <div style="display: flex; flex: 1; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.caseNum
                  }</h5>
                </div>
                <div style="display: flex; flex: 3; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.companyName || ''
                  }</h5>
                </div>
                <div style="display: flex; flex: 2.5; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                    pt.case.primaryContact.fullName
                  }</h5>
                </div>
                <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${dateFormat(
                      new Date(pt.paymentDate)
                    )}</h5>
                  </div>
                  <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      pt.caseType || ''
                    }</h5>
                  </div>
                  <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      PAYMENT_IOLTA_TYPES_MAP[pt.subType]
                    }</h5>
                  </div>
                  <div style="display: flex; flex: 1.5; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${priceFormat(
                      pt.amount
                    )}</h5>
                  </div>
                </div>
              `
                    )
                    .join('')}
                  <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab">
                  <div style="display: flex; flex: 10.5"></div>
                  <div style="display: flex; flex: 2">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${`${PAYMENT_IOLTA_TYPES_MAP[pType]} Subtotal`}</h5>
                  </div>
                  <div style="display: flex; flex: 1.5">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                      Object.keys(monthMap[currentMonth].charges[pType]).reduce(
                        (acc, cur) => {
                          return (
                            acc +
                            monthMap[currentMonth].charges[pType][cur].amount
                          );
                        },
                        0
                      )
                    )}</h5>
                  </div>
                </div>
            `
              )
              .join('')}
              <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-bottom: 2px solid #9ab; background-color: #eeeffe;">
              <div style="display: flex; flex: 10.5"></div>
              <div style="display: flex; flex: 2">
                <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${'Total Charges'}</h5>
              </div>
              <div style="display: flex; flex: 1.5">
                <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                  monthMap[currentMonth].totalCharges
                )}</h5>
              </div>
            </div>
          `
              : ``
          }
        `
            : ''
        }

        ${
          monthMap[currentMonth].payments
            ? `
          <div style="display: flex; justify-content: start; align-items: center">
            <div style="display: flex; flex: 1; flex-direction: column">
              <h4 style="font-weight: 500; margin: 0; padding: 1rem 0; text-transform: capitalize; text-style: italic;">Payments</h4>
            </div>
          </div>

          ${
            Object.keys(monthMap[currentMonth].payments).length
              ? `
            <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; background-color: #345; color: #fefefe">
              <div style="display: flex; flex: 1;">
                <h5 style="padding: .5rem; margin: 0;">Case #</h5>
              </div>
              <div style="display: flex; flex: 3;">
                <h5 style="padding: .5rem; margin: 0;">Company Name</h5>
              </div>
              <div style="display: flex; flex: 2.5;">
                <h5 style="padding: .5rem; margin: 0;">Primary Name</h5>
              </div>
              <div style="display: flex; flex: 2;">
                <h5 style="padding: .5rem; margin: 0;">Creation Date</h5>
              </div>
              <div style="display: flex; flex: 2;">
                <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
              </div>
              <div style="display: flex; flex: 2;">
                <h5 style="padding: .5rem; margin: 0;">Payment Type</h5>
              </div>
              <div style="display: flex; flex: 1.5;">
                <h5 style="padding: .5rem; margin: 0;">Amount</h5>
              </div>
            </div>
            ${Object.keys(monthMap[currentMonth].payments)
              .map(
                (pType) => `
                  ${monthMap[currentMonth].payments[pType]
                    .map(
                      (pt, i) => `
                  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
                  <div style="display: flex; flex: 1; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.caseNum
                  }</h5>
                </div>
                <div style="display: flex; flex: 3; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.companyName || ''
                  }</h5>
                </div>
                <div style="display: flex; flex: 2.5; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                    pt.case.primaryContact.fullName
                  }</h5>
                </div>
                <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${dateFormat(
                      new Date(pt.paymentDate)
                    )}</h5>
                  </div>
                  <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      pt.caseType || ''
                    }</h5>
                  </div>
                <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      PAYMENT_IOLTA_TYPES_MAP[pt.subType]
                    }</h5>
                  </div>
                  <div style="display: flex; flex: 1.5; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${priceFormat(
                      pt.amount
                    )}</h5>
                  </div>
                </div>
              `
                    )
                    .join('')}
                  <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab">
                  <div style="display: flex; flex: 10.5"></div>
                  <div style="display: flex; flex: 2">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${`${PAYMENT_IOLTA_TYPES_MAP[pType]} Subtotal`}</h5>
                  </div>
                  <div style="display: flex; flex: 1.5">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                      Object.keys(
                        monthMap[currentMonth].payments[pType]
                      ).reduce((acc, cur) => {
                        return (
                          acc +
                          monthMap[currentMonth].payments[pType][cur].amount
                        );
                      }, 0)
                    )}</h5>
                  </div>
                </div>
            `
              )
              .join('')}
              <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-bottom: 2px solid #9ab; background-color: #eeeffe;">
              <div style="display: flex; flex: 10.5"></div>
              <div style="display: flex; flex: 2">
                <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${'Total Payments'}</h5>
              </div>
              <div style="display: flex; flex: 1.5">
                <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                  monthMap[currentMonth].totalPayments
                )}</h5>
              </div>
            </div>
          `
              : ``
          }
        `
            : ''
        }
      `
        )
        .join('')}
    `);
    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const reportBtns = [
    {
      label: 'Daily Transactions',
      value: 'daily_revenue_reports',
      onClick: () => {
        runDailyRevenueReport();
      },
    },
    {
      label: 'Client Balances',
      value: 'client_balances_reports',
      onClick: () => {
        runClientBalancesReport();
      },
    },
    {
      label: 'Client Statement',
      value: 'client_statement_reports',
      onClick: () => {
        runClientStatementReport();
      },
    },
    {
      label: 'Monthly Revenue',
      value: 'monthly_revenue_reports',
      onClick: () => {
        runMonthlyRevenueReport();
      },
    },
    {
      label: 'Collection List (Regular)',
      value: 'collection_list_reports_regular',
      onClick: () => {
        runRegularCollectionListReport();
      },
      // disabled: true,
    },
  ];

  const runIoltaDailyRevenueReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let caseRes;
    let case_ids;
    let [ms, ds, ys] = dateShortFormat(new Date(startDate)).split('/');
    let [me, de, ye] = dateShortFormat(new Date(endDate)).split('/');
    let start = new Date(`${ys}-${ms}-${ds}`).setHours(0, 0, 0) - 86400000;
    let end = new Date(`${ye}-${me}-${de}`).setHours(23, 59, 59);
    if (activeType === 'all') {
      //get all payments
      res = await configService.default.getAllIoltas(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas - ', res.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(companyId, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case') {
      // get ioltas with caseId
      res = await configService.default.getAllIoltasByCase(caseId, start, end);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas by Case - ', res.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(caseType, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case_sub_type') {
      res = await configService.default.getAllIoltasByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('Got ioltas for case_sub_type', res.result);
      }
      // get ioltas with case_sub_type
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(caseStatus, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.result) {
      const dateMap = createIoltaDailyRevenueByTypeMap(start, end, res.result);
      // console.log('Returned DateMap');
      openPrintIoltaDailyRevenueByTypeReport(activeType, dateMap);
    }
    // alert(
    //   `Running Daily Report! with data - ${JSON.stringify(
    //     {
    //       startDate,
    //       endDate,
    //       activeType,
    //       caseId,
    //       companyId,
    //       excludeZero,
    //       excludeZeroToOne,
    //       printToMail,
    //       result: res.result,
    //     },
    //     null,
    //     2
    //   )}`
    // );
  };

  const openPrintIoltaDailyRevenueByTypeReport = (activeType, dateMap) => {
    if (!validateInfo()) return;
    if (!dateMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');
    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
        <div style="display: flex; flex: 0">
          <img
            height="61"
            width="100"
            alt=""
            src="/assets/img/logo.png"
          />
        </div>
        <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">IOLTA Daily Transactions Report - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
      </div>

      ${Object.keys(dateMap)
        .map(
          (currentDate) => `
        <div style="display: flex; justify-content: start; align-items: center; margin-top: 1rem; padding-top: 2rem;">
          <div style="display: flex; flex: 1; flex-direction: column">
            <h4 style="font-weight: 600; margin: 0; padding: 0; text-transform: capitalize;">${format(
              new Date(parseInt(currentDate)),
              'do MMMM yyyy'
            )}</h4>
          </div>
        </div>

        ${
          dateMap[currentDate].deposits
            ? `
          <div style="display: flex; justify-content: start; align-items: center">
            <div style="display: flex; flex: 1; flex-direction: column">
              <h4 style="font-weight: 500; margin: 0; padding: 1rem 0; text-transform: capitalize; text-style: italic;">Deposits</h4>
            </div>
          </div>

          ${
            Object.keys(dateMap[currentDate].deposits).length
              ? `
            <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; background-color: #345; color: #fefefe">
              <div style="display: flex; flex: 1;">
                <h5 style="padding: .5rem; margin: 0;">Case #</h5>
              </div>
              <div style="display: flex; flex: 3;">
                <h5 style="padding: .5rem; margin: 0;">Company Name</h5>
              </div>
              <div style="display: flex; flex: 2.5;">
                <h5 style="padding: .5rem; margin: 0;">Primary Name</h5>
              </div>
              <div style="display: flex; flex: 2;">
                <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
              </div>
              <div style="display: flex; flex: 2;">
                <h5 style="padding: .5rem; margin: 0;">Deposit Type</h5>
              </div>
              <div style="display: flex; flex: 1.5;">
                <h5 style="padding: .5rem; margin: 0;">Amount</h5>
              </div>
            </div>
            ${Object.keys(dateMap[currentDate].deposits)
              .map(
                (iType) => `
                  ${dateMap[currentDate].deposits[iType]
                    .map(
                      (pt, i) => `
                  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
                  <div style="display: flex; flex: 1; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                      pt.case.caseNum
                    }</h5>
                    </div>
                    <div style="display: flex; flex: 3; margin: auto">
                      <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                        pt.case.companyName || ''
                      }</h5>
                    </div>
                    <div style="display: flex; flex: 2.5; margin: auto">
                      <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                        pt.case.primaryContact.fullName
                      }</h5>
                    </div>
                    <div style="display: flex; flex: 2; margin: auto">
                        <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                          pt.caseType || ''
                        }</h5>
                      </div>
                    <div style="display: flex; flex: 2; margin: auto">
                        <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                          PAYMENT_IOLTA_TYPES_MAP[pt.subType]
                        }</h5>
                      </div>
                      <div style="display: flex; flex: 1.5; margin: auto">
                        <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${priceFormat(
                          pt.amount
                        )}</h5>
                      </div>
                </div>
              `
                    )
                    .join('')}
                  <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab">
                  <div style="display: flex; flex: 8.5"></div>
                  <div style="display: flex; flex: 2">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${`${PAYMENT_IOLTA_TYPES_MAP[iType]} Subtotal`}</h5>
                  </div>
                  <div style="display: flex; flex: 1.5">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                      Object.keys(dateMap[currentDate].deposits[iType]).reduce(
                        (acc, cur) => {
                          return (
                            acc +
                            dateMap[currentDate].deposits[iType][cur].amount
                          );
                        },
                        0
                      )
                    )}</h5>
                  </div>
                </div>
            `
              )
              .join('')}
              <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab; margin-bottom: 20px;">
                <div style="display: flex; flex: 8.5"></div>
                <div style="display: flex; flex: 2">
                  <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${`Total Deposits`}</h5>
                </div>
                <div style="display: flex; flex: 1.5">
                  <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                    dateMap[currentDate].totalDeposits
                  )}</h5>
                </div>
              </div>
            
          `
              : ``
          }
        `
            : ''
        }

        ${
          dateMap[currentDate].transfers
            ? `
          <div style="display: flex; justify-content: start; align-items: center">
            <div style="display: flex; flex: 1; flex-direction: column">
              <h4 style="font-weight: 500; margin: 0; padding: 1rem 0; text-transform: capitalize; text-style: italic;">Transfers</h4>
            </div>
          </div>

          ${
            Object.keys(dateMap[currentDate].transfers).length
              ? `
            <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;; background-color: #345; color: #fefefe">
              <div style="display: flex; flex: 1">
                <h5 style="padding: .5rem; margin: 0;">Case #</h5>
              </div>
              <div style="display: flex; flex: 3">
                <h5 style="padding: .5rem; margin: 0;">Company Name</h5>
              </div>
              <div style="display: flex; flex: 2.5">
                <h5 style="padding: .5rem; margin: 0;">Primary Name</h5>
              </div>
              <div style="display: flex; flex: 2">
                <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
              </div>
              <div style="display: flex; flex: 2">
                <h5 style="padding: .5rem; margin: 0;">Transfer Type</h5>
              </div>
              <div style="display: flex; flex: 1.5">
                <h5 style="padding: .5rem; margin: 0;">Amount</h5>
              </div>
            </div>
            ${Object.keys(dateMap[currentDate].transfers)
              .map(
                (iType) => `
                  ${dateMap[currentDate].transfers[iType]
                    .map(
                      (pt, i) => `
                  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
                  <div style="display: flex; flex: 1; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.caseNum
                  }</h5>
                </div>
                <div style="display: flex; flex: 3; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.companyName || ''
                  }</h5>
                </div>
                <div style="display: flex; flex: 2.5; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                    pt.case.primaryContact.fullName
                  }</h5>
                </div>
                <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      pt.caseType || ''
                    }</h5>
                  </div>
                  <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      PAYMENT_IOLTA_TYPES_MAP[pt.subType]
                    }</h5>
                  </div>
                  <div style="display: flex; flex: 1.5; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${priceFormat(
                      pt.amount
                    )}</h5>
                  </div>
                </div>
              `
                    )
                    .join('')}
                  <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab">
                  <div style="display: flex; flex: 8.5"></div>
                  <div style="display: flex; flex: 2">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${`${PAYMENT_IOLTA_TYPES_MAP[iType]} Subtotal`}</h5>
                  </div>
                  <div style="display: flex; flex: 1.5">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                      Object.keys(dateMap[currentDate].transfers[iType]).reduce(
                        (acc, cur) => {
                          return (
                            acc +
                            dateMap[currentDate].transfers[iType][cur].amount
                          );
                        },
                        0
                      )
                    )}</h5>
                  </div>
                </div>
            `
              )
              .join('')}
              <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab; margin-bottom: 20px;">
                <div style="display: flex; flex: 8.5"></div>
                <div style="display: flex; flex: 2">
                  <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${`Total Transfers`}</h5>
                </div>
                <div style="display: flex; flex: 1.5">
                  <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                    dateMap[currentDate].totalTransfers
                  )}</h5>
                </div>
              </div>
            
          `
              : ``
          }
        `
            : ''
        }
      `
        )
        .join('')}
    `);
    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const runIoltaClientBalancesReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let caseRes;
    let case_ids;
    let [ms, ds, ys] = dateShortFormat(new Date(startDate)).split('/');
    let [me, de, ye] = dateShortFormat(new Date(endDate)).split('/');
    let start = new Date(`${ys}-${ms}-${ds}`).setHours(0, 0, 0) - 86400000;
    let end = new Date(`${ye}-${me}-${de}`).setHours(23, 59, 59);
    if (activeType === 'all') {
      //get all ioltas
      res = await configService.default.getAllIoltas(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas - ', res.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(companyId, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case') {
      // get ioltas with caseId
      res = await configService.default.getAllIoltasByCase(caseId, start, end);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas by Case - ', res.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(caseType, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case_sub_type') {
      res = await configService.default.getAllIoltasByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('Got ioltas for case_sub_type', res.result);
      }
      // get ioltas with case_sub_type
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(caseStatus, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.result) {
      const caseMap = createIoltaClientBalancesMap(
        start,
        end,
        res.result,
        excludeZero,
        excludeZeroToOne
      );
      // console.log('Returned CaseMap', caseMap);
      openPrintIoltaClientBalancesNewReport(activeType, caseMap);
    }
  };

  const openPrintIoltaClientBalancesNewReport = (activeType, caseMap) => {
    if (!validateInfo()) return;
    if (!caseMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');

    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
    <div style="display: flex; flex: 0">
      <img
        height="61"
        width="100"
        alt=""
        src="/assets/img/logo.png"
      />
    </div>
    <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">IOLTA Client Balances - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
  </div>

  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; margin-top: 1rem; background-color: #345; color: #fefefe">
    <div style="display: flex; flex: 0.5;">
      <h5 style="padding: .5rem; margin: 0;">SL</h5>
    </div>
    <div style="display: flex; flex: 1;">
      <h5 style="padding: .5rem; margin: 0;">Case #</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Category</h5>
    </div>
    <div style="display: flex; flex: 3;">
      <h5 style="padding: .5rem; margin: 0;">Company Name</h5>
    </div>
    <div style="display: flex; flex: 3;">
      <h5 style="padding: .5rem; margin: 0;">Primary Name</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
    </div>
    <div style="display: flex; flex: 1.5;">
      <h5 style="padding: .5rem; margin: 0;">Balance</h5>
    </div>
  </div>

  ${Object.keys(caseMap)
    .map(
      (csId, i) => `
  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
  <div style="display: flex; flex: 0.5; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${i + 1}</h5>
  </div>
  <div style="display: flex; flex: 1; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
      caseMap[csId].case.caseNum
    }</h5>
  </div>
  <div style="display: flex; flex: 2; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${
      caseMap[csId].case.caseType
    }</h5>
  </div>
  <div style="display: flex; flex: 3; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
      caseMap[csId].case.companyName || ''
    }</h5>
  </div>
  <div style="display: flex; flex: 3; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
      caseMap[csId].case.primaryContact.fullName
    }</h5>
  </div>
  <div style="display: flex; flex: 2; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
      caseMap[csId].case.caseSubType || ''
    }</h5>
  </div>
  <div style="display: flex; flex: 1.5; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${priceFormat(
      caseMap[csId].balance
    )}</h5>
  </div>
</div>
  `
    )
    .join('')}

<div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; border-top: 1px solid #9ab9;">
  <div style="display: flex; flex: 10.5; margin: auto;">
  </div>
  <div style="display: flex; flex: 1; margin: auto;">
  <h5 style="padding: .5rem; margin: 0;">Total:</h5>
  </div>
  <div style="display: flex; flex: 1.5; margin: auto;">
    <h5 style="padding: .5rem; margin: 0;">${priceFormat(
      Object.keys(caseMap).reduce((acc, cur) => {
        return acc + caseMap[cur].balance;
      }, 0)
    )}</h5>
  </div>
</div>

    `);

    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const runIoltaClientStatementReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let caseRes;
    let case_ids;
    let [ms, ds, ys] = dateShortFormat(new Date(startDate)).split('/');
    let [me, de, ye] = dateShortFormat(new Date(endDate)).split('/');
    let start = new Date(`${ys}-${ms}-${ds}`).setHours(0, 0, 0) - 86400000;
    let end = new Date(`${ye}-${me}-${de}`).setHours(23, 59, 59);
    if (activeType === 'all') {
      //get all ioltas
      res = await configService.default.getAllIoltas(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas - ', res.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(companyId, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case') {
      // get ioltas with caseId
      res = await configService.default.getAllIoltasByCase(caseId, start, end);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas by Case - ', res.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(caseType, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case_sub_type') {
      res = await configService.default.getAllIoltasByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('Got ioltas for case_sub_type', res.result);
      }
      // get ioltas with case_sub_type
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(caseStatus, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.result) {
      const caseMap = createIoltaClientBalancesMap(
        start,
        end,
        res.result,
        excludeZero,
        excludeZeroToOne
      );
      // console.log('Returned CaseMap', caseMap);
      openPrintIoltaClientStatementNewReport(activeType, caseMap);
    }
  };

  const openPrintIoltaClientStatementNewReport = (activeType, caseMap) => {
    if (!validateInfo()) return;
    if (!caseMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');

    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
    <div style="display: flex; flex: 0">
      <img
        height="61"
        width="100"
        alt=""
        src="/assets/img/logo.png"
      />
    </div>
    <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">IOLTA Client Statement - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
  </div>

  ${Object.keys(caseMap)
    .map(
      (csId) => `
      <div style="display: flex; flex-direction: row; justify-content: start; align-items: baseline; margin-top: 1rem;">
      <h3 style="font-weight: 500; padding: 0; margin: 5px 10px; text-transform: capitalize;">Case #${
        caseMap[csId].case.caseNum
      }
      <span style="font-weight: 400; font-size: 14px; margin-left: 5px; text-transform: capitalize">(${
        caseMap[csId].case.caseType
      })</span>    
      </h3>
  
      ${
        caseMap[csId].case.companyName
          ? `<h4 style="font-weight: 500; font-size: 14px; padding: 0; margin: 5px 10px; text-transform: capitalize;"><span style="font-weight: 400">Company -</span> ${caseMap[csId].case.companyName}</h4>`
          : ``
      }
  
      <h4 style="font-weight: 500; font-size: 14px; padding: 0; margin: 5px 10px; text-transform: capitalize;"><span style="font-weight: 400">Client -</span> ${
        caseMap[csId].case.primaryContact.fullName || ''
      }</h4>
    </div>

  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; margin-top: 1rem; background-color: #345; color: #fefefe">
    <div style="display: flex; flex: 0.5;">
      <h5 style="padding: .5rem; margin: 0;">SL</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Date</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Sub Type</h5>
    </div>
    <div style="display: flex; flex: 4;">
      <h5 style="padding: .5rem; margin: 0;">Description</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Type</h5>
    </div>
    <div style="display: flex; flex: 1.5;">
      <h5 style="padding: .5rem; margin: 0;">Amount</h5>
    </div>
  </div>

  ${caseMap[csId].ioltas
    .map(
      (pt, i) => `
        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
        <div style="display: flex; flex: 0.5; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${i + 1}</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${dateFormat(
            new Date(pt.ioltaDate)
          )}</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            pt.caseType || ''
          }</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${
            PAYMENT_IOLTA_TYPES_MAP[pt.subType]
          }</h5>
        </div>
        <div style="display: flex; flex: 4; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            pt.desc
          }</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${
            pt.type
          }</h5>
        </div>
        <div style="display: flex; flex: 1.5; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${priceFormat(
            pt.amount
          )}</h5>
        </div>
        </div>
        `
    )
    .join('')}

        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; border-top: 1px solid #9ab9;">
          <div style="display: flex; flex: 10.5; margin: auto;">
          </div>
          <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0;">Total Balance:</h5>
          </div>
          <div style="display: flex; flex: 1.5; margin: auto;">
            <h5 style="padding: .5rem; margin: 0;">${priceFormat(
              caseMap[csId].balance
            )}</h5>
          </div>
        </div>
      `
    )
    .join('')}`);

    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const runRegularCollectionListReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let depRes;
    let billRes;
    let caseRes;
    let case_ids;
    let [ms, ds, ys] = dateShortFormat(new Date(startDate)).split('/');
    let [me, de, ye] = dateShortFormat(new Date(endDate)).split('/');
    let start = new Date(`${ys}-${ms}-${ds}`).setHours(0, 0, 0) - 86400000;
    let end = new Date(`${ye}-${me}-${de}`).setHours(23, 59, 59);
    if (activeType === 'all') {
      //get all payments
      res = await configService.default.getAllCharges(start, end, officeType);
      depRes = await configService.default.getAllDeposits(start, end, officeType);
      billRes = await configService.default.getAllBillingNotes(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all charges - ', res.result);
      }
      if (depRes.result) {
        // console.log('all deposits - ', depRes.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(companyId, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllChargesByCaseList(
            case_ids.join(','),
            start,
            end
          );
          depRes = await configService.default.getAllDepositsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          billRes = await configService.default.getAllBillingNotesByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('all charges for company - ', res.result);
          }
          if (depRes.result) {
            // console.log('all deposits for company - ', depRes.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'case') {
      // get payments with caseId
      res = await configService.default.getAllChargesByCase(caseId, start, end);
      depRes = await configService.default.getAllDepositsByCase(
        caseId,
        start,
        end
      );
      billRes = await configService.default.getAllBillingNotesByCase(
        caseId,
        start,
        end
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all charges by Case - ', res.result);
      }
      if (depRes.result) {
        // console.log('all deposits by Case - ', depRes.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(caseType, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllChargesByCaseList(
            case_ids.join(','),
            start,
            end
          );
          depRes = await configService.default.getAllDepositsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          billRes = await configService.default.getAllBillingNotesByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('all charges for Case Type - ', res.result);
          }
          if (depRes.result) {
            // console.log('all deposits for Case Type - ', depRes.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'case_sub_type') {
      res = await configService.default.getAllChargesByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      depRes = await configService.default.getAllDepositsByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all charges for Case Sub Type - ', res.result);
      }
      if (depRes.result) {
        // console.log('all deposits for Case Sub Type - ', depRes.result);
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(caseStatus, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllChargesByCaseList(
            case_ids.join(','),
            start,
            end
          );
          depRes = await configService.default.getAllDepositsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          billRes = await configService.default.getAllBillingNotesByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('all charges for Case Status - ', res.result);
          }
          if (depRes.result) {
            // console.log('all deposits for Case Status - ', depRes.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllChargesByCaseList(
            case_ids.join(','),
            start,
            end
          );
          depRes = await configService.default.getAllDepositsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          billRes = await configService.default.getAllBillingNotesByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('all charges for Office - ', res.result);
          }
          if (depRes.result) {
            // console.log('all deposits for Office - ', depRes.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.result || depRes.result || billRes.result) {
      const {
        newMap: caseMap,
        grandTotalCharges,
        grandTotalDeposits,
      } = createCollectionListMap(
        start,
        end,
        res.result,
        depRes.result,
        billRes.result,
        excludeZero,
        excludeZeroToOne
      );
      console.log('Returned CaseMap', caseMap);
      openPrintRegularCollectionListNewReport(
        activeType,
        caseMap,
        startDate,
        endDate,
        grandTotalCharges,
        grandTotalDeposits
      );
    }
  };

  const openPrintRegularCollectionListNewReport = (
    activeType,
    caseMap,
    startDate,
    endDate,
    grandTotalCharges,
    grandTotalDeposits
  ) => {
    if (!validateInfo()) return;
    if (!caseMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');

    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
    <div style="display: flex; flex: 0">
      <img
        height="61"
        width="100"
        alt=""
        src="/assets/img/logo.png"
      />
    </div>
    <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">Collection List (Regular) - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
  </div>

  <div style="display: flex; justify-content: flex-start; align-items: center; margin-top: 1rem;">
  <h3 style="font-weight: 500; padding: 0 1rem; text-transform: capitalize;">${dateFormat(
    new Date(startDate)
  )}</h3>
  <h3 style="font-weight: 300; padding: 0 .2rem; text-transform: capitalize;">~</h3>
  <h3 style="font-weight: 500; padding: 0 1rem; text-transform: capitalize;">${dateFormat(
    new Date(endDate)
  )}</h3>
  </div>

  <div style="display: flex; justify-content: flex-start; align-items: center;">
  <h3 style="font-weight: 300; padding: 0 1rem; text-transform: capitalize;">Total Charges</h3>
  <h3 style="font-weight: 300; padding: 0 .2rem; text-transform: capitalize;">~</h3>
  <h3 style="font-weight: 500; padding: 0 1rem; text-transform: capitalize;">${priceFormat(
    grandTotalCharges
  )}</h3>

  <h3 style="font-weight: 300; padding: 0 1rem; text-transform: capitalize;">Total Deposits</h3>
  <h3 style="font-weight: 300; padding: 0 .2rem; text-transform: capitalize;">~</h3>
  <h3 style="font-weight: 500; padding: 0 1rem; text-transform: capitalize;">${priceFormat(
    grandTotalDeposits
  )}</h3>
  </div>

  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; margin-top: 1rem; background-color: #345; color: #fefefe">
    <div style="display: flex; flex: 1;">
      <h5 style="padding: .5rem; margin: 0;">Case</h5>
    </div>
    <div style="display: flex; flex: 3;">
      <h5 style="padding: .5rem; margin: 0;">Client Info</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Charge Type</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Charge Amt</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Deposit Type</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Deposit Amt</h5>
    </div>
  </div>

  ${Object.keys(caseMap)
    .map(
      (csId) => `
        <div style="display: flex; justify-content: space-evenly; align-items: flex-start; font-size: 12px; border-top: 1px solid #9ab4;">
          <div style="display: flex; flex: 1;">
            <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
              caseMap[csId].case.caseNum
            }</h5>
          </div>
          <div style="display: flex; flex: 3; flex-direction: column;">
            <h5 style="padding: .5rem .5rem .25rem .5rem; margin: 0; font-weight: 600;">${
              caseMap[csId].case.primaryContact.fullName
            }</h5>
            <h5 style="padding: .25rem .5rem .5rem .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${
              caseMap[csId].case.companyName || ''
            }</h5>
          </div>
          <div style="display: flex; flex: 6; flex-direction: column;">
          ${
            caseMap[csId].charges && caseMap[csId].charges.length
              ? caseMap[csId].charges
                  .map(
                    (pt) => `
              <div style="display: flex; flex: 1; width: 100%;">
              <div style="display: flex; flex: 1; width: 100%">
                <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${
                  pt.caseType || ''
                }</h5>
              </div>
                <div style="display: flex; flex: 1;">
                  <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
                    CHARGE_TYPES_LABEL_MAP[pt.subType]
                  }</h5>
                </div>
                <div style="display: flex; flex: 1; width: 100%;">
                  <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${priceFormat(
                    pt.amount
                  )}</h5>
                </div>
              </div>
            `
                  )
                  .join('')
              : ``
          }
          </div>
          <div style="display: flex; flex: 6; flex-direction: column;">
            ${
              caseMap[csId].deposits && caseMap[csId].deposits.length
                ? caseMap[csId].deposits
                    .map(
                      (pt) => `
                  <div style="display: flex; flex: 1; width: 100%;">
                      <div style="display: flex; flex: 1; width: 100%">
                      <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${
                        pt.caseType || ''
                      }</h5>
                    </div>
                <div style="display: flex; flex: 1;">
                  <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
                    DEPOSIT_TYPES_MAP[pt.subType]
                  }</h5>
                </div>
                <div style="display: flex; flex: 1; width: 100%;">
                  <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${priceFormat(
                    pt.amount
                  )}</h5>
                </div>
              </div>
            `
                    )
                    .join('')
                : ``
            }
          </div>
        </div>
        </div>



        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; border-top: 1px solid #9ab9;">
          <div style="display: flex; flex: 6;">
          </div>
          <div style="display: flex; flex: 2;">
          <h5 style="padding: .5rem; margin: 0;">Total Charges</h5>
          </div>
          <div style="display: flex; flex: 2;">
          <h5 style="padding: .5rem; margin: 0;">${priceFormat(
            caseMap[csId].totalCharges
          )}</h5>
          </div>
          <div style="display: flex; flex: 2;">
          </div>
          <div style="display: flex; flex: 2;">
          <h5 style="padding: .5rem; margin: 0;">Total Deposits</h5>
          </div>
          <div style="display: flex; flex: 2;">
          <h5 style="padding: .5rem; margin: 0;">${priceFormat(
            caseMap[csId].totalDeposits
          )}</h5>
          </div>
        </div>
        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; border-top: 1px solid #9ab9; ; border-bottom: 1px solid #9ab9;">
          <div style="display: flex; flex: 12;">
          </div>
          <div style="display: flex; flex: 2;">
          <h5 style="padding: .5rem; margin: 0;">Total:</h5>
          </div>
          <div style="display: flex; flex: 2;">
            <h5 style="padding: .5rem; margin: 0;">${priceFormat(
              caseMap[csId].total
            )}</h5>
          </div>
        </div>
        <div style="border-bottom: 2px solid #9ab9; margin-bottom: 25px;">
            ${caseMap[csId].billNotes
              .map(
                (note) => `
                <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; border-top: 1px solid #9ab9;">
                <div style="display: flex; flex: 2;">
                  <h5 style="padding: .5rem; margin: 0;">${getUserName(
                    note.createdBy
                  )}</h5>
                </div>
                <div style="display: flex; flex: 3;">
              <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${dateTimeFormat(
                new Date(note.createdAt)
              )}</h5>
              </div>
                  <div style="display: flex; flex: 9;">
                  <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
                    note.desc
                  }</h5>
                  </div>
                </div>
              `
              )
              .join('')}
        </div>
      `
    )
    .join('')}`);

    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const ioltaReportBtns = [
    {
      label: 'IOLTA Daily Transactions',
      value: 'iolta_daily_transaction_reports',
      onClick: () => {
        runIoltaDailyRevenueReport();
      },
    },
    {
      label: 'IOLTA Balance',
      value: 'iolta_balance_reports',
      onClick: () => {
        runIoltaClientBalancesReport();
      },
    },
    {
      label: 'IOLTA Statement',
      value: 'iolta_statement_reports',
      onClick: () => {
        runIoltaClientStatementReport();
      },
    },
  ];

  // Filing Iolta

  const runFilingIoltaDailyRevenueReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let caseRes;
    let case_ids;
    let [ms, ds, ys] = dateShortFormat(new Date(startDate)).split('/');
    let [me, de, ye] = dateShortFormat(new Date(endDate)).split('/');
    let start = new Date(`${ys}-${ms}-${ds}`).setHours(0, 0, 0) - 86400000;
    let end = new Date(`${ye}-${me}-${de}`).setHours(23, 59, 59);
    if (activeType === 'all') {
      //get all payments
      res = await configService.default.getAllFilingIoltas(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas - ', res.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(companyId, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllFilingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case') {
      // get ioltas with caseId
      res = await configService.default.getAllFilingIoltasByCase(
        caseId,
        start,
        end
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas by Case - ', res.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(caseType, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllFilingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case_sub_type') {
      res = await configService.default.getAllFilingIoltasByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('Got ioltas for case sub type', res.result);
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(caseStatus, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllFilingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllFilingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.result) {
      const dateMap = createIoltaDailyRevenueByTypeMap(start, end, res.result);
      // console.log('Returned DateMap');
      openPrintFilingIoltaDailyRevenueByTypeReport(activeType, dateMap);
    }
    // alert(
    //   `Running Daily Report! with data - ${JSON.stringify(
    //     {
    //       startDate,
    //       endDate,
    //       activeType,
    //       caseId,
    //       companyId,
    //       excludeZero,
    //       excludeZeroToOne,
    //       printToMail,
    //       result: res.result,
    //     },
    //     null,
    //     2
    //   )}`
    // );
  };

  const openPrintFilingIoltaDailyRevenueByTypeReport = (
    activeType,
    dateMap
  ) => {
    if (!validateInfo()) return;
    if (!dateMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');
    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
        <div style="display: flex; flex: 0">
          <img
            height="61"
            width="100"
            alt=""
            src="/assets/img/logo.png"
          />
        </div>
        <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">IOLTA (Filing Fees) Daily Transactions Report - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
      </div>
  
      ${Object.keys(dateMap)
        .map(
          (currentDate) => `
        <div style="display: flex; justify-content: start; align-items: center; margin-top: 1rem; padding-top: 2rem;">
          <div style="display: flex; flex: 1; flex-direction: column">
            <h4 style="font-weight: 600; margin: 0; padding: 0; text-transform: capitalize;">${format(
              new Date(parseInt(currentDate)),
              'do MMMM yyyy'
            )}</h4>
          </div>
        </div>
  
        ${
          dateMap[currentDate].deposits
            ? `
          <div style="display: flex; justify-content: start; align-items: center">
            <div style="display: flex; flex: 1; flex-direction: column">
              <h4 style="font-weight: 500; margin: 0; padding: 1rem 0; text-transform: capitalize; text-style: italic;">Deposits</h4>
            </div>
          </div>
  
          ${
            Object.keys(dateMap[currentDate].deposits).length
              ? `
            <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; background-color: #345; color: #fefefe">
              <div style="display: flex; flex: 1;">
                <h5 style="padding: .5rem; margin: 0;">Case #</h5>
              </div>
              <div style="display: flex; flex: 3;">
                <h5 style="padding: .5rem; margin: 0;">Company Name</h5>
              </div>
              <div style="display: flex; flex: 2.5;">
                <h5 style="padding: .5rem; margin: 0;">Primary Name</h5>
              </div>
              <div style="display: flex; flex: 2;">
                <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
              </div>
              <div style="display: flex; flex: 2;">
                <h5 style="padding: .5rem; margin: 0;">Deposit Type</h5>
              </div>
              <div style="display: flex; flex: 1.5;">
                <h5 style="padding: .5rem; margin: 0;">Amount</h5>
              </div>
            </div>
            ${Object.keys(dateMap[currentDate].deposits)
              .map(
                (iType) => `
                  ${dateMap[currentDate].deposits[iType]
                    .map(
                      (pt, i) => `
                  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
                  <div style="display: flex; flex: 1; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.caseNum
                  }</h5>
                </div>
                <div style="display: flex; flex: 3; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.companyName || ''
                  }</h5>
                </div>
                <div style="display: flex; flex: 2.5; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                    pt.case.primaryContact.fullName
                  }</h5>
                </div>
                <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      pt.caseType || ''
                    }</h5>
                  </div>
                  <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      PAYMENT_IOLTA_TYPES_MAP[pt.subType]
                    }</h5>
                  </div>
                  <div style="display: flex; flex: 1.5; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${priceFormat(
                      pt.amount
                    )}</h5>
                  </div>
                </div>
              `
                    )
                    .join('')}
                  <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab">
                  <div style="display: flex; flex: 8.5"></div>
                  <div style="display: flex; flex: 2">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${`${PAYMENT_IOLTA_TYPES_MAP[iType]} Subtotal`}</h5>
                  </div>
                  <div style="display: flex; flex: 1.5">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                      Object.keys(dateMap[currentDate].deposits[iType]).reduce(
                        (acc, cur) => {
                          return (
                            acc +
                            dateMap[currentDate].deposits[iType][cur].amount
                          );
                        },
                        0
                      )
                    )}</h5>
                  </div>
                </div>
            `
              )
              .join('')}
              <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab">
                  <div style="display: flex; flex: 8.5"></div>
                  <div style="display: flex; flex: 2">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${`Total Deposits`}</h5>
                  </div>
                  <div style="display: flex; flex: 1.5">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                      dateMap[currentDate].totalDeposits
                    )}</h5>
                  </div>
                </div>
            
          `
              : ``
          }
        `
            : ''
        }
  
        ${
          dateMap[currentDate].transfers
            ? `
          <div style="display: flex; justify-content: start; align-items: center">
            <div style="display: flex; flex: 1; flex-direction: column">
              <h4 style="font-weight: 500; margin: 0; padding: 1rem 0; text-transform: capitalize; text-style: italic;">Transfers</h4>
            </div>
          </div>
  
          ${
            Object.keys(dateMap[currentDate].transfers).length
              ? `
            <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; background-color: #345; color: #fefefe">
              <div style="display: flex; flex: 1;">
                <h5 style="padding: .5rem; margin: 0;">Case #</h5>
              </div>
              <div style="display: flex; flex: 3;">
                <h5 style="padding: .5rem; margin: 0;">Company Name</h5>
              </div>
              <div style="display: flex; flex: 2.5;">
                <h5 style="padding: .5rem; margin: 0;">Primary Name</h5>
              </div>
              <div style="display: flex; flex: 2;">
                <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
              </div>
              <div style="display: flex; flex: 2;">
                <h5 style="padding: .5rem; margin: 0;">Transfer Type</h5>
              </div>
              <div style="display: flex; flex: 1.5;">
                <h5 style="padding: .5rem; margin: 0;">Amount</h5>
              </div>
            </div>
            ${Object.keys(dateMap[currentDate].transfers)
              .map(
                (iType) => `
                  ${dateMap[currentDate].transfers[iType]
                    .map(
                      (pt, i) => `
                  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
                  <div style="display: flex; flex: 1; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.caseNum
                  }</h5>
                </div>
                <div style="display: flex; flex: 3; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.companyName || ''
                  }</h5>
                </div>
                <div style="display: flex; flex: 2.5; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                    pt.case.primaryContact.fullName
                  }</h5>
                </div>
                <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      pt.caseType || ''
                    }</h5>
                  </div>
                  <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      PAYMENT_IOLTA_TYPES_MAP[pt.subType]
                    }</h5>
                  </div>
                  <div style="display: flex; flex: 1.5; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${priceFormat(
                      pt.amount
                    )}</h5>
                  </div>
                </div>
              `
                    )
                    .join('')}
                  <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab">
                  <div style="display: flex; flex: 8.5"></div>
                  <div style="display: flex; flex: 2">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${`${PAYMENT_IOLTA_TYPES_MAP[iType]} Subtotal`}</h5>
                  </div>
                  <div style="display: flex; flex: 1.5">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                      Object.keys(dateMap[currentDate].transfers[iType]).reduce(
                        (acc, cur) => {
                          return (
                            acc +
                            dateMap[currentDate].transfers[iType][cur].amount
                          );
                        },
                        0
                      )
                    )}</h5>
                  </div>
                </div>
            `
              )
              .join('')}
              <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab">
                  <div style="display: flex; flex: 8.5"></div>
                  <div style="display: flex; flex: 2">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${'Total Transfers'}</h5>
                  </div>
                  <div style="display: flex; flex: 1.5">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                      dateMap[currentDate].totalTransfers
                    )}</h5>
                  </div>
                </div>
            
          `
              : ``
          }
        `
            : ''
        }
      `
        )
        .join('')}
    `);
    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const runFilingIoltaClientBalancesReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let caseRes;
    let case_ids;
    let [ms, ds, ys] = dateShortFormat(new Date(startDate)).split('/');
    let [me, de, ye] = dateShortFormat(new Date(endDate)).split('/');
    let start = new Date(`${ys}-${ms}-${ds}`).setHours(0, 0, 0) - 86400000;
    let end = new Date(`${ye}-${me}-${de}`).setHours(23, 59, 59);
    if (activeType === 'all') {
      //get all ioltas
      res = await configService.default.getAllFilingIoltas(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas - ', res.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(companyId, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllFilingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case') {
      // get ioltas with caseId
      res = await configService.default.getAllFilingIoltasByCase(
        caseId,
        start,
        end
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas by Case - ', res.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(caseType, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllFilingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case_sub_type') {
      res = await configService.default.getAllFilingIoltasByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('Got ioltas for case sub type', res.result);
      }
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(caseStatus, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllFilingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllFilingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.result) {
      const caseMap = createIoltaClientBalancesMap(
        start,
        end,
        res.result,
        excludeZero,
        excludeZeroToOne
      );
      // console.log('Returned CaseMap', caseMap);
      openPrintFilingIoltaClientBalancesNewReport(activeType, caseMap);
    }
  };

  const openPrintFilingIoltaClientBalancesNewReport = (activeType, caseMap) => {
    if (!validateInfo()) return;
    if (!caseMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');

    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
    <div style="display: flex; flex: 0">
      <img
        height="61"
        width="100"
        alt=""
        src="/assets/img/logo.png"
      />
    </div>
    <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">IOLTA (Filing Fees) Client Balances - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
  </div>
  
  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; margin-top: 1rem;">
    <div style="display: flex; flex: 0.5; background-color: #345; color: #fefefe">
      <h5 style="padding: .5rem; margin: 0;">SL</h5>
    </div>
    <div style="display: flex; flex: 1; background-color: #345; color: #fefefe">
      <h5 style="padding: .5rem; margin: 0;">Case #</h5>
    </div>
    <div style="display: flex; flex: 2; background-color: #345; color: #fefefe">
      <h5 style="padding: .5rem; margin: 0;">Category</h5>
    </div>
    <div style="display: flex; flex: 3; background-color: #345; color: #fefefe">
      <h5 style="padding: .5rem; margin: 0;">Company Name</h5>
    </div>
    <div style="display: flex; flex: 3; background-color: #345; color: #fefefe">
      <h5 style="padding: .5rem; margin: 0;">Primary Name</h5>
    </div>
    <div style="display: flex; flex: 2; background-color: #345; color: #fefefe">
      <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
    </div>
    <div style="display: flex; flex: 1.5; background-color: #345; color: #fefefe">
      <h5 style="padding: .5rem; margin: 0;">Balance</h5>
    </div>
  </div>
  
  ${Object.keys(caseMap)
    .map(
      (csId, i) => `
  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
  <div style="display: flex; flex: 0.5; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${i + 1}</h5>
  </div>
  <div style="display: flex; flex: 1; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
      caseMap[csId].case.caseNum
    }</h5>
  </div>
  <div style="display: flex; flex: 2; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${
      caseMap[csId].case.caseType
    }</h5>
  </div>
  <div style="display: flex; flex: 3; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
      caseMap[csId].case.companyName || ''
    }</h5>
  </div>
  <div style="display: flex; flex: 3; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
      caseMap[csId].case.primaryContact.fullName
    }</h5>
  </div>
  <div style="display: flex; flex: 2; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
      caseMap[csId].case.caseSubType || ''
    }</h5>
  </div>
  <div style="display: flex; flex: 1.5; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${priceFormat(
      caseMap[csId].balance
    )}</h5>
  </div>
  </div>
  `
    )
    .join('')}
  
  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; border-top: 1px solid #9ab9;">
  <div style="display: flex; flex: 10.5; margin: auto;">
  </div>
  <div style="display: flex; flex: 1; margin: auto;">
  <h5 style="padding: .5rem; margin: 0;">Total:</h5>
  </div>
  <div style="display: flex; flex: 1.5; margin: auto;">
    <h5 style="padding: .5rem; margin: 0;">${priceFormat(
      Object.keys(caseMap).reduce((acc, cur) => {
        return acc + caseMap[cur].balance;
      }, 0)
    )}</h5>
  </div>
  </div>
  
    `);

    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const runFilingIoltaClientStatementReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let caseRes;
    let case_ids;
    let [ms, ds, ys] = dateShortFormat(new Date(startDate)).split('/');
    let [me, de, ye] = dateShortFormat(new Date(endDate)).split('/');
    let start = new Date(`${ys}-${ms}-${ds}`).setHours(0, 0, 0) - 86400000;
    let end = new Date(`${ye}-${me}-${de}`).setHours(23, 59, 59);
    if (activeType === 'all') {
      //get all ioltas
      res = await configService.default.getAllFilingIoltas(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas - ', res.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(companyId, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllFilingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case') {
      // get ioltas with caseId
      res = await configService.default.getAllFilingIoltasByCase(
        caseId,
        start,
        end
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas by Case - ', res.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(caseType, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllFilingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case_sub_type') {
      res = await configService.default.getAllFilingIoltasByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('Got ioltas for case sub type', res.result);
      }
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(caseStatus, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllFilingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllFilingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.result) {
      const caseMap = createIoltaClientBalancesMap(
        start,
        end,
        res.result,
        excludeZero,
        excludeZeroToOne
      );
      // console.log('Returned CaseMap', caseMap);
      openPrintFilingIoltaClientStatementNewReport(activeType, caseMap);
    }
  };

  const openPrintFilingIoltaClientStatementNewReport = (
    activeType,
    caseMap
  ) => {
    if (!validateInfo()) return;
    if (!caseMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');

    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
    <div style="display: flex; flex: 0">
      <img
        height="61"
        width="100"
        alt=""
        src="/assets/img/logo.png"
      />
    </div>
    <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">IOLTA (Filing Fees) Client Statement - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
  </div>
  
  ${Object.keys(caseMap)
    .map(
      (csId) => `
      <div style="display: flex; flex-direction: row; justify-content: start; align-items: baseline; margin-top: 1rem;">
      <h3 style="font-weight: 500; padding: 0; margin: 5px 10px; text-transform: capitalize;">Case #${
        caseMap[csId].case.caseNum
      }
      <span style="font-weight: 400; font-size: 14px; margin-left: 5px; text-transform: capitalize">(${
        caseMap[csId].case.caseType
      })</span>    
      </h3>
  
      ${
        caseMap[csId].case.companyName
          ? `<h4 style="font-weight: 500; font-size: 14px; padding: 0; margin: 5px 10px; text-transform: capitalize;"><span style="font-weight: 400">Company -</span> ${caseMap[csId].case.companyName}</h4>`
          : ``
      }
  
      <h4 style="font-weight: 500; font-size: 14px; padding: 0; margin: 5px 10px; text-transform: capitalize;"><span style="font-weight: 400">Client -</span> ${
        caseMap[csId].case.primaryContact.fullName || ''
      }</h4>
    </div>
  
  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; margin-top: 1rem; background-color: #345; color: #fefefe">
    <div style="display: flex; flex: 0.5;">
      <h5 style="padding: .5rem; margin: 0;">SL</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Date</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Sub Type</h5>
    </div>
    <div style="display: flex; flex: 4;">
      <h5 style="padding: .5rem; margin: 0;">Description</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Type</h5>
    </div>
    <div style="display: flex; flex: 1.5;">
      <h5 style="padding: .5rem; margin: 0;">Amount</h5>
    </div>
  </div>
  
  ${caseMap[csId].ioltas
    .map(
      (pt, i) => `
        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
        <div style="display: flex; flex: 0.5; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${i + 1}</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${dateFormat(
              new Date(pt.ioltaDate)
          )}</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            pt.caseType || ''
          }</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${
            PAYMENT_IOLTA_TYPES_MAP[pt.subType]
          }</h5>
        </div>
        <div style="display: flex; flex: 4; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            pt.desc
          }</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${
            pt.type
          }</h5>
        </div>
        <div style="display: flex; flex: 1.5; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${priceFormat(
            pt.amount
          )}</h5>
        </div>
        </div>
        `
    )
    .join('')}
  
        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; border-top: 1px solid #9ab9;">
          <div style="display: flex; flex: 10.5; margin: auto;">
          </div>
          <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0;">Total Balance:</h5>
          </div>
          <div style="display: flex; flex: 1.5; margin: auto;">
            <h5 style="padding: .5rem; margin: 0;">${priceFormat(
              caseMap[csId].balance
            )}</h5>
          </div>
        </div>
      `
    )
    .join('')}`);

    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const filingIoltaReportBtns = [
    {
      label: 'IOLTA (Filing Fees) Daily Transactions',
      value: 'iolta_daily_transaction_reports',
      onClick: () => {
        runFilingIoltaDailyRevenueReport();
      },
    },
    {
      label: 'IOLTA (Filing Fees) Balance',
      value: 'iolta_balance_reports',
      onClick: () => {
        runFilingIoltaClientBalancesReport();
      },
    },
    {
      label: 'IOLTA (Filing Fees) Statement',
      value: 'iolta_statement_reports',
      onClick: () => {
        runFilingIoltaClientStatementReport();
      },
    },
    // {
    //   label: 'Collection List (Filing)',
    //   value: 'collection_list_reports_filing',
    //   onClick: () => {
    //     runFilingCollectionListReport();
    //   },
    //   // disabled: true,
    // },
  ];

  // Pending Ioltas

  const runPendingIoltaDailyRevenueReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let caseRes;
    let case_ids;
    let [ms, ds, ys] = dateShortFormat(new Date(startDate)).split('/');
    let [me, de, ye] = dateShortFormat(new Date(endDate)).split('/');
    let start = new Date(`${ys}-${ms}-${ds}`).setHours(0, 0, 0) - 86400000;
    let end = new Date(`${ye}-${me}-${de}`).setHours(23, 59, 59);
    if (activeType === 'all') {
      //get all payments
      res = await configService.default.getAllPendingIoltas(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas - ', res.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(companyId, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPendingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case') {
      // get ioltas with caseId
      res = await configService.default.getAllPendingIoltasByCase(
        caseId,
        start,
        end
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas by Case - ', res.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(caseType, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPendingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case_sub_type') {
      res = await configService.default.getAllPendingIoltasByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('Got ioltas for case sub type', res.result);
      }
      // get ioltas with case sub type
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(caseStatus, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPendingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPendingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.result) {
      const dateMap = createIoltaDailyRevenueByTypeMap(start, end, res.result);
      // console.log('Returned DateMap');
      openPrintPendingIoltaDailyRevenueByTypeReport(activeType, dateMap);
    }
    // alert(
    //   `Running Daily Report! with data - ${JSON.stringify(
    //     {
    //       startDate,
    //       endDate,
    //       activeType,
    //       caseId,
    //       companyId,
    //       excludeZero,
    //       excludeZeroToOne,
    //       printToMail,
    //       result: res.result,
    //     },
    //     null,
    //     2
    //   )}`
    // );
  };

  const openPrintPendingIoltaDailyRevenueByTypeReport = (
    activeType,
    dateMap
  ) => {
    if (!validateInfo()) return;
    if (!dateMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');
    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
        <div style="display: flex; flex: 0">
          <img
            height="61"
            width="100"
            alt=""
            src="/assets/img/logo.png"
          />
        </div>
        <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">IOLTA (Pending) Daily Transactions Report - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
      </div>
  
      ${Object.keys(dateMap)
        .map(
          (currentDate) => `
        <div style="display: flex; justify-content: start; align-items: center; margin-top: 1rem; padding-top: 2rem;">
          <div style="display: flex; flex: 1; flex-direction: column">
            <h4 style="font-weight: 600; margin: 0; padding: 0; text-transform: capitalize;">${format(
              new Date(parseInt(currentDate)),
              'do MMMM yyyy'
            )}</h4>
          </div>
        </div>
  
        ${
          dateMap[currentDate].deposits
            ? `
          <div style="display: flex; justify-content: start; align-items: center">
            <div style="display: flex; flex: 1; flex-direction: column">
              <h4 style="font-weight: 500; margin: 0; padding: 1rem 0; text-transform: capitalize; text-style: italic;">Deposits</h4>
            </div>
          </div>
  
          ${
            Object.keys(dateMap[currentDate].deposits).length
              ? `
            <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; background-color: #345; color: #fefefe">
              <div style="display: flex; flex: 1;">
                <h5 style="padding: .5rem; margin: 0;">Case #</h5>
              </div>
              <div style="display: flex; flex: 3;">
                <h5 style="padding: .5rem; margin: 0;">Company Name</h5>
              </div>
              <div style="display: flex; flex: 2.5;">
                <h5 style="padding: .5rem; margin: 0;">Primary Name</h5>
              </div>
              <div style="display: flex; flex: 2;">
                <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
              </div>
              <div style="display: flex; flex: 2;">
                <h5 style="padding: .5rem; margin: 0;">Deposit Type</h5>
              </div>
              <div style="display: flex; flex: 1.5;">
                <h5 style="padding: .5rem; margin: 0;">Amount</h5>
              </div>
            </div>
            ${Object.keys(dateMap[currentDate].deposits)
              .map(
                (iType) => `
                  ${dateMap[currentDate].deposits[iType]
                    .map(
                      (pt, i) => `
                  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
                  <div style="display: flex; flex: 1; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.caseNum
                  }</h5>
                </div>
                <div style="display: flex; flex: 3; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.companyName || ''
                  }</h5>
                </div>
                <div style="display: flex; flex: 2.5; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                    pt.case.primaryContact.fullName
                  }</h5>
                </div>
                <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      pt.caseType || ''
                    }</h5>
                  </div>
                  <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      PAYMENT_IOLTA_TYPES_MAP[pt.subType]
                    }</h5>
                  </div>
                  <div style="display: flex; flex: 1.5; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${priceFormat(
                      pt.amount
                    )}</h5>
                  </div>
                </div>
              `
                    )
                    .join('')}
                  <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab">
                  <div style="display: flex; flex: 8.5"></div>
                  <div style="display: flex; flex: 2">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${`${PAYMENT_IOLTA_TYPES_MAP[iType]} Subtotal`}</h5>
                  </div>
                  <div style="display: flex; flex: 1.5">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                      Object.keys(dateMap[currentDate].deposits[iType]).reduce(
                        (acc, cur) => {
                          return (
                            acc +
                            dateMap[currentDate].deposits[iType][cur].amount
                          );
                        },
                        0
                      )
                    )}</h5>
                  </div>
                </div>
            `
              )
              .join('')}
              <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab">
                  <div style="display: flex; flex: 8.5"></div>
                  <div style="display: flex; flex: 2">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${`Total Deposits`}</h5>
                  </div>
                  <div style="display: flex; flex: 1.5">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                      dateMap[currentDate].totalDeposits
                    )}</h5>
                  </div>
                </div>
            
          `
              : ``
          }
        `
            : ''
        }
  
        ${
          dateMap[currentDate].transfers
            ? `
          <div style="display: flex; justify-content: start; align-items: center">
            <div style="display: flex; flex: 1; flex-direction: column">
              <h4 style="font-weight: 500; margin: 0; padding: 1rem 0; text-transform: capitalize; text-style: italic;">Transfers</h4>
            </div>
          </div>
  
          ${
            Object.keys(dateMap[currentDate].transfers).length
              ? `
            <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
              <div style="display: flex; flex: 1; background-color: #345; color: #fefefe">
                <h5 style="padding: .5rem; margin: 0;">Case #</h5>
              </div>
              <div style="display: flex; flex: 3; background-color: #345; color: #fefefe">
                <h5 style="padding: .5rem; margin: 0;">Company Name</h5>
              </div>
              <div style="display: flex; flex: 2.5; background-color: #345; color: #fefefe">
                <h5 style="padding: .5rem; margin: 0;">Primary Name</h5>
              </div>
              <div style="display: flex; flex: 2; background-color: #345; color: #fefefe">
                <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
              </div>
              <div style="display: flex; flex: 2; background-color: #345; color: #fefefe">
                <h5 style="padding: .5rem; margin: 0;">Transfer Type</h5>
              </div>
              <div style="display: flex; flex: 1.5; background-color: #345; color: #fefefe">
                <h5 style="padding: .5rem; margin: 0;">Amount</h5>
              </div>
            </div>
            ${Object.keys(dateMap[currentDate].transfers)
              .map(
                (iType) => `
                  ${dateMap[currentDate].transfers[iType]
                    .map(
                      (pt, i) => `
                  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
                  <div style="display: flex; flex: 1; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.caseNum
                  }</h5>
                </div>
                <div style="display: flex; flex: 3; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${
                    pt.case.companyName || ''
                  }</h5>
                </div>
                <div style="display: flex; flex: 2.5; margin: auto">
                  <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                    pt.case.primaryContact.fullName
                  }</h5>
                </div>
                <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      pt.caseType || ''
                    }</h5>
                  </div>
                  <div style="display: flex; flex: 2; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
                      PAYMENT_IOLTA_TYPES_MAP[pt.subType]
                    }</h5>
                  </div>
                  <div style="display: flex; flex:1.5; margin: auto">
                    <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${priceFormat(
                      pt.amount
                    )}</h5>
                  </div>
                </div>
              `
                    )
                    .join('')}
                  <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab">
                  <div style="display: flex; flex: 8.5"></div>
                  <div style="display: flex; flex: 2">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${`${PAYMENT_IOLTA_TYPES_MAP[iType]} Subtotal`}</h5>
                  </div>
                  <div style="display: flex; flex: 1.5">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                      Object.keys(dateMap[currentDate].transfers[iType]).reduce(
                        (acc, cur) => {
                          return (
                            acc +
                            dateMap[currentDate].transfers[iType][cur].amount
                          );
                        },
                        0
                      )
                    )}</h5>
                  </div>
                </div>
            `
              )
              .join('')}
              <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab">
                  <div style="display: flex; flex: 8.5"></div>
                  <div style="display: flex; flex: 2">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${`Total Transfers`}</h5>
                  </div>
                  <div style="display: flex; flex: 1.5">
                    <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${priceFormat(
                      dateMap[currentDate].totalTransfers
                    )}</h5>
                  </div>
                </div>
            
          `
              : ``
          }
        `
            : ''
        }
      `
        )
        .join('')}
    `);
    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const runPendingIoltaClientBalancesReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let caseRes;
    let case_ids;
    let [ms, ds, ys] = dateShortFormat(new Date(startDate)).split('/');
    let [me, de, ye] = dateShortFormat(new Date(endDate)).split('/');
    let start = new Date(`${ys}-${ms}-${ds}`).setHours(0, 0, 0) - 86400000;
    let end = new Date(`${ye}-${me}-${de}`).setHours(23, 59, 59);
    if (activeType === 'all') {
      //get all ioltas
      res = await configService.default.getAllPendingIoltas(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas - ', res.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(companyId, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPendingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case') {
      // get ioltas with caseId
      res = await configService.default.getAllPendingIoltasByCase(
        caseId,
        start,
        end
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas by Case - ', res.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(caseType, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPendingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case_sub_type') {
      res = await configService.default.getAllPendingIoltasByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('Got ioltas for case sub type', res.result);
      }
      // get ioltas with case sub type
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(caseStatus, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPendingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPendingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.result) {
      const caseMap = createIoltaClientBalancesMap(
        start,
        end,
        res.result,
        excludeZero,
        excludeZeroToOne
      );
      // console.log('Returned CaseMap', caseMap);
      openPrintPendingIoltaClientBalancesNewReport(activeType, caseMap);
    }
  };

  const openPrintPendingIoltaClientBalancesNewReport = (
    activeType,
    caseMap
  ) => {
    if (!validateInfo()) return;
    if (!caseMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');

    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
    <div style="display: flex; flex: 0">
      <img
        height="61"
        width="100"
        alt=""
        src="/assets/img/logo.png"
      />
    </div>
    <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">IOLTA (Pending) Client Balances - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
  </div>
  
  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; margin-top: 1rem; background-color: #345; color: #fefefe">
    <div style="display: flex; flex: 0.5;">
      <h5 style="padding: .5rem; margin: 0;">SL</h5>
    </div>
    <div style="display: flex; flex: 1;">
      <h5 style="padding: .5rem; margin: 0;">Case #</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Category</h5>
    </div>
    <div style="display: flex; flex: 3;">
      <h5 style="padding: .5rem; margin: 0;">Company Name</h5>
    </div>
    <div style="display: flex; flex: 3;">
      <h5 style="padding: .5rem; margin: 0;">Primary Name</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
    </div>
    <div style="display: flex; flex: 1.5;">
      <h5 style="padding: .5rem; margin: 0;">Balance</h5>
    </div>
  </div>
  
  ${Object.keys(caseMap)
    .map(
      (csId, i) => `
  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
  <div style="display: flex; flex: 0.5; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${i + 1}</h5>
  </div>
  <div style="display: flex; flex: 1; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
      caseMap[csId].case.caseNum
    }</h5>
  </div>
  <div style="display: flex; flex: 2; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${
      caseMap[csId].case.caseType
    }</h5>
  </div>
  <div style="display: flex; flex: 3; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
      caseMap[csId].case.companyName || ''
    }</h5>
  </div>
  <div style="display: flex; flex: 3; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
      caseMap[csId].case.primaryContact.fullName
    }</h5>
  </div>
  <div style="display: flex; flex: 2; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
      caseMap[csId].case.caseSubType || ''
    }</h5>
  </div>
  <div style="display: flex; flex: 1.5; margin: auto;">
    <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${priceFormat(
      caseMap[csId].balance
    )}</h5>
  </div>
  </div>
  `
    )
    .join('')}
  
  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; border-top: 1px solid #9ab9;">
  <div style="display: flex; flex: 10.5; margin: auto;">
  </div>
  <div style="display: flex; flex: 1; margin: auto;">
  <h5 style="padding: .5rem; margin: 0;">Total:</h5>
  </div>
  <div style="display: flex; flex: 1.5; margin: auto;">
    <h5 style="padding: .5rem; margin: 0;">${priceFormat(
      Object.keys(caseMap).reduce((acc, cur) => {
        return acc + caseMap[cur].balance;
      }, 0)
    )}</h5>
  </div>
  </div>
  
    `);

    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const runPendingIoltaClientStatementReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let caseRes;
    let case_ids;
    let [ms, ds, ys] = dateShortFormat(new Date(startDate)).split('/');
    let [me, de, ye] = dateShortFormat(new Date(endDate)).split('/');
    let start = new Date(`${ys}-${ms}-${ds}`).setHours(0, 0, 0) - 86400000;
    let end = new Date(`${ye}-${me}-${de}`).setHours(23, 59, 59);
    if (activeType === 'all') {
      //get all ioltas
      res = await configService.default.getAllPendingIoltas(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas - ', res.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(companyId, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPendingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case') {
      // get ioltas with caseId
      res = await configService.default.getAllPendingIoltasByCase(
        caseId,
        start,
        end
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all ioltas by Case - ', res.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(caseType, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPendingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case_sub_type') {
      res = await configService.default.getAllPendingIoltasByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('Got ioltas for case sub type', res.result);
      }
      // get ioltas with case sub type
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(caseStatus, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPendingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPendingIoltasByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.result) {
      const caseMap = createIoltaClientBalancesMap(
        start,
        end,
        res.result,
        excludeZero,
        excludeZeroToOne
      );
      // console.log('Returned CaseMap', caseMap);
      openPrintPendingIoltaClientStatementNewReport(activeType, caseMap);
    }
  };

  const openPrintPendingIoltaClientStatementNewReport = (
    activeType,
    caseMap
  ) => {
    if (!validateInfo()) return;
    if (!caseMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');

    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
    <div style="display: flex; flex: 0">
      <img
        height="61"
        width="100"
        alt=""
        src="/assets/img/logo.png"
      />
    </div>
    <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">IOLTA (Pending) Client Statement - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
  </div>
  
  ${Object.keys(caseMap)
    .map(
      (csId) => `
      <div style="display: flex; flex-direction: row; justify-content: start; align-items: baseline; margin-top: 1rem;">
      <h3 style="font-weight: 500; padding: 0; margin: 5px 10px; text-transform: capitalize;">Case #${
        caseMap[csId].case.caseNum
      }
      <span style="font-weight: 400; font-size: 14px; margin-left: 5px; text-transform: capitalize">(${
        caseMap[csId].case.caseType
      })</span>    
      </h3>
  
      ${
        caseMap[csId].case.companyName
          ? `<h4 style="font-weight: 500; font-size: 14px; padding: 0; margin: 5px 10px; text-transform: capitalize;"><span style="font-weight: 400">Company -</span> ${caseMap[csId].case.companyName}</h4>`
          : ``
      }
  
      <h4 style="font-weight: 500; font-size: 14px; padding: 0; margin: 5px 10px; text-transform: capitalize;"><span style="font-weight: 400">Client -</span> ${
        caseMap[csId].case.primaryContact.fullName || ''
      }</h4>
    </div>
  
  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; margin-top: 1rem; background-color: #345; color: #fefefe">
    <div style="display: flex; flex: 0.5;">
      <h5 style="padding: .5rem; margin: 0;">SL</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Date</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Sub Type</h5>
    </div>
    <div style="display: flex; flex: 4;">
      <h5 style="padding: .5rem; margin: 0;">Description</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Type</h5>
    </div>
    <div style="display: flex; flex: 1.5;">
      <h5 style="padding: .5rem; margin: 0;">Amount</h5>
    </div>
  </div>
  
  ${caseMap[csId].ioltas
    .map(
      (pt, i) => `
        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
        <div style="display: flex; flex: 0.5; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${i + 1}</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${dateFormat(
            new Date(pt.ioltaDate)
          )}</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            pt.caseType || ''
          }</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${
            PAYMENT_IOLTA_TYPES_MAP[pt.subType]
          }</h5>
        </div>
        <div style="display: flex; flex: 4; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            pt.desc
          }</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${
            pt.type
          }</h5>
        </div>
        <div style="display: flex; flex: 1.5; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${priceFormat(
            pt.amount
          )}</h5>
        </div>
        </div>
        `
    )
    .join('')}
  
        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; border-top: 1px solid #9ab9;">
          <div style="display: flex; flex: 10.5; margin: auto;">
          </div>
          <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0;">Total Balance:</h5>
          </div>
          <div style="display: flex; flex: 1.5; margin: auto;">
            <h5 style="padding: .5rem; margin: 0;">${priceFormat(
              caseMap[csId].balance
            )}</h5>
          </div>
        </div>
      `
    )
    .join('')}`);

    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const pendingIoltaReportBtns = [
    {
      label: 'IOLTA (Pending) Daily Transactions',
      value: 'iolta_daily_transaction_reports',
      onClick: () => {
        runPendingIoltaDailyRevenueReport();
      },
    },
    {
      label: 'IOLTA (Pending) Balance',
      value: 'iolta_balance_reports',
      onClick: () => {
        runPendingIoltaClientBalancesReport();
      },
    },
    {
      label: 'IOLTA (Pending) Statement',
      value: 'iolta_statement_reports',
      onClick: () => {
        runPendingIoltaClientStatementReport();
      },
    },
    // {
    //   label: 'Collection List (Pending)',
    //   value: 'collection_list_reports_pending',
    //   onClick: () => {
    //     runPendingCollectionListReport();
    //   },
    //   // disabled: true,
    // },
  ];

  const runBillingNotesReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let caseRes;
    let case_ids;
    let [ms, ds, ys] = dateShortFormat(new Date(startDate)).split('/');
    let [me, de, ye] = dateShortFormat(new Date(endDate)).split('/');
    let start = new Date(`${ys}-${ms}-${ds}`).setHours(0, 0, 0) - 86400000;
    let end = new Date(`${ye}-${me}-${de}`).setHours(23, 59, 59);
    if (activeType === 'all') {
      //get all notes
      res = await configService.default.getAllBillingNotes(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all notes - ', res.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(companyId, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllBillingNotesByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got notes for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get notes with list of caseIds
    } else if (activeType === 'case') {
      // get notes with caseId
      res = await configService.default.getAllBillingNotesByCase(
        caseId,
        start,
        end
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all notes by Case - ', res.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(caseType, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllBillingNotesByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(caseStatus, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllBillingNotesByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllBillingNotesByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got ioltas for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get ioltas with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.result) {
      const caseMap = createBillingNotesMap(res.result);
      // console.log('Returned CaseMap', caseMap);
      openPrintBillingNotesReport(activeType, caseMap);
    }
  };

  const openPrintBillingNotesReport = (activeType, caseMap) => {
    if (!validateInfo()) return;
    if (!caseMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');

    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
    <div style="display: flex; flex: 0">
      <img
        height="61"
        width="100"
        alt=""
        src="/assets/img/logo.png"
      />
    </div>
    <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">Billing Notes - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
  </div>
  
  ${Object.keys(caseMap)
    .map(
      (csId) => `
      <hr style="margin-bottom: 0; margin-top: 30px; border: 1px solid #eeeffe" />
      <div style="display: flex; flex-direction: row; justify-content: start; align-items: baseline; margin-top: 1.5rem;">
      <h3 style="font-weight: 500; padding: 0; margin: 5px 10px; text-transform: capitalize;">Case #${
        caseMap[csId].case.caseNum
      }
      <span style="font-weight: 400; font-size: 14px; margin-left: 5px; text-transform: capitalize">(${
        caseMap[csId].case.caseType
      })</span>    
      </h3>
  
      ${
        caseMap[csId].case.companyName
          ? `<h4 style="font-weight: 500; font-size: 14px; padding: 0; margin: 5px 10px; text-transform: capitalize;"><span style="font-weight: 400">Company -</span> ${caseMap[csId].case.companyName}</h4>`
          : ``
      }
  
      <h4 style="font-weight: 500; font-size: 14px; padding: 0; margin: 5px 10px; text-transform: capitalize;"><span style="font-weight: 400">Client -</span> ${
        caseMap[csId].case.primaryContact.fullName || ''
      }</h4>
    </div>
  
  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; margin-top: 1rem;">
    <div style="display: flex; flex: 0.5; background-color: #345; color: #fefefe">
      <h5 style="padding: .5rem; margin: 0;">SL</h5>
    </div>
    <div style="display: flex; flex: 2; background-color: #345; color: #fefefe">
      <h5 style="padding: .5rem; margin: 0;">Date</h5>
    </div>
    <div style="display: flex; flex: 2; background-color: #345; color: #fefefe">
      <h5 style="padding: .5rem; margin: 0;">Created By</h5>
    </div>
    <div style="display: flex; flex: 6; background-color: #345; color: #fefefe">
      <h5 style="padding: .5rem; margin: 0;">Description</h5>
    </div>
  </div>
  
  ${caseMap[csId].notes
    .map(
      (nt, i) => `
        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
        <div style="display: flex; flex: 0.5; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${i + 1}</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${dateTimeFormat(
            new Date(nt.createdAt)
          )}</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${getUserName(
            nt.createdBy
          )}</h5>
        </div>
        <div style="display: flex; flex: 6; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            nt.desc
          }</h5>
        </div>
        </div>
        `
    )
    .join('')}
  
      `
    )
    .join('')}`);

    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const runCostsReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let caseRes;
    let case_ids;
    let [ms, ds, ys] = dateShortFormat(new Date(startDate)).split('/');
    let [me, de, ye] = dateShortFormat(new Date(endDate)).split('/');
    let start = new Date(`${ys}-${ms}-${ds}`).setHours(0, 0, 0) - 86400000;
    let end = new Date(`${ye}-${me}-${de}`).setHours(23, 59, 59);
    if (activeType === 'all') {
      //get all payments
      res = await configService.default.getAllCosts(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all payments - ', res.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(companyId, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllCostsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'case') {
      // get payments with caseId
      res = await configService.default.getAllCostsByCase(caseId, start, end);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all payments by Case - ', res.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(caseType, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllCostsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'case_sub_type') {
      res = await configService.default.getAllCostsByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('Got payments for case_sub_type', res.result);
      }
      // get payments with case_sub_type
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(caseStatus, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllCostsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllCostsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.result) {
      const caseMap = createCostsMap(start, end, res.result);
      // console.log('Returned CaseMap', caseMap);
      openPrintCostsReport(activeType, caseMap);
    }
  };

  const openPrintCostsReport = (activeType, caseMap) => {
    if (!validateInfo()) return;
    if (!caseMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');

    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
    <div style="display: flex; flex: 0">
      <img
        height="61"
        width="100"
        alt=""
        src="/assets/img/logo.png"
      />
    </div>
    <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">Case Costs - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
  </div>

  ${Object.keys(caseMap)
    .map(
      (csId) => `
      <div style="display: flex; flex-direction: row; justify-content: start; align-items: baseline; margin-top: 1rem;">
      <h3 style="font-weight: 500; padding: 0; margin: 5px 10px; text-transform: capitalize;">Case #${
        caseMap[csId].case.caseNum
      }
      <span style="font-weight: 400; font-size: 14px; margin-left: 5px; text-transform: capitalize">(${
        caseMap[csId].case.caseType
      })</span>    
      </h3>
  
      ${
        caseMap[csId].case.companyName
          ? `<h4 style="font-weight: 500; font-size: 14px; padding: 0; margin: 5px 10px; text-transform: capitalize;"><span style="font-weight: 400">Company -</span> ${caseMap[csId].case.companyName}</h4>`
          : ``
      }
  
      <h4 style="font-weight: 500; font-size: 14px; padding: 0; margin: 5px 10px; text-transform: capitalize;"><span style="font-weight: 400">Client -</span> ${
        caseMap[csId].case.primaryContact.fullName || ''
      }</h4>
    </div>

  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; margin-top: 1rem; background-color: #345; color: #fefefe">
    <div style="display: flex; flex: 0.5;">
      <h5 style="padding: .5rem; margin: 0;">SL</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Completed By</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Opened At</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Completed At</h5>
    </div>
    <div style="display: flex; flex: 3;">
      <h5 style="padding: .5rem; margin: 0;">Description</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Closing Status</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Time Spent</h5>
    </div>
  </div>

  ${caseMap[csId].costs
    .map(
      (pt, i) => `
        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
        <div style="display: flex; flex: 0.5; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${i + 1}</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            pt.caseType
          }</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${
            pt.completedBy && pt.completedBy.length
              ? pt.completedBy.map((usr) => getUserName(usr)).join(', ')
              : ''
          }</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${dateFormat(
            new Date(pt.openDateStamp)
          )}</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            pt.completionDate && pt.completionDate.length
              ? dateFormat(new Date(pt.completionDateStamp))
              : ''
          }</h5>
        </div>
        <div style="display: flex; flex: 3; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            pt.desc
          }</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            CASE_STATUS_MAP[pt.statusEnd] || ''
          }</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${getDurationFromOpenCloseDates(
            pt
          )}</h5>
        </div>
        </div>
        `
    )
    .join('')}

        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; border-top: 1px solid #9ab9;">
          <div style="display: flex; flex: 11.5; margin: auto;">
          </div>
          <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0;">Total Time Spent:</h5>
          </div>
          <div style="display: flex; flex: 2; margin: auto;">
            <h5 style="padding: .5rem; margin: 0;">${getDurationFromMills(
              caseMap[csId].total
            )}</h5>
          </div>
        </div>
      `
    )
    .join('')}`);

    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const runPercentagesReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let caseRes;
    let case_ids;
    let [ms, ds, ys] = dateShortFormat(new Date(startDate)).split('/');
    let [me, de, ye] = dateShortFormat(new Date(endDate)).split('/');
    let start = new Date(`${ys}-${ms}-${ds}`).setHours(0, 0, 0) - 86400000;
    let end = new Date(`${ye}-${me}-${de}`).setHours(23, 59, 59);
    if (activeType === 'all') {
      //get all payments
      res = await configService.default.getAllPercentages(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all payments - ', res.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(companyId, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPercentagesByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'case') {
      // get payments with caseId
      res = await configService.default.getAllPercentagesByCase(
        caseId,
        start,
        end
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all payments by Case - ', res.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(caseType, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPercentagesByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'case_sub_type') {
      res = await configService.default.getAllPercentagesByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('Got payments for case_sub_type', res.result);
      }
      // get payments with case_sub_type
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(caseStatus, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPercentagesByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllPercentagesByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.result) {
      const caseMap = createPercentagesMap(start, end, res.result);
      // console.log('Returned CaseMap', caseMap);
      openPrintPercentagesReport(activeType, caseMap);
    }
  };

  const openPrintPercentagesReport = (activeType, caseMap) => {
    if (!validateInfo()) return;
    if (!caseMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');

    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
    <div style="display: flex; flex: 0">
      <img
        height="61"
        width="100"
        alt=""
        src="/assets/img/logo.png"
      />
    </div>
    <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">Percentages - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
  </div>

  ${Object.keys(caseMap)
    .map(
      (csId) => `
      <div style="display: flex; flex-direction: row; justify-content: start; align-items: baseline; margin-top: 1rem;">
      <h3 style="font-weight: 500; padding: 0; margin: 5px 10px; text-transform: capitalize;">Case #${
        caseMap[csId].case.caseNum
      }
      <span style="font-weight: 400; font-size: 14px; margin-left: 5px; text-transform: capitalize">(${
        caseMap[csId].case.caseType
      })</span>    
      </h3>
  
      ${
        caseMap[csId].case.companyName
          ? `<h4 style="font-weight: 500; font-size: 14px; padding: 0; margin: 5px 10px; text-transform: capitalize;"><span style="font-weight: 400">Company -</span> ${caseMap[csId].case.companyName}</h4>`
          : ``
      }
  
      <h4 style="font-weight: 500; font-size: 14px; padding: 0; margin: 5px 10px; text-transform: capitalize;"><span style="font-weight: 400">Client -</span> ${
        caseMap[csId].case.primaryContact.fullName || ''
      }</h4>
    </div>

  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; margin-top: 1rem; background-color: #345; color: #fefefe">
    <div style="display: flex; flex: 0.5;">
      <h5 style="padding: .5rem; margin: 0;">SL</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Completed By</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Completed At</h5>
    </div>
    <div style="display: flex; flex: 4;">
      <h5 style="padding: .5rem; margin: 0;">Description</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">% Complete</h5>
    </div>
  </div>

  ${Object.keys(caseMap[csId].caseTypes)
    .map(
      (caseType) => `
      <div style="display: flex; flex-direction: column">
      ${caseMap[csId].caseTypes[caseType].percentages
        .map(
          (pt, i) => `
            <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
            <div style="display: flex; flex: 0.5; margin: auto;">
              <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
                i + 1
              }</h5>
            </div>
            <div style="display: flex; flex: 2; margin: auto;">
              <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
                pt.caseType
              }</h5>
            </div>
            <div style="display: flex; flex: 2; margin: auto;">
              <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${pt.completedBy
                .map((usr) => getUserName(usr))
                .join(', ')}</h5>
            </div>
            <div style="display: flex; flex: 2; margin: auto;">
              <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${dateFormat(
                new Date(pt.completionDateStamp)
              )}</h5>
            </div>
            <div style="display: flex; flex: 4; margin: auto;">
              <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
                pt.desc
              }</h5>
            </div>
            <div style="display: flex; flex: 2; margin: auto;">
              <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
                pt.percentComplete || ''
              }</h5>
            </div>
            </div>
            `
        )
        .join('')}
        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; border-top: 1px solid #9ab5;">
          <div style="display: flex; flex: 8.5; margin: auto;">
          </div>
          <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 600">${caseType} Subtotal</h5>
          </div>
          <div style="display: flex; flex: 2; margin: auto;">
            <h5 style="padding: .5rem; margin: 0; font-weight: 600">${
              caseMap[csId].caseTypes[caseType].subTotal
            }</h5>
          </div>
        </div>

      </div>
    `
    )
    .join('')}

        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; border-top: 1px solid #9ab9;">
          <div style="display: flex; flex: 8.5; margin: auto;">
          </div>
          <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0;">Total Percentage</h5>
          </div>
          <div style="display: flex; flex: 2; margin: auto;">
            <h5 style="padding: .5rem; margin: 0;">${caseMap[csId].total}</h5>
          </div>
        </div>
      `
    )
    .join('')}`);

    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const runTimesheetsReport = async () => {
    if (!validateInfo()) return;
    const configService = require('../store/config/service');
    let res;
    let caseRes;
    let case_ids;
    let [ms, ds, ys] = dateShortFormat(new Date(startDate)).split('/');
    let [me, de, ye] = dateShortFormat(new Date(endDate)).split('/');
    let start = new Date(`${ys}-${ms}-${ds}`).setHours(0, 0, 0) - 86400000;
    let end = new Date(`${ye}-${me}-${de}`).setHours(23, 59, 59);
    if (activeType === 'all') {
      //get all payments
      res = await configService.default.getAllTimesheets(start, end, officeType);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all payments - ', res.result);
      }
    } else if (activeType === 'company') {
      caseRes = await configService.default.getAllCasesByCompany(companyId, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by companyId');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllTimesheetsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'case') {
      // get payments with caseId
      res = await configService.default.getAllTimesheetsByCase(
        caseId,
        start,
        end
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all payments by Case - ', res.result);
      }
    } else if (activeType === 'case_type') {
      caseRes = await configService.default.getAllCasesByType(caseType, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_type');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllTimesheetsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'case_sub_type') {
      res = await configService.default.getAllTimesheetsByCaseSubType(
        caseSubType,
        start,
        end,
        officeType
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('Got payments for case_sub_type', res.result);
      }
      // get payments with case_sub_type
    } else if (activeType === 'case_status') {
      caseRes = await configService.default.getAllCasesByStatus(caseStatus, officeType);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by case_status');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllTimesheetsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    } else if (activeType === 'office') {
      caseRes = await configService.default.getAllCasesByOffice(office);
      if (!caseRes) {
      }
      if (caseRes.error) {
      }
      if (caseRes.result) {
        // console.log('got cases by office');
        case_ids = caseRes.result.map((cs) => cs.id);
        if (case_ids && case_ids.length) {
          res = await configService.default.getAllTimesheetsByCaseList(
            case_ids.join(','),
            start,
            end
          );
          if (!res) {
          }
          if (res.error) {
          }
          if (res.result) {
            // console.log('Got payments for company', res.result);
          }
        }
      }
      // get cases with companyId
      // get payments with list of caseIds
    }
    if (!res) {
      alert('No data found for filters applied!');
    }
    if (res.result) {
      const caseMap = createTimesheetsMap(start, end, res.result);
      // console.log('Returned CaseMap', caseMap);
      openPrintTimesheetsReport(activeType, caseMap);
    }
  };

  const openPrintTimesheetsReport = (activeType, caseMap) => {
    if (!validateInfo()) return;
    if (!caseMap) return alert('No data found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body style="padding: 2rem 0rem;">');

    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
    <div style="display: flex; flex: 0">
      <img
        height="61"
        width="100"
        alt=""
        src="/assets/img/logo.png"
      />
    </div>
    <div style="display: flex; flex: 1; flex-direction: column; align-items: flex-start; justify-content: center;">
          <h3 style="font-weight: 500; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">Timesheets - ${
            ACTIVE_TYPES_MAP[activeType]
          }</h3>
          <h4 style="font-weight: 400; padding: 0 1rem; margin: 5px 10px; text-transform: capitalize;">${
            officeType === 'all' ? 'All Offices' : officeType
          }</h4>
        </div>
        <div style="display: flex;">
          <h4 style="font-weight: 400; padding: 0 1rem;">${dateFormat(
            Date.now()
          )}</h4>
        </div>
  </div>

  ${Object.keys(caseMap)
    .map(
      (csId) => `
      <div style="display: flex; flex-direction: row; justify-content: start; align-items: baseline; margin-top: 1rem;">
      <h3 style="font-weight: 500; padding: 0; margin: 5px 10px; text-transform: capitalize;">Case #${
        caseMap[csId].case.caseNum
      }
      <span style="font-weight: 400; font-size: 14px; margin-left: 5px; text-transform: capitalize">(${
        caseMap[csId].case.caseType
      })</span>    
      </h3>
  
      ${
        caseMap[csId].case.companyName
          ? `<h4 style="font-weight: 500; font-size: 14px; padding: 0; margin: 5px 10px; text-transform: capitalize;"><span style="font-weight: 400">Company -</span> ${caseMap[csId].case.companyName}</h4>`
          : ``
      }
  
      <h4 style="font-weight: 500; font-size: 14px; padding: 0; margin: 5px 10px; text-transform: capitalize;"><span style="font-weight: 400">Client -</span> ${
        caseMap[csId].case.primaryContact.fullName || ''
      }</h4>
    </div>

  <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; margin-top: 1rem; background-color: #345; color: #fefefe">
    <div style="display: flex; flex: 0.5;">
      <h5 style="padding: .5rem; margin: 0;">SL</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Case Type</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Completed By</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Completed At</h5>
    </div>
    <div style="display: flex; flex: 4;">
      <h5 style="padding: .5rem; margin: 0;">Description</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Case Rate</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Hours Spent</h5>
    </div>
    <div style="display: flex; flex: 2;">
      <h5 style="padding: .5rem; margin: 0;">Total Rate</h5>
    </div>
  </div>

  ${caseMap[csId].timesheets
    .map(
      (pt, i) => `
        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
        <div style="display: flex; flex: 0.5; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${i + 1}</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            pt.caseType
          }</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400; text-transform: capitalize;">${pt.completedBy
            .map((usr) => getUserName(usr))
            .join(', ')}</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${dateFormat(
            new Date(pt.completionDateStamp)
          )}</h5>
        </div>
        <div style="display: flex; flex: 4; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            pt.desc
          }</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            pt.isHourly ? priceFormat(pt.rate) : 'Flat'
          }</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            pt.totalTimeSpent || ''
          }</h5>
        </div>
        <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0; font-weight: 400;">${
            pt.isHourly ? priceFormat(pt.totalTimeSpent * pt.rate) : ''
          }</h5>
        </div>
        </div>
        `
    )
    .join('')}

        <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px; border-top: 1px solid #9ab9;">
          <div style="display: flex; flex: 10.5; margin: auto;">
          </div>
          <div style="display: flex; flex: 2; margin: auto;">
          <h5 style="padding: .5rem; margin: 0;">Total</h5>
          </div>
          <div style="display: flex; flex: 2; margin: auto;">
            <h5 style="padding: .5rem; margin: 0;">${caseMap[csId].total}</h5>
          </div>
          <div style="display: flex; flex: 2; margin: auto;">
            <h5 style="padding: .5rem; margin: 0;">${priceFormat(
              caseMap[csId].totalRate
            )}</h5>
          </div>
        </div>
      `
    )
    .join('')}`);

    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const miscReportBtns = [
    {
      label: 'Billing Notes Report',
      value: 'billing_notes_reports',
      onClick: () => {
        runBillingNotesReport();
      },
    },
    {
      label: 'Case Costs Report',
      value: 'case_cost_reports',
      onClick: () => {
        runCostsReport();
      },
      // disabled: true,
    },
    {
      label: 'Percentages Report',
      value: 'percentages_report',
      onClick: () => {
        runPercentagesReport();
      },
      // disabled: true,
    },
    {
      label: 'Timesheets Report',
      value: 'timesheets_report',
      onClick: () => {
        runTimesheetsReport();
      },
      // disabled: true,
    },
  ];

  return (
    <div className="w-100">
      <h4 className="text-dark">{'Financials & Accounting Reports'}</h4>

      <div className="row my-3">
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 col-12">
          <Input
            type="date"
            placeholder={'Select start date'}
            value={startDate}
            label={'Start Date'}
            onChange={(e) => {
              if (e.target.value && e.target.value.length)
                handleError('startDate', '');
              setStartDate(e.target.value);
              setStartStamp(
                +new Date(new Date(e.target.value).setHours(0, 0, 0))
              );
            }}
            err={errors.startDate || ''}
          />
        </div>
        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 col-12">
          <Input
            type="date"
            placeholder={'Select end date'}
            value={endDate}
            label={'End Date'}
            onChange={(e) => {
              if (e.target.value && e.target.value.length)
                handleError('endDate', '');
              setEndDate(e.target.value);
              setEndStamp(
                +new Date(new Date(e.target.value).setHours(23, 59, 59))
              );
            }}
            err={errors.endDate || ''}
          />
        </div>
      </div>

      <div className="row my-3">
        <div className="d-flex justify-content-start align-items-center flex-wrap">
          <p className="mb-1 fw-normal me-3">Select Office</p>
          <div className="col-xl-6 col-lg-8 col-md-12 col-sm-12 col-xs-12 col-12">
            <div>
              <button
                className={`btn btn-sm btn-${
                  officeType === 'all' ? 'primary shadow' : 'light'
                } me-2 mb-2`}
                onClick={() => {
                  if (officeType !== 'all') {
                    setOfficeType('all');
                  }
                }}
              >
                All
              </button>
              <button
                className={`btn btn-sm btn-${
                  officeType === 'dallas' ? 'primary shadow' : 'light'
                } me-2 mb-2`}
                onClick={() => {
                  if (officeType !== 'dallas') {
                    setOfficeType('dallas');
                  }
                }}
              >
                Dallas
              </button>
              <button
                className={`btn btn-sm btn-${
                  officeType === 'houston' ? 'primary shadow' : 'light'
                } me-2 mb-2`}
                onClick={() => {
                  if (officeType !== 'houston') {
                    setOfficeType('houston');
                  }
                }}
              >
                Houston
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr className="small-hr-light mt-2 mb-4" />

      <div className="row my-3">
        <div className="col-xl-6 col-lg-8 col-md-12 col-sm-12 col-xs-12 col-12">
          <div>
            <button
              className={`btn btn-sm btn-${
                activeType === 'all' ? 'primary shadow' : 'light'
              } me-2 mb-2`}
              onClick={() => {
                if (activeType !== 'all') {
                  setActiveType('all');
                }
              }}
            >
              All Cases
            </button>
            <button
              className={`btn btn-sm btn-${
                activeType === 'company' ? 'primary shadow' : 'light'
              } me-2 mb-2`}
              onClick={() => {
                if (activeType !== 'company') {
                  setActiveType('company');
                }
              }}
            >
              Search By Company
            </button>
            <button
              className={`btn btn-sm btn-${
                activeType === 'case' ? 'primary shadow' : 'light'
              } me-2 mb-2`}
              onClick={() => {
                if (activeType !== 'case') {
                  setActiveType('case');
                  setOfficeType('all');
                }
              }}
            >
              Search By Case
            </button>

            <button
              className={`btn btn-sm btn-${
                activeType === 'case_type' ? 'primary shadow' : 'light'
              } me-2 mb-2`}
              onClick={() => {
                if (activeType !== 'case_type') {
                  setActiveType('case_type');
                }
              }}
            >
              Search By Case Category
            </button>

            <button
              className={`btn btn-sm btn-${
                activeType === 'case_sub_type' ? 'primary shadow' : 'light'
              } me-2 mb-2`}
              onClick={() => {
                if (activeType !== 'case_sub_type') {
                  setActiveType('case_sub_type');
                }
              }}
            >
              Search By Case Type
            </button>

            <button
              className={`btn btn-sm btn-${
                activeType === 'case_status' ? 'primary shadow' : 'light'
              } me-2 mb-2`}
              onClick={() => {
                if (activeType !== 'case_status') {
                  setActiveType('case_status');
                }
              }}
            >
              Search By Case Status
            </button>

            <button
              className={`btn btn-sm btn-${
                activeType === 'office' ? 'primary shadow' : 'light'
              } me-2 mb-2`}
              onClick={() => {
                if (activeType !== 'office') {
                  setActiveType('office');
                  setOfficeType('all');
                }
              }}
            >
              Search By Office
            </button>
          </div>

          {activeType === 'case' ? (
            <div className="row my-3">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12">
                <Select
                  options={cases}
                  placeholder={''}
                  value={caseId}
                  label={'Select Case'}
                  onChange={(e) => {
                    setCaseId(e.target.value);
                  }}
                  err={errors.caseId || ''}
                />
              </div>
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12">
                <Input
                  label={'Search by Case Number'}
                  placeholder={'type case number'}
                  type={'text'}
                  onChange={(e) => {
                    let matches = [];
                    matches = cases.filter(
                      (cs) => cs.caseNumForSearch === e.target.value
                    );
                    if (matches.length === 1) {
                      // console.log('matched!!');
                      setCaseId(matches[0].value);
                    }
                    handleError('caseNum', '');
                  }}
                  err={errors.caseNum || ''}
                />
              </div>
            </div>
          ) : (
            <React.Fragment />
          )}

          {activeType === 'company' ? (
            <div className="row my-3">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12">
                <Select
                  options={companies}
                  placeholder={''}
                  value={companyId}
                  label={'Select Company'}
                  onChange={(e) => {
                    setCompanyId(e.target.value);
                  }}
                  err={errors.companyId || ''}
                />
              </div>
            </div>
          ) : (
            <React.Fragment />
          )}

          {activeType === 'case_type' ? (
            <div className="row my-3">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12">
                <Select
                  options={CASE_TYPES}
                  placeholder={''}
                  value={caseType}
                  label={'Select Case Category'}
                  onChange={(e) => {
                    setCaseType(e.target.value);
                  }}
                  err={errors.caseType || ''}
                />
              </div>
            </div>
          ) : (
            <React.Fragment />
          )}

          {activeType === 'case_sub_type' ? (
            <div className="row my-3">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12">
                <Select
                  options={CASE_SUB_TYPES}
                  placeholder={''}
                  value={caseSubType}
                  label={'Select Case Type'}
                  onChange={(e) => {
                    setCaseSubType(e.target.value);
                  }}
                  err={errors.caseSubType || ''}
                />
              </div>
            </div>
          ) : (
            <React.Fragment />
          )}

          {activeType === 'case_status' ? (
            <div className="row my-3">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12">
                <Select
                  options={CASE_STATUSES}
                  placeholder={''}
                  value={caseStatus}
                  label={'Select Case Status'}
                  onChange={(e) => {
                    setCaseStatus(e.target.value);
                  }}
                  err={errors.caseStatus || ''}
                />
              </div>
            </div>
          ) : (
            <React.Fragment />
          )}

          {activeType === 'office' ? (
            <div className="row my-3">
              <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12">
                <Select
                  options={OFFICES}
                  placeholder={''}
                  value={office}
                  label={'Select Office'}
                  onChange={(e) => {
                    setOffice(e.target.value);
                  }}
                  err={errors.office || ''}
                />
              </div>
            </div>
          ) : (
            <React.Fragment />
          )}

          <div className="row my-3">
            <div className="col-12">
              <Checkbox
                type="checkbox"
                checked={excludeZero}
                onClick={() => {
                  setExcludeZero((current) => !current);
                }}
                label={'Exclude zero balances'}
                namex="excludeZero"
                idx="excludeZeroYes"
              />
            </div>
            <div className="col-12">
              <Checkbox
                type="checkbox"
                checked={excludeZeroToOne}
                onClick={() => {
                  setExcludeZeroToOne((current) => !current);
                }}
                label={'Exclude balances between 0.01 & 1.0'}
                namex="excludeZeroToOne"
                idx="excludeZeroToOneYes"
              />
            </div>
            {/* <div className="col-12">
              <Checkbox
                type="checkbox"
                disabled
                checked={printToMail}
                onClick={() => {
                  setPrintToMail((current) => !current);
                }}
                label={'Print to Email'}
                namex="printToMail"
                idx="printToMailYes"
              />
            </div> */}
          </div>
        </div>
        <div className="col-xl-6 col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12"></div>
      </div>

      <hr style={{ marginTop: '-1rem' }} />

      <h5 className="fw-light text-dark mt-4">Revenue Reports</h5>
      <div className="d-flex flex-wrap justify-content-start align-items-center ">
        {reportBtns && reportBtns.length ? (
          reportBtns.map((btn, i) => (
            <button
              key={i}
              className="btn btn btn-primary me-2 mb-2"
              onClick={btn.onClick}
              disabled={!!btn.disabled}
            >
              {btn.label}
            </button>
          ))
        ) : (
          <React.Fragment />
        )}
      </div>

      <h5 className="fw-light text-dark mt-4">IOLTA Reports</h5>

      <div className="d-flex flex-wrap justify-content-start align-items-center ">
        {ioltaReportBtns && ioltaReportBtns.length ? (
          ioltaReportBtns.map((btn, i) => (
            <button
              key={i}
              className="btn btn btn-primary me-2 mb-2"
              onClick={btn.onClick}
            >
              {btn.label}
            </button>
          ))
        ) : (
          <React.Fragment />
        )}
      </div>

      <h5 className="fw-light text-dark mt-4">IOLTA (Pending) Reports</h5>

      <div className="d-flex flex-wrap justify-content-start align-items-center ">
        {pendingIoltaReportBtns && pendingIoltaReportBtns.length ? (
          pendingIoltaReportBtns.map((btn, i) => (
            <button
              key={i}
              className="btn btn btn-primary me-2 mb-2"
              onClick={btn.onClick}
            >
              {btn.label}
            </button>
          ))
        ) : (
          <React.Fragment />
        )}
      </div>

      <h5 className="fw-light text-dark mt-4">IOLTA (Filing Fees) Reports</h5>

      <div className="d-flex flex-wrap justify-content-start align-items-center ">
        {filingIoltaReportBtns && filingIoltaReportBtns.length ? (
          filingIoltaReportBtns.map((btn, i) => (
            <button
              key={i}
              className="btn btn btn-primary me-2 mb-2"
              onClick={btn.onClick}
            >
              {btn.label}
            </button>
          ))
        ) : (
          <React.Fragment />
        )}
      </div>

      <h5 className="fw-light text-dark mt-4">Misc. Reports</h5>

      <div className="d-flex flex-wrap justify-content-start align-items-center ">
        {miscReportBtns && miscReportBtns.length ? (
          miscReportBtns.map((btn, i) => (
            <button
              key={i}
              className="btn btn btn-primary me-2 mb-2"
              onClick={btn.onClick}
            >
              {btn.label}
            </button>
          ))
        ) : (
          <React.Fragment />
        )}
      </div>
    </div>
  );
};

export default FinancialsReport;
