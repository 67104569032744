import React, { useState } from 'react';
import {
  RadialBarChart,
  RadialBar,
  Cell,
  LabelList,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';

const RadialStatCard = (props) => {
  const { title, data, dataKey } = props;
  const colors = scaleOrdinal(schemeCategory10).range();
  const style = { lineHeight: '24px', left: 300 };

  return (
    <div className="card chart-card pb-2">
      <div className="card-head px-3 py-3 d-flex justify-content-start align-items-center">
        <div>
          <h5 className="fw-semi-bold">{title}</h5>
        </div>
      </div>
      <div className="card-body p-0 py-2 m-0 d-flex justify-content-center align-items-center" style={{ fontSize: '.8rem' }}>
        {/* <ResponsiveContainer width="95%" height={400}> */}
          <RadialBarChart
          width={300}
          height={400}
            cx={150}
            cy={150}
            innerRadius={25}
            outerRadius={140}
            barSize={14}
            data={data}
          >
            <RadialBar minPointSize={20} background dataKey={dataKey}>
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} />
              ))}
              <LabelList position="insideEnd" fill="#fff" fontSize={12} />
            </RadialBar>
            <Tooltip />
            <Legend
              iconSize={10}
              width="100%"
              height={50}
              layout="horizontal"
            />
          </RadialBarChart>
        {/* </ResponsiveContainer> */}
      </div>
    </div>
  );
};

export default RadialStatCard;
