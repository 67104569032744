export const getAuthToken = () => {
  return JSON.parse(localStorage.getItem('alo_auth_token')) || 'NO_TOKEN_FOUND';
};

export const setAuthToken = (token) => {
  if (!token) return;
  return localStorage.setItem('alo_auth_token', JSON.stringify(token));
};

export const removeAuthToken = () => {
  return localStorage.removeItem('alo_auth_token');
};
