import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Input from '../common/Input';
import Select from '../common/Select';
import {
  CASE_TYPES,
  CIS_TYPES,
  COUNTRIES,
  OFFICES,
  PAYMENT_TYPES,
  PHONE_TYPES,
  REFERRED_BY_TYPES,
} from '../../constants/constants';
import {
  getUserName,
  isValidEmail,
  isValidPhone,
  getMahDate,
  createCompany,
} from '../../utils/userUtils';
import TextArea from '../common/TextArea';
import Checkbox from '../common/Checkbox';
import { useHistory } from 'react-router-dom';

const CreateConsultation = (props) => {
  const { user, isLoggedIn } = props;

  const [consultationDate, setConsultationDate] = useState(getMahDate());
  const [office, setOffice] = useState('dallas');
  const [companies, setCompanies] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [caseType, setCaseType] = useState('company');
  const [consultationAttorney, setConsultationAttorney] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyNameForSearch, setCompanyNameForSearch] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [primaryName, setPrimaryName] = useState('');
  const [secondaryName, setSecondaryName] = useState('');
  const [line1, setLine1] = useState('');
  const [line2, setLine2] = useState('');
  const [city, setCity] = useState('');
  const [stateRegion, setStateRegion] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [consultationFee, setConsultationFee] = useState(0);
  const [referredBy, setReferredBy] = useState('');
  const [paymentType, setPaymentType] = useState('cash');
  // const [displayImg, setDisplayImg] = useState('');
  const [consultationNotes, setConsultationNotes] = useState('');
  const [attorneyNotes, setAttorneyNotes] = useState('');
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    getCompanyList(caseType);
  }, [caseType]);

  useEffect(() => {
    initEmployees();
  }, []);

  const initEmployees = async () => {
    if (!user || !user.userType) return;
    const configService = require('../../store/config/service');
    const res = await configService.default.getUsers(); // change to employees only
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      setEmployees(
        res.result.map((emp) => {
          return {
            label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
            value: emp.id,
          };
        })
      );
      setConsultationAttorney(res.result[0].id);
    }
  };

  const getCompanyList = async (caseType) => {
    if (caseType !== 'beneficiary') return;
    // fetch companies from db
    const configService = require('../../store/config/service');
    const res = await configService.default.getCompanies();
    // console.log('res', res);
    if (res.result) {
      setCompanies(
        res.result.map((company) => {
          return { label: company.companyName, value: company.id };
        })
      );
      setCompanyNameForSearch(res.result[0].companyName);
      setCompanyId(res.result[0].id);
    }
  };

  const validateCompany = () => {
    let flag = true;
    if (!companyName || !companyName.length) {
      handleError('companyName', 'add a valid company name');
    }
    return flag;
  };

  const createConsultation = async () => {
    if (!validateConsultation()) return;
    // console.log('Consultation is valid');
    const configService = require('../../store/config/service');
    let companyIdx;
    if (caseType === 'company') {
      const companyRes = await createCompany(companyName, validateCompany, companies);
      if (companyRes) {
        companyIdx = companyRes;
      }
    } else if (caseType === 'beneficiary') {
      companyIdx = companyId;
    }

    let body = {
      consultationDate: new Date(consultationDate),
      office,
      caseType,
      companyName: '',
      consultationAttorney,
      consultationFee: parseFloat(consultationFee),
      consultationFeeString: `${consultationFee}`,
      primaryName,
      address: {},
      email,
      createdBy: user.id,
    };

    if (companyIdx && companyIdx.length) body.company = companyIdx;
    if (
      (caseType === 'company' || caseType === 'beneficiary') &&
      companyNameForSearch &&
      companyNameForSearch.length
    )
      body.companyName = companyNameForSearch;
    if (secondaryName && secondaryName.length)
      body.secondaryName = secondaryName;
    if (line1 && line1.length) body.address.line1 = line1;
    if (line2 && line2.length) body.address.line2 = line2;
    if (city && city.length) body.address.city = city;
    if (stateRegion && stateRegion.length) body.address.state = stateRegion;
    if (zipCode && zipCode.length) body.address.zip = zipCode;
    if (country && country.length) body.address.country = country;
    if (phone && phone.length) body.phone = phone;
    if (consultationNotes && consultationNotes.length)
      body.consultationNotes = consultationNotes;
    if (attorneyNotes && attorneyNotes.length)
      body.attorneyNotes = attorneyNotes;
    if (referredBy && referredBy.length) body.referredBy = referredBy;
    if (paymentType && paymentType.length) body.paymentType = paymentType;

    // console.log('body - is - ', body);

    const res = await configService.default.createConsultation(body);
    if (res.result) {
      // console.log('created case - ', res.result);
      history.push(`/consultations/${res.result.id}`);
    }
  };

  const validateConsultation = () => {
    let flag = true;
    if (!consultationDate || !consultationDate.length) {
      flag = false;
      handleError('consultationDate', 'select a valid date');
    }
    if (!office || !office.length) {
      flag = false;
      handleError('office', 'select a valid office');
    }
    if (!caseType || !caseType.length) {
      flag = false;
      handleError('caseType', 'select a valid case type');
    }
    if (caseType !== 'individual') {
      if (!companyName && !companyId) {
        flag = false;
        handleError('companyName', 'add a valid company');
      }
    }
    if (!consultationAttorney || !consultationAttorney.length) {
      flag = false;
      handleError(
        'consultationAttorney',
        'select a valid consultation attorney'
      );
    }
    if (!consultationFee || consultationFee <= 0) {
      flag = false;
      handleError('consultationFee', 'add a valid consultationFee');
    }
    if (!primaryName || !primaryName.length) {
      flag = false;
      handleError('primaryName', 'please add a name');
    }
    if (!email || !email.length) {
      flag = false;
      handleError('email', 'please add a valid email');
    }
    return flag;
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  if (!user || !isLoggedIn) return <React.Fragment />;
  return (
    <div className="row">
      <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 col-12">
        <div className="card p-3">
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <h4>Create New Consultation</h4>
              <p className="text-muted fs-italic fw-light size15">
                Fill in the details below to initiate a new consultation!
              </p>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <button className="btn btn-primary" onClick={createConsultation}>
                Create New Consultation
              </button>
            </div>
          </div>
          <div className="row d-flex flex-wrap justify-content-start align-items-start">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Consultation Date'}
                placeholder={'enter consultation date'}
                type={'date'}
                value={consultationDate}
                onChange={(e) => {
                  setConsultationDate(e.target.value);
                  handleError('consultationDate', '');
                }}
                err={errors.consultationDate || ''}
              />
            </div>
            
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                label={'Office'}
                placeholder={'select an office'}
                value={office}
                options={OFFICES}
                onChange={(e) => {
                  setOffice(e.target.value);
                  handleError('office', '');
                }}
                err={errors.office || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                label={'Consultation Attorney'}
                placeholder={'select an attorney'}
                value={consultationAttorney}
                options={employees}
                onChange={(e) => {
                  setConsultationAttorney(e.target.value);
                  handleError('consultationAttorney', '');
                }}
                err={errors.consultationAttorney || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                label={'Case Category'}
                placeholder={'select a case category'}
                value={caseType}
                options={CASE_TYPES}
                onChange={(e) => {
                  setCaseType(e.target.value);
                  handleError('caseType', '');
                }}
                err={errors.caseType || ''}
              />
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
              {caseType === 'company' || caseType === 'individual' ? (
                <Input
                  disabled={caseType === 'individual'}
                  label={'Company / Petitioner name'}
                  placeholder={"enter company or petitioner's name"}
                  type={'text'}
                  autoCapitalize={'words'}
                  autoComplete={'off'}
                  value={companyName}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      e.target.value.length &&
                      errors.companyName &&
                      errors.companyName.length
                    ) {
                      handleError('companyName', '');
                    }
                    setCompanyName(e.target.value);
                    setCompanyNameForSearch(e.target.value);
                    handleError('companyName', '');
                  }}
                  err={errors.companyName || ''}
                />
              ) : caseType === 'beneficiary' ? (
                <Select
                  label={'Company'}
                  placeholder={'select a company'}
                  options={companies}
                  value={companyId}
                  onChange={(e) => {
                    if (
                      e.target.value &&
                      e.target.value.length &&
                      errors.companyId &&
                      errors.companyId.length
                    ) {
                      handleError('companyId', '');
                    }
                    setCompanyId(e.target.value);
                    setCompanyNameForSearch(
                      (
                        companies.filter(
                          (cm) => cm.value === e.target.value
                        )[0] || {}
                      ).label || ''
                    );
                  }}
                  err={errors.companyId || ''}
                />
              ) : (
                <React.Fragment />
              )}
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Primary Name'}
                placeholder={'enter primary full name'}
                type={'text'}
                value={primaryName}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.primaryName &&
                    errors.primaryName.length
                  ) {
                    handleError('primaryName', '');
                  }
                  setPrimaryName(e.target.value);
                }}
                err={errors.primaryName || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Secondary Name'}
                placeholder={'enter secondary full name'}
                type={'text'}
                value={secondaryName}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.secondaryName &&
                    errors.secondaryName.length
                  ) {
                    handleError('secondaryName', '');
                  }
                  setSecondaryName(e.target.value);
                }}
                err={errors.secondaryName || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <h4 className="size16 text-dark fw-semi-bold mb-1">Add Address</h4>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Line 1'}
                placeholder={'enter address line 1'}
                type={'text'}
                value={line1}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.line1 &&
                    errors.line1.length
                  ) {
                    handleError('line1', '');
                  }
                  setLine1(e.target.value);
                }}
                err={errors.line1 || ''}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Line 2'}
                placeholder={'enter address line 2'}
                type={'text'}
                value={line2}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.line2 &&
                    errors.line2.length
                  ) {
                    handleError('line2', '');
                  }
                  setLine2(e.target.value);
                }}
                err={errors.line2 || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'City'}
                placeholder={'enter address city'}
                type={'text'}
                value={city}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.city &&
                    errors.city.length
                  ) {
                    handleError('city', '');
                  }
                  setCity(e.target.value);
                }}
                err={errors.city || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'State'}
                placeholder={'enter address state'}
                type={'text'}
                value={stateRegion}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.stateRegion &&
                    errors.stateRegion.length
                  ) {
                    handleError('stateRegion', '');
                  }
                  setStateRegion(e.target.value);
                }}
                err={errors.stateRegion || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Zipcode'}
                placeholder={'enter address zipcode'}
                type={'text'}
                value={zipCode}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.zipCode &&
                    errors.zipCode.length
                  ) {
                    handleError('zipCode', '');
                  }
                  setZipCode(e.target.value);
                }}
                err={errors.zipCode || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Country / Nationality'}
                placeholder={'enter country / nationality'}
                type={'text'}
                value={country}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.country &&
                    errors.country.length
                  ) {
                    handleError('country', '');
                  }
                  setCountry(e.target.value);
                }}
                err={errors.country || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Email'}
                placeholder={'enter email address'}
                type={'text'}
                value={email}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.email &&
                    errors.email.length
                  ) {
                    handleError('email', '');
                  }
                  setEmail(e.target.value);
                }}
                err={errors && errors.email}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Phone number'}
                placeholder={'enter phone number'}
                type={'tel'}
                value={phone}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.phone &&
                    errors.phone.length
                  ) {
                    handleError('phone', '');
                  }
                  setPhone(e.target.value);
                }}
                err={errors && errors.phone}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Consultation Fee'}
                placeholder={'enter consultation fee'}
                type={'number'}
                value={consultationFee}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.consultationFee &&
                    errors.consultationFee.length
                  ) {
                    handleError('consultationFee', '');
                  }
                  setConsultationFee(e.target.value);
                }}
                err={errors && errors.consultationFee}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Referred By'}
                placeholder={'enter referred by'}
                type={'text'}
                value={referredBy}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.country &&
                    errors.country.length
                  ) {
                    handleError('referredBy', '');
                  }
                  setReferredBy(e.target.value);
                }}
                err={errors.referredBy || ''}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                label={'Payment Type'}
                placeholder={'select payment type'}
                options={PAYMENT_TYPES}
                value={paymentType}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.country &&
                    errors.country.length
                  ) {
                    handleError('paymentType', '');
                  }
                  setPaymentType(e.target.value);
                }}
                err={errors.paymentType || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
              <TextArea
                label={'Consultation Notes'}
                placeholder={'add consultation notes'}
                value={consultationNotes}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.consultationNotes &&
                    errors.consultationNotes.length
                  ) {
                    handleError('consultationNotes', '');
                  }
                  setConsultationNotes(e.target.value);
                }}
                err={errors && errors.consultationNotes}
              />
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
              <TextArea
                label={'Attorney Notes'}
                placeholder={'add attorney notes'}
                value={attorneyNotes}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.attorneyNotes &&
                    errors.attorneyNotes.length
                  ) {
                    handleError('attorneyNotes', '');
                  }
                  setAttorneyNotes(e.target.value);
                }}
                err={errors && errors.attorneyNotes}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <button className="btn btn-primary" onClick={createConsultation}>
              Create New Consultation
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth: { user, isLoggedIn } = {} } = state;
  return {
    user,
    isLoggedIn,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateConsultation);