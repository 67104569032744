import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  VISIBLE_APPOINTMENT_COLS,
  VISIBLE_TICKLER_REPORT_COLS,
} from '../constants/constants';
import TicklerReportCell from './TicklerReportCell';
import Input from './common/Input';
import Loading from './Loading';
import MyPagination from './common/MyPagination';
import NoEntry from './common/NoEntry';

const TicklerReportList = (props) => {
  const {
    ticklers,
    page,
    pageCount,
    setPage,
    searchQuery,
    setSearchQuery,
    loading,
  } = props;

  const [dataKeys, setDataKeys] = useState([]);

  const history = useHistory();

  useEffect(() => {
    initDataKeys(ticklers);
  }, [ticklers]);

  const initDataKeys = (ticklers) => {
    if (!ticklers || !ticklers.length) return;
    let newKeys = Object.keys(ticklers[0])
      .filter((k) => VISIBLE_TICKLER_REPORT_COLS.includes(k))
      .sort();
    return setDataKeys(newKeys);
  };

  const viewTickler = (ticklerId) => {
    if (!ticklerId || !ticklerId.length) return;
    history.push(`/ticklers/${ticklerId}`);
  };

  const keyToLabelMapping = {
    type: 'Tickler Type',
    dueDate: 'Due Date',
    assignedTo: 'Assigned To',
    createdBy: 'Created By',
    createdAt: 'Creation Date',
    case: 'Case Info',
  };

  return (
    <div className="tickler-card pb-2">
      {/* <div className="card-head pb-0 d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between align-items-center w-100">
          
        </div>
      </div> */}

      <div className="d-flex justify-content-start align-items-baseline px-2">
        <MyPagination page={page} setPage={setPage} pageCount={pageCount} />
        <div className="d-flex justify-content-start align-items-center w-100 px-3">
          <div className="max-width-24rem">
            <input
              className="form-control"
              placeholder={'type something..'}
              text={'text'}
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <div className="card-body p-0 m-0 w-100 d-flex flex-column justify-content-start align-items-start overflow-hidden">
          {dataKeys && dataKeys.length ? (
            <div className="table-head-row">
              <div className="table-head-col flex-pt5">Sl No.</div>
              {dataKeys.map((dataKey) => (
                <div
                  key={dataKey}
                  className={`table-head-col ${
                    dataKey === 'case' ? 'flex-two' : 'flex-one'
                  }`}
                >
                  {keyToLabelMapping[dataKey]}
                </div>
              ))}
            </div>
          ) : (
            <React.Fragment />
          )}
          {loading ? (
            <Loading />
          ) : ticklers && ticklers.length ? (
            ticklers.map((ticklerObj, i) => (
              <div key={i} className="w-100">
                <div className="table-row no-hover">
                  <div className="table-col mt-2 flex-pt5">
                    <span className="fw-light size14">
                      {(page - 1) * 20 + i + 1}.
                    </span>
                  </div>
                  {Object.keys(ticklerObj)
                    .sort()
                    .filter((item) =>
                      VISIBLE_TICKLER_REPORT_COLS.includes(item)
                    )
                    .map((dataKey) => (
                      <div
                        key={dataKey}
                        className={`table-col ${
                          dataKey === 'case' ? 'flex-two' : 'flex-one'
                        }`}
                      >
                        <TicklerReportCell
                          dataKey={dataKey}
                          data={ticklerObj[dataKey]}
                        />
                      </div>
                    ))}
                </div>
                <div className="table-row no-hover">
                  <div className="table-col">
                    <TicklerReportCell
                      dataKey={'desc'}
                      data={ticklerObj['desc']}
                    />
                  </div>
                </div>
              </div>
            ))
          ) : (
            <NoEntry />
          )}
        </div>
      )}
    </div>
  );
};

export default TicklerReportList;
