import React, { useEffect, useState } from 'react';
import ConsultInfo from './ConsultInfo';

const Consultation = (props) => {
  const { caseData, user } = props;
  const [consultData, setConsultData] = useState({});
  useEffect(() => {
    initConsult(caseData);
  }, [caseData]);

  const initConsult = async (caseData) => {
    if (!caseData || !caseData.id) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getConsultationsByCaseId(
      caseData.id
    );
    if (res.result && res.result.length) {
      // console.log('consultss', res.result);
      const res2 = await configService.default.getConsultation(
        res.result[0].id
      );
      setConsultData(res2.result);
    }
  };
  return (
    <div className="w-100">
      <ConsultInfo
        consultData={consultData}
        user={user}
        reinitialize={() => initConsult(caseData)}
        onCase
      />
    </div>
  );
};

export default Consultation;
