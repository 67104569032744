import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { myGreenSuperLight } from '../../constants/colors';
import { CONTRACT_TYPES, USER_TYPES } from '../../constants/constants';
import { getUserName, isValidEmail } from '../../utils/userUtils';
import Checkbox from '../common/Checkbox';
import Input from '../common/Input';
import MultiSelect from '../common/MultiSelect';
import MyMessage from '../common/MyMessage';
import Select from '../common/Select';
import Loading from '../Loading';
import TextArea from '../common/TextArea';

const EditUserModal = (props) => {
  const { visible, onClose, user, reinitialize, employeeId } = props;

  const [editing, setEditing] = useState(false);
  const [resettingPass, setResettingPass] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userCreationType, setUserCreationType] = useState('');
  const [email, setEmail] = useState('');
  const [designation, setDesignation] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [sureToDelete, setSureToDelete] = useState(false);
  const [showSureSelect, setShowSureSelect] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initUserForEdit();
  }, [visible, employeeId]);

  const initUserForEdit = async () => {
    try {
      if (!visible || !employeeId || !employeeId.length) return;
      setLoading(true);
      const configService = require('../../store/config/service');
      const res = await configService.default.getUser(employeeId);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log(res.result);
        // add data to inputs.
        setFirstName(res.result.name.firstName);
        setLastName(res.result.name.lastName);
        setUserCreationType(res.result.userType);
        setEmail(res.result.email);
        setDesignation(res.result.designation);
        setIsActive(res.result.isActive || false);
        if (res.result.userType && res.result.userType === 'Admin') {
          setIsAdmin(true);
        }
        setErrors({});
        setMessage({});
        setLoading(false);
      }
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const updateUser = async () => {
    if (!validateUser()) return;
    const configService = require('../../store/config/service');

    let body = {
      name: {
        firstName,
        lastName,
      },
      email,
      // password,
      userType: userCreationType,
      designation,
      isActive,
    };

    // console.log('body - ', body);

    const res = await configService.default.updateUser(employeeId, body);
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const resetPassword = async () => {
    if (!validatePassword()) return;
    const configService = require('../../store/config/service');

    let body = {
      userId: employeeId,
      password: newPassword,
    };

    // console.log('body - ', body);

    const res = await configService.default.resetPassword(body);
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const validateUser = () => {
    let flag = true;
    if (!editing && !resettingPass) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'Click on edit to change the data',
      });
    }
    if ((resettingPass && !newPassword) || !newPassword.length) {
      flag = false;
      handleError('newPassword', 'add a valid password please');
    }
    if (!firstName || !firstName.length) {
      flag = false;
      handleError('firstName', 'select a valid case type');
    }
    if (!lastName || !lastName.length) {
      flag = false;
      handleError('lastName', 'select a valid case type');
    }
    // if (!password || !password.length) {
    //   flag = false;
    //   handleError('password', 'select a valid case type');
    // }
    if (!email || !email.length || !isValidEmail(email)) {
      flag = false;
      handleError('email', 'enter a valid email');
    }
    if (!userCreationType || !userCreationType.length) {
      flag = false;
      handleError('userCreationType', 'select a valid user type');
    }
    if (!designation || !designation.length) {
      flag = false;
      handleError('designation', 'enter a designation');
    }
    if (!user || !user.userType || user.userType !== 'Admin') {
      flag = false;
      setMessage({ type: 'warning', msg: 'Something went wrong!' });
    }
    if (!employeeId || !employeeId.length) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    return flag;
  };

  const validatePassword = () => {
    let flag = true;
    if ((resettingPass && !newPassword) || !newPassword.length) {
      flag = false;
      handleError('newPassword', 'add a valid password please');
    }
    return flag;
  }

  const deleteUser = async () => {
    if (!employeeId || !employeeId.length) return;
    if (!user || !user.userType || user.userType !== 'Admin')
      return setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    if (isAdmin)
      return setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    if (!showSureSelect) return setShowSureSelect(true);
    if (showSureSelect && !sureToDelete)
      return handleError('sureToDelete', 'you have to check this');

    const configService = require('../../store/config/service');
    const res = await configService.default.deleteUser(employeeId);

    if (!res) {
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (res.error) {
      setMessage({
        type: 'warning',
        msg: res.error,
      });
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  const toggleResetPassword = () => {
    if (!user || !user.userType || user.userType !== 'Admin') return;
    setResettingPass(!resettingPass);
  };

  const doOnClose = () => {
    resetBlankModal();
    onClose();
  };

  const resetBlankModal = () => {
    setEditing(false);
    setResettingPass(false);
    setFirstName('');
    setLastName('');
    setUserCreationType('');
    setEmail('');
    setNewPassword('');
    setDesignation('');
    setShowSureSelect(false);
    setSureToDelete(false);
    setIsAdmin(false);
    setErrors({});
    setMessage({});
    setLoading(false);
  };

  return visible ? (
    <div className="my-modal-container">
      <div className="my-modal-overlay" onClick={doOnClose}></div>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable my-modal z-index-9">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <div>
              <p className="modal-title mb-0">
                {showSureSelect
                  ? 'Deleting'
                  : editing
                  ? 'Editing'
                  : resettingPass
                  ? 'Resetting Password'
                  : 'Viewing'}
              </p>
              <h3 className="modal-title fw-bold">{'User'}</h3>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={doOnClose}
            ></button>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="modal-body">
              {showSureSelect ? (
                <>
                  <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                    <div>
                      <small className="badge rounded-pill bg-warning text-dark fw-normal mb-1">
                        Important
                      </small>
                      <p className="fw-light text-muted italic size14pt5 px-2">
                        You are about to delete this user! This action is
                        irreversible.
                      </p>
                      <div className="row g-md-2">
                        <div className="col-12 my-3 mx-auto px-3">
                          <label htmlFor="splittingWith" className="form-label">
                            Are You Sure?
                          </label>

                          <div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="areWeSure"
                                id="areWeSureYes"
                                checked={sureToDelete}
                                onClick={() => {
                                  if (!sureToDelete)
                                    handleError('sureToDelete', '');
                                  setSureToDelete(!sureToDelete);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="areWeSureYes"
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                          {errors && errors.sureToDelete ? (
                            <div className="mt-1">
                              <small className="text-red size14">
                                {errors && errors.sureToDelete}
                              </small>
                            </div>
                          ) : (
                            <React.Fragment />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {!resettingPass ? (
                    <>
                      {editing ? (
                        <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                          <div className="mb-2">
                            <p className="text-muted fs-italic fw-light size15">
                              Change the details below and update this user!
                            </p>

                            <div className="row d-flex flex-wrap justify-content-start align-items-start">
                              <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                                <Select
                                  label={'User Type'}
                                  placeholder={'select a user type'}
                                  value={userCreationType}
                                  options={USER_TYPES}
                                  onChange={(e) => {
                                    setUserCreationType(e.target.value);
                                    handleError('userCreationType', '');
                                  }}
                                  err={errors.userCreationType || ''}
                                />
                              </div>

                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                <Input
                                  label={'Designation'}
                                  placeholder={'enter designation'}
                                  type={'text'}
                                  value={designation}
                                  onChange={(e) => {
                                    if (
                                      e.target.value &&
                                      e.target.value.length &&
                                      errors.designation &&
                                      errors.designation.length
                                    ) {
                                      handleError('designation', '');
                                    }
                                    setDesignation(e.target.value);
                                  }}
                                  err={errors.designation || ''}
                                />
                              </div>

                              <hr className="hy-light-hr mt-3 mb-4" />

                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                <Input
                                  label={'First Name'}
                                  placeholder={'enter first name'}
                                  type={'text'}
                                  value={firstName}
                                  onChange={(e) => {
                                    if (
                                      e.target.value &&
                                      e.target.value.length &&
                                      errors.firstName &&
                                      errors.firstName.length
                                    ) {
                                      handleError('firstName', '');
                                    }
                                    setFirstName(e.target.value);
                                  }}
                                  err={errors.firstName || ''}
                                />
                              </div>

                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                <Input
                                  label={'Last Name'}
                                  placeholder={'enter last name'}
                                  type={'text'}
                                  value={lastName}
                                  onChange={(e) => {
                                    if (
                                      e.target.value &&
                                      e.target.value.length &&
                                      errors.lastName &&
                                      errors.lastName.length
                                    ) {
                                      handleError('lastName', '');
                                    }
                                    setLastName(e.target.value);
                                  }}
                                  err={errors.lastName || ''}
                                />
                              </div>

                              <hr className="hy-light-hr mt-3 mb-4" />

                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                <Input
                                  label={'Email'}
                                  placeholder={'enter email'}
                                  type={'text'}
                                  value={email}
                                  onChange={(e) => {
                                    if (
                                      e.target.value &&
                                      e.target.value.length &&
                                      errors.email &&
                                      errors.email.length &&
                                      isValidEmail(e.target.value)
                                    ) {
                                      handleError('email', '');
                                    }
                                    setEmail(e.target.value);
                                  }}
                                  err={errors.email || ''}
                                />
                              </div>

                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                <Checkbox
                                  type="checkbox"
                                  checked={isActive}
                                  onClick={() => {
                                    setIsActive((current) => !current);
                                  }}
                                  label={'Is Active?'}
                                  namex="isActive"
                                  idx="isActiveYes"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
                        </div>
                      ) : (
                        <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                          <div className="mb-2">
                            <p className="text-muted fs-italic fw-light size15">
                              Click on Edit to edit the data!
                            </p>

                            <div className="row d-flex flex-wrap justify-content-start align-items-start">
                              <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                                <Select
                                  disabled
                                  label={'User Type'}
                                  placeholder={'select a user type'}
                                  value={userCreationType}
                                  options={USER_TYPES}
                                  onChange={(e) => {
                                    setUserCreationType(e.target.value);
                                    handleError('userCreationType', '');
                                  }}
                                  err={errors.userCreationType || ''}
                                />
                              </div>

                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                <Input
                                  disabled
                                  label={'Designation'}
                                  placeholder={'enter designation'}
                                  type={'text'}
                                  value={designation}
                                  onChange={(e) => {
                                    if (
                                      e.target.value &&
                                      e.target.value.length &&
                                      errors.designation &&
                                      errors.designation.length
                                    ) {
                                      handleError('designation', '');
                                    }
                                    setDesignation(e.target.value);
                                  }}
                                  err={errors.designation || ''}
                                />
                              </div>

                              <hr className="hy-light-hr mt-3 mb-4" />

                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                <Input
                                  disabled
                                  label={'First Name'}
                                  placeholder={'enter first name'}
                                  type={'text'}
                                  value={firstName}
                                  onChange={(e) => {
                                    if (
                                      e.target.value &&
                                      e.target.value.length &&
                                      errors.firstName &&
                                      errors.firstName.length
                                    ) {
                                      handleError('firstName', '');
                                    }
                                    setFirstName(e.target.value);
                                  }}
                                  err={errors.firstName || ''}
                                />
                              </div>

                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                <Input
                                  disabled
                                  label={'Last Name'}
                                  placeholder={'enter last name'}
                                  type={'text'}
                                  value={lastName}
                                  onChange={(e) => {
                                    if (
                                      e.target.value &&
                                      e.target.value.length &&
                                      errors.lastName &&
                                      errors.lastName.length
                                    ) {
                                      handleError('lastName', '');
                                    }
                                    setLastName(e.target.value);
                                  }}
                                  err={errors.lastName || ''}
                                />
                              </div>

                              <hr className="hy-light-hr mt-3 mb-4" />

                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                <Input
                                  disabled
                                  label={'Email'}
                                  placeholder={'enter email'}
                                  type={'text'}
                                  value={email}
                                  onChange={(e) => {
                                    if (
                                      e.target.value &&
                                      e.target.value.length &&
                                      errors.email &&
                                      errors.email.length &&
                                      isValidEmail(e.target.value)
                                    ) {
                                      handleError('email', '');
                                    }
                                    setEmail(e.target.value);
                                  }}
                                  err={errors.email || ''}
                                />
                              </div>

                              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                                <Checkbox
                                  disabled
                                  type="checkbox"
                                  checked={isActive}
                                  onClick={() => {
                                    setIsActive((current) => !current);
                                  }}
                                  label={'Is Active'}
                                  namex="isActive"
                                  idx="isActiveYes"
                                />
                              </div>

                              {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                        <Input
                          disabled
                          label={'Password'}
                          placeholder={'enter password'}
                          type={'text'}
                          value={password}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              e.target.value.length &&
                              errors.password &&
                              errors.password.length
                            ) {
                              handleError('password', '');
                            }
                            setPassword(e.target.value);
                          }}
                          err={errors.password || ''}
                        />
                      </div> */}
                            </div>
                          </div>

                          <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                        <div className="mb-2">
                          <p className="text-muted fs-italic fw-light size15">
                            Enter a new password to reset this user's old one!
                          </p>

                          <div className="row d-flex flex-wrap justify-content-start align-items-start">
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                              <Input
                                disabled
                                label={'Email'}
                                placeholder={'enter email'}
                                type={'text'}
                                value={email}
                                onChange={(e) => {
                                  if (
                                    e.target.value &&
                                    e.target.value.length &&
                                    errors.email &&
                                    errors.email.length &&
                                    isValidEmail(e.target.value)
                                  ) {
                                    handleError('email', '');
                                  }
                                  setEmail(e.target.value);
                                }}
                                err={errors.email || ''}
                              />
                            </div>

                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                              <Input
                                label={'New Password'}
                                placeholder={'enter new password'}
                                type={'text'}
                                value={newPassword}
                                onChange={(e) => {
                                  if (
                                    e.target.value &&
                                    e.target.value.length &&
                                    errors.newPassword &&
                                    errors.newPassword.length
                                  ) {
                                    handleError('newPassword', '');
                                  }
                                  setNewPassword(e.target.value);
                                }}
                                err={errors.newPassword || ''}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
                      </div>
                    </>
                  )}
                </>
              )}
              {!showSureSelect &&
              !resettingPass &&
              !editing &&
              user &&
              user.userType &&
              user.userType === 'Admin' ? (
                <div className="p-3 d-flex justify-content-end align-items-center">
                  <button
                    className="btn btn-warning btn-sm"
                    onClick={toggleResetPassword}
                  >
                    Reset Password
                  </button>
                </div>
              ) : (
                <React.Fragment />
              )}
            </div>
          )}
          <div className="px-3">
            <MyMessage
              visible={!!message}
              message={message}
              onClose={() => {
                setMessage(undefined);
              }}
            />
          </div>
          <div
            className="modal-footer d-flex justify-content-between align-items-center"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <div>
              {!isAdmin &&
              user &&
              user.userType &&
              user.userType === 'Admin' &&
              !editing &&
              !resettingPass &&
              !showSureSelect ? (
                <span
                  className="btn btn-danger m-0 py-0 px-1 cursor-pointer"
                  onClick={deleteUser}
                >
                  <i className="bi bi-trash md-icon"></i>
                </span>
              ) : (
                <React.Fragment />
              )}
            </div>

            {!showSureSelect ? (
              <div className="d-flex flex-one justify-content-end align-items-center">
                {editing ? (
                  <button
                    type="button"
                    className="btn btn-outline ms-2"
                    onClick={() => setEditing(false)}
                  >
                    Cancel
                  </button>
                ) : resettingPass ? (
                  <button
                    type="button"
                    className="btn btn-outline ms-2"
                    onClick={() => setResettingPass(false)}
                  >
                    Cancel
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-outline ms-2"
                    onClick={doOnClose}
                  >
                    Close
                  </button>
                )}
                {user && user.userType === 'Admin' ? (
                  editing ? (
                    <button
                      type="button"
                      className="btn btn-primary ms-2"
                      onClick={updateUser}
                    >
                      Update User!
                    </button>
                  ) : resettingPass ? (
                    <button
                      type="button"
                      className="btn btn-primary ms-2"
                      onClick={resetPassword}
                    >
                      Reset Password!
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary ms-2"
                      onClick={() => {
                        setEditing(true);
                      }}
                    >
                      Edit
                    </button>
                  )
                ) : (
                  <React.Fragment />
                )}
              </div>
            ) : (
              <div className="d-flex flex-one justify-content-end align-items-center">
                <div className="d-flex align-tems-center justify-content-end">
                  <button
                    type="button"
                    className="btn btn-outline ms-2"
                    onClick={() => {
                      setShowSureSelect(false);
                      setSureToDelete(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger ms-2 capitalize"
                    onClick={deleteUser}
                  >
                    Delete User
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default EditUserModal;
