import React from 'react'

const NoEntry = () => {
    return (
        <div className="d-flex flex-column justify-content-center align-items-center w-100 p-2 py-3 bt-1">
            <h5 className="text-dark my-1">No data found!</h5>
            <p className="text-muted mb-0 my-1">Try changing the filters or search query.</p>
        </div>
    )
}

export default NoEntry
