import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Input from '../common/Input';
import Select from '../common/Select';
import {
  APPOINTMENT_TYPES,
  CASE_TYPES,
  CIS_TYPES,
  COUNTRIES,
  LOCATIONS,
  OFFICES,
  PAYMENT_TYPES,
  PHONE_TYPES,
  REFERRED_BY_TYPES,
} from '../../constants/constants';
import {
  getUserName,
  isValidEmail,
  isValidPhone,
  getMahDate,
  getCurrentDateTime,
  getNextQuarterHourDateTime,
} from '../../utils/userUtils';
import TextArea from '../common/TextArea';
import Checkbox from '../common/Checkbox';
import MultiSelect from '../common/MultiSelect';
import { format } from 'date-fns';
import MyMessage from '../common/MyMessage';

const CreateAppointment = (props) => {
  const { user, isLoggedIn } = props;

  const [cases, setCases] = useState([]);
  const [office, setOffice] = useState('dallas');
  const [caseNum, setCaseNum] = useState('');
  const [names, setNames] = useState([]);
  const [phones, setPhones] = useState([]);
  const [onOtherName, setOnOtherName] = useState(true);
  const [onOtherPhone, setOnOtherPhone] = useState(true);
  const [employees, setEmployees] = useState([]);
  const [caseId, setCaseId] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [otherName, setOtherName] = useState('');
  const [otherPhone, setOtherPhone] = useState('');
  const [appointmentType, setAppointmentType] = useState('interview');
  const [location, setLocation] = useState('office');
  const [details, setDetails] = useState('');
  const [startTime, setStartTime] = useState(getCurrentDateTime());
  const [endTime, setEndTime] = useState(getNextQuarterHourDateTime());
  const [charge, setCharge] = useState(0);
  const [appointmentWith, setAppointmentWith] = useState([]);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getCaseList();
  }, []);

  useEffect(() => {
    initEmployees();
  }, []);

  useEffect(() => {
    initNamesAndPhones(caseId);
  }, [caseId]);

  const initNamesAndPhones = (csId) => {
    if (!csId || !csId.length) return;
    if (csId === 'select-a-case') {
      setName('');
      setNames([]);
      setOtherName('');
      setOnOtherName(true);
      setPhone('');
      setPhones([]);
      setOtherPhone('');
      setOnOtherPhone(true);
      return;
    }
    setName('');
    setPhone('');
    setOnOtherName(false);
    setOnOtherPhone(false);
    let selected = cases.filter((cs) => cs.id === csId)[0];
    let newNames = [];
    if (selected.primaryContact && selected.primaryContact.fullName) {
      newNames.push({
        label: selected.primaryContact.fullName,
        value: selected.primaryContact.fullName,
      });
    }
    if (selected.secondaryContact && selected.secondaryContact.fullName) {
      newNames.push({
        label: selected.secondaryContact.fullName,
        value: selected.secondaryContact.fullName,
      });
    }
    let newPhones =
      selected.phones &&
      selected.phones.map((ph) => {
        return {
          label: `${ph.phoneNum} (${ph.type})`,
          value: `${ph.phoneNum} (${ph.type})`,
        };
      });
    if (!newNames.length) {
      setName('');
      setNames([]);
      setOtherName('');
      setOnOtherName(true);
    } else {
      setNames(newNames);
      setName(newNames[0].value);
      handleError('otherName', '');
    }
    if (!newPhones.length) {
      setPhone('');
      setPhones([]);
      setOtherPhone('');
      setOnOtherPhone(true);
    } else {
      setPhones(newPhones);
      setPhone(newPhones[0].value);
      handleError('otherPhone', '');
    }
    // console.log(names);
    // console.log(phones);
  };

  const initEmployees = async () => {
    if (!user || !user.userType) return;
    const configService = require('../../store/config/service');
    const res = await configService.default.getUsers(); // change to employees only
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      setEmployees(
        res.result
          .map((emp) => {
            return {
              label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
              value: emp.id,
            };
          })
          // .filter((el) => el.value !== user.id)
      );
    }
  };

  const getCaseList = async () => {
    // fetch companies from db
    const configService = require('../../store/config/service');
    const res = await configService.default.getAllCases();
    // console.log('res', res);
    if (res.result && res.result.length) {
      setCases([
        { label: 'Select a case', value: 'select-a-case' },
        ...res.result.map((cs) => {
          return { label: getCaseLabel(cs), value: cs.id, ...cs };
        }),
      ]);
    }
  };

  const getCaseLabel = (cs) => {
    // console.log(cs.openedAt);
    let o = format(new Date(cs.openedAt), 'do MMM yy');
    // console.log(o);
    let p = cs.primaryContact.fullName;
    let t = cs.caseType;
    let caseNum = cs.caseNum;
    return `(${caseNum}) - ${p} (${t}) (${o})`;
  };

  const createAppointment = async () => {
    if (!validateAppointment()) return;
    // console.log('appointment is valid');
    const configService = require('../../store/config/service');

    let body = {
      appointmentType,
      startTime,
      startTimeStamp: +new Date(startTime),
      endTime,
      endTimeStamp: +new Date(endTime),
      charge,
      createdBy: user.id,
    };

    if (caseId && caseId.length) {
      body.case = caseId;
    }
    if (onOtherPhone) {
      body.phone = otherPhone;
    } else {
      body.phone = phone;
    }
    if (onOtherName) {
      body.name = otherName;
    } else {
      body.name = name;
    }
    if (details && details.length) body.details = details;
    if (office && office.length) body.office = office;
    if (location && location.length) body.location = location;
    if (appointmentWith && appointmentWith.length) {
      body.appointmentWith = appointmentWith.map((usr) => usr.value);
    }

    // console.log('body - is - ', body);

    const res = await configService.default.createAppointment(body);
    if (!res) {
      setMessage({ type: 'warning', msg: 'no response from server' });
    }
    if (res.error) {
      setMessage({ type: 'danger', msg: res.error });
    }
    if (res.result) {
      setMessage({ type: 'success', msg: 'Created appointment succesfully!' });
      // console.log('created appointment - ', res.result);
    }
  };

  const validateAppointment = () => {
    let flag = true;
    if (!appointmentType || !appointmentType.length) {
      flag = false;
      handleError('appointmentType', 'select a valid appointment type');
    }
    // if (!caseId || !caseId.length) {
    //   flag = false;
    //   handleError('caseId', 'select a valid case');
    // }
    if (onOtherName && (!otherName || !otherName.length)) {
      flag = false;
      handleError('otherName', 'add a valid name');
    }
    if (onOtherPhone && (!otherPhone || !otherPhone.length)) {
      flag = false;
      handleError('otherPhone', 'add a valid phone number');
    }

    if (!onOtherName && (!name || !name.length)) {
      flag = false;
      handleError('name', 'select a valid name');
    }
    if (!onOtherPhone && (!phone || !phone.length)) {
      flag = false;
      handleError('phone', 'select a valid phone');
    }

    if (!location || !location.length) {
      flag = false;
      handleError('location', 'select a valid location');
    }
    if (!startTime || !startTime.length) {
      flag = false;
      handleError('startTime', 'select a valid start time');
    }
    if (!endTime || !endTime.length) {
      flag = false;
      handleError('endTime', 'select a valid end time');
    }
    // if (!appointmentWith || !appointmentWith.length) {
    //   flag = false;
    //   handleError('appointmentWith', 'select a valid employee');
    // }

    if (!user || !user.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    return flag;
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  if (!user || !isLoggedIn) return <React.Fragment />;
  return (
    <div className="row">
      <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-xs-12 col-12">
        <div className="card p-3">
          <div className="d-flex justify-content-between align-items-start">
            <div>
              <h4>Create New Appointment</h4>
              <p className="text-muted fs-italic fw-light size15">
                Fill in the details below to initiate a new appointment!
              </p>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <button className="btn btn-primary" onClick={createAppointment}>
                Create New Appointment
              </button>
            </div>
          </div>
          <div className="row d-flex flex-wrap justify-content-start align-items-start">
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Start Time'}
                placeholder={'add start time'}
                type={'datetime-local'}
                min={getCurrentDateTime()}
                value={startTime}
                onChange={(e) => {
                  setStartTime(e.target.value);
                  setEndTime(
                    getNextQuarterHourDateTime(new Date(e.target.value))
                  );
                  handleError('startTime', '');
                }}
                err={errors.startTime || ''}
              />
            </div>

            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'End Time'}
                placeholder={'add end time'}
                type={'datetime-local'}
                min={startTime}
                value={endTime}
                onChange={(e) => {
                  setEndTime(e.target.value);
                  handleError('endTime', '');
                }}
                err={errors.endTime || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                label={'Choose Case'}
                placeholder={'select a case'}
                value={caseId}
                options={cases}
                onChange={(e) => {
                  setCaseId(e.target.value);
                  handleError('caseId', '');
                }}
                err={errors.caseId || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Search by Case Number'}
                placeholder={'type case number'}
                type={'text'}
                value={caseNum}
                onChange={(e) => {
                  setCaseNum(e.target.value);
                  let matches = [];
                  matches = cases.filter(
                    (cs) => cs.caseNumForSearch === e.target.value
                  );
                  if (matches.length === 1) {
                    // console.log('matched!!');
                    setCaseId(matches[0].id);
                  }
                  handleError('caseNum', '');
                }}
                err={errors.caseNum || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                disabled={onOtherName}
                label={'Choose Name'}
                placeholder={'select a name'}
                value={name}
                options={names}
                onChange={(e) => {
                  setName(e.target.value);
                  handleError('name', '');
                }}
                err={errors.name || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                disabled={!onOtherName}
                label={'Other'}
                placeholder={'enter full name'}
                type={'text'}
                value={otherName}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.otherName &&
                    errors.otherName.length
                  ) {
                    handleError('otherName', '');
                  }
                  setOtherName(e.target.value);
                }}
                err={errors.otherName || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                disabled={onOtherPhone}
                label={'Choose Phone'}
                placeholder={'select a phone'}
                value={phone}
                options={phones}
                onChange={(e) => {
                  setPhone(e.target.value);
                  handleError('phone', '');
                }}
                err={errors.phone || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                disabled={!onOtherPhone}
                label={'Other'}
                placeholder={'enter phone number'}
                type={'tel'}
                value={otherPhone}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.otherPhone &&
                    errors.otherPhone.length
                  ) {
                    handleError('otherPhone', '');
                  }
                  setOtherPhone(e.target.value);
                }}
                err={errors.otherPhone || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Input
                label={'Charge'}
                placeholder={'enter charge'}
                type={'number'}
                value={charge}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.charge &&
                    errors.charge.length
                  ) {
                    handleError('charge', '');
                  }
                  setCharge(e.target.value);
                }}
                err={errors && errors.charge}
              />
            </div>

            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12">
              <MultiSelect
                label={'Appointment With'}
                displayValue={'label'}
                values={appointmentWith}
                options={employees}
                onSelect={(e) => {
                  setAppointmentWith(e);
                  handleError('appointmentWith', '');
                }}
                onRemove={(e) => {
                  setAppointmentWith(e);
                }}
                err={errors.appointmentWith || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
              <TextArea
                label={'Details'}
                placeholder={'add details'}
                value={details}
                onChange={(e) => {
                  if (
                    e.target.value &&
                    e.target.value.length &&
                    errors.details &&
                    errors.details.length
                  ) {
                    handleError('details', '');
                  }
                  setDetails(e.target.value);
                }}
                err={errors && errors.details}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                label={'Appointment Type'}
                placeholder={'select an appointment type'}
                value={appointmentType}
                options={APPOINTMENT_TYPES}
                onChange={(e) => {
                  setAppointmentType(e.target.value);
                  handleError('appointmentType', '');
                }}
                err={errors.appointmentType || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                label={'Location'}
                placeholder={'select a location'}
                value={location}
                options={LOCATIONS}
                onChange={(e) => {
                  setLocation(e.target.value);
                  handleError('location', '');
                }}
                err={errors.location || ''}
              />
            </div>

            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
              <Select
                label={'Office'}
                placeholder={'select an office'}
                value={office}
                options={OFFICES}
                onChange={(e) => {
                  setOffice(e.target.value);
                  handleError('office', '');
                }}
                err={errors.office || ''}
              />
            </div>

            <hr className="hy-light-hr mt-3 mb-4" />
          </div>
          <MyMessage
            visible={!!message}
            message={message}
            onClose={() => {
              setMessage({});
            }}
          />
          <div className="d-flex justify-content-end align-items-center">
            <button className="btn btn-primary" onClick={createAppointment}>
              Create New Appointment
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth: { user, isLoggedIn } = {} } = state;
  return {
    user,
    isLoggedIn,
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateAppointment);
