import React, { useEffect, useState } from 'react';
import { DAYS, MONTHS, YEARS } from '../constants/constants';
import Loading from './Loading';
import {
  getMonthEndDate,
  getMonthStartDate,
  daysInMonth,
} from '../utils/userUtils';
import { getDaysInMonth } from 'date-fns';
import Select from './common/Select';

const Calender = (props) => {
  const { user } = props;

  const [calenderWeeks, setCalenderWeeks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [month, setMonth] = useState(() => new Date().getMonth());
  const [year, setYear] = useState(() => new Date().getFullYear());
  const [firstDay, setFirstDay] = useState(undefined);

  useEffect(() => {
    initFirstDay(user, month, year);
  }, [user, month, year]);

  useEffect(() => {
    initCalenderWeeks(user, month, year);
  }, [user, firstDay]);

  const initFirstDay = (user, month, year) => {
    if (!user || !user.id || (!month && month !== 0) || !year) return;
    return setFirstDay(new Date(year, month).getDay());
  };

  const initMonthStartAndEnd = (month, year) => {
    let ms = new Date(year, month, 1);
    let me = new Date(year, month + 1, 0);
    return [ms, me];
  };

  const initCalenderWeeks = async (user, month, year) => {
    try {
      // console.log('reachingggg');
      if (firstDay === undefined) return;
      if (!user || !user.id || (!month && month !== 0) || !year) return;
      // console.log('reachingggg', { month, year, user });
      setLoading(true);
      setCalenderWeeks([]);
      const [monthStart, monthEnd] = initMonthStartAndEnd(month, year);
      // console.log('reachingggg', { month, year, monthStart, monthEnd });

      const configService = require('../store/config/service');
      const res = await configService.default.getAppointmentsForCalender(
        user.id,
        monthStart,
        monthEnd
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('meetings', res.result);
        fillDaysWithAppointments(month, year, res.result);
        setLoading(false);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const fillDaysWithAppointments = (month, year, apts) => {
    if (!firstDay) return;
    let days = [];
    let day = {};
    let dt = new Date();
    dt.setFullYear(year);
    dt.setMonth(month);
    let date = 1,
      dayApts = [],
      startTime;
    for (let i = 0; i < 6; i++) {
      // creates a calender row
      //creating individual cells, filling them up with data.
      for (let j = 0; j < 7; j++) {
        if (i === 0 && j < firstDay) {
          day = {};
          day.dateVal = '';
          days.push(day);
        } else if (date > getDaysInMonth(dt)) {
          break;
        } else {
          day = {};
          dayApts = apts.filter((apt) => {
            startTime = new Date(parseInt(`${apt.startTimeStamp}000`));
            if (startTime.getDate() === date) return apt;
          });
          day.dateVal = date.toString();
          day.apts = dayApts;
          days.push(day);
          date++;
        }
      }
    }
    chopWeeks(days);
  };

  const chopWeeks = (days) => {
    let weeks = [];
    for (let i = 0, j = days.length; i < j; i += 7) {
      let week = days.slice(i, i + 7);
      weeks = [...weeks, week];
    }
    // console.log('weeks -', { weeks });
    setCalenderWeeks(weeks);
  };

  const Month = (props) => {
    const { weeks } = props;
    if (!weeks || !weeks.length) <React.Fragment />;
    return (
      <div className="d-flex flex-column justify-content-start align-items-start w-100">
        {weeks.map((week) => (
          <Week week={week} />
        ))}
      </div>
    );
  };

  const Week = (props) => {
    const { week } = props;
    if (!week || !week.length) return <React.Fragment />;
    return (
      <div className="calender-row">
        {week.map((day) => (
          <Day dateVal={day.dateVal} appointments={day.apts} />
        ))}
      </div>
    );
  };

  const Day = (props) => {
    const { dateVal, appointments } = props;
    return (
      <div className="calender-col">
        <div
          className={`py-3 mx-1 position-relative ${
            appointments && appointments.length ? 'bg-super-light' : ''
          }`}
        >
          {dateVal}
          {appointments && appointments.length ? (
            <div className="position-absolute bottom-0 right-0">
              <small className="badge bg-primary text-white fw-regular px-1 rounded shadow-sm">
                {appointments.length}
              </small>
            </div>
          ) : (
            <React.Fragment />
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="card chart-card pb-2 h-100">
      <div className="card-head px-3 py-3 pb-0 d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between align-items-center w-100 mb-3">
          <h5 className="fw-semi-bold">Calender</h5>

          <div className="d-flex justify-content-end align-items-center">
            <Select
              placeholder={'select a month'}
              value={month}
              options={MONTHS}
              onChange={(e) => {
                setMonth(e.target.value);
              }}
            />

            <Select
              placeholder={'select a year'}
              value={year}
              options={YEARS}
              onChange={(e) => {
                setYear(e.target.value);
              }}
            />
          </div>

          {/* <div className="btn-group dropstart">
            <button
              type="button"
              className="btn btn-dark shadow dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              style={{ width: 'max-content' }}
              onClick={(e) => {
                // console.log('open sort modal');
              }}
            >
              This Month <i className="bi bi-clock-history ms-1"></i>
            </button>
            <ul className="dropdown-menu">
              <li>
                <a className="dropdown-item" href="#">
                  Settings
                </a>
              </li>
              <li>
                <a className="dropdown-item" onClick={() => {}}>
                  Log Out
                </a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>

      <div className="card-body p-0 m-0 mb-auto w-100 d-flex flex-column justify-content-start align-items-start">
        {DAYS && DAYS.length ? (
          <div className="calender-head-row">
            {DAYS.map((day) => (
              <div key={day} className="calender-head-col capitalize">
                {day}
              </div>
            ))}
          </div>
        ) : (
          <React.Fragment />
        )}
        {loading ? (
          <Loading />
        ) : calenderWeeks && calenderWeeks.length ? (
          <Month weeks={calenderWeeks} />
        ) : (
          <React.Fragment />
        )}
      </div>
      <hr className="hy-light-hr mt-2 mb-4" />
    </div>
  );
};

export default Calender;
