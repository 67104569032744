export const routeList = [
  {
    label: 'Dashboard',
    route: '/dashboard',
    icon: 'bi-speedometer',
    indent: 'ms-0',
    iconSize: 'size16',
    itemSize: 'size15 weight400',
    showHr: true,
  },

  {
    label: 'Cases',
    route: '/cases',
    icon: 'bi-collection',
    indent: 'ms-0',
    iconSize: 'size16',
    itemSize: 'size15 weight400',
  },

  {
    label: 'Create New',
    route: '/create-case',
    icon: 'bi-plus-fill-circle',
    indent: 'ms-4',
    iconSize: 'size15',
    itemSize: 'size14pt5',
    showHr: true,
  },

  {
    label: 'Consultations',
    route: '/consultations',
    icon: 'bi-person-lines-fill',
    indent: 'ms-0',
    iconSize: 'size16',
    itemSize: 'size15 weight400',
  },

  {
    label: 'Create New',
    route: '/create-consultation',
    indent: 'ms-4',
    iconSize: 'size15',
    itemSize: 'size14pt5',
    showHr: true,
  },

  {
    label: 'Appointments',
    route: '/appointments',
    icon: 'bi-people',
    indent: 'ms-0',
    iconSize: 'size16',
    itemSize: 'size15 weight400',
  },
  {
    label: 'Create New',
    route: '/create-appointment',
    indent: 'ms-4',
    iconSize: 'size15',
    itemSize: 'size14pt5',
    showHr: true,
  },

  {
    label: 'Reports',
    route: '/reports',
    icon: 'bi-people-fill',
    indent: 'ms-0',
    iconSize: 'size16',
    itemSize: 'size15 weight400',
    showHr: true,
  },

  {
    label: 'Users',
    route: '/users',
    icon: 'bi-people',
    indent: 'ms-0',
    iconSize: 'size16',
    itemSize: 'size15 weight400',
  },

  {
    label: 'Create New',
    route: '/create-user',
    icon: 'bi-plus-fill-circle',
    indent: 'ms-4',
    iconSize: 'size15',
    itemSize: 'size14pt5',
    showHr: true,
  },
  // {
  //   label: 'Smart Search',
  //   route: '/smart-search',
  //   indent: 'ms-4',
  //   iconSize: 'size15',
  //   itemSize: 'size14pt5',
  // },
  // {
  //   label: 'Clients',
  //   route: '/clients',
  //   indent: 'ms-4',
  //   iconSize: 'size15',
  //   itemSize: 'size14pt5',
  //   showHr: true,
  // },

  // {
  //   label: 'Insights',
  //   route: '/insights',
  //   icon: 'bi-clipboard-data',
  //   indent: 'ms-0',
  //   iconSize: 'size16',
  //   itemSize: 'size15 weight400',
  // },
  // {
  //   label: 'Sales',
  //   route: '/sales',
  //   indent: 'ms-4',
  //   iconSize: 'size15',
  //   itemSize: 'size14pt5',
  // },
  // {
  //   label: 'Productivity',
  //   route: '/productivity',
  //   indent: 'ms-4',
  //   iconSize: 'size15',
  //   itemSize: 'size14pt5',
  //   showHr: true,
  // },
  // {
  //   label: 'Messages',
  //   route: '/messages/mine',
  //   icon: 'bi-chat-dots',
  //   indent: 'ms-0',
  //   iconSize: 'size16',
  //   itemSize: 'size15 weight400',
  //   showHr: true,
  // },
];

export const PLATFORMS = [
  { label: 'Instagram', value: 'instagram' },
  { label: 'YouTube', value: 'youtube' },
  { label: 'Zomato', value: 'zomato' },
];

export const PAYMENT_MODES = [
  { label: 'UPI', value: 'upi' },
  { label: 'Bank Transfer', value: 'bank_transfer' },
  { label: 'Paytm', value: 'paytm' },
  { label: 'Google Pay', value: 'gpay' },
  { label: 'Cash', value: 'Cash' },
];

export const TOKEN_NOT_FOUND = 'NO_TOKEN_FOUND';

export const CARDS = [
  {
    title: 'Active Clients',
    desc: 'desc one',
    percentage: '+3.0%',
    count: 2324,
    myTintColor: '#33f945',
  },
  {
    title: 'Total Cases',
    desc: 'akjshdfkjasdhfljasdf',
    percentage: '-2.0%',
    count: 1234,
    myTintColor: '#f93345',
  },
  {
    title: 'Active Cases',
    desc: 'faksjdhfkjasdfkjsda',
    percentage: '+10.0%',
    count: 234,
    myTintColor: '#3345f9',
    dark: true,
  },
];

export const STAT_LINE_CARDS = [
  {
    roles: ['Admin', 'Employee'],
    title: 'Cases on Status (by month)',
    percentage: '+10.0%',
    data: [
      {
        name: 'Jan',
        Active: Math.floor(Math.random() * 99),
        Inactive: Math.floor(Math.random() * 99),
        'Not Proceeding': Math.floor(Math.random() * 99),
        'On Hold due to non payment': Math.floor(Math.random() * 99),
        Closed: Math.floor(Math.random() * 99),
      },
      {
        name: 'Feb',
        Active: Math.floor(Math.random() * 99),
        Inactive: Math.floor(Math.random() * 99),
        'Not Proceeding': Math.floor(Math.random() * 99),
        'On Hold due to non payment': Math.floor(Math.random() * 99),
        Closed: Math.floor(Math.random() * 99),
      },
      {
        name: 'Mar',
        Active: Math.floor(Math.random() * 99),
        Inactive: Math.floor(Math.random() * 99),
        'Not Proceeding': Math.floor(Math.random() * 99),
        'On Hold due to non payment': Math.floor(Math.random() * 99),
        Closed: Math.floor(Math.random() * 99),
      },
      {
        name: 'Apr',
        Active: Math.floor(Math.random() * 99),
        Inactive: Math.floor(Math.random() * 99),
        'Not Proceeding': Math.floor(Math.random() * 99),
        'On Hold due to non payment': Math.floor(Math.random() * 99),
        Closed: Math.floor(Math.random() * 99),
      },
      {
        name: 'May',
        Active: Math.floor(Math.random() * 99),
        Inactive: Math.floor(Math.random() * 99),
        'Not Proceeding': Math.floor(Math.random() * 99),
        'On Hold due to non payment': Math.floor(Math.random() * 99),
        Closed: Math.floor(Math.random() * 99),
      },
      {
        name: 'Jun',
        Active: Math.floor(Math.random() * 99),
        Inactive: Math.floor(Math.random() * 99),
        'Not Proceeding': Math.floor(Math.random() * 99),
        'On Hold due to non payment': Math.floor(Math.random() * 99),
        Closed: Math.floor(Math.random() * 99),
      },
      {
        name: 'Jul',
        Active: Math.floor(Math.random() * 99),
        Inactive: Math.floor(Math.random() * 99),
        'Not Proceeding': Math.floor(Math.random() * 99),
        'On Hold due to non payment': Math.floor(Math.random() * 99),
        Closed: Math.floor(Math.random() * 99),
      },
      {
        name: 'Aug',
        Active: Math.floor(Math.random() * 99),
        Inactive: Math.floor(Math.random() * 99),
        'Not Proceeding': Math.floor(Math.random() * 99),
        'On Hold due to non payment': Math.floor(Math.random() * 99),
        Closed: Math.floor(Math.random() * 99),
      },
      {
        name: 'Sep',
        Active: Math.floor(Math.random() * 99),
        Inactive: Math.floor(Math.random() * 99),
        'Not Proceeding': Math.floor(Math.random() * 99),
        'On Hold due to non payment': Math.floor(Math.random() * 99),
        Closed: Math.floor(Math.random() * 99),
      },
      {
        name: 'Oct',
        Active: Math.floor(Math.random() * 99),
        Inactive: Math.floor(Math.random() * 99),
        'Not Proceeding': Math.floor(Math.random() * 99),
        'On Hold due to non payment': Math.floor(Math.random() * 99),
        Closed: Math.floor(Math.random() * 99),
      },
      {
        name: 'Nov',
        Active: Math.floor(Math.random() * 99),
        Inactive: Math.floor(Math.random() * 99),
        'Not Proceeding': Math.floor(Math.random() * 99),
        'On Hold due to non payment': Math.floor(Math.random() * 99),
        Closed: Math.floor(Math.random() * 99),
      },
      {
        name: 'Dec',
        Active: Math.floor(Math.random() * 99),
        Inactive: Math.floor(Math.random() * 99),
        'Not Proceeding': Math.floor(Math.random() * 99),
        'On Hold due to non payment': Math.floor(Math.random() * 99),
        Closed: Math.floor(Math.random() * 99),
      },
    ],
    key1: 'Active',
    key2: 'Inactive',
    key3: 'Not Proceeding',
    key4: 'On Hold due to non payment',
    key5: 'Closed',
    dataKey: 'name',
  },
  {
    roles: ['Admin'],
    title: 'Case Type Revenue (by month)',
    percentage: '+4.0%',
    data: [
      {
        name: 'Jan',
        company: Math.floor(Math.random() * 99),
        beneficiary: Math.floor(Math.random() * 99),
        individual: Math.floor(Math.random() * 99),
      },
      {
        name: 'Feb',
        company: Math.floor(Math.random() * 99),
        beneficiary: Math.floor(Math.random() * 99),
        individual: Math.floor(Math.random() * 99),
      },
      {
        name: 'Mar',
        company: Math.floor(Math.random() * 99),
        beneficiary: Math.floor(Math.random() * 99),
        individual: Math.floor(Math.random() * 99),
      },
      {
        name: 'Apr',
        company: Math.floor(Math.random() * 99),
        beneficiary: Math.floor(Math.random() * 99),
        individual: Math.floor(Math.random() * 999),
      },
      {
        name: 'May',
        company: Math.floor(Math.random() * 999),
        beneficiary: Math.floor(Math.random() * 999),
        individual: Math.floor(Math.random() * 999),
      },
      {
        name: 'Jun',
        company: Math.floor(Math.random() * 999),
        beneficiary: Math.floor(Math.random() * 999),
        individual: Math.floor(Math.random() * 999),
      },
      {
        name: 'Jul',
        company: Math.floor(Math.random() * 999),
        beneficiary: Math.floor(Math.random() * 999),
        individual: Math.floor(Math.random() * 999),
      },
      {
        name: 'Aug',
        company: Math.floor(Math.random() * 999),
        beneficiary: Math.floor(Math.random() * 999),
        individual: Math.floor(Math.random() * 999),
      },
      {
        name: 'Sep',
        company: Math.floor(Math.random() * 999),
        beneficiary: Math.floor(Math.random() * 999),
        individual: Math.floor(Math.random() * 999),
      },
      {
        name: 'Oct',
        company: Math.floor(Math.random() * 999),
        beneficiary: Math.floor(Math.random() * 999),
        individual: Math.floor(Math.random() * 999),
      },
      {
        name: 'Nov',
        company: Math.floor(Math.random() * 999),
        beneficiary: Math.floor(Math.random() * 999),
        individual: Math.floor(Math.random() * 999),
      },
      {
        name: 'Dec',
        company: Math.floor(Math.random() * 999),
        beneficiary: Math.floor(Math.random() * 999),
        individual: Math.floor(Math.random() * 999),
      },
    ],
    key1: 'company',
    key2: 'beneficiary',
    key3: 'individual',
    dataKey: 'name',
  },
];

export const RADIAL_STAT_CARDS = [
  {
    title: 'Employees by Age',
    data: [
      { name: '18-24', uv: 60, amt: 31.47, pv: 2400, fill: '#8884d8' },
      { name: '25-29', uv: 50, amt: 26.69, pv: 4500, fill: '#83a6ed' },
      { name: '30-34', uv: 30, amt: 15.69, pv: -1398, fill: '#8dd1e1' },
      { name: '35-39', uv: 59, amt: 8.22, pv: 2800, fill: '#82ca9d' },
      { name: '40-49', uv: 48, amt: 8.63, pv: 1908, fill: '#a4de6c' },
      { name: '50+', uv: 62, amt: 2.63, pv: -2800, fill: '#d0ed57' },
      { name: 'unknown', uv: 38, amt: 6.67, pv: 4800, fill: '#ffc658' },
    ],
    dataKey: 'uv',
  },
  {
    title: 'Second Radial Chart',
    data: [
      { name: '18-24', uv: 60, amt: 31.47, pv: 2400, fill: '#8884d8' },
      { name: '25-29', uv: 50, amt: 26.69, pv: 4500, fill: '#83a6ed' },
      { name: '30-34', uv: 30, amt: 15.69, pv: -1398, fill: '#8dd1e1' },
      { name: '35-39', uv: 59, amt: 8.22, pv: 2800, fill: '#82ca9d' },
      { name: '40-49', uv: 48, amt: 8.63, pv: 1908, fill: '#a4de6c' },
      { name: '50+', uv: 62, amt: 2.63, pv: -2800, fill: '#d0ed57' },
      { name: 'unknown', uv: 38, amt: 6.67, pv: 4800, fill: '#ffc658' },
    ],
    dataKey: 'pv',
  },
  {
    title: 'Third Radial Chart',
    data: [
      { name: '18-24', uv: 60, amt: 31.47, pv: 2400, fill: '#8884d8' },
      { name: '25-29', uv: 50, amt: 26.69, pv: 4500, fill: '#83a6ed' },
      { name: '30-34', uv: 30, amt: 15.69, pv: -1398, fill: '#8dd1e1' },
      { name: '35-39', uv: 59, amt: 8.22, pv: 2800, fill: '#82ca9d' },
      { name: '40-49', uv: 48, amt: 8.63, pv: 1908, fill: '#a4de6c' },
      { name: '50+', uv: 62, amt: 2.63, pv: -2800, fill: '#d0ed57' },
      { name: 'unknown', uv: 38, amt: 6.67, pv: 4800, fill: '#ffc658' },
    ],
    dataKey: 'amt',
  },
];

export const CASE_TYPES = [
  { label: 'Company', value: 'company' },
  { label: 'Beneficiary', value: 'beneficiary' },
  { label: 'Individual', value: 'individual' },
];

export const OFFICES = [
  { label: 'Dallas', value: 'dallas' },
  { label: 'Houston', value: 'houston' },
];

export const USER_TYPES = [
  { label: 'Admin', value: 'Admin' },
  { label: 'Employee', value: 'Employee' },
];

// export const DESIGNATIONS = [
//   { label: 'Manager', value: 'Manager' },
//   { label: 'Employee', value: 'Employee' },
// ];

export const CASE_STATUSES = [
  { label: 'Open', value: 'OPEN' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'Not Proceeding', value: 'NOT_PROCEEDING' },
  { label: 'On Hold due to non payment', value: 'ON_HOLD_DUE_TO_NON_PAYMENT' },
  { label: 'Closed', value: 'CLOSED' },
];

export const CASE_CLOSING_STATUSES = [
  { label: 'Select', value: 'SELECT' },
  { label: 'Inactive', value: 'INACTIVE' },
  { label: 'Not Proceeding', value: 'NOT_PROCEEDING' },
  { label: 'On Hold due to non payment', value: 'ON_HOLD_DUE_TO_NON_PAYMENT' },
  { label: 'Closed', value: 'CLOSED' },
];

export const CASE_STATUS_MAP = {
  OPEN: 'Open',
  INACTIVE: 'Inactive',
  NOT_PROCEEDING: 'Not Proceeding',
  ON_HOLD_DUE_TO_NON_PAYMENT: 'On Hold due to non payment',
  CLOSED: 'Closed',
};

export const CIS_TYPES = [
  { label: 'None', value: 'None' },
  { label: 'Citizen', value: 'Citizen' },
  { label: 'LPR', value: 'LPR' },
  { label: 'H-1B', value: 'H-1B' },
  { label: 'USNC', value: 'USNC' },
  { label: "Visitor's Visa", value: "Visitor's Visa" },
  { label: 'H-4', value: 'H-4' },
];

export const COUNTRIES = [
  { label: 'United States', value: 'us' },
  { label: 'Canada', value: 'ca' },
  { label: 'United Kingdom', value: 'uk' },
];

export const PHONE_TYPES = [
  { label: 'Home', value: 'home' },
  { label: 'Work', value: 'work' },
  { label: 'Cell', value: 'cell' },
  { label: 'Fax', value: 'fax' },
];

export const REFERRED_BY_TYPES = [
  {
    label: 'Client',
    value: 'client',
  },
  {
    label: 'Vendor',
    value: 'cendor',
  },
  {
    label: 'FunAsia Show',
    value: 'funasia-show',
  },
  {
    label: 'FunAsia Spot',
    value: 'funasia-spot',
  },
  {
    label: 'Avvo',
    value: 'avvo',
  },
  {
    label: 'Internet',
    value: 'internet',
  },
  {
    label: 'Search Engine',
    value: 'search-engine',
  },
  {
    label: 'Bollywoord Magazine',
    value: 'bollywoord-magazine',
  },
  {
    label: 'Desi Yellow Page',
    value: 'desi-yellow-page',
  },
  {
    label: 'FunAsia Magazine',
    value: 'funasia-magazine',
  },
  {
    label: 'Bharati Magazine',
    value: 'bharati-magazine',
  },
  {
    label: 'Friend',
    value: 'friend',
  },
  {
    label: 'Current Client',
    value: 'current-client',
  },
  {
    label: 'Consult',
    value: 'consult',
  },
  {
    label: 'Other-Notify',
    value: 'other-notify',
  },
  {
    label: 'ALO Website',
    value: 'alo-website',
  },
];

export const PAYMENT_TYPES = [
  {
    label: 'Cash',
    value: 'cash',
  },
  {
    label: 'Check',
    value: 'check',
  },
  {
    label: 'Money Order',
    value: 'money-order',
  },
  {
    label: 'Credit Card',
    value: 'credit-card',
  },
  {
    label: 'Refund',
    value: 'refund',
  },
  {
    label: 'Unknown',
    value: 'unknown',
  },
  {
    label: 'Discount',
    value: 'discount',
  },
  {
    label: 'Credit',
    value: 'credit',
  },
  {
    label: 'Retainer',
    value: 'retianer',
  },
  {
    label: 'IOLTA Transfer',
    value: 'iolta-transfer',
  },
  {
    label: 'Paypal',
    value: 'paypal',
  },
  {
    label: 'Online Credit Card',
    value: 'online-credit-card',
  },
  {
    label: 'No Charge / Pro Bono',
    value: 'no-charge',
  },
  {
    label: 'Cashiers Check',
    value: 'cashiers_check',
  },
  {
    label: 'Wire Transfer',
    value: 'wire_transfer',
  },
  {
    label: 'ACH Transfer',
    value: 'ach_transfer',
  },
  {
    label: 'Direct Deposit',
    value: 'direct_deposit',
  },
];

export const FILING_PAYMENT_TYPES = [
  { label: 'IOLTA Check', value: 'IOLTA Check' },
  { label: 'Personal Check', value: 'Personal Check' },
  { label: 'Cashiers Check', value: 'Cashiers Check' },
  { label: 'Money Order', value: 'Money Order' },
];

export const PAYMENT_TYPES_MAP = {
  cash: 'Cash',
  check: 'Check',
  'money-order': 'Money Order',
  'credit-card': 'Credit Card',
  refund: 'Refund',
  unknown: 'Unknown',
  discount: 'Discount',
  credit: 'Credit',
  retianer: 'Retainer',
  'iolta-transfer': 'IOLTA Transfer',
  paypal: 'Paypal',
  'online-credit-card': 'Online Credit Card',
  'no-charge': 'No Charge / Pro Bono',
  cashiers_check: 'Cashiers Check',
  wire_transfer: 'Wire Transfer',
  ach_transfer: 'ACH Transfer',
  direct_deposit: 'Direct Deposit',
};

export const APPOINTMENT_TYPES = [
  {
    label: 'Interview',
    value: 'interview',
  },
  {
    label: 'Hearing',
    value: 'hearing',
  },
  {
    label: 'Client',
    value: 'client',
  },
  {
    label: 'Consult',
    value: 'consult',
  },
  {
    label: 'Other',
    value: 'other',
  },
  {
    label: 'CIS Interview',
    value: 'cis_interview',
  },
  {
    label: 'Biometrics',
    value: 'biometrics',
  },
  {
    label: 'Vendor',
    value: 'vendor',
  },
  {
    label: 'Telephone',
    value: 'telephone',
  },
];

export const APPOINTMENT_TYPES_MAP = {
  interview: 'Interview',
  hearing: 'Hearing',
  client: 'Client',
  consult: 'Consult',
  other: 'Other',
  cis_interview: 'CIS Interview',
  biometrics: 'Biometrics',
  vendor: 'Vendor',
  telephone: 'Telephone',
};

export const LOCATIONS = [
  {
    label: 'Office',
    value: 'office',
  },
  {
    label: 'USCIS',
    value: 'cis',
  },
  {
    label: 'Out of Office',
    value: 'out-of-office',
  },
  {
    label: 'Court',
    value: 'court',
  },
  {
    label: 'Telephone',
    value: 'telephone',
  },
  {
    label: 'Zoom',
    value: 'zoom',
  },
];

export const LOCATIONS_MAP = {
  office: 'Office',
  cis: 'USCIS',
  'out-of-office': 'Out of Office',
  court: 'Court',
  telephone: 'Telephone',
  zoom: 'Zoom',
};

export const VISIBLE_CASE_COLS = [
  'caseNum',
  'caseType',
  'companyName',
  'openedAt',
  'primaryContact',
  'status',
];

export const VISIBLE_CONTRACT_COLS = ['type', 'desc', 'createdAt'];
export const VISIBLE_PERCENTAGE_COLS = [
  'caseType',
  'desc',
  'completionDateStamp',
  'percentCompleteString',
  'completedBy',
];
export const VISIBLE_TIMESHEET_COLS = [
  'caseType',
  'completedBy',
  'completionDateStamp',
  'times',
  'desc',
  'rate',
];
export const VISIBLE_COST_COLS = [
  'caseType',
  'desc',
  'openDateStamp',
  'completionDateStamp',
  'statusEnd',
  'completedBy',
];
export const VISIBLE_TICKLER_COLS = [
  'type',
  'dueDate',
  'assignedTo',
  'createdBy',
  'createdAt',
  'desc',
];
export const VISIBLE_TICKLER_REPORT_COLS = [
  'type',
  'dueDate',
  'assignedTo',
  'createdBy',
  'createdAt',
  'case',
];
export const VISIBLE_NOTE_COLS = ['desc', 'createdBy', 'createdAt'];
export const VISIBLE_PAYMENT_COLS = ['type', 'amount', 'desc', 'paymentDate'];
export const VISIBLE_IOLTA_COLS = ['type', 'amount', 'desc', 'ioltaDate'];
export const VISIBLE_EMPLOYEE_COLS = [
  'email',
  'name',
  'userType',
  'designation',
];

export const VISIBLE_CONSULTATION_COLS = [
  'consultNum',
  'caseType',
  'companyName',
  'primaryName',
  'consultationDate',
  'email',
];

export const VISIBLE_APPOINTMENT_COLS = [
  'appointmentType',
  'name',
  'location',
  'appointmentWith',
  'startTimeStamp',
  'endTimeStamp',
];

export const DAYS = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

export const MONTHS = [
  { label: 'Jan', value: 0 },
  { label: 'Feb', value: 1 },
  { label: 'Mar', value: 2 },
  { label: 'Apr', value: 3 },
  { label: 'May', value: 4 },
  { label: 'Jun', value: 5 },
  { label: 'Jul', value: 6 },
  { label: 'Aug', value: 7 },
  { label: 'Sep', value: 8 },
  { label: 'Oct', value: 9 },
  { label: 'Nov', value: 10 },
  { label: 'Dec', value: 11 },
];

export const YEARS = new Array(5).fill().map((_, i) => {
  return {
    label: `${2020 + i}`,
    value: 2020 + i,
  };
});

export const CASE_TABS = [
  { label: 'Client Info', idx: 'client_info', icon: '' },
  { label: 'Consultation', idx: 'consultation', icon: '' },
  { label: 'Contracts', idx: 'contracts', icon: '' },
  { label: 'Ticklers', idx: 'ticklers', icon: '' },
  { label: 'Notes', idx: 'notes', icon: '' },
  { label: 'Billing Notes', idx: 'billing_notes', icon: '' },
  { label: 'Payments/Charges', idx: 'payments/charges', icon: '' },
  {
    label: 'Filing Fees Payments/Charges',
    idx: 'filing_payments/charges',
    icon: '',
  },
  { label: 'IOLTA', idx: 'iolta', icon: '' },
  { label: 'IOLTA (Filing Fees)', idx: 'iolta_filing', icon: '' },
  { label: 'IOLTA (Pending)', idx: 'iolta_pending', icon: '' },
  { label: 'Percentages', idx: 'percentages', icon: '' },
  { label: 'Timesheets', idx: 'timesheets', icon: '' },
  { label: 'Case Cost', idx: 'case_cost', icon: '' },
  { label: 'Appointments', idx: 'appointments', icon: '' },
  { label: 'H1B', idx: 'h1b', icon: '' },
  { label: 'PERM', idx: 'perm', icon: '' },
  // { label: 'Emails', idx: 'emails', icon: '' },
];

export const REPORT_TABS = [
  {
    label: 'Ticklers',
    idx: 'tickler_reports',
    icon: '',
    roles: ['Employee', 'Admin'],
  },
  {
    label: 'Financials & Accounting',
    idx: 'financial_reports',
    icon: '',
    roles: ['Admin'],
  },
  {
    label: 'Immigration',
    idx: 'immigration_reports',
    icon: '',
    roles: ['Employee', 'Admin'],
  },
];

export const FINANCIAL_REPORT_TABS = [
  { label: 'Daily Revenue', idx: 'daily_revenue_reports', icon: '' },
  { label: 'Client Balances', idx: 'client_balances_reports', icon: '' },
  { label: 'Client Statement', idx: 'client_statement_reports', icon: '' },
  { label: 'Monthly Revenue', idx: 'monthly_revenue_reports', icon: '' },
  {
    label: 'IOLTA Daily Transaction',
    idx: 'iolta_daily_transaction_reports',
    icon: '',
  },
  { label: 'IOLTA Balance', idx: 'iolta_balance_reports', icon: '' },
  { label: 'IOLTA Statement', idx: 'iolta_statement_reports', icon: '' },
  { label: 'Collection List', idx: 'collection_list_reports', icon: '' },
  { label: 'Case Cost', idx: 'case_cost_reports', icon: '' },
];

export const IMMIGRATION_REPORT_TABS = [
  { label: 'PERM Status', idx: 'perm_status_reports', icon: '' },
  { label: 'H1B Status', idx: 'h1b_status_reports', icon: '' },
];

export const CONTRACT_TYPES = [
  {
    label: 'N-400',
    value: 'n-400',
  },
  {
    label: 'AOS',
    value: 'aos',
  },
  {
    label: 'PREM',
    value: 'prem',
  },
  {
    label: 'H-1B',
    value: 'h-1b',
  },
  {
    label: 'I-140',
    value: 'i-140',
  },
  {
    label: 'RFE',
    value: 'rfe',
  },
  {
    label: 'Civil Law',
    value: 'civil-law',
  },
  {
    label: 'Removal',
    value: 'removal',
  },
  {
    label: 'Consular Processing',
    value: 'consular-processing',
  },
  {
    label: 'I-751',
    value: 'i-751',
  },
  {
    label: 'AC21',
    value: 'ac21',
  },
  {
    label: 'R1',
    value: 'r1',
  },

  { label: 'H-1B CAP', value: 'H-1B CAP' },
  { label: 'H-1B Extension', value: 'H-1B Extension' },
  { label: 'H-1B Transfer', value: 'H-1B Transfer' },
  { label: 'H-1B Amendment', value: 'H-1B Amendment' },
  { label: 'H4 dependant', value: 'H4 dependant' },
  { label: 'LCA Refile', value: 'LCA Refile' },
  { label: 'L1A', value: 'L1A' },
  { label: 'L1B', value: 'L1B' },
  { label: 'L2 Dependent', value: 'L2 Dependent' },
  { label: 'EB1A', value: 'EB1A' },
  { label: 'EB1C', value: 'EB1C' },
  { label: 'E2', value: 'E2' },
  { label: 'E2D (Dependant)', value: 'E2D (Dependant)' },
  { label: 'TN Visa', value: 'TN Visa' },
  { label: 'TN (Dependant)', value: 'TN (Dependant)' },
  { label: 'Perm Step 1', value: 'Perm Step 1' },
  { label: 'Perm step 2', value: 'Perm step 2' },
  { label: 'I-140 Downgrade', value: 'I-140 Downgrade' },
  { label: 'I-485 (EB)', value: 'I-485 (EB)' },
  { label: 'I-485 (EB Dirivative)', value: 'I-485 (EB Dirivative)' },
  { label: 'Premium Processing', value: 'Premium Processing' },
  { label: 'Divorce', value: 'Divorce' },
  { label: 'I-130', value: 'I-130' },
  { label: 'I-130a', value: 'I-130a' },
  { label: 'I-485', value: 'I-485' },
  { label: 'I-485 (FBD)', value: 'I-485 (FBD)' },
  { label: 'K1(fiance visa)', value: 'K1(fiance visa)' },
  { label: 'NVC', value: 'NVC' },
  { label: 'U-Visa', value: 'U-Visa' },
  { label: 'I-360', value: 'I-360' },
  { label: 'WAVA', value: 'WAVA' },
  { label: 'N600', value: 'N600' },
  { label: 'I-212', value: 'I-212' },
  { label: 'Corporate Matter', value: 'Corporate Matter' },
  { label: 'I-90', value: 'I-90' },
  { label: 'I-765(EAD)', value: 'I-765(EAD)' },
  { label: 'I-131(AP)', value: 'I-131(AP)' },
  { label: 'I-102', value: 'I-102' },
  { label: 'I-407', value: 'I-407' },
  { label: 'I-539', value: 'I-539' },
  { label: 'I-600', value: 'I-600' },
  { label: 'I-600A', value: 'I-600A' },
  { label: 'I-601', value: 'I-601' },
  { label: 'I-601A', value: 'I-601A' },
  { label: 'I-612', value: 'I-612' },
  { label: 'I-821', value: 'I-821' },
  { label: 'I-821D', value: 'I-821D' },
  { label: 'I-824', value: 'I-824' },
  { label: 'I-829', value: 'I-829' },
  { label: 'O1', value: 'O1' },
  { label: 'P1', value: 'P1' },
  { label: 'Pro Bono', value: 'Pro Bono' },
  { label: 'I485J', value: 'I485J' },
  { label: 'Audit', value: 'Audit' },
  { label: 'F2 COS-B2 BRIDGE', value: 'F2 COS-B2 BRIDGE' },
  { label: 'FBGC', value: 'FBGC' },
  { label: 'Withdrawal', value: 'Withdrawal' },
  { label: 'Will', value: 'Will' },
  { label: 'Other', value: 'Other' },
];

export const CONTRACT_TYPES_MAP = {
  'n-400': 'N-400',
  aos: 'AOS',
  prem: 'PREM',
  'h-1b': 'H-1B',
  'i-140': 'I-140',
  rfe: 'RFE',
  'civil-law': 'Civil Law',
  removal: 'Removal',
  'consular-processing': 'Consular Processing',
  'i-751': 'I-751',
  ac21: 'AC21',
  r1: 'R1',
  'H-1B CAP': 'H-1B CAP',
  'H-1B Extension': 'H-1B Extension',
  'H-1B Transfer': 'H-1B Transfer',
  'H-1B Amendment': 'H-1B Amendment',
  'H4 dependant': 'H4 dependant',
  'LCA Refile': 'LCA Refile',
  L1A: 'L1A',
  L1B: 'L1B',
  'L2 Dependent': 'L2 Dependent',
  EB1A: 'EB1A',
  EB1C: 'EB1C',
  E2: 'E2',
  'E2D (Dependant)': 'E2D (Dependant)',
  'TN Visa': 'TN Visa',
  'TN (Dependant)': 'TN (Dependant)',
  'Perm Step 1': 'Perm Step 1',
  'Perm step 2': 'Perm step 2',
  'I-140 Downgrade': 'I-140 Downgrade',
  'I-485 (EB)': 'I-485 (EB)',
  'I-485 (EB Dirivative)': 'I-485 (EB Dirivative)',
  'Premium Processing': 'Premium Processing',
  Divorce: 'Divorce',
  'I-130': 'I-130',
  'I-130a': 'I-130a',
  'I-485': 'I-485',
  'I-485 (FBD)': 'I-485 (FBD)',
  'K1(fiance visa)': 'K1(fiance visa)',
  NVC: 'NVC',
  'U-Visa': 'U-Visa',
  'I-360': 'I-360',
  WAVA: 'WAVA',
  N600: 'N600',
  'I-212': 'I-212',
  'Corporate Matter': 'Corporate Matter',
  'I-90': 'I-90',
  'I-765(EAD)': 'I-765(EAD)',
  'I-131(AP)': 'I-131(AP)',
  'I-102': 'I-102',
  'I-407': 'I-407',
  'I-539': 'I-539',
  'I-600': 'I-600',
  'I-600A': 'I-600A',
  'I-601': 'I-601',
  'I-601A': 'I-601A',
  'I-612': 'I-612',
  'I-821': 'I-821',
  'I-821D': 'I-821D',
  'I-824': 'I-824',
  'I-829': 'I-829',
  O1: 'O1',
  P1: 'P1',
  'Pro Bono': 'Pro Bono',
  I485J: 'I485J',
  Audit: 'Audit',
  'F2 COS-B2 BRIDGE': 'F2 COS-B2 BRIDGE',
  FBGC: 'FBGC',
  Withdrawal: 'Withdrawal',
  Will: 'Will',
  Other: 'Other',
};

export const TICKLER_STATUSES = [
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
];

export const TICKLER_TYPES = [
  {
    label: 'Critical',
    value: 'critical',
  },
  {
    label: 'Regular',
    value: 'regular',
  },
  {
    label: 'Warning',
    value: 'warning',
  },
  {
    label: 'Mail',
    value: 'mail',
  },
  {
    label: 'H-1B Cap',
    value: 'h-1b-cap',
  },
  {
    label: 'New Item',
    value: 'new-item',
  },
  {
    label: 'Status',
    value: 'status',
  },
  {
    label: 'PREM',
    value: 'prem',
  },
  {
    label: 'Phone Call',
    value: 'phone-call',
  },
  {
    label: 'Priority Date',
    value: 'priority-date',
  },
];

export const TICKLER_TYPES_MAP = {
  critical: 'Critical',
  regular: 'Regular',
  warning: 'Warning',
  mail: 'Mail',
  'h-1b-cap': 'H-1B Cap',
  'new-item': 'New Item',
  status: 'Status',
  prem: 'PREM',
  'phone-call': 'Phone Call',
  'priority-date': 'Priority Date',
};

export const CHARGE_TYPES = [
  {
    label: 'Expenses',
    value: 'expenses',
  },
  {
    label: 'Consult Fee',
    value: 'consult-fee',
  },
  {
    label: 'Legal Fee',
    value: 'legal-fee',
  },
  {
    label: 'Unknown',
    value: 'unknown',
  },
  {
    label: 'CIS Fee',
    value: 'cis-fee',
  },
  {
    label: 'Court Fee',
    value: 'court-fee',
  },
  {
    label: 'Citation Fee',
    value: 'citation-fee',
  },
  {
    label: 'Adjustment/Loss',
    value: 'adjustment-loss',
  },
  {
    label: 'IOLTA Funds Avalaible',
    value: 'iolta-funds-avalaible',
  },
  {
    label: 'Courier/Delivery Fee',
    value: 'courier-delivery-fee',
  },
  {
    label: 'Discount',
    value: 'discount',
  },
  {
    label: 'Direct Deposit',
    value: 'direct_deposit',
  },
];

export const CHARGE_TYPES_LABEL_MAP = {
  expenses: 'Expenses',
  'consult-fee': 'Consult Fee',
  'legal-fee': 'Legal Fee',
  unknown: 'Unknown',
  'cis-fee': 'CIS Fee',
  'court-fee': 'Court Fee',
  'citation-fee': 'Citation Fee',
  'adjustment-loss': 'Adjustment/Loss',
  'iolta-funds-avalaible': 'IOLTA Funds Avalaible',
  'courier-delivery-fee': 'Courier/Delivery Fee',
  discount: 'Discount',
  direct_deposit: 'Direct Deposit',
};

export const DEPOSIT_TYPES = [
  {
    label: 'Cash',
    value: 'cash',
  },
  {
    label: 'Check',
    value: 'check',
  },
  {
    label: 'Money Order',
    value: 'money-order',
  },
  {
    label: 'Credit Card',
    value: 'credit-card',
  },
  {
    label: 'Cashiers Check',
    value: 'cashiers_check',
  },
  {
    label: 'Wire Transfer',
    value: 'wire_transfer',
  },
  {
    label: 'ACH Transfer',
    value: 'ach_transfer',
  },
  {
    label: 'Retainer',
    value: 'retainer',
  },
  {
    label: 'Direct Deposit',
    value: 'direct_deposit',
  },
];

export const DEPOSIT_TYPES_MAP = {
  cash: 'Cash',
  check: 'Check',
  'money-order': 'Money Order',
  'credit-card': 'Credit Card',
  cashiers_check: 'Cashiers Check',
  wire_transfer: 'Wire Transfer',
  ach_transfer: 'ACH Transfer',
  retainer: 'Retainer',
  direct_deposit: 'Direct Deposit',
};

export const IOLTA_TYPES_MAP = {
  cash: 'Cash',
  check: 'Check',
  'money-order': 'Money Order',
  'credit-card': 'Credit Card',
  cashiers_check: 'Cashiers Check',
  retainer: 'Retainer',
  direct_deposit: 'Direct Deposit',
  'cis-fees': 'CIS Fees',
  'court-fees': 'Court Fees',
  'return-to-client': 'Return To Client',
  'legal-fees': 'Legal Fees',
  'case-expenses': 'Case Expenses',
  'courier-fee': 'Courier Fee',
  wire_transfer: 'Wire Transfer',
  ach_transfer: 'ACH Transfer',
};

export const PAYMENT_CHARGE_TYPES_MAP = {
  expenses: 'Expenses',
  'consult-fee': 'Consult Fee',
  'legal-fee': 'Legal Fee',
  unknown: 'Unknown',
  'cis-fee': 'CIS Fee',
  'court-fee': 'Court Fee',
  'citation-fee': 'Citation Fee',
  'adjustment-loss': 'Adjustment/Loss',
  'iolta-funds-avalaible': 'IOLTA Funds Avalaible',
  'courier-delivery-fee': 'Courier/Delivery Fee',
  discount: 'Discount',
  direct_deposit: 'Direct Deposit',
  cash: 'Cash',
  check: 'Check',
  'money-order': 'Money Order',
  'credit-card': 'Credit Card',
  refund: 'Refund',
  unknown: 'Unknown',
  credit: 'Credit',
  retianer: 'Retainer',
  'iolta-transfer': 'IOLTA Transfer',
  paypal: 'Paypal',
  'online-credit-card': 'Online Credit Card',
  'no-charge': 'No Charge / Pro Bono',
  cashiers_check: 'Cashiers Check',
  wire_transfer: 'Wire Transfer',
  ach_transfer: 'ACH Transfer',
};

export const PAYMENT_IOLTA_TYPES_MAP = {
  expenses: 'Expenses',
  'consult-fee': 'Consult Fee',
  'legal-fee': 'Legal Fee',
  unknown: 'Unknown',
  'cis-fee': 'CIS Fee',
  'court-fee': 'Court Fee',
  'citation-fee': 'Citation Fee',
  'adjustment-loss': 'Adjustment/Loss',
  'iolta-funds-avalaible': 'IOLTA Funds Avalaible',
  'courier-delivery-fee': 'Courier/Delivery Fee',
  discount: 'Discount',
  refund: 'Refund',
  credit: 'Credit',
  retianer: 'Retainer',
  'iolta-transfer': 'IOLTA Transfer',
  paypal: 'Paypal',
  'online-credit-card': 'Online Credit Card',
  'no-charge': 'No Charge / Pro Bono',
  cashiers_check: 'Cashiers Check',
  wire_transfer: 'Wire Transfer',
  ach_transfer: 'ACH Transfer',

  cash: 'Cash',
  check: 'Check',
  'money-order': 'Money Order',
  'credit-card': 'Credit Card',
  cashiers_check: 'Cashiers Check',
  retainer: 'Retainer',
  direct_deposit: 'Direct Deposit',
  'cis-fees': 'CIS Fees',
  'court-fees': 'Court Fees',
  'return-to-client': 'Return To Client',
  'legal-fees': 'Legal Fees',
  'case-expenses': 'Case Expenses',
  'courier-fee': 'Courier Fee',
  wire_transfer: 'Wire Transfer',
  ach_transfer: 'ACH Transfer',
};

export const TRANSFER_TYPES = [
  {
    label: 'CIS Fees',
    value: 'cis-fees',
  },
  {
    label: 'Court Fees',
    value: 'court-fees',
  },
  {
    label: 'Return To Client',
    value: 'return-to-client',
  },
  {
    label: 'Legal Fees',
    value: 'legal-fees',
  },
  {
    label: 'Case Expenses',
    value: 'case-expenses',
  },
  {
    label: 'Courier Fee',
    value: 'courier-fee',
  },
  {
    label: 'Wire Transfer',
    value: 'wire_transfer',
  },
  {
    label: 'ACH Transfer',
    value: 'ach_transfer',
  },
];

export const TRANSFER_TYPES_MAP = {
  'cis-fees': 'CIS Fees',
  'court-fees': 'Court Fees',
  'return-to-client': 'Return To Client',
  'legal-fees': 'Legal Fees',
  'case-expenses': 'Case Expenses',
  'courier-fee': 'Courier Fee',
  wire_transfer: 'Wire Transfer',
  ach_transfer: 'ACH Transfer',
};

export const H1B_TYPES = [
  { label: 'CAP', value: 'CAP' },
  { label: 'Urgent/Non-CAP', value: 'Urgent/Non-CAP' },
  { label: 'Transfer', value: 'Transfer' },
  { label: 'Renewal', value: 'Renewal' },
];

export const H1B_STEPS = [
  { label: 'Questionnaires Sent', value: 'Questionnaires Sent' },
  { label: 'LCA Filed', value: 'LCA Filed' },
  { label: 'LCA Certified', value: 'LCA Certified' },
  { label: 'CIS Fees Communicated', value: 'Fees Communicated' },
  { label: 'CIS Fees Received', value: 'Fees Received' },
  { label: 'Forms Completed', value: 'Forms Completed' },
  { label: 'Signature Obtained', value: 'Signature Obtained' },
  { label: 'Supporting Docs', value: 'Supporting Docs' },
];

export const PERM_STEPS = [
  { label: 'Consult to Explain Process', value: 'Consult to Explain Process' },
  { label: 'Ad Drafted', value: 'Ad Drafted' },
  { label: 'Created File with Dividers', value: 'Created File with Dividers' },
  { label: 'Sent Questionnaires', value: 'Sent Questionnaires' },
  { label: 'Confirm Ad Draft', value: 'Confirm Ad Draft' },
  { label: 'Sample SHaring Job Post', value: 'Sample SHaring Job Post' },
  { label: 'PWR Submitted', value: 'PWR Submitted' },
  { label: 'Client Uploaded', value: 'Client Uploaded' },
  { label: 'Firm Review of Upload', value: 'Firm Review of Upload' },
  { label: 'Advice About Recruitment', value: 'Advice About Recruitment' },
  { label: 'PWR Returned/Confirmed', value: 'PWR Returned/Confirmed' },
  { label: 'Touchbase On Recruitment', value: 'Touchbase On Recruitment' },
  { label: 'Recruitment Report', value: 'Recruitment Report' },
  { label: 'Populate PERM Folder', value: 'Populate PERM Folder' },
  { label: 'PERM Filing', value: 'PERM Filing' },
];

export const CASE_SUB_TYPES = [
  { label: 'H-1B CAP', value: 'H-1B CAP' },
  { label: 'H-1B Extension', value: 'H-1B Extension' },
  { label: 'H-1B Transfer', value: 'H-1B Transfer' },
  { label: 'H-1B Amendment', value: 'H-1B Amendment' },
  { label: 'H4 dependant', value: 'H4 dependant' },
  { label: 'LCA Refile', value: 'LCA Refile' },
  { label: 'L1A', value: 'L1A' },
  { label: 'L1B', value: 'L1B' },
  { label: 'L2 Dependent', value: 'L2 Dependent' },
  { label: 'EB1A', value: 'EB1A' },
  { label: 'EB1C', value: 'EB1C' },
  { label: 'E2', value: 'E2' },
  { label: 'E2D (Dependant)', value: 'E2D (Dependant)' },
  { label: 'TN Visa', value: 'TN Visa' },
  { label: 'TN (Dependant)', value: 'TN (Dependant)' },
  { label: 'Perm Step 1', value: 'Perm Step 1' },
  { label: 'Perm step 2', value: 'Perm step 2' },
  { label: 'I-140', value: 'I-140' },
  { label: 'I-140 Downgrade', value: 'I-140 Downgrade' },
  { label: 'I-485 (EB)', value: 'I-485 (EB)' },
  { label: 'I-485 (EB Dirivative)', value: 'I-485 (EB Dirivative)' },
  { label: 'Premium Processing', value: 'Premium Processing' },
  { label: 'Divorce', value: 'Divorce' },
  { label: 'I-130', value: 'I-130' },
  { label: 'I-130a', value: 'I-130a' },
  { label: 'I-485', value: 'I-485' },
  { label: 'I-485 (FBD)', value: 'I-485 (FBD)' },
  { label: 'K1(fiance visa)', value: 'K1(fiance visa)' },
  { label: 'NVC', value: 'NVC' },
  { label: 'U-Visa', value: 'U-Visa' },
  { label: 'I-360', value: 'I-360' },
  { label: 'WAVA', value: 'WAVA' },
  { label: 'N400', value: 'N400' },
  { label: 'N600', value: 'N600' },
  { label: 'I-212', value: 'I-212' },
  { label: 'Corporate Matter', value: 'Corporate Matter' },
  { label: 'I-90', value: 'I-90' },
  { label: 'I-765(EAD)', value: 'I-765(EAD)' },
  { label: 'I-131(AP)', value: 'I-131(AP)' },
  { label: 'I-102', value: 'I-102' },
  { label: 'I-407', value: 'I-407' },
  { label: 'I-539', value: 'I-539' },
  { label: 'I-600', value: 'I-600' },
  { label: 'I-600A', value: 'I-600A' },
  { label: 'I-601', value: 'I-601' },
  { label: 'I-601A', value: 'I-601A' },
  { label: 'I-612', value: 'I-612' },
  { label: 'I-751', value: 'I-751' },
  { label: 'I-821', value: 'I-821' },
  { label: 'I-821D', value: 'I-821D' },
  { label: 'I-824', value: 'I-824' },
  { label: 'I-829', value: 'I-829' },
  { label: 'O1', value: 'O1' },
  { label: 'P1', value: 'P1' },
  { label: 'Pro Bono', value: 'Pro Bono' },
  { label: 'I485J', value: 'I485J' },
  { label: 'RFE', value: 'RFE' },
  { label: 'Audit', value: 'Audit' },
  { label: 'F2 COS-B2 BRIDGE', value: 'F2 COS-B2 BRIDGE' },
  { label: 'R1', value: 'R1' },
  { label: 'FBGC', value: 'FBGC' },
  { label: 'Withdrawal', value: 'Withdrawal' },
  { label: 'Will', value: 'Will' },
  { label: 'Other', value: 'Other' },
];

export const ACTIVE_TYPES_MAP = {
  all: 'All',
  company: 'Company',
  case: 'Case',
  case_type: 'Case Category',
  case_sub_type: 'Case Type',
  case_status: 'Case Status',
  office: 'Office',
};

export const PERCENTAGE_OPTIONS = [
  // { label: '0%', value: 0 },
  { label: '5%', value: 5 },
  { label: '10%', value: 10 },
  { label: '15%', value: 15 },
  { label: '20%', value: 20 },
  { label: '25%', value: 25 },
  { label: '30%', value: 30 },
  { label: '35%', value: 35 },
  { label: '40%', value: 40 },
  { label: '45%', value: 45 },
  { label: '50%', value: 50 },
  { label: '55%', value: 55 },
  { label: '60%', value: 60 },
  { label: '65%', value: 65 },
  { label: '70%', value: 70 },
  { label: '75%', value: 75 },
  { label: '80%', value: 80 },
  { label: '85%', value: 85 },
  { label: '90%', value: 90 },
  { label: '95%', value: 95 },
  { label: '100%', value: 100 },
];

export const SEARCH_TYPES = [
  { label: 'Search by Notes', value: 'by_notes' },
  { label: 'Search by Contracts', value: 'by_contracts' },
  { label: 'Search by Ticklers', value: 'by_ticklers' },
  { label: 'Search by Payments/Charges', value: 'by_payments' },
  { label: 'Search by IOLTA', value: 'by_iolta' },
  { label: 'Search by Percentages', value: 'by_percentages' },
  { label: 'Search by Timesheets', value: 'by_timesheets' },
  { label: 'Search by Case Costs', value: 'by_costs' },
];
