import { format, formatDistance } from 'date-fns';
import React from 'react';
import { IOLTA_TYPES_MAP } from '../constants/constants';
import { dateFormat, getUserName, priceFormat } from '../utils/userUtils';

const IoltaCell = (props) => {
  const { dataKey, data } = props;

  const PrimaryName = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <small className="badge rounded px-1 text-dark fw-regular mx-1 capitalize">
        {data.fullName}
      </small>
    );
  };

  const IoltaType = (props) => {
    const { type, subType } = props;
    if (!type || !type.length || !subType || !subType.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-normal mx-1 capitalize">
          <span>{type}</span>
          <span> | </span>
          <span>{IOLTA_TYPES_MAP[subType]}</span>
        </small>
      </div>
    );
  };

  const OpeningDate = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-regular mx-1 capitalize">
          {dateFormat(new Date(data))}
        </small>
      </div>
    );
  };

  const IoltaId = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 fw-normal mx-1 capitalize bg-light-dark text-white">
          {data.id.substring(0, 4) +
            '...' +
            data.id.substring(data.id.length - 4, data.id.length)}
          <i className="bi bi-box-arrow-up-right ms-1"></i>
        </small>
      </div>
    );
  };

  const IoltaNum = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 fw-normal mx-1 capitalize bg-light-dark text-white">
          {data}
          <i className="bi bi-box-arrow-up-right ms-1"></i>
        </small>
      </div>
    );
  };

  const Text = (props) => {
    const { data, light, trunc = false, truncSm = false, noCap = false } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small
          className={`badge px-1 ${light ? 'fw-normal' : 'fw-regular'} ${
            trunc ? 'truncated' : ''
          } ${truncSm ? 'truncated-sm' : ''} ${noCap ? '' : 'capitalize'} text-dark`}
        >
          {data}
        </small>
      </div>
    );
  };

  const CreationDate = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className={`badge px-1 fw-normal capitalize text-dark`}>
          {dateFormat(new Date(data))}
        </small>
      </div>
    );
  };

  const Emails = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge rounded text-and-border-blue px-1 fw-normal mx-1">
          {data[0]}
        </small>
        {/* {data.map((email) => (
          <small className="badge rounded text-and-border-blue px-1 fw-normal mx-1">
            {email}
          </small>
        ))} */}
      </div>
    );
  };

  return (
    <div className="my-2">
      {dataKey === 'ioltaDate' ? (
        <OpeningDate data={data} />
      ) : (
        <React.Fragment />
      )}

      {dataKey === 'amount' ? (
        <Text data={`${priceFormat(data)}`} />
      ) : (
        <React.Fragment />
      )}

      {dataKey === 'type' ? <IoltaType type={data.type} subType={data.subType}  /> : <React.Fragment />}
      {dataKey === 'caseType' ? <Text data={data} /> : <React.Fragment />}

      {dataKey === 'dueDate' ? (
        <CreationDate data={data} />
      ) : (
        <React.Fragment />
      )}
      {dataKey === 'desc' ? <Text data={data} truncSm noCap /> : <React.Fragment />}
      {dataKey === 'createdAt' ? (
        <CreationDate data={data} />
      ) : (
        <React.Fragment />
      )}

      {dataKey === 'createdBy' ? (
        <Text data={getUserName(data)} />
      ) : (
        <React.Fragment />
      )}

      {/* {dataKey === 'status' ? <Text data={data} /> : <React.Fragment />} */}
      {/* {dataKey === 'location' ? <Text data={data} light /> : <React.Fragment />}

      {dataKey === 'case' ? <IoltaId data={data} /> : <React.Fragment />} */}

      {/* {dataKey === 'case' ? <IoltaId data={data} /> : <React.Fragment />} */}
      {/* {dataKey === 'caseNum' ? <IoltaNum data={data} /> : <React.Fragment />} */}
    </div>
  );
};

export default IoltaCell;
