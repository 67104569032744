export const myGreenColor = '#33f945';
export const myGreenColorLight = '#33f94522';
export const myGreenSuperLight = '#f9fffa';
export const myGreenColorDark = '#22c935';
export const myRedColor = '#f93345';
export const myRedColorLight = '#f9334522';
export const myBlueColorDark = '#2235e9';
export const myBlueColor = '#3345f9';
export const myBlueColorLight = '#3345f922';
export const myYellowColor = '#f9a809';
export const myYellowColorLight = '#f9a80922';
export const myBrownColor = '#a78809';
export const myBrownColorLight = '#f7f80922';