import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { myGreenSuperLight } from '../../constants/colors';
import {
  CASE_SUB_TYPES,
  CONTRACT_TYPES,
  PERCENTAGE_OPTIONS,
} from '../../constants/constants';
import { getMahDateCustom, getUserName } from '../../utils/userUtils';
import Checkbox from '../common/Checkbox';
import Input from '../common/Input';
import MultiSelect from '../common/MultiSelect';
import MyMessage from '../common/MyMessage';
import Select from '../common/Select';
import Loading from '../Loading';
import TextArea from '../common/TextArea';

const EditPercentageModal = (props) => {
  const {
    caseData,
    visible,
    onClose,
    user,
    employees,
    reinitialize,
    percentageId,
    onEdit = false,
  } = props;

  const [editing, setEditing] = useState(onEdit);
  const [percentComplete, setPercentComplete] = useState(0);
  const [caseType, setCaseType] = useState('H-1B CAP');
  const [completionDate, setCompletionDate] = useState('');
  const [completedByNames, setCompletedByNames] = useState('');
  const [completedBy, setCompletedBy] = useState([]);
  const [desc, setDesc] = useState('');
  const [sureToDelete, setSureToDelete] = useState(false);
  const [showSureSelect, setShowSureSelect] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initPercentageForEdit();
  }, [visible, percentageId]);

  const initPercentageForEdit = async () => {
    try {
      if (!visible || !percentageId || !percentageId.length) return;
      setLoading(true);
      const configService = require('../../store/config/service');
      const res = await configService.default.getPercentage(percentageId);
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log(res.result);
        // add data to inputs.
        if (user && user.userType && user.userType === 'Admin')
          setIsAdmin(true);
        setPercentComplete(res.result.percentComplete);
        setCaseType(res.result.caseType);
        setCompletionDate(getMahDateCustom(res.result.completionDate));
        setCompletedByNames(res.result.completedByNames);
        setCompletedBy(
          res.result.completedBy.map((el) => ({
            label: `${getUserName(el)} ${el.designation || el.userType}`,
            value: el.id,
          }))
        );
        setDesc(res.result.desc);
        setErrors({});
        setMessage({});
        setLoading(false);
      }
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const updatePercentage = async () => {
    if (!validatePercentage()) return;
    const configService = require('../../store/config/service');

    let body = {
      caseType,
      percentComplete: parseInt(percentComplete),
      percentCompleteString: `${percentComplete}%`,
      completionDate: new Date(completionDate),
      completionDateStamp: +new Date(completionDate),
      completedBy: completedBy.map((el) => el.value),
      completedByNames,
      desc,
      createdBy: user.id,
      createdByName: getUserName(user),
    };

    // console.log('body - ', body);

    const res = await configService.default.updatePercentage(
      percentageId,
      body
    );
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const validatePercentage = () => {
    let flag = true;
    if (!caseType || !caseType.length) {
      flag = false;
      handleError('caseType', 'select a valid case type');
    }
    if (!percentComplete || isNaN(percentComplete)) {
      flag = false;
      handleError('percentComplete', 'select a percentage');
    }
    if (!completionDate || !completionDate.length) {
      flag = false;
      handleError('completionDate', 'select a valid completion date');
    }
    if (!completedBy || !completedBy.length) {
      flag = false;
      handleError('completedBy', 'select a valid emlpoyee');
    }
    if (!completedByNames || !completedByNames.length) {
      flag = false;
      handleError('completedBy', 'select a valid employee');
    }
    if (!desc || !desc.length) {
      flag = false;
      handleError('desc', 'select a add description');
    }
    if (!user || !user.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (!caseData || !caseData.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    return flag;
  };

  const deletePercentage = async () => {
    if (!percentageId || !percentageId.length) return;
    if (!isAdmin) return;
    if (!showSureSelect) return setShowSureSelect(true);
    if (showSureSelect && !sureToDelete)
      return handleError('sureToDelete', 'you have to check this');

    const configService = require('../../store/config/service');
    const res = await configService.default.deletePercentage(percentageId);

    if (!res) {
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (res.error) {
      setMessage({
        type: 'warning',
        msg: res.error,
      });
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  const doOnClose = () => {
    resetBlankModal();
    onClose();
  };

  const resetBlankModal = () => {
    setEditing(false);
    setCaseType('H-1B CAP');
    setPercentComplete('');
    setCompletionDate('');
    setCompletedByNames('');
    setCompletedBy([]);
    setDesc('');
    setShowSureSelect(false);
    setSureToDelete(false);
    setIsAdmin(false);
    setErrors({});
    setMessage({});
    setLoading(false);
  };

  return visible ? (
    <div className="my-modal-container">
      <div className="my-modal-overlay" onClick={doOnClose}></div>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable my-modal z-index-9">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <div>
              <p className="modal-title mb-0">
                {showSureSelect ? 'Deleting' : editing ? 'Editing' : 'Viewing'}
              </p>
              <h3 className="modal-title fw-bold">{'Percentage'}</h3>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={doOnClose}
            ></button>
          </div>
          {loading ? (
            <Loading />
          ) : (
            <div className="modal-body">
              {showSureSelect ? (
                <>
                  <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                    <div>
                      <small className="badge rounded-pill bg-warning text-dark fw-normal mb-1">
                        Important
                      </small>
                      <p className="fw-light text-muted italic size14pt5 px-2">
                        You are about to delete this appointment! This action is
                        irreversible.
                      </p>
                      <div className="row g-md-2">
                        <div className="col-12 my-3 mx-auto px-3">
                          <label htmlFor="splittingWith" className="form-label">
                            Are You Sure?
                          </label>

                          <div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                name="areWeSure"
                                id="areWeSureYes"
                                checked={sureToDelete}
                                onClick={() => {
                                  if (!sureToDelete)
                                    handleError('sureToDelete', '');
                                  setSureToDelete(!sureToDelete);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="areWeSureYes"
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                          {errors && errors.sureToDelete ? (
                            <div className="mt-1">
                              <small className="text-red size14">
                                {errors && errors.sureToDelete}
                              </small>
                            </div>
                          ) : (
                            <React.Fragment />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {editing ? (
                    <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                      <div className="mb-2">
                        <p className="text-muted fs-italic fw-light size15">
                          Change the details below and update this percentage!
                        </p>

                        <div className="row d-flex flex-wrap justify-content-start align-items-start">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <Select
                              label={'Case Type'}
                              placeholder={'select a case type'}
                              value={caseType}
                              options={CASE_SUB_TYPES}
                              onChange={(e) => {
                                setCaseType(e.target.value);
                                handleError('caseType', '');
                              }}
                              err={errors.caseType || ''}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                            <Select
                              label={'Percent Complete'}
                              placeholder={'select percentage completed'}
                              value={percentComplete}
                              options={PERCENTAGE_OPTIONS}
                              onChange={(e) => {
                                setPercentComplete(e.target.value);
                                handleError('percentComplete', '');
                              }}
                              err={errors.percentComplete || ''}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                            <Input
                              label={'Completion Date'}
                              placeholder={'enter completion date'}
                              type={'date'}
                              value={completionDate}
                              onChange={(e) => {
                                if (
                                  e.target.value &&
                                  e.target.value.length &&
                                  errors.completionDate &&
                                  errors.completionDate.length
                                ) {
                                  handleError('completionDate', '');
                                }
                                setCompletionDate(e.target.value);
                              }}
                              err={errors && errors.completionDate}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <MultiSelect
                              label={'Completed By'}
                              displayValue={'label'}
                              values={completedBy}
                              options={employees}
                              onSelect={(e) => {
                                setCompletedBy(e);
                                // console.log(e);
                                if (e.length) {
                                  setCompletedByNames(
                                    e.map((el) => el.label).join(',')
                                  );
                                }
                                handleError('completedBy', '');
                              }}
                              onRemove={(e) => {
                                setCompletedBy(e);
                              }}
                              err={errors.completedBy || ''}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <TextArea
                              label={'Description'}
                              placeholder={'add a description'}
                              value={desc}
                              onChange={(e) => {
                                if (
                                  e.target.value &&
                                  e.target.value.length &&
                                  errors.desc &&
                                  errors.desc.length
                                ) {
                                  handleError('desc', '');
                                }
                                setDesc(e.target.value);
                              }}
                              err={errors && errors.desc}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
                    </div>
                  ) : (
                    <div className="container d-flex flex-column justify-content-center align-items-start p-2">
                      <div className="mb-2">
                        <p className="text-muted fs-italic fw-light size15">
                          Click on Edit to edit the data!
                        </p>

                        <div className="row d-flex flex-wrap justify-content-start align-items-start">
                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <Select
                              disabled
                              label={'Case Type'}
                              placeholder={'select a case type'}
                              value={caseType}
                              options={CASE_SUB_TYPES}
                              onChange={(e) => {
                                setCaseType(e.target.value);
                                handleError('caseType', '');
                              }}
                              err={errors.caseType || ''}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                            <Select
                              disabled
                              label={'Percent Complete'}
                              placeholder={'select percentage completed'}
                              value={percentComplete}
                              options={PERCENTAGE_OPTIONS}
                              onChange={(e) => {
                                setPercentComplete(e.target.value);
                                handleError('percentComplete', '');
                              }}
                              err={errors.percentComplete || ''}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-6 col-sm-12 col-xs-12 col-12">
                            <Input
                              disabled
                              label={'Completion Date'}
                              placeholder={'enter completion date'}
                              type={'date'}
                              value={completionDate}
                              onChange={(e) => {
                                if (
                                  e.target.value &&
                                  e.target.value.length &&
                                  errors.completionDate &&
                                  errors.completionDate.length
                                ) {
                                  handleError('completionDate', '');
                                }
                                setCompletionDate(e.target.value);
                              }}
                              err={errors && errors.completionDate}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <MultiSelect
                              disabled
                              label={'Completed By'}
                              displayValue={'label'}
                              values={completedBy}
                              options={employees}
                              onSelect={(e) => {
                                setCompletedBy(e);
                                // console.log(e);
                                if (e.length) {
                                  setCompletedByNames(
                                    e.map((el) => el.label).join(',')
                                  );
                                }
                                handleError('completedBy', '');
                              }}
                              onRemove={(e) => {
                                setCompletedBy(e);
                              }}
                              err={errors.completedBy || ''}
                            />
                          </div>

                          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                            <TextArea
                              disabled
                              label={'Description'}
                              placeholder={'add a description'}
                              value={desc}
                              onChange={(e) => {
                                if (
                                  e.target.value &&
                                  e.target.value.length &&
                                  errors.desc &&
                                  errors.desc.length
                                ) {
                                  handleError('desc', '');
                                }
                                setDesc(e.target.value);
                              }}
                              err={errors && errors.desc}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          <div className="px-3">
            <MyMessage
              visible={!!message}
              message={message}
              onClose={() => {
                setMessage(undefined);
              }}
            />
          </div>
          <div
            className="modal-footer d-flex justify-content-between align-items-center"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <div>
              {isAdmin && !editing && !showSureSelect ? (
                <span
                  className="btn btn-danger m-0 py-0 px-1 cursor-pointer"
                  onClick={deletePercentage}
                >
                  <i className="bi bi-trash md-icon"></i>
                </span>
              ) : (
                <React.Fragment />
              )}
            </div>

            <>
              {!showSureSelect ? (
                <>
                  <div className="d-flex flex-one justify-content-end align-items-center">
                    {editing ? (
                      <button
                        type="button"
                        className="btn btn-outline ms-2"
                        onClick={() => setEditing(false)}
                      >
                        Cancel
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-outline ms-2"
                        onClick={doOnClose}
                      >
                        Close
                      </button>
                    )}
                  </div>

                  {editing ? (
                    <button
                      type="button"
                      className="btn btn-primary ms-2 capitalize"
                      onClick={updatePercentage}
                    >
                      Update Percentage
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary ms-2"
                      onClick={() => {
                        setEditing(true);
                      }}
                    >
                      Edit
                    </button>
                  )}
                </>
              ) : (
                <div className="d-flex align-tems-center justify-content-end">
                  <button
                    type="button"
                    className="btn btn-outline ms-2"
                    onClick={() => {
                      setShowSureSelect(false);
                      setSureToDelete(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger ms-2 capitalize"
                    onClick={deletePercentage}
                  >
                    Delete Percentage
                  </button>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default EditPercentageModal;
