import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { logout } from '../actions/authActions';
import { toggleStickyDrawer } from '../actions/stickyDrawerActions';
import { firstFive } from '../utils/userUtils';
import { removeAuthToken } from '../services/StorageService';

const TopBar = (props) => {
  const {
    bg = 'bg-my-light',
    currentRoute,
    user,
    isLoggedIn,
    logout,
    stickyDrawerVisible,
    toggleAppDrawer,
  } = props;

  const logOut = async () => {
    // console.log('user is - ', user, isLoggedIn);
    logout({ user: null, isLoggedIn: false, token: null });
    removeAuthToken();
  };

  const createBreadcrumbs = () => {
    let crumbs = currentRoute.split('/').splice(1);
    // console.log(crumbs);
    return crumbs.map((crumb, i) => {
      // console.log('crumb', crumb);
      const link = i === 0 ? `/${crumb}` : `/${crumbs[i - 1]}/${crumb}`;
      return (
        <span key={i} className="justify-content-center">
          {i === 0 ? (
            ''
          ) : (
            <span className="colorDark weight200 size13 capitalize px-1">
              <i className="bi bi-caret-right-fill"></i>
            </span>
          )}
          <NavLink className="crumbLink colorDark" to={link}>
            <span className="colorDark weight400 size14pt8 capitalize badge rounded-pill bg-extra-light weight400 px-3 my-border">
              {'  ' + crumb.toString().length > 20
                ? firstFive(crumb, true)
                : crumb.split('-').join(' ')}
            </span>
          </NavLink>
        </span>
      );
    });
  };
  return (
    <nav className={`bd-1 container-fluid d-flex-row vh8 ${bg}`}>
      <button
        type="button"
        className="btn align-self-center btn-no-outline"
        onClick={() => {
          if (!stickyDrawerVisible) {
            toggleAppDrawer({ stickyDrawerVisible: true });
          } else {
            toggleAppDrawer({ stickyDrawerVisible: false });
          }
        }}
      >
        <i className="bi bi-filter-left md-icon colorDark bg-extra-light br-10 p-1 pt-0 pb-1 my-border my-light-shadow"></i>
      </button>
      <div style={{ flex: 1 }}>{createBreadcrumbs()}</div>
      <div className="btn-group dropstart">
        <button
          type="button"
          className="btn dropdown-toggle align-self-center pt-0"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="bi bi-person-circle md-icon colorDark"></i>
        </button>
        <ul className="dropdown-menu">
          <li>
            <NavLink className="dropdown-item" to="/profile">
              Profile
            </NavLink>
          </li>
          <li>
            <a className="dropdown-item" href="#">
              Settings
            </a>
          </li>
          <li>
            <a
              className="dropdown-item"
              onClick={() => {
                logOut();
              }}
            >
              Log Out
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

const mapStateToProps = (state) => {
  const {
    auth: { user, isLoggedIn } = {},
    stickyDrawer: { stickyDrawerVisible } = {},
  } = state;
  return {
    user,
    isLoggedIn,
    stickyDrawerVisible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: (payload) => {
      dispatch(logout(payload));
    },
    toggleAppDrawer: (payload) => {
      dispatch(toggleStickyDrawer(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopBar);
