import { format, formatDistance } from 'date-fns';
import React from 'react';
import { TICKLER_TYPES_MAP } from '../constants/constants';
import { dateFormat, getUserName } from '../utils/userUtils';

const TicklerCell = (props) => {
  const { dataKey, data } = props;

  const PrimaryName = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <small className="badge rounded px-1 text-dark fw-regular mx-1 capitalize">
        {data.fullName}
      </small>
    );
  };

  const TicklerType = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-normal mx-1 capitalize">
          {data}
        </small>
      </div>
    );
  };

  const OpeningDate = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 text-dark fw-regular mx-1 capitalize">
          {dateFormat(new Date(data))}
        </small>
      </div>
    );
  };

  const TicklerId = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 fw-normal mx-1 capitalize bg-light-dark text-white">
          {data.id.substring(0, 4) +
            '...' +
            data.id.substring(data.id.length - 4, data.id.length)}
          <i className="bi bi-box-arrow-up-right ms-1"></i>
        </small>
      </div>
    );
  };

  const TicklerNum = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge px-1 fw-normal mx-1 capitalize bg-light-dark text-white">
          {data}
          <i className="bi bi-box-arrow-up-right ms-1"></i>
        </small>
      </div>
    );
  };

  const Text = (props) => {
    const { data, light, trunc = false, truncSm = false, noCap = false } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small
          className={`badge px-1 ${light ? 'fw-normal' : 'fw-regular'} ${
            trunc ? 'truncated' : ''
          } ${
            truncSm ? 'truncated-sm' : ''
          } ${noCap ? '' : 'capitalize'} text-dark`}
        >
          {data}
        </small>
      </div>
    );
  };

  const CreationDate = (props) => {
    const { data } = props;
    if (!data)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className={`badge px-1 fw-normal capitalize text-dark`}>
          {dateFormat(new Date(data))}
        </small>
      </div>
    );
  };

  const Emails = (props) => {
    const { data } = props;
    if (!data || !data.length)
      return <small className="px-1 text-muted fw-light mx-1">Not added</small>;
    return (
      <div>
        <small className="badge rounded text-and-border-blue px-1 fw-normal mx-1">
          {data[0]}
        </small>
        {/* {data.map((email) => (
          <small className="badge rounded text-and-border-blue px-1 fw-normal mx-1">
            {email}
          </small>
        ))} */}
      </div>
    );
  };

  return (
    <div className="my-2">
      {/* {dataKey === 'openedAt' ? (
        <OpeningDate data={data} />
      ) : (
        <React.Fragment />
      )} */}

      {dataKey === 'assignedTo' ? (
        <Text data={data.map(((usr) => getUserName(usr))).join(', ')} truncSm />
      ) : (
        <React.Fragment />
      )}

      {dataKey === 'type' ? <TicklerType data={TICKLER_TYPES_MAP[data]} /> : <React.Fragment />}

      {dataKey === 'dueDate' ? (
        <CreationDate data={data} />
      ) : (
        <React.Fragment />
      )}
      {dataKey === 'desc' ? <Text data={data} truncSm noCap /> : <React.Fragment />}
      {dataKey === 'createdAt' ? (
        <CreationDate data={data} />
      ) : (
        <React.Fragment />
      )}

{dataKey === 'createdBy' ? (
        <Text data={getUserName(data)} />
      ) : (
        <React.Fragment />
      )}

      {/* {dataKey === 'status' ? <Text data={data} /> : <React.Fragment />} */}
      {/* {dataKey === 'location' ? <Text data={data} light /> : <React.Fragment />}

      {dataKey === 'case' ? <TicklerId data={data} /> : <React.Fragment />} */}

      {/* {dataKey === 'case' ? <TicklerId data={data} /> : <React.Fragment />} */}
      {/* {dataKey === 'caseNum' ? <TicklerNum data={data} /> : <React.Fragment />} */}
    </div>
  );
};

export default TicklerCell;
