import React, { useEffect, useState } from 'react';
import { getUserName } from '../utils/userUtils';
import PaymentList from './PaymentList';
import AddPaymentModal from './modals/AddPaymentModal';
import EditPaymentModal from './modals/EditPaymentModal';

const Payments = (props) => {
  const { caseData, user } = props;
  const [employees, setEmployees] = useState([]);
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(undefined);
  const [showEditPaymentModal, setShowEditPaymentModal] = useState(undefined);
  const [reinitializing, setReinitializing] = useState(false);

  useEffect(() => {
    initEmployees();
  }, []);

  const initEmployees = async () => {
    if (!user || !user.userType) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getUsers(); // change to employees only
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      setEmployees(
        res.result.map((emp) => {
          return {
            label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
            value: emp.id,
          };
        })
      );
    }
  };

  const openAddPaymentModal = (type) => {
    setShowAddPaymentModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowAddPaymentModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
      type: type ? type : '',
    });
  };

  const openEditPaymentModal = (type, paymentId) => {
    setShowEditPaymentModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowEditPaymentModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
      type: type ? type : '',
      paymentId: paymentId ? paymentId : '',
    });
  };

  const reinitialize = (bool = true) => {
    setReinitializing(bool);
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mb-0">{'Payments & Charges'}</h4>
        <div className="flex-one"></div>
        <div>
          <button
            className="btn btn-sm btn-outline-success shadow-sm ms-2"
            style={{ width: 'max-content' }}
            onClick={() => openAddPaymentModal('payment')}
          >
            Add New Payment<i className="bi bi-plus ms-1"></i>
          </button>
          <button
            className="btn btn-sm btn-outline-success shadow-sm ms-2"
            style={{ width: 'max-content' }}
            onClick={() => openAddPaymentModal('charge')}
          >
            Add New Charge<i className="bi bi-plus ms-1"></i>
          </button>
        </div>
      </div>
      <div className="w-100">
        <PaymentList
          caseId={(caseData || {}).id}
          caseData={caseData}
          user={user}
          reinitialize={reinitialize}
          reinitializing={reinitializing}
          openEditPaymentModal={(type, paymentId) => openEditPaymentModal(type, paymentId)}
        />

        <AddPaymentModal
          visible={!!showAddPaymentModal}
          caseData={showAddPaymentModal && showAddPaymentModal.caseData}
          onClose={showAddPaymentModal && showAddPaymentModal.onClose}
          user={showAddPaymentModal && showAddPaymentModal.user}
          employees={showAddPaymentModal && showAddPaymentModal.employees}
          type={showAddPaymentModal && showAddPaymentModal.type}
          reinitialize={reinitialize}
        />

        <EditPaymentModal
          visible={!!showEditPaymentModal}
          caseData={showEditPaymentModal && showEditPaymentModal.caseData}
          onClose={showEditPaymentModal && showEditPaymentModal.onClose}
          user={showEditPaymentModal && showEditPaymentModal.user}
          employees={showEditPaymentModal && showEditPaymentModal.employees}
          type={showEditPaymentModal && showEditPaymentModal.type}
          paymentId={showEditPaymentModal && showEditPaymentModal.paymentId}
          reinitialize={reinitialize}
        />
      </div>
    </div>
  );
};

export default Payments;
