import React, { useEffect, useState } from 'react';
import { getUserName } from '../utils/userUtils';
import TicklerList from './TicklerList';
import AddTicklerModal from './modals/AddTicklerModal';
import EditTicklerModal from './modals/EditTicklerModal';

const Ticklers = (props) => {
  const { caseData, user } = props;
  const [employees, setEmployees] = useState([]);
  const [showAddTicklerModal, setShowAddTicklerModal] = useState(undefined);
  const [showEditTicklerModal, setShowEditTicklerModal] = useState(undefined);
  const [reinitializing, setReinitializing] = useState(false);

  useEffect(() => {
    initEmployees();
  }, []);

  const initEmployees = async () => {
    if (!user || !user.userType) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getUsers(); // change to employees only
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      setEmployees(
        res.result.map((emp) => {
          return {
            label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
            value: emp.id,
          };
        })
      );
    }
  };

  const openAddTicklerModal = () => {
    setShowAddTicklerModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowAddTicklerModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
    });
  };

  const openEditTicklerModal = (ticklerId) => {
    setShowEditTicklerModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowEditTicklerModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
      ticklerId: ticklerId ? ticklerId : {},
    });
  };

  const reinitialize = (bool = true) => {
    setReinitializing(bool);
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Ticklers</h4>
        <button
          className="btn btn-sm btn-outline-success shadow-sm ms-2"
          style={{ width: 'max-content' }}
          onClick={openAddTicklerModal}
        >
          Add New <i className="bi bi-plus ms-1"></i>
        </button>
      </div>
      <div className="w-100">
        <TicklerList
          caseId={(caseData || {}).id}
          user={user}
          reinitialize={reinitialize}
          reinitializing={reinitializing}
          openEditTicklerModal={(ticklerId) => openEditTicklerModal(ticklerId)}
        />

        <AddTicklerModal
          visible={!!showAddTicklerModal}
          caseData={showAddTicklerModal && showAddTicklerModal.caseData}
          onClose={showAddTicklerModal && showAddTicklerModal.onClose}
          user={showAddTicklerModal && showAddTicklerModal.user}
          employees={showAddTicklerModal && showAddTicklerModal.employees}
          reinitialize={reinitialize}
        />

        <EditTicklerModal
          visible={!!showEditTicklerModal}
          caseData={showEditTicklerModal && showEditTicklerModal.caseData}
          onClose={showEditTicklerModal && showEditTicklerModal.onClose}
          user={showEditTicklerModal && showEditTicklerModal.user}
          employees={showEditTicklerModal && showEditTicklerModal.employees}
          ticklerId={showEditTicklerModal && showEditTicklerModal.ticklerId}
          reinitialize={reinitialize}
        />
      </div>
    </div>
  );
};

export default Ticklers;
