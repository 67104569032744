import React, { useEffect, useState } from 'react';
import { getUserName } from '../utils/userUtils';
import PercentageList from './PercentageList';
import AddPercentageModal from './modals/AddPercentageModal';
import EditPercentageModal from './modals/EditPercentageModal';

const Percentages = (props) => {
  const { caseData, user } = props;
  const [employees, setEmployees] = useState([]);
  const [showAddPercentageModal, setShowAddPercentageModal] = useState(undefined);
  const [showEditPercentageModal, setShowEditPercentageModal] = useState(undefined);
  const [reinitializing, setReinitializing] = useState(false);

  useEffect(() => {
    initEmployees();
  }, []);

  const initEmployees = async () => {
    if (!user || !user.userType) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getUsers(); // change to employees only
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      setEmployees(
        res.result.map((emp) => {
          return {
            label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
            value: emp.id,
          };
        })
      );
    }
  };

  const openAddPercentageModal = () => {
    setShowAddPercentageModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowAddPercentageModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
    });
  };

  const openEditPercentageModal = (percentageId, onEdit = false) => {
    setShowEditPercentageModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowEditPercentageModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
      percentageId: percentageId ? percentageId : {},
      onEdit,
    });
  };

  const reinitialize = (bool = true) => {
    setReinitializing(bool);
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Percentages</h4>
        <button
          className="btn btn-sm btn-outline-success shadow-sm ms-2"
          style={{ width: 'max-content' }}
          onClick={openAddPercentageModal}
        >
          Add New <i className="bi bi-plus ms-1"></i>
        </button>
      </div>
      <div className="w-100">
        <PercentageList
          caseId={(caseData || {}).id}
          user={user}
          reinitialize={reinitialize}
          reinitializing={reinitializing}
          openEditPercentageModal={(percentageId, onEdit) =>
            openEditPercentageModal(percentageId, onEdit)
          }
        />

        <AddPercentageModal
          visible={!!showAddPercentageModal}
          caseData={showAddPercentageModal && showAddPercentageModal.caseData}
          onClose={showAddPercentageModal && showAddPercentageModal.onClose}
          user={showAddPercentageModal && showAddPercentageModal.user}
          employees={showAddPercentageModal && showAddPercentageModal.employees}
          reinitialize={reinitialize}
        />

        <EditPercentageModal
          visible={!!showEditPercentageModal}
          caseData={showEditPercentageModal && showEditPercentageModal.caseData}
          onClose={showEditPercentageModal && showEditPercentageModal.onClose}
          user={showEditPercentageModal && showEditPercentageModal.user}
          employees={showEditPercentageModal && showEditPercentageModal.employees}
          percentageId={showEditPercentageModal && showEditPercentageModal.percentageId}
          onEdit={showEditPercentageModal && showEditPercentageModal.onEdit}
          reinitialize={reinitialize}
        />
      </div>
    </div>
  );
};

export default Percentages;
