import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { myGreenSuperLight } from '../../constants/colors';
import { CASE_SUB_TYPES, CONTRACT_TYPES } from '../../constants/constants';
import { getUserName } from '../../utils/userUtils';
import Checkbox from '../common/Checkbox';
import Input from '../common/Input';
import MultiSelect from '../common/MultiSelect';
import MyMessage from '../common/MyMessage';
import Select from '../common/Select';
import TextArea from '../common/TextArea';

const AddTimesheetModal = (props) => {
  const { caseData, visible, onClose, user, employees, reinitialize } = props;

  const [times, setTimes] = useState([]);
  const [isHourly, setIsHourly] = useState(true);
  const [isFlat, setIsFlat] = useState(false);
  const [rate, setRate] = useState(0);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');
  const [caseType, setCaseType] = useState('H-1B CAP');
  const [completionDate, setCompletionDate] = useState('');
  const [completedByNames, setCompletedByNames] = useState('');
  const [completedBy, setCompletedBy] = useState([]);
  const [desc, setDesc] = useState('');
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);

  const createTimesheet = async () => {
    if (!validateTimesheet()) return;
    const configService = require('../../store/config/service');

    let body = {
      case: caseData.id,
      caseType,
      isHourly,
      isFlat,
      times,
      totalTimeSpent: parseFloat(
        times
          .reduce((acc, cur) => acc + parseFloat(cur.timeSpent), 0)
          .toFixed(2)
      ),
      totalTimeSpentString: times
        .reduce((acc, cur) => acc + parseFloat(cur.timeSpent), 0)
        .toFixed(2),
      completionDate: new Date(completionDate),
      completionDateStamp: +new Date(completionDate),
      completedBy: completedBy.map((el) => el.value),
      completedByNames,
      desc,
      createdBy: user.id,
      createdByName: getUserName(user),
    };

    if (isHourly) {
      body.rate = parseFloat(rate);
    }

    console.log('body - ', body);

    const res = await configService.default.createTimesheet(body);
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      // console.log('result --- ', res.result);
      reinitialize();
      doOnClose();
    }
  };

  const validateTimesheet = () => {
    let flag = true;
    if (!caseType || !caseType.length) {
      flag = false;
      handleError('caseType', 'select a valid case type');
    }
    if (isHourly) {
      if (!rate || isNaN(rate)) {
        flag = false;
        handleError('rate', 'add a valid rate');
      }
    }
    if (!times || !times.length) {
      flag = false;
      handleError('times', 'add atleast one time range');
    }
    if (!completionDate || !completionDate.length) {
      flag = false;
      handleError('completionDate', 'select a valid completion date');
    }
    if (!completedBy || !completedBy.length) {
      flag = false;
      handleError('completedBy', 'select a valid emlpoyee');
    }
    if (!completedByNames || !completedByNames.length) {
      flag = false;
      handleError('completedBy', 'select a valid employee');
    }
    if (!desc || !desc.length) {
      flag = false;
      handleError('desc', 'select a add description');
    }
    if (!user || !user.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    if (!caseData || !caseData.id) {
      flag = false;
      setMessage({
        type: 'warning',
        msg: 'something went wrong! try again later!',
      });
    }
    return flag;
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  const doOnClose = () => {
    resetBlankModal();
    onClose();
  };

  const resetBlankModal = () => {
    setTimes([]);
    setFrom('');
    setTo('');
    setIsHourly(true);
    setIsFlat(false);
    setRate(0);
    setCaseType('H-1B CAP');
    setCompletionDate('');
    setCompletedByNames('');
    setCompletedBy([]);
    setDesc('');
    setErrors({});
    setMessage({});
    setLoading(false);
  };

  const addTimeRangeToList = () => {
    if (!from || !from.length) handleError('from', 'select valid time');
    if (!to || !to.length) handleError('to', 'select valid time');
    if (!from || !to || !from.length || !to.length) return;
    // console.log({from, to});

    let start = from.split(':');
    let end = to.split(':');
    var startDate = new Date(0, 0, 0, start[0], start[1], 0);
    var endDate = new Date(0, 0, 0, end[0], end[1], 0);
    var diff = endDate.getTime() - startDate.getTime();
    if (diff <= 0) {
      handleError('times', 'Please select time range in the same day');
      return;
    }
    var hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    var minutes = Math.floor(diff / 1000 / 60);

    // console.log({times});

    setTimes((cur) => {
      return [
        ...cur,
        {
          start: from,
          end: to,
          timeSpent: parseFloat((hours + minutes / 60).toFixed(2)),
        },
      ];
    });

    setFrom('');
    setTo('');
    handleError('times', '');
    return;
  };

  const deleteTime = (index) => {
    setTimes((current) => {
      return current.filter((el, i) => i !== index);
    });
  };

  return visible ? (
    <div className="my-modal-container">
      <div className="my-modal-overlay" onClick={doOnClose}></div>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable my-modal z-index-9">
        <div className="modal-content">
          <div
            className="modal-header"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <div>
              <p className="modal-title mb-0">{'Adding'}</p>
              <h3 className="modal-title fw-bold">{'New Timesheet'}</h3>
            </div>
            <button
              type="button"
              className="btn-close"
              onClick={doOnClose}
            ></button>
          </div>
          <div className="modal-body">
            <div className="container d-flex flex-column justify-content-center align-items-start p-2">
              <div className="mb-2">
                <p className="text-muted fs-italic fw-light size15">
                  Fill in the details below to add a new timesheet!
                </p>

                <div className="row d-flex flex-wrap justify-content-start align-items-start">
                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <Select
                      label={'Case Type'}
                      placeholder={'select a case type'}
                      value={caseType}
                      options={CASE_SUB_TYPES}
                      onChange={(e) => {
                        setCaseType(e.target.value);
                        handleError('caseType', '');
                      }}
                      err={errors.caseType || ''}
                    />
                  </div>

                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 px-4 py-3">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="rate"
                        id="rateHourly"
                        checked={isHourly}
                        onClick={() => {
                          if (!isHourly) handleError('isHourly', '');
                          setIsHourly(true);
                          setIsFlat(false);
                        }}
                      />
                      <label className="form-check-label" htmlFor="rateHourly">
                        Hourly Rate
                      </label>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 col-12 px-4 py-3">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="rate"
                        id="rateFlat"
                        checked={isFlat}
                        onClick={() => {
                          if (!isFlat) handleError('isFlat', '');
                          setIsFlat(true);
                          setIsHourly(false);
                        }}
                      />
                      <label className="form-check-label" htmlFor="rateFlat">
                        Flat Rate
                      </label>
                    </div>
                  </div>

                  {isHourly ? (
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                      <Input
                        label={`Hourly Rate`}
                        placeholder={'enter rate'}
                        type={'number'}
                        value={rate}
                        onChange={(e) => {
                          if (
                            e.target.value &&
                            e.target.value.length &&
                            errors.rate &&
                            errors.rate.length
                          ) {
                            handleError('rate', '');
                          }
                          setRate(e.target.value);
                        }}
                        err={errors && errors.rate}
                      />
                    </div>
                  ) : (
                    <React.Fragment />
                  )}

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <Input
                      label={'Completion Date'}
                      placeholder={'enter completion date'}
                      type={'date'}
                      value={completionDate}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.completionDate &&
                          errors.completionDate.length
                        ) {
                          handleError('completionDate', '');
                        }
                        setCompletionDate(e.target.value);
                      }}
                      err={errors && errors.completionDate}
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <div className="row d-flex justify-content-center align-items-center">
                      <div className="col-5">
                        <Input
                          label={'From'}
                          placeholder={'enter start time'}
                          type={'time'}
                          value={from}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              e.target.value.length &&
                              errors.from &&
                              errors.from.length
                            ) {
                              handleError('from', '');
                            }
                            setFrom(e.target.value);
                          }}
                          err={errors && errors.from}
                        />
                      </div>

                      <div className="col-5">
                        <Input
                          label={'To'}
                          placeholder={'enter end time'}
                          type={'time'}
                          value={to}
                          min={from}
                          onChange={(e) => {
                            if (
                              e.target.value &&
                              e.target.value.length &&
                              errors.to &&
                              errors.to.length
                            ) {
                              handleError('to', '');
                            }
                            setTo(e.target.value);
                          }}
                          err={errors && errors.to}
                        />
                      </div>

                      <div className="col-2">
                        <button
                          onClick={addTimeRangeToList}
                          className="btn btn-sm btn-primary"
                        >
                          Add +
                        </button>
                      </div>
                    </div>
                  </div>

                  {errors.times && errors.times.length ? (
                    <div className="form-error mx-2 mb-3">
                      <small className="form-error-text px-1">
                        {errors.times}
                      </small>
                    </div>
                  ) : (
                    <React.Fragment />
                  )}

                  {times && times.length ? (
                    times.map((tm, i) => (
                      <div
                        key={i}
                        className="mb-2 d-flex flex-row justify-content-evenly align-items-center px-4"
                      >
                        <small className="flex-zero mb-0 fw-semi-bold text-muted me-2">
                          {i + 1}
                        </small>
                        <small className="flex-one mb-0 fw-semi-bold text-dark px-1">
                          <span className="text-muted fw-light">From -</span>{' '}
                          {tm.start}
                        </small>
                        <small className="flex-two mb-0 fw-semi-bold text-dark px-1">
                          <span className="text-muted fw-light">To -</span>{' '}
                          {tm.end}
                        </small>
                        <small className="flex-one mb-0 fw-semi-bold text-dark px-1">
                          <span className="text-muted fw-light">Hours -</span>{' '}
                          {tm.timeSpent}
                        </small>
                        <small
                          className="flex-zero mb-0 fw-semi-bold text-red px-1 cursor-pointer"
                          onClick={() => deleteTime(i)}
                        >
                          <i className="bi bi-x-circle-fill"></i>
                        </small>
                      </div>
                    ))
                  ) : (
                    <React.Fragment />
                  )}

                  {times && times.length ? (
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 d-flex flex-row justify-content-around align-items-center mb-3">
                      <small className="mx-2 bt-1 pt-1 flex-one">
                        Total Time Spent
                      </small>
                      <small className="mx-2 bt-1 pt-1 fw-semi-bold">
                        {times
                          .reduce(
                            (acc, cur) => acc + parseFloat(cur.timeSpent),
                            0
                          )
                          .toFixed(2)}{' '}
                        hrs
                      </small>
                    </div>
                  ) : (
                    <React.Fragment />
                  )}

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <MultiSelect
                      label={'Completed By'}
                      displayValue={'label'}
                      values={completedBy}
                      options={employees}
                      onSelect={(e) => {
                        setCompletedBy(e);
                        // console.log(e);
                        if (e.length) {
                          setCompletedByNames(
                            e.map((el) => el.label).join(',')
                          );
                        }
                        handleError('completedBy', '');
                      }}
                      onRemove={(e) => {
                        setCompletedBy(e);
                      }}
                      err={errors.completedBy || ''}
                    />
                  </div>

                  <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12">
                    <TextArea
                      label={'Description'}
                      placeholder={'add a description'}
                      value={desc}
                      onChange={(e) => {
                        if (
                          e.target.value &&
                          e.target.value.length &&
                          errors.desc &&
                          errors.desc.length
                        ) {
                          handleError('desc', '');
                        }
                        setDesc(e.target.value);
                      }}
                      err={errors && errors.desc}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center align-items-center mb-4 w-100"></div>
            </div>
          </div>
          <div className="px-3">
            <MyMessage
              visible={!!message}
              message={message}
              onClose={() => {
                setMessage(undefined);
              }}
            />
          </div>
          <div
            className="modal-footer"
            style={{ backgroundColor: myGreenSuperLight }}
          >
            <button
              type="button"
              className="btn btn-outline ms-2"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary ms-2"
              onClick={createTimesheet}
            >
              Add Timesheet
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <React.Fragment />
  );
};

export default AddTimesheetModal;
