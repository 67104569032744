import React, { useEffect, useState } from 'react';
import { getUserName } from '../utils/userUtils';
import BillingNoteList from './BillingNoteList';
import AddBillingNoteModal from './modals/AddBillingNoteModal';
import EditBillingNoteModal from './modals/EditBillingNoteModal';

const BillingNotes = (props) => {
  const { caseData, user } = props;
  const [employees, setEmployees] = useState([]);
  const [showAddBillingNoteModal, setShowAddBillingNoteModal] = useState(undefined);
  const [showEditBillingNoteModal, setShowEditBillingNoteModal] = useState(undefined);
  const [reinitializing, setReinitializing] = useState(false);

  useEffect(() => {
    initEmployees();
  }, []);

  const initEmployees = async () => {
    if (!user || !user.userType) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getUsers(); // change to employees only
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      setEmployees(
        res.result.map((emp) => {
          return {
            label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
            value: emp.id,
          };
        })
      );
    }
  };

  const openAddBillingNoteModal = () => {
    setShowAddBillingNoteModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowAddBillingNoteModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
    });
  };

  const openEditBillingNoteModal = (noteId) => {
    setShowEditBillingNoteModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowEditBillingNoteModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
      noteId: noteId ? noteId : {},
    });
  };

  const reinitialize = (bool = true) => {
    setReinitializing(bool);
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Billing Notes</h4>
        <button
          className="btn btn-sm btn-outline-success shadow-sm ms-2"
          style={{ width: 'max-content' }}
          onClick={openAddBillingNoteModal}
        >
          Add New <i className="bi bi-plus ms-1"></i>
        </button>
      </div>
      <div className="w-100">
        <BillingNoteList
          caseId={(caseData || {}).id}
          user={user}
          reinitialize={reinitialize}
          reinitializing={reinitializing}
          openEditBillingNoteModal={(noteId) => openEditBillingNoteModal(noteId)}
        />

        <AddBillingNoteModal
          visible={!!showAddBillingNoteModal}
          caseData={showAddBillingNoteModal && showAddBillingNoteModal.caseData}
          onClose={showAddBillingNoteModal && showAddBillingNoteModal.onClose}
          user={showAddBillingNoteModal && showAddBillingNoteModal.user}
          employees={showAddBillingNoteModal && showAddBillingNoteModal.employees}
          reinitialize={reinitialize}
        />

        <EditBillingNoteModal
          visible={!!showEditBillingNoteModal}
          caseData={showEditBillingNoteModal && showEditBillingNoteModal.caseData}
          onClose={showEditBillingNoteModal && showEditBillingNoteModal.onClose}
          user={showEditBillingNoteModal && showEditBillingNoteModal.user}
          employees={showEditBillingNoteModal && showEditBillingNoteModal.employees}
          noteId={showEditBillingNoteModal && showEditBillingNoteModal.noteId}
          reinitialize={reinitialize}
        />
      </div>
    </div>
  );
};

export default BillingNotes;
