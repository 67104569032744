import React from 'react';

const ClientStatementReport = (props) => {
  const { user } = props;
  return <div>
    <h3>ClientStatementReport</h3>
  </div>;
};

export default ClientStatementReport;
