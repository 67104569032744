import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CASE_STATUS_MAP, CASE_TABS } from '../../constants/constants';
import ClientInfo from '../ClientInfo';
import Consultation from '../Consultation';
import Contracts from '../Contracts';
import Ticklers from '../Ticklers';
import Notes from '../Notes';
import Payments from '../Payments';
import Ioltas from '../Ioltas';
import FilingIoltas from '../FilingIoltas';
import PendingIoltas from '../PendingIoltas';
import CaseAppointments from '../CaseAppointments';
import H1BStatus from '../H1BStatus';
import PERMStatus from '../PERMStatus';
import Emails from '../Emails';
import BillingNotes from '../BillingNotes';
import Percentages from '../Percentages';
import Timesheets from '../Timesheets';
import Costs from '../Costs';
import FilingPayments from '../FilingPayments';

const ViewCase = (props) => {
  const { user, isLoggedIn } = props;
  const { id: caseId } = useParams();
  const [caseData, setCaseData] = useState({});
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    initCase(caseId);
  }, [caseId]);

  const initCase = async (caseId) => {
    if (!caseId || !caseId.length) return;
    const configService = require('../../store/config/service');
    const res = await configService.default.getCase(caseId);
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      // console.log('case data', res.result);
      setCaseData(res.result);
    }
  };

  const getActiveComponent = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return (
          <ClientInfo
            caseData={caseData}
            user={user}
            reinitialize={() => initCase(caseId)}
          />
        );
      case 1:
        return <Consultation caseData={caseData} user={user} />;
      case 2:
        return <Contracts caseData={caseData} user={user} />;
      case 3:
        return <Ticklers caseData={caseData} user={user} />;
      case 4:
        return <Notes caseData={caseData} user={user} />;
      case 5:
        return <BillingNotes caseData={caseData} user={user} />;
      case 6:
        return <Payments caseData={caseData} user={user} />;
      case 7:
        return <FilingPayments caseData={caseData} user={user} />;
      case 8:
        return <Ioltas caseData={caseData} user={user} />;
      case 9:
        return <FilingIoltas caseData={caseData} user={user} />;
      case 10:
        return <PendingIoltas caseData={caseData} user={user} />;
      case 11:
        return <Percentages caseData={caseData} user={user} />;
      case 12:
        return <Timesheets caseData={caseData} user={user} />;
      case 13:
        return <Costs caseData={caseData} user={user} />;
      case 14:
        return <CaseAppointments caseData={caseData} user={user} />;
      case 15:
        if (caseData.activateH1B)
          return <H1BStatus caseData={caseData} user={user} />;
        else if (caseData.activatePerm)
          return <PERMStatus caseData={caseData} user={user} />;
      case 16:
        return <PERMStatus caseData={caseData} user={user} />;
      default:
        break;
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mb-4">
          <div className="card pb-2">
            <div className="card-head px-3 py-3 pb-0 d-flex justify-content-start align-items-center">
              <div className="d-flex flex-column justify-content-start align-items-center w-100">
                <div className="d-flex flex-row flex-wrap justify-content-start align-items-baseline w-100">
                  <h5 className="fw-semi-bold">
                    Case{' '}
                    <span className="fw-bold">
                      {(caseData && caseData.caseNum) || ''}
                    </span>{' '}
                    {caseData && caseData.status && caseData.status.length ? <span className="fw-light text-muted capitalize">
                      {`(${CASE_STATUS_MAP[caseData.status]})`}
                    </span> : <React.Fragment />}
                  </h5>

                  <h6>
                    <span className="fw-light text-muted ms-2 me-2">
                      {' > '}
                    </span>
                    <span className="fw-light">Primary Name (</span>
                    <span className="fw-semi-bold text-dark">
                      {`${`${
                        ((caseData || {}).primaryContact || {}).fullName || ''
                      }`.trim()}`}
                    </span>
                    <span className="fw-light">)</span>
                  </h6>

                  {caseData &&
                  caseData.secondaryContact &&
                  caseData.secondaryContact.fullName &&
                  caseData.secondaryContact.fullName.length ? (
                    <h6>
                      <span className="fw-light text-muted ms-3 me-2">
                        {' > '}
                      </span>
                      <span className="fw-light">Secondary Name (</span>
                      <span className="fw-semi-bold text-dark">
                        {`${`${
                          ((caseData || {}).secondaryContact || {}).fullName ||
                          ''
                        }`.trim()}`}
                      </span>
                      <span className="fw-light">)</span>
                    </h6>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
                <hr className="hy-light-hr mt-0 mb-2 w-100" />
                <div className="d-flex flex-wrap justify-content-start align-items-center mt-2">
                  {CASE_TABS.filter((tab) => {
                    if (tab.idx === 'h1b') {
                      if (caseData.activateH1B) {
                        return true;
                      } else return false;
                    }
                    if (tab.idx === 'perm') {
                      if (caseData.activatePerm) {
                        return true;
                      } else return false;
                    }
                    if (tab.idx !== 'h1b' || tab.idx !== 'perm') {
                      return true;
                    }
                    return false;
                  }).map((tab, i) => (
                    <button
                      key={i}
                      className={`btn btn-sm btn-${
                        i === activeTabIndex
                          ? 'primary shadow'
                          : 'my-dark text-white shadow-sm'
                      } me-2 mb-2`}
                      style={{ width: 'max-content' }}
                      onClick={() => {
                        // console.log('clicked on tab', tab.idx);
                        setActiveTabIndex(i);
                      }}
                    >
                      {tab.label} <i className={`bi bi-${tab.icon} ms-1`}></i>
                    </button>
                  ))}
                </div>
              </div>
            </div>

            <hr className="hy-light-hr mt-3 mb-2" />

            <div className="card-body p-3 m-0 w-100 d-flex flex-column justify-content-start align-items-start overflow-hidden">
              {getActiveComponent(activeTabIndex || 0)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth: { user, isLoggedIn } = {} } = state;
  return {
    user,
    isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload) => {
      dispatch(login(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCase);
