const initialState = {
  user: null,
  token: null,
  isLoggedIn: false,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'LOGIN':
      return { ...state, ...payload };

    case 'LOGOUT':
      return { ...state, ...payload };

    case 'SIGNUP':
      return { ...state, ...payload };

    default:
      return state;
  }
};
