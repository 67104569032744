import format from 'date-fns/format';
import React, { useEffect, useState } from 'react';
import { TICKLER_TYPES, TICKLER_TYPES_MAP } from '../constants/constants';
import { dateFormat, getMahDate, getUserName } from '../utils/userUtils';
import Input from './common/Input';
import Select from './common/Select';
import TicklerReportList from './TicklerReportList';

const TicklersReport = (props) => {
  const { user } = props;

  const [employees, setEmployees] = useState([]);
  const [ticklers, setTicklers] = useState([]);
  const [userForReport, setUserForReport] = useState('all');
  const [ticklerType, setTicklerType] = useState('all');
  const [endDate, setEndDate] = useState(() => getMahDate());
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    initEmployees();
  }, []);

  useEffect(() => {
    runReport(page, searchQuery);
  }, [page, searchQuery]);

  const initEmployees = async () => {
    if (!user || !user.userType) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getUsers(); // change to employees only
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      setEmployees(
        res.result.map((emp) => {
          return {
            label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
            value: emp.id,
          };
        })
      );
    }
  };

  const runReport = async (page = 1, searchQuery = '') => {
    try {
      if (!validateInfo()) return;
      setLoading(true);
      if (page === 1) {
        setPage(1);
      }
      const configService = require('../store/config/service');
      const res = await configService.default.getTicklersByUserAndType(
        userForReport,
        ticklerType,
        new Date(endDate).getTime(),
        page,
        searchQuery
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('tickler report');
        console.table(res.result);
        setTicklers(res.result);
        initPageCount(searchQuery);
      }
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const printReport = async () => {
    try {
      if (!validateInfo()) return;
      setLoading(true);
      const configService = require('../store/config/service');
      const res = await configService.default.getAllTicklersByUserAndType(
        userForReport,
        ticklerType,
        new Date(endDate).getTime()
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('all tickler report for print', res.result);
        let userName = employees.filter((emp) => emp.value === userForReport);
        if (userName.length) {
          userName = userName[0].label;
        } else {
          userName = 'All';
        }
        openPrintView('tickler report', res.result, userName);
      }
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const openPrintView = (docType, data, userName) => {
    if (!validateInfo()) return;
    if (!data.length) return alert('No Ticklers found for your filters!');
    const mywindow = window.open('', 'PRINT', 'height=500,width=700');
    mywindow.document.body.innerHTML = '';
    mywindow.document.write(
      '<html><head><title>' + document.title + '</title>'
    );

    mywindow.document.write(
      `<style>
        body {
          font-family: 'Lato', sans-serif;
        }
        @media print {
          div {
            break-inside: avoid;
          }
        }
      </style>`
    );

    mywindow.document.write('</head><body>');
    mywindow.document.write(`
    <div style="display: flex; justify-content: between; align-items: center">
        <div style="display: flex; flex: 0">
          <img
            height="61"
            width="100"
            alt=""
            src="/assets/img/logo.png"
          />
        </div>
        <div style="display: flex; flex: 1">
          <h3 style="font-weight: 500; padding: 0 1rem; text-transform: capitalize;">${docType}</h3>
        </div>
        <div style="display: flex;">
          <h3 style="font-weight: 400; padding: 0 1rem">${dateFormat(
            Date.now()
          )}</h3>
        </div>
      </div>

      <div style="display: flex; justify-content: start; align-items: center">
        <div style="display: flex; flex: 1; flex-direction: column">
          <h4 style="font-weight: 600; margin: 0; padding: 1rem 0; text-transform: capitalize;">${userName} - ${
      TICKLER_TYPES_MAP[ticklerType]
    }</h4>
        </div>
      </div>
      <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
      <div style="display: flex; flex: 1; background-color: #345; color: #fefefe">
          <h5 style="padding: .5rem; margin: 0;">SL</h5>
        </div>
        <div style="display: flex; flex: 2; background-color: #345; color: #fefefe">
          <h5 style="padding: .5rem; margin: 0;">Due Date</h5>
        </div>
        <div style="display: flex; flex: 2; background-color: #345; color: #fefefe">
          <h5 style="padding: .5rem; margin: 0;">Case No.</h5>
        </div>
        <div style="display: flex; flex: 3; background-color: #345; color: #fefefe">
          <h5 style="padding: .5rem; margin: 0;">Primary Name</h5>
        </div>
        <div style="display: flex; flex: 3; background-color: #345; color: #fefefe">
          <h5 style="padding: .5rem; margin: 0;">Company</h5>
        </div>
        <div style="display: flex; flex: 3; background-color: #345; color: #fefefe">
          <h5 style="padding: .5rem; margin: 0;">Assigned To</h5>
        </div>
        <div style="display: flex; flex: 2; background-color: #345; color: #fefefe">
          <h5 style="padding: .5rem; margin: 0;">Type</h5>
        </div>
        <div style="display: flex; flex: 3; background-color: #345; color: #fefefe">
          <h5 style="padding: .5rem; margin: 0;">Creation Date</h5>
        </div>
      </div>
      ${data
        .map(
          (tk, i) => `
      <div style="display: flex; justify-content: space-evenly; align-items: center; font-size: 12px;">
      <div style="display: flex; flex: 1; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${i + 1}</h5>
      </div>
      <div style="display: flex; flex: 2; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${dateFormat(
          new Date(tk.dueDate)
        )}</h5>
      </div>
      <div style="display: flex; flex: 2; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
          tk.case.caseNum
        }</h5>
      </div>
      <div style="display: flex; flex: 3; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
          tk.case.primaryContact.fullName
        }</h5>
      </div>
      <div style="display: flex; flex: 3; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
          tk.case && tk.case.companyName ? tk.case.companyName : ''
        }</h5>
      </div>
      <div style="display: flex; flex: 3; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0; text-transform: capitalize;">${
          tk.assignedTo && tk.assignedTo.length ? (
            tk.assignedTo
              .map((usr) => {
                return getUserName(usr);
              })
              .join(', ')
          ) : (
            <React.Fragment />
          )
        }</h5>
      </div>
      <div style="display: flex; flex: 2; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${tk.type}</h5>
      </div>
      <div style="display: flex; flex: 3; margin: auto">
        <h5 style="font-weight: 500; padding: .5rem; margin: 0;">${dateFormat(
          new Date(tk.createdAt)
        )}</h5>
      </div>
    </div>
    <div style="display: flex; justify-content: flex-start; align-items: center; font-size: 12px; border-top: 1px solid #9ab; border-bottom: 2px solid #9ab">
        <div style="display: flex; flex: 1">
          
        </div>
        <div style="display: flex; flex: 18">
          <h5 style="font-weight: 600; padding: .5rem; margin: 0;">${
            tk.desc
          }</h5>
        </div>
      </div>
      `
        )
        .join('')}
    `);
    mywindow.document.write('</body></html>');
    // mywindow.document.close(); // necessary for IE >= 10
    setTimeout(function () {
      mywindow.focus(); // necessary for IE >= 10*/
      mywindow.print();
    }, 250);
    // mywindow.close();
    // return true;
  };

  const initPageCount = async (searchQuery = '') => {
    try {
      if (!validateInfo()) return;
      const configService = require('../store/config/service');
      const res = await configService.default.getTicklersByUserAndTypePageCount(
        userForReport,
        ticklerType,
        new Date(endDate).getTime(),
        searchQuery
      );
      if (!res) {
      }
      if (res.error) {
        setPageCount(1);
      }
      if (res.result && res.result.count) {
        setPageCount(res.result.count);
      }
    } catch (e) {
      // console.log(e);
    }
  };

  const validateInfo = () => {
    let flag = true;
    if (!userForReport || !userForReport.length) {
      flag = false;
      handleError('userForReport', 'select a user');
    }

    if (!ticklerType || !ticklerType.length) {
      flag = false;
      handleError('ticklerType', 'select a tickler type');
    }

    if (!endDate || !endDate.length) {
      flag = false;
      handleError('endDate', 'select end date');
    }

    if (!user || !user.userType || !user.userType.length || !page || page < 1) {
      flag = false;
    }
    return flag;
  };

  const handleError = (key, val) => {
    if (!key) return;
    setErrors((current) => {
      let newErrors = Object.assign({}, current);
      newErrors[key] = val;
      return newErrors;
    });
  };

  return (
    <div className="w-100">
      <h4 className="text-dark">Pending Ticklers / Deadlines Report</h4>
      <div className="row">
        <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-xs-12 col-12">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 col-12">
              <Select
                options={[{ label: 'All', value: 'all' }, ...employees]}
                placeholder={''}
                value={userForReport}
                label={'User'}
                onChange={(e) => {
                  setUserForReport(e.target.value);
                }}
                err={errors.userForReport || ''}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 col-12">
              <Select
                options={[{ label: 'All', value: 'all' }, ...TICKLER_TYPES]}
                placeholder={''}
                value={ticklerType}
                label={'Type'}
                onChange={(e) => {
                  setTicklerType(e.target.value);
                }}
                err={errors.ticklerType || ''}
              />
            </div>
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-xs-12 col-12">
              <Input
                type="date"
                placeholder={'Select end  date'}
                value={endDate}
                label={'End Date'}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
                err={errors.endDate || ''}
              />
            </div>
          </div>
        </div>
        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-xs-12 col-12 d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-center align-items-center mt-2">
            <button
              onClick={() => runReport(1, searchQuery)}
              className="btn btn-primary shadow-sm m-1"
            >
              Run Report
            </button>
            <button
              onClick={printReport}
              className="btn btn-success shadow-sm m-1"
            >
              Print Report
            </button>
          </div>
        </div>
      </div>

      <hr />

      <div>
        <TicklerReportList
          ticklers={ticklers}
          page={page}
          pageCount={pageCount}
          setPage={setPage}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default TicklersReport;
