import React from 'react';

const PERMStatusReport = (props) => {
  const { user } = props;
  return <div>
    <h3>PERMStatusReport</h3>
  </div>;
};

export default PERMStatusReport;
