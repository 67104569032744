import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CASE_TABS } from '../../constants/constants';
import Consultation from '../Consultation';
import ConsultInfo from '../ConsultInfo';

const ViewConsult = (props) => {
  const { user, isLoggedIn } = props;
  const { id: consultId } = useParams();
  const [consultData, setConsultData] = useState({});

  useEffect(() => {
    initConsult(consultId);
  }, [consultId]);

  const initConsult = async (consultId) => {
    if (!consultId || !consultId.length) return;
    const configService = require('../../store/config/service');
    const res = await configService.default.getConsultation(consultId);
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      // console.log('case data', res.result);
      setConsultData(res.result);
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 col-12 mb-4">
          <div className="card pb-2">
            {/* <div className="card-head px-3 py-3 pb-0 d-flex justify-content-start align-items-center">
              <div className="d-flex flex-column justify-content-start align-items-start w-100">
                <h5 className="fw-semi-bold">
                  Consult Number{' '}
                  {consultData && consultData.consultNum ? (
                    <span className="fw-bold">
                      {`C-${consultData.consultNum}`}
                    </span>
                  ) : (
                    <React.Fragment />
                  )}
                </h5>
              </div>
            </div>

            <hr className="hy-light-hr mt-3 mb-2" /> */}

            <div className="card-body p-3 m-0 w-100 d-flex flex-column justify-content-start align-items-start overflow-hidden">
              <ConsultInfo
                consultData={consultData}
                user={user}
                reinitialize={() => initConsult(consultId)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { auth: { user, isLoggedIn } = {} } = state;
  return {
    user,
    isLoggedIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (payload) => {
      dispatch(login(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewConsult);
