import React, { useEffect } from 'react';
import { sleep } from '../../utils/userUtils';

const MyMessage = (props) => {
  const { visible, message, onClose } = props;

  // useEffect(() => {
  //   sleepAndClose();
  // }, [visible]);

  // const sleepAndClose = async () => {
  //   if (visible) {
  //     setTimeout(() => {
  //       onClose();
  //     }, 5000);
  //     return;
  //   }
  //   return;
  // };

  if (!message) return <React.Fragment />;
  if (!visible) return <React.Fragment />;
  return (
    <div>
      {message && message.msg && message.type && (
        <div
          className={`alert d-flex justify-content-between align-items-center alert-${message.type}`}
          role="alert"
        >
          {message.msg}
          {onClose ? (
            <i
              className="bi bi-x-circle-fill"
              onClick={onClose}
              style={{ cursor: 'pointer' }}
            ></i>
          ) : (
            <React.Fragment />
          )}
        </div>
      )}
    </div>
  );
};

export default MyMessage;
