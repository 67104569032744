import { GET, POST, PUT, DELETE } from '../../services/APIService';

const signUp = (user) => {
  return POST('/auth/signup', user);
};

const login = (body) => {
  return POST('/auth/login', body);
};

const resetPassword = (body) => {
  return POST('/auth/reset_password', body);
};

const logout = () => {
  return POST('/auth/logout');
};

const getUsers = () => {
  return GET('/auth/users');
};

const getUser = (id) => {
  return GET(`/auth/${id}`);
};

const updateUser = (id, body) => {
  return PUT(`/auth/${id}`, body);
};

const deleteUser = (id) => {
  return DELETE(`/auth/${id}`);
};

const getUsersByUserType = (userType) => {
  return GET(`/auth/users?userType=${userType}`);
};

const getPagedUsers = (page, searchQuery) => {
  return GET(`/auth/users_paged?page=${page}&q=${searchQuery}`);
};

const getUsersPageCount = (searchQuery) => {
  return GET(`/auth/users/page_count?q=${searchQuery}`);
};

//
//

const createCase = (body) => {
  return POST('/case/create', body);
};

const updateCase = (id, body) => {
  return PUT('/case/' + id, body);
};

const getCases = (page, searchQuery, isAdvanced, advanceType) => {
  return GET(`/case?page=${page}&q=${searchQuery}&isAdvanced=${isAdvanced}&advanceType=${advanceType}`);
};

const getAllCases = () => {
  return GET(`/case?all=${true}`);
};

const getAllCasesByCompany = (companyId, officeType = 'all') => {
  return GET(`/case?company=${companyId}&office=${officeType}&all=${true}`);
};

const getAllCasesByCompanyList = (company_list) => {
  return GET(`/case?company_list=${company_list}&all=${true}`);
};

const getAllCasesByType = (type, officeType = 'all') => {
  return GET(`/case?caseType=${type}&office=${officeType}&all=${true}`);
};

const getAllCasesByStatus = (status, officeType = 'all') => {
  return GET(`/case?status=${status}&office=${officeType}&all=${true}`);
};

const getAllCasesByOffice = (office) => {
  return GET(`/case?office=${office}&all=${true}`);
};

const getCasesPageCount = (searchQuery, isAdvanced, advanceType) => {
  return GET(`/case/page_count?q=${searchQuery}&isAdvanced=${isAdvanced}&advanceType=${advanceType}`);
};

const getMyCases = (id) => {
  return GET(`/case/mine/${id}`);
};

const getCase = (id) => {
  return GET(`/case/${id}`);
};

//
//

const createContract = (body) => {
  return POST('/contract/create', body);
};

const updateContract = (id, body) => {
  return PUT('/contract/' + id, body);
};

const getContracts = (caseId, page, searchQuery) => {
  return GET(`/contract?case=${caseId}&page=${page}&q=${searchQuery}`);
};

const getContractsPageCount = (caseId, searchQuery) => {
  return GET(`/contract/page_count?case=${caseId}&q=${searchQuery}`);
};

const getMyContracts = (id) => {
  return GET(`/contract/mine/${id}`);
};

const getContract = (id) => {
  return GET(`/contract/${id}`);
};

//
//

//
//

const createTickler = (body) => {
  return POST('/tickler/create', body);
};

const updateTickler = (id, body) => {
  return PUT('/tickler/' + id, body);
};

const getTicklers = (caseId, page, searchQuery) => {
  return GET(
    `/tickler?isPending=${true}&case=${caseId}&page=${page}&q=${searchQuery}`
  );
};

const getTicklersPageCount = (caseId, searchQuery) => {
  return GET(
    `/tickler/page_count?isPending=${true}&case=${caseId}&q=${searchQuery}`
  );
};

const getCompletedTicklers = (caseId, page, searchQuery) => {
  return GET(
    `/tickler?isCompleted=${true}&case=${caseId}&page=${page}&q=${searchQuery}`
  );
};

const getCompletedTicklersPageCount = (caseId, searchQuery) => {
  return GET(
    `/tickler/page_count?isCompleted=${true}&case=${caseId}&q=${searchQuery}`
  );
};

const getTicklersByUserAndType = (userId, type, endDate, page, searchQuery) => {
  return GET(
    `/tickler?isPending=${true}&createdBy=${userId}&type=${type}&endDate=${endDate}&page=${page}&q=${searchQuery}`
  );
};

const getTicklersByUserAndTypePageCount = (
  userId,
  type,
  endDate,
  searchQuery
) => {
  return GET(
    `/tickler/page_count?isPending=${true}&createdBy=${userId}&type=${type}&endDate=${endDate}&q=${searchQuery}`
  );
};

const getAllTicklersByUserAndType = (userId, type, endDate) => {
  return GET(
    `/tickler?isPending=${true}&createdBy=${userId}&type=${type}&endDate=${endDate}&all=${true}`
  );
};

const getMyTicklers = (id) => {
  return GET(`/tickler/mine/${id}`);
};

const getTickler = (id) => {
  return GET(`/tickler/${id}`);
};

//
//

//
//

const createNote = (body) => {
  return POST('/note/create', body);
};

const updateNote = (id, body) => {
  return PUT('/note/' + id, body);
};

const getNotes = (caseId, page, searchQuery) => {
  return GET(
    `/note?isRegular=${true}&case=${caseId}&page=${page}&q=${searchQuery}`
  );
};

const getMyNotes = (caseId, page, searchQuery, userId) => {
  return GET(
    `/note?isRegular=${true}&createdBy=${userId}&case=${caseId}&page=${page}&q=${searchQuery}`
  );
};

const getNotesPageCount = (caseId, searchQuery) => {
  return GET(
    `/note/page_count?isRegular=${true}&case=${caseId}&q=${searchQuery}`
  );
};

const getMyNotesPageCount = (caseId, searchQuery, userId) => {
  return GET(
    `/note/page_count?isRegular=${true}&createdBy=${userId}&case=${caseId}&q=${searchQuery}`
  );
};

const getBillingNotes = (caseId, page, searchQuery) => {
  return GET(
    `/note?isBilling=${true}&case=${caseId}&page=${page}&q=${searchQuery}`
  );
};

const getMyBillingNotes = (caseId, page, searchQuery, userId) => {
  return GET(
    `/note?isBilling=${true}&createdBy=${userId}&case=${caseId}&page=${page}&q=${searchQuery}`
  );
};

const getBillingNotesPageCount = (caseId, searchQuery) => {
  return GET(
    `/note/page_count?isBilling=${true}&case=${caseId}&q=${searchQuery}`
  );
};

const getMyBillingNotesPageCount = (caseId, searchQuery, userId) => {
  return GET(
    `/note/page_count?isBilling=${true}&createdBy=${userId}&case=${caseId}&q=${searchQuery}`
  );
};

const getAllBillingNotes = (startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/note?isBilling=${true}&startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

const getAllBillingNotesByCase = (caseId, startStamp, endStamp) => {
  return GET(
    `/note?isBilling=${true}&case=${caseId}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllBillingNotesByCaseList = (case_list, startStamp, endStamp) => {
  return GET(
    `/note?isBilling=${true}&case_list=${case_list}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

// const getMyNotes = (id) => {
//   return GET(`/note/mine/${id}`);
// };

const getNote = (id) => {
  return GET(`/note/${id}`);
};

//
//

//
//

const createPayment = (body) => {
  return POST('/payment/create', body);
};

const updatePayment = (id, body) => {
  return PUT('/payment/' + id, body);
};

const deletePayment = (id) => {
  return DELETE(`/payment/${id}`);
};

const getPayments = (caseId, page, searchQuery, startStamp, endStamp) => {
  return GET(
    `/payment?case=${caseId}&page=${page}&q=${searchQuery}&startStamp=${startStamp}&endStamp=${endStamp}`
  );
};

const getFilingPayments = (caseId, page, searchQuery, startStamp, endStamp) => {
  return GET(
    `/payment?isFiling=${true}&case=${caseId}&page=${page}&q=${searchQuery}&startStamp=${startStamp}&endStamp=${endStamp}`
  );
};

const getPaymentsPageCount = (caseId, searchQuery, startStamp, endStamp) => {
  return GET(
    `/payment/page_count?case=${caseId}&q=${searchQuery}&startStamp=${startStamp}&endStamp=${endStamp}`
  );
};

const getFilingPaymentsPageCount = (caseId, searchQuery, startStamp, endStamp) => {
  return GET(
    `/payment/page_count?isFiling=${true}&case=${caseId}&q=${searchQuery}&startStamp=${startStamp}&endStamp=${endStamp}`
  );
};

const getAllPayments = (startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/payment?startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

const getAllPaymentsByCase = (caseId, startStamp, endStamp) => {
  return GET(
    `/payment?case=${caseId}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllFilingPaymentsByCase = (caseId, startStamp, endStamp) => {
  return GET(
    `/payment?isFiling=${true}&case=${caseId}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllPaymentsByCaseList = (case_list, startStamp, endStamp) => {
  return GET(
    `/payment?case_list=${case_list}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllPaymentsByCaseSubType = (caseSubType, startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/payment?caseSubType=${caseSubType}&startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

// only charges

const getAllCharges = (startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/payment?type=${'charge'}&startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

const getAllChargesByCase = (caseId, startStamp, endStamp) => {
  return GET(
    `/payment?type=${'charge'}&case=${caseId}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllChargesByCaseList = (case_list, startStamp, endStamp) => {
  return GET(
    `/payment?type=${'charge'}&case_list=${case_list}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllChargesByCaseSubType = (caseSubType, startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/payment?type=${'charge'}&caseSubType=${caseSubType}&startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

const getMyPayments = (id) => {
  return GET(`/payment/mine/${id}`);
};

const getPayment = (id) => {
  return GET(`/payment/${id}`);
};

//
//

//
//

const createIolta = (body) => {
  return POST('/iolta/create', body);
};

const updateIolta = (id, body) => {
  return PUT('/iolta/' + id, body);
};

const deleteIolta = (id) => {
  return DELETE(`/iolta/${id}`);
};

const getIoltas = (caseId, page, searchQuery, startStamp, endStamp) => {
  return GET(
    `/iolta?isRegular=${true}&case=${caseId}&page=${page}&q=${searchQuery}&startStamp=${startStamp}&endStamp=${endStamp}`
  );
};

const getIoltasPageCount = (caseId, searchQuery, startStamp, endStamp) => {
  return GET(
    `/iolta/page_count?isRegular=${true}&case=${caseId}&q=${searchQuery}&startStamp=${startStamp}&endStamp=${endStamp}`
  );
};

const getAllIoltas = (startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/iolta?isRegular=${true}&startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

const getAllIoltasByCase = (caseId, startStamp, endStamp) => {
  return GET(
    `/iolta?isRegular=${true}&case=${caseId}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllIoltasByCaseList = (case_list, startStamp, endStamp) => {
  return GET(
    `/iolta?isRegular=${true}&case_list=${case_list}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllIoltasByCaseSubType = (caseSubType, startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/iolta?isRegular=${true}&caseSubType=${caseSubType}&startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

// Only Deposits (Regular)

const getAllDeposits = (startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/iolta?type=${'deposit'}&isRegular=${true}&startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

const getAllDepositsByCase = (caseId, startStamp, endStamp) => {
  return GET(
    `/iolta?type=${'deposit'}&isRegular=${true}&case=${caseId}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllDepositsByCaseList = (case_list, startStamp, endStamp) => {
  return GET(
    `/iolta?type=${'deposit'}&isRegular=${true}&case_list=${case_list}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllDepositsByCaseSubType = (caseSubType, startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/iolta?type=${'deposit'}&isRegular=${true}&caseSubType=${caseSubType}&startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

// Only Deposits (Filing)

const getAllFilingDeposits = (startStamp, endStamp) => {
  return GET(
    `/iolta?type=${'deposit'}&isFiling=${true}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllFilingDepositsByCase = (caseId, startStamp, endStamp) => {
  return GET(
    `/iolta?type=${'deposit'}&isFiling=${true}&case=${caseId}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllFilingDepositsByCaseList = (case_list, startStamp, endStamp) => {
  return GET(
    `/iolta?type=${'deposit'}&isFiling=${true}&case_list=${case_list}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllFilingDepositsByCaseSubType = (
  caseSubType,
  startStamp,
  endStamp
) => {
  return GET(
    `/iolta?type=${'deposit'}&isFiling=${true}&caseSubType=${caseSubType}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

// Only Deposits (Pending)

const getAllPendingDeposits = (startStamp, endStamp) => {
  return GET(
    `/iolta?type=${'deposit'}&isPending=${true}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllPendingDepositsByCase = (caseId, startStamp, endStamp) => {
  return GET(
    `/iolta?type=${'deposit'}&isPending=${true}&case=${caseId}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllPendingDepositsByCaseList = (case_list, startStamp, endStamp) => {
  return GET(
    `/iolta?type=${'deposit'}&isPending=${true}&case_list=${case_list}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllPendingDepositsByCaseSubType = (
  caseSubType,
  startStamp,
  endStamp
) => {
  return GET(
    `/iolta?type=${'deposit'}&isPending=${true}&caseSubType=${caseSubType}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

// Filing

const getFilingIoltas = (caseId, page, searchQuery, startStamp, endStamp) => {
  return GET(
    `/iolta?isFiling=${true}&case=${caseId}&page=${page}&q=${searchQuery}&startStamp=${startStamp}&endStamp=${endStamp}`
  );
};

const getFilingIoltasPageCount = (
  caseId,
  searchQuery,
  startStamp,
  endStamp
) => {
  return GET(
    `/iolta/page_count?isFiling=${true}&case=${caseId}&q=${searchQuery}&startStamp=${startStamp}&endStamp=${endStamp}`
  );
};

const getAllFilingIoltas = (startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/iolta?isFiling=${true}&startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

const getAllFilingIoltasByCase = (caseId, startStamp, endStamp) => {
  return GET(
    `/iolta?isFiling=${true}&case=${caseId}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllFilingIoltasByCaseList = (case_list, startStamp, endStamp) => {
  return GET(
    `/iolta?isFiling=${true}&case_list=${case_list}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllFilingIoltasByCaseSubType = (caseSubType, startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/iolta?isFiling=${true}&caseSubType=${caseSubType}&startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

// Pending

const getPendingIoltas = (caseId, page, searchQuery, startStamp, endStamp) => {
  return GET(
    `/iolta?isPending=${true}&case=${caseId}&page=${page}&q=${searchQuery}&startStamp=${startStamp}&endStamp=${endStamp}`
  );
};

const getPendingIoltasPageCount = (
  caseId,
  searchQuery,
  startStamp,
  endStamp
) => {
  return GET(
    `/iolta/page_count?isPending=${true}&case=${caseId}&q=${searchQuery}&startStamp=${startStamp}&endStamp=${endStamp}`
  );
};

const getAllPendingIoltas = (startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/iolta?isPending=${true}&startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

const getAllPendingIoltasByCase = (caseId, startStamp, endStamp) => {
  return GET(
    `/iolta?isPending=${true}&case=${caseId}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllPendingIoltasByCaseList = (case_list, startStamp, endStamp) => {
  return GET(
    `/iolta?isPending=${true}&case_list=${case_list}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllPendingIoltasByCaseSubType = (
  caseSubType,
  startStamp,
  endStamp,
  officeType = 'all'
) => {
  return GET(
    `/iolta?isPending=${true}&caseSubType=${caseSubType}&startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

const getMyIoltas = (id) => {
  return GET(`/iolta/mine/${id}`);
};

const getIolta = (id) => {
  return GET(`/iolta/${id}`);
};

//
//

//
//

const createH1b = (body) => {
  return POST('/h1b/create', body);
};

const updateH1b = (id, body) => {
  return PUT('/h1b/' + id, body);
};

const getH1bByCase = (caseId) => {
  return GET(`/h1b?case=${caseId}`);
};

const getAllH1bs = () => {
  return GET(`/h1b?all=${true}`);
};

const getH1bByCaseList = (case_list) => {
  return GET(`/h1b?case_list=${case_list}`);
};

const getH1b = (id) => {
  return GET(`/h1b/${id}`);
};

//
//

//
//

const createPerm = (body) => {
  return POST('/perm/create', body);
};

const updatePerm = (id, body) => {
  return PUT('/perm/' + id, body);
};

const getPermByCase = (caseId) => {
  return GET(`/perm?case=${caseId}`);
};

const getPermByCaseList = (case_list) => {
  return GET(`/perm?case_list=${case_list}`);
};

const getPerm = (id) => {
  return GET(`/perm/${id}`);
};

const getAllPerms = () => {
  return GET(`/perm?all=${true}`);
};

//
//

//
//

const createEmail = (body) => {
  return POST('/email/create', body);
};

const updateEmail = (id, body) => {
  return PUT('/email/' + id, body);
};

const getEmails = (caseId, page, searchQuery) => {
  return GET(`/email?case=${caseId}&page=${page}&q=${searchQuery}`);
};

const getEmailsPageCount = (caseId, searchQuery) => {
  return GET(`/email/page_count?case=${caseId}&q=${searchQuery}`);
};

const getMyEmails = (id) => {
  return GET(`/email/mine/${id}`);
};

const getEmail = (id) => {
  return GET(`/email/${id}`);
};

//
//

const createConsultation = (body) => {
  return POST('/consultation/create', body);
};

const updateConsultation = (id, body) => {
  return PUT('/consultation/' + id, body);
};

const getConsultations = (page, searchQuery) => {
  return GET(`/consultation?page=${page}&q=${searchQuery}`);
};

const getConsultationsByCaseId = (caseId) => {
  return GET(`/consultation?case=${caseId}`);
};

const getConsultationsPageCount = (searchQuery) => {
  return GET(`/consultation/page_count?q=${searchQuery}`);
};

const getMyConsultations = (id) => {
  return GET(`/consultation/mine/${id}`);
};

const getConsultation = (id) => {
  return GET(`/consultation/${id}`);
};

const createAppointment = (body) => {
  return POST('/appointment/create', body);
};

const updateAppointment = (id, body) => {
  return PUT('/appointment/' + id, body);
};

const deleteAppointment = (id) => {
  return DELETE(`/appointment/${id}`);
};

const getAppointments = (page, searchQuery, startTime, endTime) => {
  return GET(
    `/appointment?isPending=${true}&page=${page}&q=${searchQuery}&startTime=${startTime}&endTime=${endTime}`
  );
};

const getAppointmentsPageCount = (searchQuery, startTime, endTime) => {
  return GET(
    `/appointment/page_count?isPending=${true}&q=${searchQuery}&startTime=${startTime}&endTime=${endTime}`
  );
};

const getCaseAppointments = (caseId, page, searchQuery, startTime, endTime) => {
  return GET(
    `/appointment?isPending=${true}&case=${caseId}&page=${page}&q=${searchQuery}&startTime=${startTime}&endTime=${endTime}`
  );
};

const getCaseAppointmentsPageCount = (
  caseId,
  searchQuery,
  startTime,
  endTime
) => {
  return GET(
    `/appointment/page_count?isPending=${true}&case=${caseId}&q=${searchQuery}&startTime=${startTime}&endTime=${endTime}`
  );
};

const getMyAppointments = (
  id,
  caseId,
  page,
  searchQuery,
  startTime,
  endTime
) => {
  return GET(
    `/appointment?isPending=${true}&createdBy=${id}&case=${caseId}&page=${page}&q=${searchQuery}&startTime=${startTime}&endTime=${endTime}`
  );
};

const getMyAppointmentsPageCount = (
  id,
  caseId,
  searchQuery,
  startTime,
  endTime
) => {
  return GET(
    `/appointment/page_count?isPending=${true}&createdBy=${id}&case=${caseId}&q=${searchQuery}&startTime=${startTime}&endTime=${endTime}`
  );
};

// Completed Appointments

const getCompletedAppointments = (page, searchQuery, startTime, endTime) => {
  return GET(
    `/appointment?isCompleted=${true}&page=${page}&q=${searchQuery}&startTime=${startTime}&endTime=${endTime}`
  );
};

const getCompletedAppointmentsPageCount = (searchQuery, startTime, endTime) => {
  return GET(
    `/appointment/page_count?isCompleted=${true}&q=${searchQuery}&startTime=${startTime}&endTime=${endTime}`
  );
};

const getCaseCompletedAppointments = (
  caseId,
  page,
  searchQuery,
  startTime,
  endTime
) => {
  return GET(
    `/appointment?isCompleted=${true}&case=${caseId}&page=${page}&q=${searchQuery}&startTime=${startTime}&endTime=${endTime}`
  );
};

const getCaseCompletedAppointmentsPageCount = (
  caseId,
  searchQuery,
  startTime,
  endTime
) => {
  return GET(
    `/appointment/page_count?isCompleted=${true}&case=${caseId}&q=${searchQuery}&startTime=${startTime}&endTime=${endTime}`
  );
};

const getMyCompletedAppointments = (
  id,
  caseId,
  page,
  searchQuery,
  startTime,
  endTime
) => {
  return GET(
    `/appointment?isCompleted=${true}&createdBy=${id}&case=${caseId}&page=${page}&q=${searchQuery}&startTime=${startTime}&endTime=${endTime}`
  );
};

const getMyCompletedAppointmentsPageCount = (
  id,
  caseId,
  searchQuery,
  startTime,
  endTime
) => {
  return GET(
    `/appointment/page_count?isCompleted=${true}&createdBy=${id}&case=${caseId}&q=${searchQuery}&startTime=${startTime}&endTime=${endTime}`
  );
};

const getAppointmentsForCalender = (id, startTime, endTime) => {
  return GET(
    `/appointment?createdBy=${id}&startTime=${startTime}&endTime=${endTime}`
  );
};

const getAppointment = (id) => {
  return GET(`/appointment/${id}`);
};

const createCompany = (body) => {
  return POST('/company/create', body);
};

const updateCompany = (id, body) => {
  return PUT('/company/' + id, body);
};

const getCompanies = () => {
  return GET('/company');
};

const getCompaniesByName = (companyName) => {
  return GET(`/company?companyName=${companyName}&r=10`);
};

const getCompany = (id) => {
  return GET(`/company/${id}`);
};


//
//

const createPercentage = (body) => {
  return POST('/percentage/create', body);
};

const updatePercentage = (id, body) => {
  return PUT('/percentage/' + id, body);
};

const getPercentages = (caseId, page, searchQuery) => {
  return GET(`/percentage?case=${caseId}&page=${page}&q=${searchQuery}`);
};

const getPercentagesPageCount = (caseId, searchQuery) => {
  return GET(`/percentage/page_count?case=${caseId}&q=${searchQuery}`);
};

const getMyPercentages = (id) => {
  return GET(`/percentage/mine/${id}`);
};

const getAllPercentages = (startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/percentage?startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

const getAllPercentagesByCase = (caseId, startStamp, endStamp) => {
  return GET(
    `/percentage?case=${caseId}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllPercentagesByCaseList = (case_list, startStamp, endStamp) => {
  return GET(
    `/percentage?case_list=${case_list}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllPercentagesByCaseSubType = (caseSubType, startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/percentage?caseSubType=${caseSubType}&startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

const getPercentage = (id) => {
  return GET(`/percentage/${id}`);
};

const deletePercentage = (id) => {
  return DELETE(`/percentage/${id}`);
};

//
//

//
//

const createTimesheet = (body) => {
  return POST('/timesheet/create', body);
};

const updateTimesheet = (id, body) => {
  return PUT('/timesheet/' + id, body);
};

const getTimesheets = (caseId, page, searchQuery) => {
  return GET(`/timesheet?case=${caseId}&page=${page}&q=${searchQuery}`);
};

const getTimesheetsPageCount = (caseId, searchQuery) => {
  return GET(`/timesheet/page_count?case=${caseId}&q=${searchQuery}`);
};

const getMyTimesheets = (id) => {
  return GET(`/timesheet/mine/${id}`);
};

const getAllTimesheets = (startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/timesheet?startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

const getAllTimesheetsByCase = (caseId, startStamp, endStamp) => {
  return GET(
    `/timesheet?case=${caseId}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllTimesheetsByCaseList = (case_list, startStamp, endStamp) => {
  return GET(
    `/timesheet?case_list=${case_list}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllTimesheetsByCaseSubType = (caseSubType, startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/timesheet?caseSubType=${caseSubType}&startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

const getTimesheet = (id) => {
  return GET(`/timesheet/${id}`);
};

const deleteTimesheet = (id) => {
  return DELETE(`/timesheet/${id}`);
};

//
//

//
//

const createCost = (body) => {
  return POST('/cost/create', body);
};

const updateCost = (id, body) => {
  return PUT('/cost/' + id, body);
};

const getCosts = (caseId, page, searchQuery) => {
  return GET(`/cost?case=${caseId}&page=${page}&q=${searchQuery}`);
};

const getCostsPageCount = (caseId, searchQuery) => {
  return GET(`/cost/page_count?case=${caseId}&q=${searchQuery}`);
};

const getMyCosts = (id) => {
  return GET(`/cost/mine/${id}`);
};

const getAllCosts = (startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/cost?startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

const getAllCostsByCase = (caseId, startStamp, endStamp) => {
  return GET(
    `/cost?case=${caseId}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllCostsByCaseList = (case_list, startStamp, endStamp) => {
  return GET(
    `/cost?case_list=${case_list}&startStamp=${startStamp}&endStamp=${endStamp}&all=${true}`
  );
};

const getAllCostsByCaseSubType = (caseSubType, startStamp, endStamp, officeType = 'all') => {
  return GET(
    `/cost?caseSubType=${caseSubType}&startStamp=${startStamp}&endStamp=${endStamp}&office=${officeType}&all=${true}`
  );
};

const getCost = (id) => {
  return GET(`/cost/${id}`);
};

const deleteCost = (id) => {
  return DELETE(`/cost/${id}`);
};

//
//



export default {
  signUp,
  login,
  resetPassword,
  getUser,
  updateUser,
  deleteUser,
  getUsers,
  getUsersByUserType,
  getPagedUsers,
  getUsersPageCount,
  logout,
  createCase,
  updateCase,
  getCases,
  getAllCases,
  getAllCasesByCompany,
  getAllCasesByCompanyList,
  getAllCasesByType,
  getAllCasesByStatus,
  getAllCasesByOffice,
  getCasesPageCount,
  getMyCases,
  getCase,
  createContract,
  updateContract,
  getContracts,
  getContractsPageCount,
  getMyContracts,
  getContract,
  createTickler,
  updateTickler,
  getTicklers,
  getTicklersPageCount,
  getCompletedTicklers,
  getCompletedTicklersPageCount,
  getTicklersByUserAndType,
  getTicklersByUserAndTypePageCount,
  getAllTicklersByUserAndType,
  getMyTicklers,
  getTickler,
  createNote,
  updateNote,
  getNotes,
  getNotesPageCount,
  getMyNotes,
  getMyNotesPageCount,
  getBillingNotes,
  getMyBillingNotes,
  getBillingNotesPageCount,
  getMyBillingNotesPageCount,
  getAllBillingNotes,
  getAllBillingNotesByCase,
  getAllBillingNotesByCaseList,
  getNote,
  createPayment,
  updatePayment,
  deletePayment,
  getPayments,
  getFilingPayments,
  getPaymentsPageCount,
  getFilingPaymentsPageCount,
  getAllPayments,
  getAllPaymentsByCase,
  getAllFilingPaymentsByCase,
  getAllPaymentsByCaseList,
  getAllPaymentsByCaseSubType,
  getAllCharges,
  getAllChargesByCase,
  getAllChargesByCaseList,
  getAllChargesByCaseSubType,
  getMyPayments,
  getPayment,
  createIolta,
  updateIolta,
  deleteIolta,
  getIoltas,
  getIoltasPageCount,
  getAllIoltas,
  getAllIoltasByCase,
  getAllIoltasByCaseList,
  getAllIoltasByCaseSubType,
  getAllDeposits,
  getAllDepositsByCase,
  getAllDepositsByCaseList,
  getAllDepositsByCaseSubType,
  getAllFilingDeposits,
  getAllFilingDepositsByCase,
  getAllFilingDepositsByCaseList,
  getAllFilingDepositsByCaseSubType,
  getAllPendingDeposits,
  getAllPendingDepositsByCase,
  getAllPendingDepositsByCaseList,
  getAllPendingDepositsByCaseSubType,
  getFilingIoltas,
  getFilingIoltasPageCount,
  getAllFilingIoltas,
  getAllFilingIoltasByCase,
  getAllFilingIoltasByCaseList,
  getAllFilingIoltasByCaseSubType,
  getPendingIoltas,
  getPendingIoltasPageCount,
  getAllPendingIoltas,
  getAllPendingIoltasByCase,
  getAllPendingIoltasByCaseList,
  getAllPendingIoltasByCaseSubType,
  getMyIoltas,
  getIolta,
  createH1b,
  updateH1b,
  getH1bByCase,
  getH1bByCaseList,
  getH1b,
  getAllH1bs,
  createPerm,
  updatePerm,
  getPermByCase,
  getPermByCaseList,
  getPerm,
  getAllPerms,
  createEmail,
  updateEmail,
  getEmails,
  getEmailsPageCount,
  getMyEmails,
  getEmail,
  createConsultation,
  updateConsultation,
  getConsultations,
  getConsultationsByCaseId,
  getConsultationsPageCount,
  getMyConsultations,
  getConsultation,
  createAppointment,
  updateAppointment,
  deleteAppointment,
  getAppointments,
  getAppointmentsPageCount,
  getCaseAppointments,
  getCaseAppointmentsPageCount,
  getMyAppointments,
  getMyAppointmentsPageCount,
  getCompletedAppointments,
  getCompletedAppointmentsPageCount,
  getCaseCompletedAppointments,
  getCaseCompletedAppointmentsPageCount,
  getMyCompletedAppointments,
  getMyCompletedAppointmentsPageCount,
  getAppointmentsForCalender,
  getAppointment,
  createCompany,
  updateCompany,
  getCompanies,
  getCompaniesByName,
  getCompany,
  createPercentage,
  updatePercentage,
  getPercentages,
  getPercentagesPageCount,
  getMyPercentages,
  getAllPercentages,
  getAllPercentagesByCase,
  getAllPercentagesByCaseList,
  getAllPercentagesByCaseSubType,
  getPercentage,
  deletePercentage,
  createTimesheet,
  updateTimesheet,
  getTimesheets,
  getTimesheetsPageCount,
  getMyTimesheets,
  getAllTimesheets,
  getAllTimesheetsByCase,
  getAllTimesheetsByCaseList,
  getAllTimesheetsByCaseSubType,
  getTimesheet,
  deleteTimesheet,
  createCost,
  updateCost,
  getCosts,
  getCostsPageCount,
  getMyCosts,
  getAllCosts,
  getAllCostsByCase,
  getAllCostsByCaseList,
  getAllCostsByCaseSubType,
  getCost,
  deleteCost,
};
