import React from 'react';

const AppBar = (props) => {
  const { bg = 'bg-my-light', user, isLoggedIn } = props;

  return (
    <>
      <div className="my-app-bar row g-0 py-0 py-md-4 pb-4 pb-md-3">
        <div className="col-3 text-center align-self-center my-3">
          <img
            src={
              user && user.name
                ? `https://ui-avatars.com/api/?name=${user.name.firstName}+${user.name.lastName}`
                : 'https://ui-avatars.com/api'
            }
            alt=""
            className="w-60 rounded-circle shadow-sm"
          />
        </div>
        <div className="col-9 text-left align-self-center">
          <h5 className="h5 mb-1 truncate text-white">
            {'Hi, ' + user.name.firstName + '!'}
          </h5>
          <span className="badge rounded-pill bg-primary pt-1pt5 pb-1pt5 size14 weight300 px-2 py-1 my-1">
            {user.userType}
          </span>
        </div>
      </div>
    </>
  );
};

export default AppBar;
