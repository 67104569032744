import React, { useEffect, useState } from 'react';
import { getUserName } from '../utils/userUtils';
import IoltaList from './IoltaList';
import AddIoltaModal from './modals/AddIoltaModal';
import EditIoltaModal from './modals/EditIoltaModal';

const Ioltas = (props) => {
  const { caseData, user } = props;
  const [employees, setEmployees] = useState([]);
  const [showAddIoltaModal, setShowAddIoltaModal] = useState(undefined);
  const [showEditIoltaModal, setShowEditIoltaModal] = useState(undefined);
  const [reinitializing, setReinitializing] = useState(false);

  useEffect(() => {
    initEmployees();
  }, []);

  const initEmployees = async () => {
    if (!user || !user.userType) return;
    const configService = require('../store/config/service');
    const res = await configService.default.getUsers(); // change to employees only
    if (!res) {
    }
    if (res.error) {
    }
    if (res.result) {
      setEmployees(
        res.result.map((emp) => {
          return {
            label: `${getUserName(emp)} (${emp.designation || emp.userType})`,
            value: emp.id,
          };
        })
      );
    }
  };

  const openAddIoltaModal = (type) => {
    setShowAddIoltaModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowAddIoltaModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
      type: type ? type : '',
    });
  };
  
  const openEditIoltaModal = (type, ioltaId) => {
    setShowEditIoltaModal({
      caseData: caseData ? caseData : undefined,
      onClose: () => {
        setShowEditIoltaModal(undefined);
      },
      user: user ? user : {},
      employees: employees ? employees : {},
      type: type ? type : '',
      ioltaId: ioltaId ? ioltaId : '',
    });
  };

  const reinitialize = (bool = true) => {
    setReinitializing(bool);
  };

  return (
    <div className="w-100">
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="mb-0">IOLTA</h4>
        <div className="flex-one"></div>
        <div>
          <button
            className="btn btn-sm btn-outline-success shadow-sm ms-2"
            style={{ width: 'max-content' }}
            onClick={() => openAddIoltaModal('deposit')}
          >
            Add New Deposit<i className="bi bi-plus ms-1"></i>
          </button>
          <button
            className="btn btn-sm btn-outline-success shadow-sm ms-2"
            style={{ width: 'max-content' }}
            onClick={() => openAddIoltaModal('transfer')}
          >
            Add New Transfer<i className="bi bi-plus ms-1"></i>
          </button>
        </div>
      </div>
      <div className="w-100">
        <IoltaList
          caseId={(caseData || {}).id}
          caseData={caseData}
          user={user}
          reinitialize={reinitialize}
          reinitializing={reinitializing}
          openEditIoltaModal={(type, ioltaId) => openEditIoltaModal(type, ioltaId)}
        />

        <AddIoltaModal
          visible={!!showAddIoltaModal}
          caseData={showAddIoltaModal && showAddIoltaModal.caseData}
          onClose={showAddIoltaModal && showAddIoltaModal.onClose}
          user={showAddIoltaModal && showAddIoltaModal.user}
          employees={showAddIoltaModal && showAddIoltaModal.employees}
          type={showAddIoltaModal && showAddIoltaModal.type}
          reinitialize={reinitialize}
        />

        <EditIoltaModal
          visible={!!showEditIoltaModal}
          caseData={showEditIoltaModal && showEditIoltaModal.caseData}
          onClose={showEditIoltaModal && showEditIoltaModal.onClose}
          user={showEditIoltaModal && showEditIoltaModal.user}
          employees={showEditIoltaModal && showEditIoltaModal.employees}
          type={showEditIoltaModal && showEditIoltaModal.type}
          ioltaId={showEditIoltaModal && showEditIoltaModal.ioltaId}
          reinitialize={reinitialize}
        />
      </div>
    </div>
  );
};

export default Ioltas;
