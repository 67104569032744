import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  TICKLER_STATUSES,
  VISIBLE_APPOINTMENT_COLS,
  VISIBLE_TICKLER_COLS,
} from '../constants/constants';
import TicklerCell from './TicklerCell';
import Input from './common/Input';
import Loading from './Loading';
import Select from './common/Select';
import MyPagination from './common/MyPagination';
import NoEntry from './common/NoEntry';

const TicklerList = (props) => {
  const { user, caseId, reinitialize, reinitializing, openEditTicklerModal } =
    props;

  const [ticklers, setTicklers] = useState([]);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState('');
  const [dataKeys, setDataKeys] = useState([]);
  const [ticklerStatus, setTicklerStatus] = useState('pending');
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  // useEffect(() => {
  //   initDataKeys(ticklers);
  // }, [ticklers]);

  useEffect(() => {
    initPage(reinitializing, caseId, user);
  }, [reinitializing, caseId, user]);

  const initPage = (reinitializing, caseId, user) => {
    if (reinitializing) {
      reinitialize(false);
      // setting things back to empty before reinitializing
      setTicklers([]);
      setPageCount(1);
      setSearchQuery('');
      // setting page to activate reinitialization
      setPage(1);
      initTicklers(1, caseId, user, '');
      initPageCount(caseId, user, '');
    }
  };

  useEffect(() => {
    initTicklers(page, caseId, user, searchQuery);
  }, [page, caseId, user, searchQuery, ticklerStatus]);

  useEffect(() => {
    initPageCount(caseId, user, searchQuery);
  }, [caseId, user, searchQuery, ticklerStatus]);

  const initPageCount = async (caseId, user, searchQuery) => {
    if (!user || !user.id) return;
    if (!caseId || !caseId.length) return;
    const configService = require('../store/config/service');
    const res = ticklerStatus === 'pending' ? await configService.default.getTicklersPageCount(
      caseId,
      searchQuery
    ) : await configService.default.getCompletedTicklersPageCount(
      caseId,
      searchQuery
    );
    if (!res) {
    }
    if (res.error) {
      setPageCount(1);
    }
    if (res.result && res.result.count) {
      setPageCount(res.result.count);
    }
  };

  const initTicklers = async (page, caseId, user, searchQuery) => {
    try {
      if (!user || !user.id || !caseId || !caseId.length || !page) return;
      setLoading(true);
      const configService = require('../store/config/service');
      const res = ticklerStatus === 'pending' ? await configService.default.getTicklers(
        caseId,
        page,
        searchQuery
      ) : await configService.default.getCompletedTicklers(
        caseId,
        page,
        searchQuery
      );
      if (!res) {
      }
      if (res.error) {
      }
      if (res.result) {
        // console.log('ticklers --', res.result);
        setTicklers(res.result);
        initDataKeys(res.result);
        initPageCount(page, caseId, user, searchQuery);
        setLoading(false);
      }
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const initDataKeys = (ticklers) => {
    if (!ticklers || !ticklers.length) return;
    let newKeys = Object.keys(ticklers[0]).filter((k) =>
      VISIBLE_TICKLER_COLS.includes(k)
    ).sort();
    return setDataKeys(newKeys);
  };

  const viewTickler = (ticklerId) => {
    if (!ticklerId || !ticklerId.length) return;
    history.push(`/ticklers/${ticklerId}`);
  };

  const keyToLabelMapping = {
    type: 'Tickler Type',
    dueDate: 'Due Date',
    assignedTo: 'Assigned To',
    createdBy: 'Created By',
    createdAt: 'Creation Date',
    desc: 'Description',
  };

  return (
    <div className="tickler-card pb-2">
      {/* <div className="card-head pb-0 d-flex justify-content-start align-items-center">
        <div className="d-flex justify-content-between align-items-center w-100">
          
        </div>
      </div> */}

      <div className="d-flex justify-content-start align-items-baseline px-2">
        <MyPagination page={page} setPage={setPage} pageCount={pageCount} />
        <div className="d-flex justify-content-start align-items-center w-100 px-3">
          <div className="max-width-24rem">
            <input
              className="form-control"
              placeholder={'type something..'}
              text={'text'}
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="d-flex justify-content-end align-items-baseline">
          <div className="max-width-24rem">
            <Select
              placeholder={'select status'}
              value={ticklerStatus}
              options={TICKLER_STATUSES}
              onChange={(e) => {
                setTicklerStatus(e.target.value);
                handleError('ticklerStatus', '');
              }}
              err={errors.ticklerStatus || ''}
            />
          </div>
        </div>
      </div>

      <div className="card-body p-0 m-0 w-100 d-flex flex-column justify-content-start align-items-start overflow-hidden">
        {dataKeys && dataKeys.length ? (
          <div className="table-head-row">
            <div className="table-head-col flex-pt5">S No.</div>
            {dataKeys.map((dataKey) => (
              <div key={dataKey} className="table-head-col">
                {keyToLabelMapping[dataKey]}
              </div>
            ))}
          </div>
        ) : (
          <React.Fragment />
        )}
        {loading ? (
          <Loading />
        ) : ticklers && ticklers.length ? (
          ticklers.map((ticklerObj, i) => (
            <div key={i} className="table-row" onClick={() => openEditTicklerModal(ticklerObj.id)}>
              <div className="table-col mt-2 flex-pt5">
                <span className="fw-light size14">
                  {(page - 1) * 20 + i + 1}.
                </span>
              </div>
              {Object.keys(ticklerObj).sort()
                .filter((item) => VISIBLE_TICKLER_COLS.includes(item))
                .map((dataKey) => (
                  <div key={dataKey} className="table-col">
                    <TicklerCell dataKey={dataKey} data={ticklerObj[dataKey]} />
                  </div>
                ))}
            </div>
          ))
        ) : (
          <NoEntry />
        )}
      </div>
    </div>
  );
};

export default TicklerList;
