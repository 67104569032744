export const baseUrl = 'https://api.alologin.com';
const apiPath = '/api/v1';

const basePrefix = baseUrl + apiPath;

const { getAuthToken } = require('./StorageService');

const getHeaders = () => {
    const headers = {
        'Content-Type': 'application/json'
    };

    const authToken = getAuthToken();
    if (authToken) {
        headers.Authorization = authToken;
    }

    return headers;
};

export const GET = (path) => {
    return new Promise((resolve, reject) => {
        fetch(basePrefix + path, {
            method: 'GET',
            headers: getHeaders()
        })
            .then((response) => response.json())
            .then(resolve)
            .catch(reject);
    });
};

export const POST = (path, data) => {
    return new Promise((resolve, reject) => {
        fetch(basePrefix + path, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(data)
        })
            .then((response) => {
                return response.json();
            })
            .then(resolve)
            .catch(reject);
    });
};

export const POSTFILE = (path, file) => {
    const formData = new FormData();
    formData.append('file', file);
    return new Promise((resolve, reject) => {
        fetch(basePrefix + path, {
            method: 'POST',
            headers: { ...getHeaders(), 'Content-Type': 'multipart/form-data' },
            body: formData
        })
            .then((response) => {
                return response.json();
            })
            .then(resolve)
            .catch(reject);
    });
};

export const PUT = (path, data) => {
    return new Promise(async (resolve, reject) => {
        fetch(basePrefix + path, {
            method: 'PUT',
            headers: getHeaders(),
            body: JSON.stringify(data)
        })
            .then((response) => {
                return response.json();
            })
            .then(resolve)
            .catch(reject);
    });
};

export const DELETE = (path, data) => {
    return new Promise(async (resolve, reject) => {
        fetch(basePrefix + path, {
            method: 'DELETE',
            headers: getHeaders(),
            body: JSON.stringify(data)
        })
            .then((response) => {
                return response.json();
            })
            .then(resolve)
            .catch(reject);
    });
};
