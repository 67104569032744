import React from 'react';

const Input = (props) => {
  const {
    label,
    placeholder,
    type,
    textContentType,
    autoCapitalize,
    autoComplete,
    value,
    err,
    onChange,
    min,
    max,
    disabled = false,
    classes = undefined,
  } = props;

  return (
    <div className={`my-input-group ${classes ? classes : ''}`}>
      {label && <label className="form-label">{label}</label>}
      <input
        className="form-control"
        disabled={disabled}
        label={label}
        placeholder={placeholder}
        type={type}
        textcontenttype={textContentType}
        autoCapitalize={autoCapitalize}
        autoComplete={autoComplete}
        value={value}
        min={min}
        max={max}
        onChange={onChange}
      />
      {err && (
        <div className="form-error">
          <small className="form-error-text">{err}</small>
        </div>
      )}
    </div>
  );
};

export default Input;
